import {HttpClient} from '@angular/common/http';
import {Component, OnInit, TemplateRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ngxLoadingAnimationTypes} from 'ngx-loading';
import {API_URL} from '../../../../../Global_API_URL';
import {Location} from '@angular/common';

@Component({
  selector: 'app-view-airline-promotion',
  templateUrl: './view-airline-promotion.component.html',
  styleUrls: ['./view-airline-promotion.component.sass'],
})
export class ViewAirlinePromotionComponent implements OnInit {
  constructor(private router: Router, private http: HttpClient, private route: ActivatedRoute, private locationService: Location) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const id = +params['id']; // The '+' converts the string to a number
      if (id) {
        this.getAirlinePromotionById(id);
      }
    });
  }

  isLoading: boolean = false;
  public loadingTemplate: TemplateRef<any>;
  public readonly ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  selected = '1';
  promotion: any;
  displayedColumns: string[] = ['productType', 'consignmentType', 'consignmentSubtype', 'type', 'origin', 'destination', 'nRate', 'above45', 'above100', 'above300', 'above500', 'Above1000'];

  selectedDays: string[] = [];

  formatTime(timeInMinutes: number): string {
    if (!timeInMinutes && timeInMinutes !== 0) {
      return '';
    }
    const hours = Math.floor(timeInMinutes / 60);
    const minutes = timeInMinutes % 60;
    const formattedHours = ('0' + hours).slice(-2);
    const formattedMinutes = ('0' + minutes).slice(-2);
    return `${formattedHours}:${formattedMinutes}`;
  }

  toggleEveryday(checked: boolean) {
    if (checked) {
      this.selectedDays = ['0', '1', '2', '3', '4', '5', '6']; // Setting other days
    } else {
      this.selectedDays = [];
    }
  }
  getAirlinePromotionById(id: number): void {
    this.isLoading = true;
    this.http.get(API_URL + `/airline-promotions/plan/${id}`).subscribe(
        (data: any) => {
          this.promotion = data.data.content[0];
          // console.log("datarewrwere", this.promotion) // Adjusting the structure based on the provided JSON
          this.isLoading = false;
        },
        (error) => {
          console.error('Error fetching promotion details:', error);
          this.isLoading = false;
        },
    );
  }


  goBackToAirlinePricingList(): void {
    this.locationService.back();
  }
}
