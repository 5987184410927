<!-- <ngx-loading [show]="isLoading"
    [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: '#0000ff', secondaryColour: '#00f', backdropBorderRadius: '3px'}"
    [template]="loadingTemplate"></ngx-loading>

<div class="header-container">
    <button mat-icon-button (click)="goBackToAirlinePricingList()">
        <span class="material-icons">arrow_back</span>
    </button>&nbsp;View Airline Promotions
</div>

<mat-card>
    <div class="custom-container">
        <div>
            <strong>Airline Name:</strong> {{ promotion?.airline?.name }}
        </div>
        <div>
            <strong>Start Time:</strong> {{ promotion?.startTime | date: 'shortTime' }}
        </div>
        <div>
            <strong>End Time:</strong> {{ promotion?.endTime | date: 'shortTime' }}
        </div>
        <div>
            <strong>Valid From:</strong> {{ promotion?.startDate | date: 'dd-MM-yyyy' }}
        </div>
        <div>
            <strong>Valid Upto:</strong> {{ promotion?.endDate | date: 'dd-MM-yyyy' }}
        </div>
        <div>
            <strong>Frequency:</strong> {{ promotion?.frequency }}
        </div>
        <div>
            <table mat-table [dataSource]="promotion?.airlinePromotionList">
                <ng-container matColumnDef="productType">
                    <th mat-header-cell *matHeaderCellDef style="padding: 10px;">Product</th>
                    <td mat-cell *matCellDef="let item">{{ item?.productType?.name }}</td>
                </ng-container>
                <ng-container matColumnDef="consignmentType">
                    <th mat-header-cell *matHeaderCellDef style="padding: 10px;">CN Type</th>
                    <td mat-cell *matCellDef="let item">{{ item?.consignmentType?.name || 'ALL' }}</td>
                </ng-container>
                <ng-container matColumnDef="consignmentSubtype">
                    <th mat-header-cell *matHeaderCellDef style="padding: 10px;">CN Subtype</th>
                    <td mat-cell *matCellDef="let item">{{ item?.consignmentSubtype?.name || 'ALL' }}</td>
                </ng-container>
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef style="padding: 10px;">Type</th>
                    <td mat-cell *matCellDef="let item">{{ item?.type }}</td>
                </ng-container>
                <ng-container matColumnDef="origin">
                    <th mat-header-cell *matHeaderCellDef style="padding: 10px;">Origin</th>
                    <td mat-cell *matCellDef="let item">{{ item?.origin?.city?.name || 'ALL'}}</td>
                </ng-container>
                <ng-container matColumnDef="destination">
                    <th mat-header-cell *matHeaderCellDef style="padding: 10px;">Destination</th>
                    <td mat-cell *matCellDef="let item">{{ item?.destination?.city?.name || 'ALL' }}</td>
                </ng-container>
                <ng-container matColumnDef="nRate">
                    <th mat-header-cell *matHeaderCellDef style="padding: 10px;">nRate</th>
                    <td mat-cell *matCellDef="let item">{{ item?.airlineTarrifNRate || 'N/A' }}</td>
                </ng-container>
                <ng-container matColumnDef="above45">
                    <th mat-header-cell *matHeaderCellDef style="padding: 10px;">+45</th>
                    <td mat-cell *matCellDef="let item">{{ item?.airlineTarrifAbove45 || 'N/A' }}</td>
                </ng-container>
                <ng-container matColumnDef="above100">
                    <th mat-header-cell *matHeaderCellDef style="padding: 10px;">+100</th>
                    <td mat-cell *matCellDef="let item">{{ item?.airlineTarrifAbove100 || 'N/A' }}</td>
                </ng-container>
                <ng-container matColumnDef="above300">
                    <th mat-header-cell *matHeaderCellDef style="padding: 10px;">+300</th>
                    <td mat-cell *matCellDef="let item">{{ item?.airlineTarrifAbove300 || 'N/A' }}</td>
                </ng-container>
                <ng-container matColumnDef="above500">
                    <th mat-header-cell *matHeaderCellDef style="padding: 10px;">+500</th>
                    <td mat-cell *matCellDef="let item">{{ item?.airlineTarrifAbove500 || 'N/A' }}</td>
                </ng-container>
                <ng-container matColumnDef="Above1000">
                    <th mat-header-cell *matHeaderCellDef style="padding: 10px;">+1000</th>
                    <td mat-cell *matCellDef="let item">{{ item?.airlineTarrifAbove1000 || 'N/A' }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</mat-card> -->

<ngx-loading [show]="isLoading"
  [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: '#0000ff', secondaryColour: '#00f', backdropBorderRadius: '3px'}"
  [template]="loadingTemplate"></ngx-loading>


<mat-card style="width: 100%;">

  <mat-card-title>
    <div style="display: flex">
      <button mat-icon-button (click)="goBackToAirlinePricingList()">
        <span class="material-icons" style="margin-top: -15px">arrow_back</span>
      </button>
      <div class="h4"> <b style="color: blue;">VIEW AIRLINE PROMOTION</b> </div>
    </div>
  </mat-card-title>

  <div class="custom-container">
    <div style="font-size: medium; text-align: center;" class="m-auto">
      <p>Airline</p>
    </div>
    <div>
      <mat-form-field appearance="fill" style="margin-top: -8px">
        <mat-label></mat-label>
        <mat-select name="airlines" [(value)]="selected" disabled>
          <mat-option value="1">
            <strong>{{ promotion?.airline.name }}</strong>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="custom-container">
    <div style="font-size: medium; text-align: center;" class="mt-3">Flights</div>
    <div style="display: flex; flex-direction: column">
      <textarea style="width: 100%; background: white; padding: 8px; margin-top: 10px" disabled>
        {{ promotion?.flights }}
      </textarea>
    </div>
  </div>

  <br>
  <br>

  <div style="display: flex; gap: 1rem; align-items: center; margin-left: 1%">
    <div style="font-size: medium; text-align: center; margin-top: -35px">Time</div>
    <div style="margin-top: -2%">
      <mat-form-field appearance="standard">
        <mat-label>Start Time (HH:MM)</mat-label>
        <input [disabled]="true" type="time" placeholder="Type" aria-label="Start Time" matInput
          [value]="promotion?.startTime ? formatTime(promotion.startTime) : ''">
      </mat-form-field>
    </div>
    <div style="margin-top: -2%">
      <mat-form-field appearance="standard">
        <mat-label>End Time (HH:MM)</mat-label>
        <input [disabled]="true" type="time" placeholder="Type" aria-label="End Time" matInput
          [value]="promotion?.endTime ? formatTime(promotion.endTime) : ''">
      </mat-form-field>
    </div>
    <div style="margin-top: -4%">
      <mat-checkbox [checked]="promotion?.frequency === '0123456'" (change)="toggleEveryday($event.checked)" disabled
        style="font-size: smaller;">Everyday
      </mat-checkbox>
      <br>
      <mat-button-toggle-group multiple disabled>
        <mat-button-toggle value="0"
          [style.background-color]="promotion?.frequency && promotion?.frequency.includes('0') ? 'lightblue' : 'inherit'">
          Sunday
        </mat-button-toggle>
        <mat-button-toggle value="1"
          [style.background-color]="promotion?.frequency && promotion?.frequency.includes('1') ? 'lightblue' : 'inherit'">
          Monday
        </mat-button-toggle>
        <mat-button-toggle value="2"
          [style.background-color]="promotion?.frequency && promotion?.frequency.includes('2') ? 'lightblue' : 'inherit'">
          Tuesday
        </mat-button-toggle>
        <mat-button-toggle value="3"
          [style.background-color]="promotion?.frequency && promotion?.frequency.includes('3') ? 'lightblue' : 'inherit'">
          Wednesday
        </mat-button-toggle>
        <mat-button-toggle value="4"
          [style.background-color]="promotion?.frequency && promotion?.frequency.includes('4') ? 'lightblue' : 'inherit'">
          Thursday
        </mat-button-toggle>
        <mat-button-toggle value="5"
          [style.background-color]="promotion?.frequency && promotion?.frequency.includes('5') ? 'lightblue' : 'inherit'">
          Friday
        </mat-button-toggle>
        <mat-button-toggle value="6"
          [style.background-color]="promotion?.frequency && promotion?.frequency.includes('6') ? 'lightblue' : 'inherit'">
          Saturday
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

  </div>

  <div class="custom-container" style="display: flex; gap: 4rem; flex-wrap: wrap; margin-top: 20px; margin-left: 1.1%">
    <div style="display: flex; gap: 1rem">
      <div style="font-size: medium; text-align: center;" class="mt-3">Valid From</div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>{{ promotion?.startDate | date: 'dd/MM/yyyy' }}</mat-label>
          <input matInput [matDatepicker]="startDate" disabled>
          <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
          <mat-datepicker timezone="UTC" #startDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div style="display: flex; gap: 1rem">
      <div style="font-size: medium; text-align: center;" class="mt-3">Valid Upto</div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>{{ promotion?.endDate | date: 'dd/MM/yyyy' }}</mat-label>
          <input matInput [matDatepicker]="endDate" disabled>
          <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

  </div>
  <br><br><br>
  <div>
    <table class="table-responsive"
      style="table-layout: fixed; width: 100%; border-collapse: collapse; border: 1px solid lightgray;">

      <colgroup>
        <col>
        <col style="width: 10%">
        <col style="width: 14%">
        <col style="width: 14%">
        <col style="width: 10%">
        <col style="width: 12%">
        <col style="width: 12%">
        <col style="width: 7%">
        <col style="width: 7%">
        <col style="width: 7%">
        <col style="width: 7%">
        <col style="width: 7%">
        <col style="width: 7%">
        <col style="width: 10%">
        <!--            <col style="width: 10%">-->
      </colgroup>
      <tr>
        <th style="width: 3%; text-align: center; border: 1px solid lightgray;"> #</th>
        <th style="text-align: center; border: 1px solid lightgray;"> Product </th>
        <th style="text-align: center; border: 1px solid lightgray;"> CN Type </th>
        <th style="text-align: center; border: 1px solid lightgray;"> CN Sub-type </th>
        <th style="text-align: center; border: 1px solid lightgray;"> Type </th>
        <th style="text-align: center; border: 1px solid lightgray;"> Origin </th>
        <th style="text-align: center; border: 1px solid lightgray;"> Destination </th>
        <th style="text-align: center; border: 1px solid lightgray;"> N Rate</th>
        <th style="text-align: center; border: 1px solid lightgray;"> +45</th>
        <th style="text-align: center; border: 1px solid lightgray;"> +100</th>
        <th style="text-align: center; border: 1px solid lightgray;"> +300</th>
        <th style="text-align: center; border: 1px solid lightgray;"> +500</th>
        <th style="text-align: center; border: 1px solid lightgray;"> +1000</th>
        <th style="text-align: center; border: 1px solid lightgray;"> Recommend</th>
      </tr>
      <!-- <br> -->

      <tr *ngFor="let promotion of promotion?.airlinePromotionList; let i = index">
        <td style="text-align: center; border: 1px solid lightgray; word-wrap: break-word;">{{ i + 1 }}</td>
        <td style="text-align: center; border: 1px solid lightgray; word-wrap: break-word">{{
          promotion?.productType?.name || 'ALL' }}</td>
        <td style="text-align: center; border: 1px solid lightgray; word-wrap: break-word">{{
          promotion?.consignmentType?.name || 'ALL' }}</td>
        <td style="text-align: center; border: 1px solid lightgray; word-wrap: break-word">{{
          promotion?.consignmentSubtype?.name || 'ALL' }}</td>
        <td style="text-align: center; border: 1px solid lightgray; word-wrap: break-word">{{ promotion?.type || 'ALL'
          }}</td>
        <td style="text-align: center; border: 1px solid lightgray; word-wrap: break-word; word-break: break-all">{{
          promotion?.origin?.city?.name || 'ALL' }}</td>
        <td style="text-align: center; border: 1px solid lightgray; word-wrap: break-word; word-break: break-all">{{
          promotion?.destination?.city?.name || 'ALL' }}</td>
        <td style="text-align: center; border: 1px solid lightgray; word-wrap: break-word">{{
          promotion?.airlineTarrifNRate || 'N/A' }}</td>
        <td style="text-align: center; border: 1px solid lightgray; word-wrap: break-word">{{
          promotion?.airlineTarrifAbove45 || 'N/A' }}</td>
        <td style="text-align: center; border: 1px solid lightgray;word-wrap: break-word">{{
          promotion?.airlineTarrifAbove100 || 'N/A' }}</td>
        <td style="text-align: center; border: 1px solid lightgray; word-wrap: break-word">{{
          promotion?.airlineTarrifAbove300 || 'N/A' }}</td>
        <td style="text-align: center; border: 1px solid lightgray;word-wrap: break-word">{{
          promotion?.airlineTarrifAbove500 || 'N/A' }}</td>
        <td style="text-align: center; border: 1px solid lightgray; word-wrap: break-word">{{
          promotion?.airlineTarrifAbove1000 || 'N/A' }}</td>
        <td style="text-align: center; border: 1px solid lightgray; padding: 0;">
          <section>
            <mat-checkbox [checked]="promotion?.recommended" disabled></mat-checkbox>
          </section>
          <!--                    <input type="checkbox" style="color: blue" [checked]="promotion?.recommended">-->
        </td>
      </tr>
    </table>
  </div>

</mat-card>