export interface ConsignmentType {
  id: number,
  name: String,
  details: String,
  createdAt: any,
  updatedAt: any,
  active: Boolean
}

export interface ConsignmentSubtypes {
  id: number;
  name: string
}

export interface DimentionUnits {
  id: number,
  name: string
}

export interface WeightUnits {
  id: number,
  name: string
}

export interface Currency {
  data: [
    id: number,
    name: string,
    alias: string
  ]
}

export interface Package {
  id: number,
  length: number,
  breadth: number,
  height: number,
  weight: number,
  total_weight: number,
  packageNo: number,
  volume_weight: number,
  chargeable_weight: number,
  totalvolumeweight_per_pack : number
}
export interface MawbPackage {
  length: number,
  breadth: number,
  height: number,
  total_weight: number,
  packageNo: number,
  volume_weight: number,
  chargeable_weight: number,
  totalvolumeweight_per_pack : number,
  customs: number,
  DeclaredValue: number
}

export interface PackageJSON {
  packageNo: number,
  shipment_id: number,
  weight_per_package: number,
  length: number,
  breadth: number,
  height: number,
  total_weight: number,
  chargeable_weight: number
}

export interface Airlines{
  id: number,
  alias: String,
  createdAt: String,
  updatedAt: String,
  createdBy: String,
  updatedBy: String,
  iataCode: String,
  iataCodeMawb: String,
  name: String,
  isActive: true
}

export interface ConsignmentDetails {
  id: number,
  consignmentNo: String,
  consignment_type: number,
  consignment_subtype:number,
  dimention_unit: number,
  weight_unit: number,
  currency: number,
  package: number,
  total_weight: number,
  total_packages: number,
  origin_location: any,
  destination_location: any,
  expected_time: number,
  selected_plan: number,
  total_chargeable_weight: number,
  awb_no: number,
  e_waybill_no: number,
  sender_id: number,
  recever_id: number
}

export interface route {
  source: string,
  destination: string,
  transit_time: string,
  drop_time: string,
  pickup_time: string,

}

export interface Plans {
  id: number,
  source: string,
  destination: string,
  pickup_time: string,
  drop_time: string,
  total_transit_time: string,
  tatal_cost: number
}

export interface Countries {
  id: number,
  name: String
}

export interface Customer {
  id: number,
  first_name: String,
  last_name: String,
  email: String,
  phone_1: String,
  customer_type_id: number,
  address: String,

}


export interface ConsignmentDocumentType {
  id: number,
  name: String,
  description: String,
  is_mandatory: Boolean,
  upload_later: Boolean
}

export interface Receiver {
  id: number,
  firstName: String,
  lastName: String,
  address: String,
  city: number,
  zipCode: String,
  phone1: string,
  email1: String
}


export interface States {
  'id': number,
  'name': String,
  'country': Countries
}

export interface Countries {
  'id': number,
  'name': String
}


export interface Airport {
  id: number,
  name: String,
  address: String,
  alias: String,
  latitude: number,
  longitude:number,
  isActive: true,
  iataCode: String,
  icaoCode: String,
  createdAt: String,
  createdBy: String,
  updatedAt: String,
  updatedBy: String,
  city: Cities,
  ib_active : true,
  ob_active : true
}

export interface Cities {

  'id': number,
  'name': String,
  'latitude': number,
  'longitude': number,
  'state_id': States,
  'supportedAirportId':Airport,
  'obAirport':Airport,
  'ibAirport':Airport

}

export interface Airportib {
  id: number,
  name: String,
  address: String,
  alias: String,
  latitude: number,
  longitude:number,
  isActive: true,
  iataCode: String,
  icaoCode: String,
  createdAt: String,
  createdBy: String,
  updatedAt: String,
  updatedBy: String,
  city: Cities
}

export interface Airportob {
  id: number,
  name: String,
  address: String,
  alias: String,
  latitude: number,
  longitude:number,
  isActive: true,
  iataCode: String,
  icaoCode: String,
  createdAt: String,
  createdBy: String,
  updatedAt: String,
  updatedBy: String,
  city: Cities
}

export interface CustomerList{
    'id': number,
    'firstName': String,
    'address_1': String,
    'city_1': any,
    'createdAt': String,
    'creditPeriod': String,
    'creditValue': number,
    'email_1': String,
    'isCreditExtended': false,
    'isKycApproved': false,
    'kycApprovedAt': false,
    'lastName': String,
    'phone_1': String,
    'updatedAt': String,
    'createdBy': number,
    'customerTypeId': number,
    'updatedBy': number,
    'kycApprovedBy': number,
    'isBusinessAccount': false,
    'pan': String,
    'gst': String,
    'creditOutstanding': number,
    'zip_code': String,
    'otp_varified': String,
    'email1': String,
    'phone1': String,
    'address1': String
}
export interface individuallist{
  id: null,
  firstName: '',
  middleName: '',
  lastName: '',
  avatarUrl: '',
  email: '',
  emailForMasterGST: '',
  phone: '',
  whatsapp: '',
  designation: null,
  employmentNo: null,
  billingAddress: {
    pincode: {
      pin_number: ''
    },
    address: ''
  },
  isSuperUser: null,
  userName: '',
  passwordHash: null,
  lastLoggedInAt: null,
  lastLoggedInIp: null,
  aadhaarNumber: null,
  drivingLicense: null,
  gstNumber: null,
  panNumber: null,
  gstDetails: null,
  panDetails: null,
  aadhaarDetails: null,
  isActive: true,
  createdAt: null,
  updatedAt: null,
  createdBy: null,
  updatedBy: null,
}

export interface OrderType{
  'id': number,
  'name': String
}

export class ConsignmentPaperWorkDTO {
  id: number;
  contentDisposition: string;
  fileName: string;
  fileType: string;
  fileSize: number;
  fileLocation: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: number;
  updatedBy: number;
  isActive: boolean;
}

export class CSVFileFormatDTO {
  id: number;
  contentDisposition: string;
  fileName: string;
  fileType: string;
  fileSize: number;
  fileLocation: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: number;
  updatedBy: number;
  isActive: boolean;
}
