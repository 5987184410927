import {Cities, States} from './../../golbalInterfaces';
import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {API_URL} from '../../../../../Global_API_URL';
import Swal from 'sweetalert2';
import {getCities, getCitiesById, getCountries, getStates} from '../../getCities.js';
import {MatPaginator} from '@angular/material/paginator';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialog} from '../../Components/confirmDialog/confirmDialog.component';
import {AuthService} from '../../../auth.service';
import {Router} from "@angular/router";

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.sass'],
})
export class CustomersComponent implements OnInit {
  OfficesList

  filterUsersList = [];
  representativeList = [];
  states = getStates();
  countries = getCountries();
  cities = getCities();
  pincodes;
  customerList = this.getUserList();
  userDetails;
  displaytoAccountant = false;
  TOKEN;

  constructor(private matDialog: MatDialog, private authService: AuthService, private route: Router) {
    this.TOKEN = this.authService.getAuthToken();
  }

  async ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.customerlist = this.getCustomersList();
    if (this.userDetails.userTypes[0].name === 'Accountant') {
      this.displaytoAccountant = true;
    }
    this.filterscorporate();
    this.getOfficeLocations();
    this.states = await getStates();
    this.getAllCustomers();
  }

  getCities(id) {
    this.cities = getCitiesById(id);
  }


  async getUserList() {
    const TOKEN = this.authService.getAuthToken();
    let users;
    await fetch(API_URL + '/totalcustomers',
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      })

      // Converting received data to JSON
      .then((response) => response.json())
      .then((json) => {
        users = json;
      });
    return users;
  }

  filterParams = '';
  showFirstLastButtons;
  pageSizeOptions = [10, 30];
  pagenation = {
    col_name: 'id',
    page_size: 10,
    page_no: 0,
    sort_dir: 'DESC',
  }
  customerlist = null;


  filter() {
    this.filterParams = '?';
    if (this.corporatefilters.first_name != '' && this.corporatefilters.first_name != null) {
      this.filterParams = this.filterParams + 'first_name=' + this.corporatefilters.first_name + '&';
    }
    if (this.corporatefilters.cityid != '' && this.corporatefilters.cityid != null) {
      this.filterParams = this.filterParams + 'cityid=' + this.corporatefilters.cityid.id + '&';
    }
    if (this.corporatefilters.stateid != '' && this.corporatefilters.stateid != null) {
      this.filterParams = this.filterParams + 'stateid=' + this.corporatefilters.stateid.id + '&';
    }
    if (this.corporatefilters.email != null && this.corporatefilters.email != '') {
      this.filterParams = this.filterParams + 'email_1=' + this.corporatefilters.email + '&';
    }
    if (this.corporatefilters.phone != null && this.corporatefilters.phone != '') {
      this.filterParams = this.filterParams + 'phone_1=' + this.corporatefilters.phone + '&';
    }
    if (this.corporatefilters.status != 'all') {
      this.filterParams = this.filterParams + 'gstVerified=' + this.corporatefilters.status + '&';
    }
    this.filterParams = this.filterParams + 'page_no=' + this.pagenation.page_no + '&page_size=' + this.pagenation.page_size + '&col_name=' + this.pagenation.col_name + '&sort_dir=' + this.pagenation.sort_dir;
    return this.filterParams;
  }


  customers = []

  async getAllCustomers() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/totalcustomers/', {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })

      // Converting received data to JSON
      .then((response) => response.json())
      .then((json) => {
        this.customers = json;
      });
  }

  async getCustomersList() {
    const TOKEN = this.authService.getAuthToken();
    const api = '/CustomerList';
    const params = this.filter();
    await fetch(API_URL + api + params, {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })
      // Converting received data to JSON
      .then((response) => response.json())
      .then((json) => {
        this.customerlist = json;
      });
    return this.customerlist;
  }

  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public Representativeload = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public loadingTemplate: TemplateRef<any>;
  public config = {
    animationType: ngxLoadingAnimationTypes.none,
    primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour,
    tertiaryColour: this.primaryColour,
    backdropBorderRadius: '3px',
  };

  corporatefilters: any = {
    first_name: '',
    email: null,
    phone: null,
    stateid: '',
    cityid: '',
    status: 'all'
  }

  corporate = null;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: any;
  displayedColumns = ['companyName', 'email', 'phone', 'state', 'city', 'status', 'Active']

  find() {
    this.filterscorporate();
  }

  reset() {
    this.corporatefilters.cityid = '';
    this.corporatefilters.email = '';
    this.corporatefilters.first_name = '';
    this.corporatefilters.phone = '';
    this.corporatefilters.stateid = '';
    this.search = '';
    this.search1 = '';
    this.search2 = '';
    this.filterscorporate();
  }

  async filterscorporate() {
    const TOKEN = this.authService.getAuthToken();
    const params = this.filter();
    this.loading = true;
    const api = '/customerfilters';
    // this.filter()
    await fetch(API_URL + api + params, {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })

      // Converting received data to JSON
      .then((response) => response.json())
      .then((json) => {
        if (json.status == 400) {
          this.corporate = [];
        } else {
          this.corporate = json.data;
          this.filterUsersList = json.data.reverse();
          // Apply status filter locally
          if (this.corporatefilters.status !== 'all') {
            this.filterUsersList = this.filterUsersList.filter((user) => {
              return this.corporatefilters.status === 'verified' ? user.gstVerified : !user.gstVerified;
            });
          }
          // this.dataSource = new MatTableDataSource(json.data.reverse());
          this.dataSource = new MatTableDataSource(this.filterUsersList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      });
    this.loading = false;
    return this.corporate;
    // return loads;
  }

  async Deletedata(id) {
    const TOKEN = this.authService.getAuthToken();
    this.loading = true;
    const data = JSON.stringify({
      'is_active': 0,
    });
    await fetch(API_URL + '/cust_is_active/' + id,
      {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: data,
      })

      // Converting received data to JSON
      .then((response) => response.text());
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Customer Deactivated Successfully',

      timer: 5000,
    });
    // this.customerlist = this.getCustomersList()
    this.filterscorporate()
      // this.getAllCustomers()
      .catch((error) => console.error('error', error));

    this.loading = false;
  }

  DeleteCustomer(customer) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to deactivate ' + customer.firstName + ' ?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.loading = true;
        this.Deletedata(customer.id);
      }
    });
  }

  async Restoredata(id) {
    const TOKEN = this.authService.getAuthToken();
    const data = JSON.stringify({
      'is_active': 1,
    });
    await fetch(API_URL + '/cust_is_active/' + id,
      {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: data,
      })

      // Converting received data to JSON
      .then((response) => response.text());
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Customer activated Successfully',
      timer: 5000,
    });
    this.filterscorporate()
      .catch((error) => console.error('error', error));
  }

  ActivateCustomer(customer) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to activate ' + customer.firstName + ' ?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.loading = true;
        this.Restoredata(customer.id);
      }
    });
  }

  onKeyDown(event: KeyboardEvent) {
    // Allow backspace key to execute normally
    if (event.key === 'Backspace') {
      return;
    }
    // Allow only numbers
    if (isNaN(Number(event.key))) {
      event.preventDefault();
    }
  }

  search

  searchByCons(con) {
    this.dataSource = new MatTableDataSource(this.filterUsersList.filter((cons) => cons?.email_1?.toLowerCase().includes(con.toLowerCase())));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  search1

  searchByCons1(con) {
    this.dataSource = new MatTableDataSource(this.filterUsersList.filter((cons) => cons?.phone_1?.includes(con)));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  search2

  searchByCons2(con) {
    this.dataSource = new MatTableDataSource(this.filterUsersList.filter((cons) => cons?.firstName?.toLowerCase().includes(con.toLowerCase())));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  displayStateFn(state: States): String {
    return state?.name ? state?.name : '';
  }

  displayCityFn(city: Cities): String {
    return city?.name ? city?.name : '';
  }

  async getOfficeLocations() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/office_locations',
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      })
      .then((response) => response.json())
      .then((json) => {
        this.OfficesList = json.data;
      });
    return this.OfficesList;
  }

  gotoCreateCustomerPage() {
    this.route.navigate(["/customerCreate"])
  }

  gotoEditCustomerPage(id) {
    this.route.navigate(["/customerEdit/", id])
  }

  applyFilter(){
    this.filterscorporate();
  }

}

