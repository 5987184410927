import {ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { API_URL } from "../../../../../Global_API_URL";
import { ActivatedRoute, Router } from '@angular/router';
import { ContractService } from '../contract.service';
import { MatTableDataSource } from '@angular/material/table';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';
@Component({
  selector: 'app-region-prices',
  templateUrl: './region-prices.component.html',
  styleUrls: ['./region-prices.component.sass']
})
export class RegionPricesComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;

  constructor(private httpService: HttpService, private router: Router, private service: ContractService, private route: ActivatedRoute, private cdr: ChangeDetectorRef) { }

  regions: any[] = [];
  prices: any;
  origins: string[] = [];
  destinations: string[] = [];
  matrixData: any = {};
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['destination'];
  consignmentTypes: string[] = [];
  consignmentSubtypes: string[] = [];
  products: string[] = [];
  modes: string[] = [];
  selectedConsignmentType: string;
  selectedConsignmentSubtype: string;
  selectedProduct: string;
  selectedMode: string;

  ngOnInit(): void {
    // this.getRegions();
    // const id = this.route.snapshot.params['id'];
    // const priceType = this.route.snapshot.params['priceType'];
    // this.service.getContractualPricesByContractRegion(id, priceType).subscribe(data => {
    //   this.extractDropdownData(data);
    //   this.setDefaultSelections(data);
    //   this.prices = data;
    //   this.buildMatrix();
    // });
    this.getRegions();
    const id = this.route.snapshot.params['id'];
    const priceType = this.route.snapshot.params['priceType'];
    this.service.getContractualPricesByContractRegion(id, priceType).subscribe(data => {
      this.extractDropdownData(data);
      this.setDefaultSelections(data);
      this.prices = data;
      this.buildMatrix();
      this.cdr.detectChanges();
    });
    this.displayedColumns = ['origin', ...this.destinations];
  }

  getRegions() {
    this.httpService.get(API_URL + '/regions', null, null).subscribe(response => {
      this.regions = response.data;
      this.origins = this.regions.map(region => region.name);
      this.destinations = this.regions.map(region => region.name);
      this.displayedColumns.push(...this.origins);
      this.loading = false;
    });
  }

  goToContractPage() {
    this.router.navigate(['/contracts']);
  }

  setDefaultSelections(data) {
    if (data.data.content.length > 0 && data.data.content[0].regionPrices.length > 0) {
      const firstPrice = data.data.content[0].regionPrices[0].price;
      this.selectedConsignmentType = firstPrice.consignmentType.name;
      this.selectedConsignmentSubtype = firstPrice.consignmentSubtype.name;
      this.selectedProduct = firstPrice.productType.name;
      this.selectedMode = firstPrice.mode;
    }
  }

  extractDropdownData(data) {
    const types: Set<string> = new Set();
    const subtypes: Set<string> = new Set();
    const products: Set<string> = new Set();
    const modes: Set<string> = new Set();

    for (const content of data.data.content) {
      for (const regionPrice of content.regionPrices) {
        types.add(regionPrice.price.consignmentType.name);
        subtypes.add(regionPrice.price.consignmentSubtype.name);
        products.add(regionPrice.price.productType.name);
        modes.add(regionPrice.price.mode);
      }
    }

    this.consignmentTypes = Array.from(types);
    this.consignmentSubtypes = Array.from(subtypes);
    this.products = Array.from(products);
    this.modes = Array.from(modes);
  }

  onFilterChange() {
    this.buildMatrix();
  }

  // buildMatrix() {
  //   this.matrixData = {};

  //   for (const content of this.prices.data.content) {
  //     for (const regionPrice of content.regionPrices) {
  //       if (
  //         regionPrice.price.consignmentType.name === this.selectedConsignmentType &&
  //         regionPrice.price.consignmentSubtype.name === this.selectedConsignmentSubtype &&
  //         regionPrice.price.productType.name === this.selectedProduct &&
  //         regionPrice.price.mode === this.selectedMode
  //       ) {
  //         const origin = regionPrice.origin.name;
  //         const destination = regionPrice.destination.name;

  //         if (!this.matrixData[destination]) {
  //           this.matrixData[destination] = {};
  //         }

  //         this.matrixData[destination][origin] = regionPrice.price.perKg;
  //       }
  //     }
  //   }

  //   const matrixArray = this.destinations.map(destination => {
  //     const row: any = { destination: destination };
  //     for (const origin of this.origins) {
  //       row[origin] = this.matrixData[destination] ? this.matrixData[destination][origin] : '';
  //     }
  //     return row;
  //   });

  //   this.dataSource.data = matrixArray;
  // }

  buildMatrix() {
    this.matrixData = {};

    for (const content of this.prices.data.content) {
      for (const regionPrice of content.regionPrices) {
        if (
          regionPrice.price.consignmentType.name === this.selectedConsignmentType &&
          regionPrice.price.consignmentSubtype.name === this.selectedConsignmentSubtype &&
          regionPrice.price.productType.name === this.selectedProduct &&
          regionPrice.price.mode === this.selectedMode
        ) {
          const origin = regionPrice.origin.name;
          const destination = regionPrice.destination.name;

          if (!this.matrixData[origin]) {
            this.matrixData[origin] = {};
          }

          this.matrixData[origin][destination] = regionPrice.price.perKg;
        }
      }
    }

    const matrixArray = this.origins.map(origin => {
      const row: any = { origin: origin };
      for (const destination of this.destinations) {
        row[destination] = this.matrixData[origin] ? this.matrixData[origin][destination] : '';
      }
      return row;
    });

    this.dataSource.data = matrixArray;
  }

}
