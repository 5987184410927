import { LiveAnnouncer } from '@angular/cdk/a11y';
import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { API_URL } from '../../../../../Global_API_URL';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';
import { getCities } from '../../getCities.js';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { MatInput } from '@angular/material/input';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Cities } from '../../golbalInterfaces';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BillingService } from '../../../modules/billingInfo/service/billing.service';
import { BillingOfflineDialog, CostCenterOfflineDialog } from '../billing-info-page/billing-info-page.component';
import { MatDialog } from '@angular/material/dialog';
import { HideurlService } from 'src/app/shared/hideurl.service';
import { ConfirmDialog } from '../../Components/confirmDialog/confirmDialog.component';
import { AuthService } from '../../../auth.service';

const PrimaryWhite = '#fff';
const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';
const FILTERS_STORAGE_KEY = 'consignment_filters';
const PAGINATION_STORAGE_KEY = 'consignment_pagination';

@Component({
  selector: 'app-consignment-table',
  templateUrl: './consignment-table.component.html',
  styleUrls: ['./consignment-table.component.sass'],
  animations: [
    trigger('rotate', [
      state('inactive', style({
        transform: 'rotate(0)',
      })),
      state('active', style({
        transform: 'rotate(360deg)',
      })),
      transition('inactive <=> active', animate('500ms ease-out')),
    ]),
  ],
})
export class ConsignmentTableComponent implements OnInit {
  pipe: DatePipe;
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;
  search
  mode: string = '';
  id: string = '';
  constructor(private router: ActivatedRoute, private _liveAnnouncer: LiveAnnouncer, private http: HttpClient, private sanitizer: DomSanitizer, private modalService: NgbModal, public dialog: MatDialog, private route: Router, public commonApi: BillingService, private hideurl: HideurlService, private matDialog: MatDialog, private authService: AuthService) {
    this.authService.getAuthToken();
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Load saved filters whenever navigation ends
        this.loadSavedFilters();
      }
    });
    window.addEventListener('beforeunload', () => {
      localStorage.removeItem(FILTERS_STORAGE_KEY);
      localStorage.removeItem(PAGINATION_STORAGE_KEY);
    });
  }
  //
  filterParams = '';
  activestatus = false;
  searchText;
  userDetails;
  errorMessage: boolean;
  displaytoAdmin = false;
  displaytoSupervisor = false;
  displaytoProcessingAgent = false;
  displaytoAccountant = false;
  refreshing = false;
  pageview = 'table';
  customers = []
  airports = []
  sortedAirports
  pagenation = {
    col_name: 'id',
    page_size: 10,
    page_no: 0,
    sort_dir: 'DESC',
  }
  storedPage;
  pageSize: number
  city
  cities: Array<any> = getCities();
  consignment_id;
  sender_id = '';
  pickup_location: any = '';
  drop_location: any = '';
  is_coperate_booking = '';
  billing_and_cost = '';
  // image_1 = null;
  // image_2 = null;
  // image_3 = null;
  // image_4 = null;
  // POD = null;
  // largeImage: any
  // image1: File;
  // image2: File;
  // image3: File;
  // image4: File;
  // temperature_record_file: File;
  // pod: File;
  // data_logger_file: File;
  // other_documents_file: File;
  // vehicle_calibration_file: File;
  // selectedWeight: string = "kg";
  //
  // // filterForm1 = new FormGroup({
  // //   pickupDate: new FormControl()
  // // });
  //
  //
  //
  // // get fromDate1() { return this.filterForm.get('pickupDate').value; }
  // consignment_details = {
  //   id: null,
  //   consignmentType: null,
  //   weightUnit: 1,
  //   currency: 1,
  //   weightDivisor: null,
  //   consignmentNo: null,
  //   airlinesId: null,
  //   isStopover: null,
  //   totalTransitTime: null,
  //   senderIndividual: null,
  //   isIndividualCustomer: null,
  //   paymentCompletedAt: null,
  //   isXrayScreening: null,
  //   isXrayCertification: null,
  //   isDryIce: null,
  //   totalWeight: null,
  //   totalChargableWeight: null,
  //   planSelected: null,
  //   senderId: null,
  //   packages: [],
  //   receiverId: null,
  //   isDocumentUploaded: null,
  //   isInsured: false,
  //   totalShippingCost: null,
  //   paymentMode: null,
  //   isPaymentCompleted: null,
  //   isActive: null,
  //   createdAt: "",
  //   updatedAt: null,
  //   createdBy: null,
  //   updatedBy: null,
  //   totalNoOfPackages: null,
  //   destinationLocation: null,
  //   consignmentSubType: null,
  //   originLocation: null,
  //   dimensionuUnit: null,
  //   insuranceCoverage: 0,
  //   totalConsignmentValue: 0,
  //   shippingCost: 0,
  //   invoiceCreatedDate: "",
  //   invoiceReferenceNo: null,
  //   productType: null,
  //   remarks: null,
  //   tentative_pickup_time: null,
  //   required_temperature: null,
  //   package_type: null
  // }
  // changeable_packages = [
  //   {
  //     id: 1,
  //     length: null,
  //     breadth: null,
  //     height: null,
  //     weight_per_package: null,
  //     total_weight: null,
  //     package_no: null,
  //     volume_weight: null,
  //     chargeable_weight: null,
  //     totalvolumeweight_per_pack: null
  //   }
  // ]
  // weightDivisorList = [
  //   6000, 5000, 4500, 3600, 2700
  // ]
  // // async allPackCal() {
  // //   this.changeable_packages.forEach(element => {
  // //     this.eachPackageCharged(element.id);
  // //   });
  // // }
  // // async eachPackageCharged(id) {
  // //   this.consignment_details.totalNoOfPackages = 0;
  // //   this.consignment_details.totalWeight = 0;
  // //   this.consignment_details.totalChargableWeight = 0;
  // //   for (let index = 0; index < this.packages.length; index++) {
  // //     this.changeable_packages[index].total_weight = Number(this.changeable_packages[index].package_no) * this.changeable_packages[index].weight_per_package;
  // //     if (this.changeable_packages[index].id == id) {
  // //       this.changeable_packages[index].volume_weight = Math.ceil(Number(this.changeable_packages[index].length) * Number(this.changeable_packages[index].breadth) * Number(this.changeable_packages[index].height) / Number(this.consignment_details.weightDivisor));
  // //       // console.log(this.packages[index].volume_weight + "  This is volume weight")
  // //
  // //       if (this.changeable_packages[index].volume_weight > this.changeable_packages[index].weight_per_package) {
  // //
  // //         this.changeable_packages[index].chargeable_weight = Number(this.changeable_packages[index].volume_weight) * Number(this.changeable_packages[index].package_no);
  // //       }
  // //       else {
  // //         this.changeable_packages[index].chargeable_weight = this.changeable_packages[index].total_weight;
  // //       }
  // //     }
  // //     this.consignment_details.totalNoOfPackages = Number(this.consignment_details.totalNoOfPackages) + Number(this.changeable_packages[index].package_no);
  // //     this.consignment_details.totalWeight = this.consignment_details.totalWeight + Number(this.changeable_packages[index].total_weight);
  // //     this.consignment_details.totalChargableWeight = Math.ceil(this.consignment_details.totalChargableWeight + this.changeable_packages[index].chargeable_weight)
  // //   }
  // // }
  // // consignmentNo = 0;
  consignmentlists: any = {
    id: '',
    consignment_no: '',
    consignmentType: '',
    weightUnit: 1,
    currency: 1,
    weightDivisor: null,
    consignmentNo: '',
    airlinesId: '',
    isStopover: '',
    totalTransitTime: '',
    senderIndividual: '',
    isIndividualCustomer: '',
    paymentCompletedAt: '',
    isXrayScreening: '',
    isXrayCertification: '',
    isDryIce: '',
    totalWeight: '',
    totalChargableWeight: '',
    planSelected: '',
    senderId: '',
    receiverId: '',
    isDocumentUploaded: '',
    isInsured: false,
    totalShippingCost: '',
    paymentMode: '',
    isPaymentCompleted: '',
    isActive: '',
    createdAt: '',
    updatedAt: '',
    createdBy: '',
    updatedBy: '',
    totalNoOfPackages: '',
    destinationLocation: '',
    consignmentSubType: '',
    originLocation: '',
    dimensionuUnit: '',
    insuranceCoverage: '',
    totalConsignmentValue: '',
    shippingCost: '',
    invoiceCreatedDate: '',
    invoiceReferenceNo: '',
    productType: '',
    image1: '',
    image2: '',
    image3: '',
    image4: '',
    data_logger_file: '',
    vehicle_calibration_file: '',
    other_documents_file: '',
    temperature_record_file: '',
    pod: '',

  }
  // packages = [
  //   {
  //     id: 1,
  //     length: null,
  //     breadth: null,
  //     height: null,
  //     weight_per_package: null,
  //     total_weight: null,
  //     packageNo: null,
  //     volume_weight: null,
  //     chargeable_weight: null,
  //     totalvolumeweight_per_pack: null
  //   }
  // ]
  //
  // open(content5, img) {
  //   this.modalService.open(content5, {
  //     size: 'lg'
  //   });
  //   this.largeImage = img
  // }
  //
  //
  // addpackageviewchange(val, consignment) {
  //   this.consignment_id = consignment.id;
  //   this.getconsignmentById();
  //   // this.totalNoOfPackages=null;
  //   // this.totalWeight=null;
  //   // this.totalChargableWeight=null;
  //   this.totalNoOfPackages = consignment.totalNoOfPackages;
  //   this.totalWeight = consignment.totalWeight;
  //   this.totalChargableWeight = consignment.totalChargableWeight;
  //   let length = this.add_packages.length;
  //   for (let i = 0; i < length; i++) {
  //     this.add_packages.pop();
  //   }
  //   this.weightDivisor = consignment.weightDivisor;
  //   this.consignmentNo = consignment.consignmentNo;
  //   this.pageview = val;
  // }
  //
  //
  // viewchanges(val, element) {
  //
  //   this.consignment_id = element.id;
  //   if (this.consignment_id != 0) {
  //     this.getconsignmentById();
  //     // it stores all existing values of edit consignment
  //   }
  //   this.pageview = val;
  // }
  //
  // ivalue;
  // viewchange1(val, id) {
  //   this.ivalue = id
  //   if (val == "view") {
  //     this.viewconsignmentById(id);
  //     this.paginator.pageIndex = this.storedPage;
  //     // this.getimage1(id);
  //     // this.getimage2(id);
  //     // this.getimage3(id);
  //     // this.getimage4(id);
  //     // this.getpod(id);
  //   } else if (val == "table") {
  //
  //     if(this.mode == null){
  //       this.getconsignmentsList();
  //     }
  //     else{
  //       this.route.navigate(['/createinvoice']);
  //     }
  //   }
  //   // this.consignment_id=id;
  //   this.consignment_id = id;
  //   this.pageview = val;
  //
  // }
  // viewchange(val, id) {
  //   this.ivalue = id;
  //   if (val === 'view') {
  //     this.viewconsignmentById(id);
  //     // this.paginator.pageIndex = this.storedPage;
  //     // this.getimage1(id);
  //     // this.getimage2(id);
  //     // this.getimage3(id);
  //     // this.getimage4(id);
  //     // this.getpod(id);
  //   } else if (val == "table") {
  //     this.getconsignmentsList();
  //
  //   }
  //   // this.consignment_id=id;
  //   this.consignment_id = id;
  //   this.pageview = val;
  //
  // }
  //
  //
  // // backViewChange(val, id) {
  // //   this.ivalue = id;
  // //   this.consignment_id = id;
  // //   this.pageview = val;
  // //   if (val === "table") {
  // //     this.getconsignmentsList().then(() => {
  // //       if (this.paginator) {
  // //         // Set the paginator to the stored page index after a slight delay
  // //         setTimeout(() => {
  // //           this.paginator.pageIndex = this.storedPage;
  // //         });
  // //       }
  // //     });
  // //   }
  //
  // // }
  consignments: any;
  // consignmentlists="";
  showFirstLastButtons;
  pageSizeOptions = [10, 30];
  @ViewChild('fromInput', {
    read: MatInput,
  }) fromInput: MatInput;

  @ViewChild('toInput', {
    read: MatInput,
  }) toInput: MatInput;
  //
  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    // console.log(this.userDetails.userTypes[0].name);
    this.getconsignmentsList();
    this.getAllCustomers();
    this.getAirports();
    this.city = getCities();
    if (this.userDetails.userTypes[0].name === 'Admin') {
      this.displaytoAdmin = true;
    }
    if (this.userDetails.userTypes[0].name === 'Supervisor') {
      this.displaytoSupervisor = true;
    }
    if (this.userDetails.userTypes[0].name === 'Processing Agent') {
      this.displaytoProcessingAgent = true;
    }
    if (this.userDetails.userTypes[0].name === 'Accountant') {
      this.displaytoAccountant = true;
    }

    // this.router.params.subscribe(params => {
    //   this.mode = params['pageview']; // Retrieve the 'mode' parameter from the route
    //   this.id = params['id']; // Retrieve the 'id' parameter from the route
    //   if (this.mode === 'view' && this.id != null){
    //     this.viewchange('view', this.id);
    //   }
    // });

    const savedPageState = JSON.parse(localStorage.getItem('pageState')) || {};
    this.storedPage = savedPageState.pageIndex || 0;
    this.pageSize = savedPageState.pageSize || 10;

    this.loadSavedFilters();
    this.tablefilter();
  }

  applySavedFilters(savedFilters: any) {
    this.sender_id = savedFilters.sender_id;
    this.pickup_location = savedFilters.pickup_location;
    this.drop_location = savedFilters.drop_location;
    this.is_coperate_booking = savedFilters.is_coperate_booking;
    this.billing_and_cost = savedFilters.billing_and_cost;
  }

  saveFiltersAndPaginationToStorage() {
    const filters = {
      sender_id: this.sender_id,
      pickup_location: this.pickup_location,
      drop_location: this.drop_location,
      is_coperate_booking: this.is_coperate_booking,
      billing_and_cost: this.billing_and_cost,
    };
    localStorage.setItem(FILTERS_STORAGE_KEY, JSON.stringify(filters));
    localStorage.setItem(PAGINATION_STORAGE_KEY, JSON.stringify(this.pagenation));
  }

  loadSavedFilters() {
    const savedFilters = JSON.parse(localStorage.getItem(FILTERS_STORAGE_KEY));
    const savedPagination = JSON.parse(localStorage.getItem(PAGINATION_STORAGE_KEY));
    if (savedFilters) {
      this.applySavedFilters(savedFilters);
    }
    if (savedPagination) {
      this.pagenation = savedPagination;
    }
  }

  refresh() {
    this.refreshing = true; // set the refreshing variable to true to trigger the animation
    this.fromInput.value = '';
    this.toInput.value = '';
    this.is_coperate_booking = '';
    this.sender_id = '';
    this.pickup_location = '';
    this.drop_location = '';
    this.consignment_id = '';
    this.search = '';
    this.billing_and_cost = '';
    this.getconsignmentsList();
    this.saveFiltersAndPaginationToStorage();
    setTimeout(() => {
      this.refreshing = false; // set the refreshing variable back to false to stop the animation
    }, 500); // this timeout should match the animation duration in the trigger
  }
  //
  resetForm() {
    this.fromInput.value = '';
    this.toInput.value = '';
    this.is_coperate_booking = '';
    this.sender_id = '';
    this.pickup_location = '';
    this.drop_location = '';
    this.consignment_id = '';
    this.search = '';
    this.billing_and_cost = '';
    this.getconsignmentsList();
    this.saveFiltersAndPaginationToStorage();
  }
  // // async tablefilter() {
  // //   await this.getconsignmentsList()
  // //   const fromDateValue = this.filterForm.get('fromDate').value;
  // //   const toDateValue = this.filterForm.get('toDate').value;
  //
  // //   if (fromDateValue && toDateValue) {
  // //     const fDate = new Date(fromDateValue);
  // //     const tDate = new Date(toDateValue);
  //
  // //     // Set the toDate to the end of the day to include all records for the selected day
  // //     tDate.setDate(tDate.getDate() + 1);
  // //     tDate.setHours(23, 59, 59, 999);
  //
  // //     this.dataSource.data = this.consignmentlists.filter(d => {
  // //       const created_at = new Date(d.created_at);
  // //       return created_at >= fDate && created_at <= tDate;
  // //     });
  // //   }
  // // }

  // async tablefilter() {
  //   await this.getconsignmentsList();
  //   const fromDateValue = this.filterForm.get('fromDate').value;
  //   const toDateValue = this.filterForm.get('toDate').value;
  //   let filteredData = this.consignmentlists;
  //   if (fromDateValue && toDateValue) {
  //     const fDate = new Date(fromDateValue);
  //     const tDate = new Date(toDateValue);
  //     tDate.setHours(23, 59, 59, 999);
  //     this.dataSource.data = this.consignmentlists.filter((d) => {
  //       const created_at = new Date(d.created_at);
  //       return created_at >= fDate && created_at <= tDate;
  //     });
  //   }
  //   if (this.billing_and_cost === '0') {
  //     // Billing Info selected
  //     filteredData = filteredData.filter((d) => d.billingTaxableAmount !== null);
  //   } else if (this.billing_and_cost === '1') {
  //     // No Billing Info selected
  //     filteredData = filteredData.filter((d) => d.billingTaxableAmount === null);
  //   } else if (this.billing_and_cost === '2') {
  //     // Cost Center selected
  //     filteredData = filteredData.filter((d) => d.costTaxableAmount !== null);
  //   } else if (this.billing_and_cost === '3') {
  //     // No Cost Center selected
  //     filteredData = filteredData.filter((d) => d.costTaxableAmount === null);
  //   }
  //   this.dataSource.data = filteredData;
  // }

  async tablefilter() {
    await this.getconsignmentsList();
    const fromDateValue = this.filterForm.get('fromDate').value;
    const toDateValue = this.filterForm.get('toDate').value;
    let filteredData = this.consignmentlists;
    // Apply date filtering
    if (fromDateValue && toDateValue) {
      const fDate = new Date(fromDateValue);
      const tDate = new Date(toDateValue);
      tDate.setHours(23, 59, 59, 999);
      filteredData = filteredData.filter((d) => {
        const created_at = new Date(d.created_at);
        return created_at >= fDate && created_at <= tDate;
      });
    }
    // Apply billing and cost info filtering
    if (this.billing_and_cost === '0') {
      // Billing Info selected
      filteredData = filteredData.filter((d) => d.billingTaxableAmount !== null);
    } else if (this.billing_and_cost === '1') {
      // Billing Info & Cost Center selected
      filteredData = filteredData.filter((d) => d.billingTaxableAmount !== null && d.costTaxableAmount !== null);
    } else if (this.billing_and_cost === '2') {
      // No Billing Info & Cost Center selected
      filteredData = filteredData.filter((d) => d.billingTaxableAmount === null && d.costTaxableAmount === null);
    // } else if (this.billing_and_cost === '6') {
      // No Billing Info & Cost Center selected
      // filteredData = filteredData.filter((d) => d.billingTaxableAmount === null && d.costTaxableAmount !== null);
    // } else if (this.billing_and_cost === '7') {
      // No Billing Info & Cost Center selected
      // filteredData = filteredData.filter((d) => d.billingTaxableAmount !== null && d.costTaxableAmount === null);
    }

    this.dataSource.data = filteredData;
  }
  //
  filter() {
    this.filterParams = '?';
    if (this.sender_id != '') {
      this.filterParams = this.filterParams + 'sender_id=' + this.sender_id + '&';
    }
    if (this.pickup_location != '') {
      this.filterParams = this.filterParams + 'pickup_location=' + this.pickup_location.id + '&';
    }
    if (this.drop_location != '') {
      this.filterParams = this.filterParams + 'drop_location=' + this.drop_location.id + '&';
    }
    if (this.is_coperate_booking != '') {
      this.filterParams = this.filterParams + 'is_coperate_booking=' + this.is_coperate_booking + '&';
    }
    return this.filterParams;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  filterForm = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
  });
  //
  // get fromDate() { return this.filterForm.get('fromDate').value; }
  // get toDate() { return this.filterForm.get('toDate').value; }
  // applyFilter1() {
  //   // this.pipe = new DatePipe('en');
  //   // console.log(this.fromDate.format('yyyy/MM/DD hh:mm'));
  //   var fDate = new Date(this.fromDate.format('yyyy/MM/DD hh:mm:ss'));
  //   var tDate = new Date(this.toDate.format('yyyy/MM/DD hh:mm:ss'));
  //   tDate.setDate(tDate.getDate() + 1)
  //   tDate.setHours(0);
  //   tDate.setMinutes(0)
  //   // console.log(fDate, tDate)
  //   // this.dataSource.data = this
  //   this.dataSource.data = this.dataSource.data.filter(d => {
  //       // console.log(d.createdAt)
  //       d.createdAt = new Date(d.createdAt);
  //       // console.log(d.createdAt == fDate);
  //       return d.createdAt >= fDate && d.createdAt <= tDate
  //     }
  //   )
  //
  //   // return true;
  //   // }
  //   // console.log(this.dataSource.data);
  // }
  //
  //
  searchByCons(con) {
    this.dataSource = new MatTableDataSource(this.consignmentlists.filter((cons) => cons.consignment_no.includes(con)));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  //
  //
  // handlePageEvent(event: PageEvent) {
  //   this.pagenation.page_no = event.pageIndex;
  //   this.pagenation.page_size = event.pageSize;
  //   this.getconsignmentsList();
  // }
  async getconsignmentsList() {
    const TOKEN = this.authService.getAuthToken();
    this.loading = true;
    const api = '/consignmentfilters';
    const params = this.filter();
    let consignment;
    await fetch(API_URL + api + params, {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })
      // Converting received data to JSON
      .then((response) => response.json())
      .then((json) => {
        if (json.status == 400) {
          this.consignmentlists = [];
        } else {
          this.consignmentlists = json.data;
          this.dataSource = new MatTableDataSource(json.data);
          this.dataSource.paginator = this.paginator;
          // this.paginator.pageIndex = this.storedPage;
          this.dataSource.sort = this.sort;
          this.saveFiltersAndPaginationToStorage();
        }
      });
    this.loading = false;
    return this.consignmentlists;
  }
  //
  // // async getconsignmentsList() {
  // //   try {
  // //     const TOKEN = this.authService.getAuthToken();
  // //     const api = "/consignmentfilters";
  // //     const params = this.filter();
  //
  // //     const response = await fetch(API_URL + api + params, {
  // //       method: "GET",
  // //       headers: {
  // //         "Accept": "application/json",
  // //         "Authorization": "Bearer " + TOKEN
  // //       }
  // //     });
  //
  // //     const json = await response.json();
  //
  // //     if (json.status === 400) {
  // //       this.consignmentlists = [];
  // //     } else {
  // //       // this.consignmentlists = json.data;
  // //       this.dataSource = new MatTableDataSource(json.data);
  //
  // //       if (this.paginator) {
  // //         this.dataSource.paginator = this.paginator;
  //
  // //         // Store the current page index when the page changes
  // //         this.paginator.page.subscribe(() => {
  // //           this.storedPage = this.paginator.pageIndex;
  // //         });
  //
  // //         // Restore the stored page index
  // //         setTimeout(() => {
  // //           this.paginator.pageIndex = this.storedPage;
  // //           console.log(this.paginator.pageIndex,"this is index")
  // //         });
  // //       }
  //
  // //       if (this.sort) {
  // //         this.dataSource.sort = this.sort;
  // //       }
  // //     }
  // //   } catch (error) {
  // //     console.error("Error fetching consignments:", error);
  // //   }
  //
  // //   // return this.consignmentlists;
  // // }
  //
  //
  //
  //
  formatDate(date) {
    const date2 = new Date(date);
    return (
      [
        date2.getFullYear(),
        this.padTo2Digits(date2.getMonth() + 1),
        this.padTo2Digits(date2.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date2.getHours()),
        this.padTo2Digits(date2.getMinutes()),
      ].join(':')
    );
  }
  // formatDatePickup(date) {
  //   let date2 = new Date(date)
  //   return (
  //     [
  //       date2.getFullYear(),
  //       this.padTo2Digits(date2.getMonth() + 1),
  //       this.padTo2Digits(date2.getDate()),
  //     ].join('-')
  //   );
  // }
  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  async Deletedata(id) {
    const TOKEN = this.authService.getAuthToken();
    this.loading = true;
    // console.log(id)
    const data = JSON.stringify({
      'id': id,
    });
    await fetch(API_URL + '/consignment/active/' + this.activestatus,
      {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: data,
      })

      // Converting received data to JSON
      .then((response) => response.text());
    // .then(result => console.log(result))
    this.consignments = this.getconsignmentsList()
      .catch((error) => console.error('error', error));

    this.loading = false;
  }
  //
  onPaginateChange(event) {
    this.storedPage = event.pageIndex;
    this.pageSize = event.pageSize;
    localStorage.setItem('pageState', JSON.stringify({ pageIndex: this.storedPage, pageSize: this.pageSize }));
  }
  // async viewconsignmentById(id) {
  //   let TOKEN = this.authService.getAuthToken();
  //   await fetch(API_URL + "/consignment/" + id, {
  //     method: "GET", headers: {
  //       "Accept": "application/json",
  //       "Content-Type": "application/json",
  //       "Authorization": "Bearer " + TOKEN
  //     }
  //   })
  //
  //     // Converting received data to JSON
  //     .then(response => response.json())
  //     .then(json => {
  //       if (json.data[0].image1 != null) {
  //         this.getimage1(id)
  //       }
  //       if (json.data[0].image2 != null) {
  //         this.getimage2(id)
  //       }
  //       if (json.data[0].image3 != null) {
  //         this.getimage3(id)
  //       }
  //       if (json.data[0].image4 != null) {
  //         this.getimage4(id)
  //       }
  //       if (json.data[0].pod != null) {
  //         this.getpod(id)
  //       }
  //       this.consignmentlists = json;
  //       this.getTrack()
  //     });
  //   // console.log(this.consignmentlists);
  //
  // }
  //
  // status_update = {
  //   id: null,
  //   updatedAt: ""
  // }
  // async updateDateTime() {
  //   let TOKEN = this.authService.getAuthToken();
  //   // console.log(this.consignment_id);
  //   // this.loading = true;
  //   let Date_update = {
  //     id: this.consignmentlists.data[0].trackingId.trackingStatusComments[this.ivalue].id,
  //     updatedAt: this.status_update.updatedAt
  //   }
  //   // this.consignment_sts_update.consignmentId=this.consignment_id;
  //   // this.consignment_sts_update.comment=;
  //   // console.log(sts_update);
  //   await fetch(API_URL + "/updateDateTime",
  //     {
  //       method: "PATCH",
  //       body: JSON.stringify(Date_update),
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Accept": "application/json",
  //         "Authorization": "Bearer " + TOKEN
  //       }
  //     })
  //     // Converting received data to JSON
  //     .then(response => response.json())
  //     .then(json => {
  //       this.getTrack();
  //       // this.consignment_sts_update.status=null
  //       // this.consignment_sts_update.comment=null
  //       // alert("Status Changed")
  //       // this.loading=false;
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'success',
  //         title: 'Status updated successfully',
  //         showConfirmButton: false,
  //         timer: 5000
  //       });
  //       this.viewchange('view', this.consignmentlists.data[0].id);
  //     });
  //   // console.log(this.consignment_sts_update.consignmentId)
  //
  // }
  //
  // consignment_sts_update = {
  //   consignmentId: null,
  //   status: null,
  //   comment: "",
  // }
  // async update_con_status() {
  //   let TOKEN = this.authService.getAuthToken();
  //   // console.log(this.consignment_id);
  //   this.loading = true;
  //   let sts_update = {
  //     consignmentId: this.consignmentlists.data[0].consignmentNo,
  //     status: this.consignment_sts_update.status,
  //     comment: this.consignment_sts_update.comment
  //   }
  //   // this.consignment_sts_update.consignmentId=this.consignment_id;
  //   // this.consignment_sts_update.comment=;
  //   // console.log(sts_update);
  //   await fetch(API_URL + "/trackByConsignment",
  //     {
  //       method: "POST",
  //       body: JSON.stringify(sts_update),
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Accept": "application/json",
  //         "Authorization": "Bearer " + TOKEN
  //       }
  //     })
  //     // Converting received data to JSON
  //     .then(response => response.json())
  //     .then(json => {
  //       this.getTrack();
  //       // this.consignment_sts_update.status=null
  //       // this.consignment_sts_update.comment=null
  //       // alert("Status Changed")
  //       this.loading = false;
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'success',
  //         title: 'Status updated successfully',
  //         showConfirmButton: false,
  //         timer: 5000
  //       });
  //     });
  //   // console.log(this.consignment_sts_update.consignmentId)
  //
  // }
  // manifestation;
  // consignment_track;
  // consignment_with_tracker;
  // consignment_status
  // trackorigin
  // trackdestination
  // async getTrack() {
  //   let TOKEN = this.authService.getAuthToken();
  //   await fetch(API_URL + "/tracking/consignment/" + this.consignmentlists.data[0].consignmentNo, {
  //     method: "GET",
  //     headers: {
  //       "Accept": "application/json",
  //       "Authorization": "Bearer " + TOKEN
  //     }
  //   })
  //     // Converting received data to JSON
  //     .then(response => response.json())
  //     .then(json => {
  //
  //       this.consignmentlists = json;
  //       this.consignment_track = json.data[0].trackingId.trackingStatusComments;
  //       let updatedStatus = json.statuses.filter((eachStatus) => {
  //         let selectedStatus = this.consignment_track.map(each => each.status.id)
  //         let highOrder = 0
  //         this.consignment_track.forEach(each => {
  //           highOrder = highOrder < each.status.order ? each.status.order : highOrder
  //         })
  //         // console.log("highhhhhh",highOrder)
  //         if(eachStatus.order < highOrder && eachStatus.id != 19 && eachStatus.id != 35 &&  eachStatus.id != 18){
  //           return false
  //         }
  //         if (eachStatus.id === 36) {
  //           return false;
  //         }
  //         if(selectedStatus.includes(eachStatus.id) && eachStatus.id != 19 && eachStatus.id != 35  &&  eachStatus.id != 18){
  //           return false
  //         }else{
  //           return true
  //         }
  //       })
  //       this.consignment_status = updatedStatus
  //       this.consignment_sts_update.status = null
  //       this.consignment_sts_update.comment = ""
  //       // console.log(this.consignment_track)
  //       this.consignment_track.sort((a, b) => a.id - b.id)
  //       // console.log(this.consignment_track)
  //       if (json.data[0].series_ref.id != 13 && json.data[0].series_ref.id != 14 && json.data[0].series_ref.id != 15 && json.data[0].series_ref.id != 16) {
  //         this.trackorigin = json.data[0].originLocation.city.name
  //         this.trackdestination = json.data[0].dropLocation.city.name
  //       }
  //     });
  // }
  // ngAfterViewChecked() {
  //
  // }
  //
  //
  // getTime(date) {
  //   if (date) {
  //
  //     let date2 = new Date(date)
  //     return (
  //       [
  //         date2.getFullYear(
  //
  //         ),
  //         this.padTo2Digits(date2.getMonth() + 1),
  //         this.padTo2Digits(date2.getDate()),
  //       ].join('-') +
  //       ' ' +
  //       [
  //         this.padTo2Digits(date2.getHours()),
  //         this.padTo2Digits(date2.getMinutes())
  //       ].join(':')
  //     );
  //   }
  //   else {
  //     return "-";
  //   }
  // }
  displayedColumns = ['consignmentNo', 'name', 'receivername', 'totalNoOfPackages', 'totalChargableWeight', 'orign', 'destination', 'status', 'isInvoiced', 'createdAt', 'pickup_date', 'delivery_date', 'Active']
  // // viewchange(val,id){
  // //   if(val=="view"){
  // //     this.viewLoadById(id);
  // //   }
  // //   else if(val=="table"){
  // //     this.getconsignmentsList();
  // //   }
  // // }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource
  // // paginator: MatPaginator
  // // ngAfterViewInit() {
  // //   if(this.dataSource.sort){
  // //     this.dataSource.sort = this.sort;
  // //   }
  // //   if(this.dataSource.paginator){
  // //     this.dataSource.paginator = this.paginator;
  // //   }
  // // }
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  async getAllCustomers() {
    const TOKEN = this.authService.getAuthToken();
    let consignment;
    await fetch(API_URL + '/totalcustomers/', {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })

      // Converting received data to JSON
      .then((response) => response.json())
      .then((json) => {
        this.customers = json;
      });
  }
  async getAirports() {
    const TOKEN = this.authService.getAuthToken();
    // let airports = [];
    await fetch(API_URL + '/airport', {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })

      // Converting received data to JSON
      .then((response) => response.json())
      .then((json) => {
        this.airports = json.data;
        this.sortedAirports = this.airports.sort((a, b) => a.city.name.localeCompare(b.city.name));
      });
  }

  async getRecord(id) {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/report/' + id,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf',
          'Authorization': 'Bearer ' + TOKEN,
        },
      })
      .then(async (response) => {
        const filename = 'consignmentReport' + id;
        const blob = response.blob();
        const a = document.createElement('a');
        a.download = filename;
        a.href = window.URL.createObjectURL(await blob);
        a.click();
      },
      );
  }
  //
  // async saveChanges() {
  //   let TOKEN = this.authService.getAuthToken();
  //   let cons =
  //     {
  //       "totalNoOfPackages": this.consignment_details.totalNoOfPackages,
  //       "totalWeight": this.consignment_details.totalWeight,
  //       "totalChargableWeight": this.consignment_details.totalChargableWeight,
  //       "packages": this.changeable_packages,
  //       "totalConsignmentValue": this.consignment_details.totalConsignmentValue,
  //       "createdAt": this.consignment_details.createdAt,
  //       "invoiceCreatedDate": this.consignment_details.invoiceCreatedDate,
  //       "invoiceReferenceNo": this.consignment_details.invoiceReferenceNo,
  //       "remarks": this.consignment_details.remarks,
  //       "weightDivisor": this.consignment_details.weightDivisor,
  //       "tentative_pickup_time": this.consignment_details.tentative_pickup_time,
  //       "required_temperature": this.consignment_details.required_temperature,
  //       "package_type": this.consignment_details.package_type
  //     }
  //
  //   await fetch(API_URL + "/consignment/" + this.consignment_id,
  //     {
  //       method: "PATCH",
  //       body: JSON.stringify(cons),
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Authorization": "Bearer " + TOKEN
  //       }
  //     })
  //     .then((response) => response.json())
  //     .then((result) => {
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'success',
  //         title: 'Changes Saved',
  //         showConfirmButton: false,
  //         timer: 5000
  //       });
  //       // alert("Changes Saved");
  //       this.viewchange("table", 0);
  //       // console.log(result);
  //     })
  //     .catch((error) => {
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'error',
  //         title: 'Error',
  //         showConfirmButton: false,
  //         timer: 5000
  //       });
  //       console.error("Error", error);
  //       alert(error)
  //     });
  // }
  //
  // async getconsignmentById() {
  //   let TOKEN = this.authService.getAuthToken();
  //   await fetch(API_URL + "/consignment/" + this.consignment_id,
  //     {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Authorization": "Bearer " + TOKEN
  //       }
  //     })
  //     .then((response) => response.json())
  //     .then((result) => {
  //       this.consignment_details = result.data[0];
  //       this.packages = this.consignment_details.packages
  //       this.changeable_packages = this.consignment_details.packages
  //     })
  //     .catch((error) => {
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'error',
  //         title: 'Error',
  //         showConfirmButton: false,
  //         timer: 5000
  //       });
  //     });
  //
  // }
  // requiredDocument: any = [];
  // getFiles(event) {
  //   this.requiredDocument[0] = event.target.files[0];
  // }
  //
  //
  // async uploadimage1() {
  //   let TOKEN = this.authService.getAuthToken();
  //   if (this.image1 != null) {
  //     alert("Upload Image 1")
  //   } else {
  //     var formdata = new FormData();
  //     formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //     formdata.append("image1", this.requiredDocument[0])
  //
  //     await fetch(API_URL + "/consignment/updateImage1", {
  //       method: "POST",
  //       body: formdata,
  //       headers: {
  //         "Authorization": "Bearer " + TOKEN,
  //         "Accept": "application/json"
  //       }
  //     })
  //       .then((response) => response.text())
  //       .then((result) => {
  //         // console.log(result)
  //         Swal.fire({
  //           position: 'center',
  //           icon: 'success',
  //           title: 'Image 1 Uploaded successfully',
  //           showConfirmButton: false,
  //           timer: 5000
  //         });
  //         this.viewchange('view', this.consignmentlists.data[0].id);
  //       })
  //       .catch(error => console.error('error', error));
  //   }
  // }
  // async uploadimage2() {
  //   let TOKEN = this.authService.getAuthToken();
  //   if (this.image2 != null) {
  //     alert("Upload Image 2")
  //   } else {
  //     var formdata = new FormData();
  //     formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //     formdata.append("image2", this.requiredDocument[0])
  //
  //     await fetch(API_URL + "/consignment/updateImage2", {
  //       method: "POST",
  //       body: formdata,
  //       headers: {
  //         "Authorization": "Bearer " + TOKEN,
  //         "Accept": "application/json"
  //       }
  //     })
  //       .then((response) => response.text())
  //       .then((result) => {
  //         // console.log(result)
  //         Swal.fire({
  //           position: 'center',
  //           icon: 'success',
  //           title: 'Image 2 Uploaded successfully',
  //           showConfirmButton: false,
  //           timer: 5000
  //         });
  //         this.viewchange('view', this.consignmentlists.data[0].id);
  //       })
  //       .catch(error => console.error('error', error));
  //   }
  // }
  //
  // async uploadimage3() {
  //   let TOKEN = this.authService.getAuthToken();
  //   if (this.image3 != null) {
  //     alert("Upload Image 3")
  //   } else {
  //     var formdata = new FormData();
  //     formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //     formdata.append("image3", this.requiredDocument[0])
  //
  //     await fetch(API_URL + "/consignment/updateImage3", {
  //       method: "POST",
  //       body: formdata,
  //       headers: {
  //         "Authorization": "Bearer " + TOKEN,
  //         "Accept": "application/json"
  //       }
  //     })
  //       .then((response) => response.text())
  //       .then((result) => {
  //         // console.log(result)
  //         Swal.fire({
  //           position: 'center',
  //           icon: 'success',
  //           title: 'Image 3 Uploaded successfully',
  //           showConfirmButton: false,
  //           timer: 5000
  //         });
  //         this.viewchange('view', this.consignmentlists.data[0].id);
  //       })
  //       .catch(error => console.error('error', error));
  //   }
  // }
  //
  // async uploadimage4() {
  //   let TOKEN = this.authService.getAuthToken();
  //   if (this.image4 != null) {
  //     alert("Upload Image 4")
  //   } else {
  //     var formdata = new FormData();
  //     formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //     formdata.append("image4", this.requiredDocument[0])
  //
  //     await fetch(API_URL + "/consignment/updateImage4", {
  //       method: "POST",
  //       body: formdata,
  //       headers: {
  //         "Authorization": "Bearer " + TOKEN,
  //         "Accept": "application/json"
  //       }
  //     })
  //       .then((response) => response.text())
  //       .then((result) => {
  //         // console.log(result)
  //         Swal.fire({
  //           position: 'center',
  //           icon: 'success',
  //           title: 'Image 4 Uploaded successfully',
  //           showConfirmButton: false,
  //           timer: 5000
  //         });
  //         this.viewchange('view', this.consignmentlists.data[0].id);
  //       })
  //       .catch(error => console.error('error', error));
  //   }
  // }
  //
  //
  // async uploadPOD() {
  //   let TOKEN = this.authService.getAuthToken();
  //   if (this.pod != null) {
  //     alert("Upload POD")
  //   } else {
  //     var formdata = new FormData();
  //     formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //     formdata.append("Pod", this.requiredDocument[0])
  //
  //     await fetch(API_URL + "/consignment/updatePod", {
  //       method: "POST",
  //       body: formdata,
  //       headers: {
  //         "Authorization": "Bearer " + TOKEN,
  //         // "Accept":"multipart/form-data"
  //       }
  //     })
  //       .then((response) => response.text())
  //       .then((result) => {
  //         // console.log(result)
  //         Swal.fire({
  //           position: 'center',
  //           icon: 'success',
  //           title: 'POD Uploaded successfully',
  //           showConfirmButton: false,
  //           timer: 5000
  //         });
  //         this.viewchange('view', this.consignmentlists.data[0].id);
  //       })
  //       .catch(error => console.error('error', error));
  //   }
  // }
  // async uploadDatafiles() {
  //   let TOKEN = this.authService.getAuthToken();
  //   if (this.data_logger_file != null) {
  //     alert("Upload dataLoggerFile")
  //   } else {
  //     var formdata = new FormData();
  //     formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //     formdata.append("DataLoggerFile", this.requiredDocument[0])
  //
  //     await fetch(API_URL + "/consignment/updateDataLoggerFile", {
  //       method: "POST",
  //       body: formdata,
  //       headers: {
  //         "Authorization": "Bearer " + TOKEN,
  //         // "Accept":"multipart/form-data"
  //
  //       }
  //     })
  //
  //       .then((response) => response.text())
  //       .then((result) => {
  //         // console.log(result)
  //         Swal.fire({
  //           position: 'center',
  //           icon: 'success',
  //           title: 'Data Logger Uploaded successfully',
  //           showConfirmButton: false,
  //           timer: 5000
  //         });
  //         this.viewchange('view', this.consignmentlists.data[0].id);
  //       })
  //       .catch(error => console.error('error', error));
  //   }
  //
  // }
  //
  // async uploadVechilefiles() {
  //   let TOKEN = this.authService.getAuthToken();
  //   if (this.vehicle_calibration_file != null) {
  //     alert("Upload VehicleCalibrationFile")
  //   } else {
  //     var formdata = new FormData();
  //     formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //     formdata.append("VehicleCalibrationFile", this.requiredDocument[0])
  //
  //     await fetch(API_URL + "/consignment/updateVehicleCalibrationFile", {
  //       method: "POST",
  //       body: formdata,
  //       headers: {
  //         "Authorization": "Bearer " + TOKEN,
  //         // "Accept":"multipart/form-data"
  //       }
  //     })
  //
  //       .then((response) => response.text())
  //       .then((result) => {
  //         // console.log(result)
  //         Swal.fire({
  //           position: 'center',
  //           icon: 'success',
  //           title: 'Vehicle Calibration Uploaded successfully',
  //           showConfirmButton: false,
  //           timer: 5000
  //         });
  //         this.viewchange('view', this.consignmentlists.data[0].id);
  //       })
  //       .catch(error => console.error('error', error));
  //   }
  //
  // }
  //
  // async uploadOthersfiles() {
  //   let TOKEN = this.authService.getAuthToken();
  //   if (this.other_documents_file != null) {
  //     alert("Upload OtherDocumentsFile")
  //   } else {
  //     var formdata = new FormData();
  //     formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //     formdata.append("OtherDocumentsFile", this.requiredDocument[0])
  //
  //     await fetch(API_URL + "/consignment/updateOtherDocumentsFile", {
  //       method: "POST",
  //       body: formdata,
  //       headers: {
  //         "Authorization": "Bearer " + TOKEN,
  //         // "Accept":"multipart/form-data"
  //       }
  //     })
  //
  //       .then((response) => response.text())
  //       .then((result) => {
  //         // console.log(result)
  //         Swal.fire({
  //           position: 'center',
  //           icon: 'success',
  //           title: 'OtherDocuments Uploaded successfully',
  //           showConfirmButton: false,
  //           timer: 5000
  //         });
  //         this.viewchange('view', this.consignmentlists.data[0].id);
  //       })
  //       .catch(error => console.error('error', error));
  //   }
  //
  // }
  //
  // async uploadTemp() {
  //   let TOKEN = this.authService.getAuthToken();
  //   if (this.temperature_record_file != null) {
  //     alert("Upload TemperatureRecordFile")
  //   } else {
  //     var formdata = new FormData();
  //     formdata.append("consignmentId", this.consignmentlists.data[0].consignmentNo)
  //     formdata.append("TemperatureRecordFile", this.requiredDocument[0])
  //
  //     await fetch(API_URL + "/consignment/updateTemperatureRecordFile", {
  //       method: "POST",
  //       body: formdata,
  //       headers: {
  //         "Authorization": "Bearer " + TOKEN,
  //         // "Accept":"multipart/form-data"
  //       }
  //     })
  //
  //       .then((response) => response.text())
  //       .then((result) => {
  //         // console.log(result)
  //         Swal.fire({
  //           position: 'center',
  //           icon: 'success',
  //           title: 'TemperatureRecord Uploaded successfully',
  //           showConfirmButton: false,
  //           timer: 5000
  //         });
  //         this.viewchange('view', this.consignmentlists.data[0].id);
  //       })
  //       .catch(error => console.error('error', error));
  //   }
  //
  // }
  // async getimage1(id) {
  //   let TOKEN = this.authService.getAuthToken();
  //   await fetch(API_URL + "/consignment/getImage1/" + id, {
  //     method: "GET", headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       "responseType": 'blob'
  //     },
  //   })
  //     .then(response => response.blob())
  //     .then(result => {
  //       // console.log(result)
  //       let objectURL = URL.createObjectURL(result);
  //       // console.log(objectURL);
  //       this.image_1 = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //       // console.log(this.image_1);
  //     })
  //     .catch(error => console.error('error', error));
  // }
  //
  // async getimage2(id) {
  //   let TOKEN = this.authService.getAuthToken();
  //
  //   await fetch(API_URL + "/consignment/getImage2/" + id, {
  //     method: "GET", headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       "responseType": 'blob'
  //     },
  //   })
  //     .then(response => response.blob())
  //     .then(result => {
  //       // console.log(result)
  //       let objectURL = URL.createObjectURL(result);
  //       this.image_2 = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //     })
  //     .catch(error => console.error('error', error));
  // }
  // async getimage3(id) {
  //   let TOKEN = this.authService.getAuthToken();
  //   await fetch(API_URL + "/consignment/getImage3/" + id, {
  //     method: "GET", headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       "responseType": 'blob'
  //     },
  //   })
  //     .then(response => response.blob())
  //     .then(result => {
  //       // console.log(result)
  //       let objectURL = URL.createObjectURL(result);
  //       this.image_3 = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //     })
  //     .catch(error => console.error('error', error));
  // }
  // async getimage4(id) {
  //   let TOKEN = this.authService.getAuthToken();
  //   await fetch(API_URL + "/consignment/getImage4/" + id, {
  //     method: "GET", headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       "responseType": 'blob'
  //     },
  //   })
  //     .then(response => response.blob())
  //     .then(result => {
  //       // console.log(result)
  //       let objectURL = URL.createObjectURL(result);
  //       this.image_4 = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //     })
  //     .catch(error => console.error('error', error));
  //
  // }
  //
  // async getpod(id) {
  //   let TOKEN = this.authService.getAuthToken();
  //   await fetch(API_URL + "/consignment/getPod/" + id, {
  //     method: "GET", headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       "responseType": 'blob'
  //     },
  //   })
  //     .then(response => response.blob())
  //     .then(result => {
  //       // console.log(result)
  //       let objectURL = URL.createObjectURL(result);
  //       this.POD = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //     })
  //     .catch(error => console.error('error', error));
  //
  // }
  //
  // async getvehiclecalibration() {
  //   let TOKEN = this.authService.getAuthToken();
  //   await fetch(API_URL + "/consignment/getVehicleCalibrationFile/" + this.consignmentlists.data[0].id, {
  //     method: "GET", headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       "responseType": 'blob'
  //     },
  //   })
  //     .then(async (response) => {
  //       let filename = "VehicleCalibrationFile" + this.consignmentlists.data[0].id;
  //       let blob = response.blob();
  //       let a = document.createElement("a");
  //       a.download = filename;
  //       a.href = window.URL.createObjectURL(await blob);
  //       a.click();
  //     })
  //     .catch(error => console.error('error', error));
  //
  // }
  //
  // async getDatalogger() {
  //   let TOKEN = this.authService.getAuthToken();
  //   await fetch(API_URL + "/consignment/getDataLoggerFile/" + this.consignmentlists.data[0].id, {
  //     method: "GET", headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       "responseType": 'blob'
  //     },
  //   })
  //     .then(async (response) => {
  //       let filename = "DataloggerFile" + this.consignmentlists.data[0].id;
  //       let blob = response.blob();
  //       let a = document.createElement("a");
  //       a.download = filename;
  //       a.href = window.URL.createObjectURL(await blob);
  //       a.click();
  //     })
  //     .catch(error => console.error('error', error));
  //
  // }
  //
  // async getotherdocuments() {
  //   let TOKEN = this.authService.getAuthToken();
  //   await fetch(API_URL + "/consignment/getOtherDocumentsFile/" + this.consignmentlists.data[0].id, {
  //     method: "GET", headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       "responseType": 'blob'
  //     },
  //   })
  //     .then(async (response) => {
  //       let filename = "OtherDocumentsFile" + this.consignmentlists.data[0].id;
  //       let blob = response.blob();
  //       let a = document.createElement("a");
  //       a.download = filename;
  //       a.href = window.URL.createObjectURL(await blob);
  //       a.click();
  //     })
  //     .catch(error => console.error('error', error));
  //
  // }
  //
  // async gettemp() {
  //   let TOKEN = this.authService.getAuthToken();
  //   await fetch(API_URL + "/consignment/getTemperatureRecordFile/" + this.consignmentlists.data[0].id, {
  //     method: "GET", headers: {
  //       "Authorization": "Bearer " + TOKEN,
  //       "responseType": 'blob'
  //     },
  //   })
  //     .then(async (response) => {
  //       let filename = "TemperatureRecordFile" + this.consignmentlists.data[0].id;
  //       let blob = response.blob();
  //       let a = document.createElement("a");
  //       a.download = filename;
  //       a.href = window.URL.createObjectURL(await blob);
  //       a.click();
  //     })
  //     .catch(error => console.error('error', error));
  //
  // }
  //
  //
  //
  // // exportToCSV() {
  // //   let csvData = [];
  // //   let headers = ["Consignment No", "Consignor Company", "Consignor Name", "Consignee Company", "Consignee Name", "Consignee Phone", "Consignee Address", "Is Business Customer","Origin", "Destination","Product Type","Product Mode", "No.of Packages",
  // //     "Actual Weight(KGS)", "Total Chargable Weight(KGS)", "User Role", "Created By", "Created At", "Updated At", "Status", "POD", "Temperature Range", "Pickup Date", "Airline", "Consignment Value(INR)", "Weight Divisor", "AWB Number",
  // //     "Airline Charge(INR)", "AHC-Outbond(INR)", "AHC-Inbound(INR)", "MAWB Charge(INR)", "OtherCharges(INR)", "ICLSCM Handling Charge(INR)", "Airline Surcharge(INR)",
  // //     "Additional Surcharge(INR)", "Shipping Cost(INR)", "Is Insuranced", "Insurance Value(INR)", "Tax(INR)", "Total Shipping Cost(INR)"];
  // //   csvData.push(headers);
  // //   this.dataSource.filteredData.forEach(row => {
  // //     let created = this.formatDate(row.created_at);
  // //     let updated;
  // //     let pod;
  // //     let insurance;
  // //     let weightdivisor;
  // //     let tax;
  // //     let Temp;
  // //     let pickup;
  // //     let corporatebooking;
  // //     tax = Math.ceil((18 / 100) * row.shipping_cost);
  // //     // let total_weight = row.total_weight +" "+ "kg"
  // //     if (row.weight_divisor == 1) {
  // //       weightdivisor = "6000"
  // //     } else if (row.weightdivisor == 2) {
  // //       weightdivisor = "5000"
  // //     } else {
  // //       weightdivisor = row.weight_divisor
  // //     }
  // //     if (row.is_insured == 1) {
  // //       insurance = "Yes"
  // //     } else {
  // //       insurance = "No"
  // //     }
  // //     if (row.is_coperate_booking == 1) {
  // //       corporatebooking = "Yes"
  // //     } else {
  // //       corporatebooking = "No"
  // //     }
  // //     if (row.required_temperature == null) {
  // //       Temp = "-"
  // //     } else {
  // //       Temp = row.required_temperature;
  // //     }
  // //     if (row.tentative_pickup_time == null) {
  // //       pickup = "-"
  // //     } else {
  // //       pickup = this.formatDate(row.tentative_pickup_time);
  // //     }
  // //     if (row.pod == null) {
  // //       pod = "No"
  // //     } else {
  // //       pod = "Yes"
  // //     }
  //
  // //     if (row.updated_at == null) {
  // //       updated = this.formatDate(row.created_at);
  // //     } else {
  // //       updated = this.formatDate(row.updated_at);
  // //     }
  // //     let data = [row.consignment_no, row.customer_name, row.individual_name, row.receiver_company, `"${row.receiver_name}"`, `"${row.receiver_phone}"`, `"${row.receiver_address}"`,corporatebooking,
  // //     row.cityname, row.receivercity, row.product_type,row.product_mode,row.no_of_packages, row.total_weight, row.total_chargable_weight, row.user_role, row.created_user_name,
  // //       created, updated, row.name, pod, Temp, pickup, row.airline, row.consignment_value, weightdivisor, row.awb_number,
  // //     row.airline_tarrif_charge, row.ahc_outbound_charge, row.ahc_inbound_charge,
  // //     row.mawb_fee_charge, row.other_charges, row.iclscm_handling_charge, row.airline_surcharge, row.additional_surcharge,
  // //     row.shipping_cost, insurance, row.insured_value, tax,
  // //     row.total_shipping_cost];
  // //     csvData.push(data);
  // //   });
  //
  // //   let csv = csvData.map(e => e.join(",")).join("\n");
  // //   let blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
  // //   saveAs(blob, "ConsignmentDetails.csv");
  // // }
  //
  exportToCSV() {
    const csvData = [];
    const headers = ['Consignment No', 'Consignor Company', 'Consignor Name', 'Consignee Company', 'Consignee Name', 'Consignee Phone', 'Consignee Address',
      'Is Business Customer', 'Origin', 'Destination', 'Product Type', 'Product Mode', 'No.of Packages', 'Actual Weight(KGS)', 'Total Chargable Weight(KGS)',
      'User Role', 'Created By', 'Created At', 'Updated At', 'Status', 'POD', 'Data Logger File', 'Vehicle Calibration File', 'Other Documents File',
      'Temperature Record File', 'Temperature Range', 'Container Size', 'Tentative Pickup Date',
      'Airline', 'Consignment Value(INR)', 'Weight Divisor', 'AWB Number', 'Pickup Date', 'Delivery Date', 'Is Insuranced', 'Is_Sez',

      'billing_freightCharges(INR)', 'billing_ahcCharges(INR)', 'billing_mawbCharges(INR)', 'billing_iclCnCharges(INR)', 'billing_pickupCharges(INR)', 'billing_deliveryCharges', 'billing_iclHandlingCharges(INR)',
      'billing_peakHandlingCharges(INR)', 'billing_loadingCharges(INR)', 'billing_unloadingCharges(INR)', 'billing_fuelSurcharges(INR)', 'billing_packingMaterialCharges(INR)', 'billing_dataLoggerCharges(INR)',
      'billing_warehouseCharges(INR)', 'billing_detentionCharges(INR)', 'billing_dgCharges(INR)', 'billing_demurrageCharges(INR)', 'billing_miscellaneousCharges(INR)', 'billing_discountAmount(INR)',
      'billing_fovPercentage(%)', 'billing_fov(INR)', 'billing_taxableAmount(INR)', 'billing_taxablePercentage(%)', 'billing_taxAmount(INR)', 'billing_grandTotal(INR)', 'billing_remarks', 'billing_createdAt',
      'billing_createdBy', 'billing_updatedAt', 'billing_updatedBy',

      'cost_freightCharges(INR)', 'cost_ahcCharges(INR)', 'cost_mawbCharges(INR)', 'cost_iclCnCharges(INR)', 'cost_pickupCharges(INR)', 'cost_deliveryCharges', 'cost_iclHandlingCharges(INR)', 'cost_peakHandlingCharges(INR)',
      'cost_loadingCharges(INR)', 'cost_unloadingCharges(INR)', 'cost_fuelSurcharges(INR)', 'cost_packingMaterialCharges(INR)', 'cost_dataLoggerCharges(INR)', 'cost_warehouseCharges(INR)', 'cost_detentionCharges(INR)',
      'cost_dgCharges(INR)', 'cost_demurrageCharges(INR)', 'cost_miscellaneousCharges(INR)', 'cost_discountedAmount(INR)', 'cost_fovPercentage(%)', 'cost_fov(INR)', 'cost_taxableAmount(INR)', 'cost_taxablePercentage(%)',
      'cost_taxAmount(INR)', 'cost_grandTotal(INR)', 'cost_remarks', 'cost_createdAt', 'cost_createdBy', 'cost_updatedAt', 'cost_updatedBy',

      'gst', 'is_invoiced', 'invoice_no', 'profit/loss', 'co2 emissions',

    ];
    csvData.push(headers);

    function formatForCSV(value) {
      if (value === null || value === undefined) {
        return '';
      }
      const escapedValue = value.toString().replace(/"/g, '""');
      if (escapedValue.includes(',') || escapedValue.includes('\n')) {
        return `"${escapedValue}"`;
      }
      return escapedValue;
    }
    this.dataSource.filteredData.forEach((row) => {
      const created = this.formatDate(row.created_at);
      let updated;
      let pod;
      let dataLoggerFile;
      let otherDocumentsFile;
      let vehicleCalibrationFile;
      let temperatureRecordFile;
      // let insurance;
      let weightdivisor;
      // let tax;
      let Temp;
      let containerSize;
      let pickup;
      // let corporatebooking;
      // tax = Math.ceil((18 / 100) * row.shipping_cost);

      if (row.weight_divisor == 1) {
        weightdivisor = '6000';
      } else if (row.weightdivisor == 2) {
        weightdivisor = '5000';
      } else {
        weightdivisor = row.weight_divisor;
      }
      // if (row.is_insured == 1) {
      //   insurance = "Yes";
      // } else {
      //   insurance = "No";
      // }
      // if (row.is_coperate_booking == 1) {
      //   corporatebooking = "Yes";
      // } else {
      //   corporatebooking = "No";
      // }
      if (row.required_temperature == null) {
        Temp = '-';
      } else {
        Temp = row.required_temperature;
      }
      if (row.container_size == null) {
        containerSize = '-';
      } else {
        containerSize = row.container_size;
      }
      if (row.tentative_pickup_time == null) {
        pickup = '-';
      } else {
        pickup = this.formatDate(row.tentative_pickup_time);
      }
      if (row.pod == null) {
        pod = 'No';
      } else {
        pod = 'Yes';
      }
      if (row.dataLoggerFile == null) {
        dataLoggerFile = "No";
      } else {
        dataLoggerFile = "Yes";
      }
      if (row.otherDocumentsFile == null) {
        otherDocumentsFile = "No";
      } else {
        otherDocumentsFile = "Yes";
      }
      if (row.vehicleCalibrationFile == null) {
        vehicleCalibrationFile = "No";
      } else {
        vehicleCalibrationFile = "Yes";
      }
      if (row.temperatureRecordFile == null) {
        temperatureRecordFile = "No";
      } else {
        temperatureRecordFile = "Yes";
      }
      if (row.updated_at == null) {
        updated = this.formatDate(row.created_at);
      } else {
        updated = this.formatDate(row.updated_at);
      }

      let billCreatedAt;
      let costCreatedAt;
      let billUpdatedAt;
      let costUpdatedAt;
      let pickUpDate;
      let deliveryDate;

      if (row.billingCreatedAt) {
        billCreatedAt = this.formatDate(row.billingCreatedAt);
      } else {
        billCreatedAt = '';
      }

      if (row.billingUpdatedAt) {
        billUpdatedAt = this.formatDate(row.billingUpdatedAt);
      } else {
        billUpdatedAt = '';
      }

      if (row.costCreatedAt) {
        costCreatedAt = this.formatDate(row.costCreatedAt);
      } else {
        costCreatedAt = '';
      }

      if (row.costUpdatedAt) {
        costUpdatedAt = this.formatDate(row.costUpdatedAt);
      } else {
        costUpdatedAt = '';
      }

      if (row.pickup_date) {
        pickUpDate = this.formatDate(row.pickup_date);
      } else {
        pickUpDate = '';
      }

      if (row.delivery_date) {
        deliveryDate = this.formatDate(row.delivery_date);
      } else {
        deliveryDate = '';
      }

      const data = [
        formatForCSV(row.consignment_no),
        formatForCSV(row.customer_name),
        formatForCSV(row.individual_name),
        formatForCSV(row.receiver_company),
        formatForCSV(row.receiver_name),
        formatForCSV(row.receiver_phone),
        formatForCSV(row.receiver_address),
        formatForCSV(!row.isRetailCustomer),
        formatForCSV(row.cityname),
        formatForCSV(row.receivercity),
        formatForCSV(row.product_type),
        formatForCSV(row.product_mode),
        formatForCSV(row.no_of_packages),
        formatForCSV(row.totalWeight),
        formatForCSV(row.total_chargable_weight),
        formatForCSV(row.user_role),
        formatForCSV(row.created_user_name),
        formatForCSV(created),
        formatForCSV(updated),
        formatForCSV(row.name),
        formatForCSV(pod),
        formatForCSV(dataLoggerFile),
        formatForCSV(vehicleCalibrationFile),
        formatForCSV(otherDocumentsFile),
        formatForCSV(temperatureRecordFile),
        formatForCSV(Temp) + ' °C',
        formatForCSV(containerSize),
        formatForCSV(pickup),
        formatForCSV(row.airline),
        formatForCSV(row.consignment_value),
        formatForCSV(weightdivisor),
        formatForCSV(row.awb_number),
        formatForCSV(pickUpDate),
        formatForCSV(deliveryDate),
        formatForCSV(row.is_insured),
        formatForCSV(row.isSezCustomer),
        // formatForCSV(row.airline_tarrif_charge),
        // formatForCSV(row.ahc_outbound_charge),
        // formatForCSV(row.ahc_inbound_charge),
        // formatForCSV(row.mawb_fee_charge),
        // formatForCSV(row.other_charges),
        // formatForCSV(row.iclscm_handling_charge),
        // formatForCSV(row.airline_surcharge),
        // formatForCSV(row.additional_surcharge),
        // formatForCSV(row.shipping_cost),
        // formatForCSV(insurance),
        // formatForCSV(row.insured_value),
        // formatForCSV(tax),
        // formatForCSV(row.total_shipping_cost)


        formatForCSV(row.billingFreightCharges),
        formatForCSV(row.billingAhcCharges),
        formatForCSV(row.billingMawbCharges),
        formatForCSV(row.billingIclCnCharges),
        formatForCSV(row.billingPickupCharges),
        formatForCSV(row.billingDeliveryCharges),
        formatForCSV(row.billingIclHandlingCharges),
        formatForCSV(row.billingPeakHandlingCharges),
        formatForCSV(row.billingLoadingCharges),
        formatForCSV(row.billingUnloadingCharges),
        formatForCSV(row.billingFuelSurcharges),
        formatForCSV(row.billingPackingMaterialCharges),
        formatForCSV(row.billingDataLoggerCharges),
        formatForCSV(row.billingWarehouseCharges),
        formatForCSV(row.billingDetentionCharges),
        formatForCSV(row.billingDgCharges),
        formatForCSV(row.billingDemurrageCharges),
        formatForCSV(row.billingMiscellaneousCharges),
        formatForCSV(row.billingDiscountedAmount),
        formatForCSV(row.billingInsurancePercentage),
        formatForCSV(row.billingFov),
        formatForCSV(row.billingTaxableAmount),
        formatForCSV(row.billingTaxablePercentage),
        formatForCSV(row.billingTaxAmount),
        formatForCSV(row.billingGrandTotal),
        formatForCSV(row.billingRemarks),
        formatForCSV(billCreatedAt),
        formatForCSV(row.billingCreatedBy?.first_name),
        formatForCSV(billUpdatedAt),
        formatForCSV(row.billingUpdatedBy?.first_name),


        formatForCSV(row.costFreightCharges),
        formatForCSV(row.costAhcCharges),
        formatForCSV(row.costMawbCharges),
        formatForCSV(row.costIclCnCharges),
        formatForCSV(row.costPickupCharges),
        formatForCSV(row.costDeliveryCharges),
        formatForCSV(row.costIclHandlingCharges),
        formatForCSV(row.costPeakHandlingCharges),
        formatForCSV(row.costLoadingCharges),
        formatForCSV(row.costUnloadingCharges),
        formatForCSV(row.costFuelSurcharges),
        formatForCSV(row.costPackingMaterialCharges),
        formatForCSV(row.costDataLoggerCharges),
        formatForCSV(row.costWarehouseCharges),
        formatForCSV(row.costDetentionCharges),
        formatForCSV(row.costDgCharges),
        formatForCSV(row.costDemurrageCharges),
        formatForCSV(row.costMiscellaneousCharges),
        formatForCSV(row.costDiscountedAmount),
        formatForCSV(row.costInsurancePercentage),
        formatForCSV(row.costFov),
        formatForCSV(row.costTaxableAmount),
        formatForCSV(row.costTaxablePercentage),
        formatForCSV(row.costTaxAmount),
        formatForCSV(row.costGrandTotal),
        formatForCSV(row.costRemarks),
        formatForCSV(costCreatedAt),
        formatForCSV(row.costCreatedBy?.first_name),
        formatForCSV(costUpdatedAt),
        formatForCSV(row.costUpdatedBy?.first_name),


        formatForCSV(row.billingIsGst),
        formatForCSV(row.isInvoiced),
        formatForCSV(row.invoiceNo),
        formatForCSV(row.profitOrLoss),

      ];
      csvData.push(data);
    });

    const csv = csvData.map((e) => e.join(',')).join('\n');
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    const currentDate = new Date();
    const formattedDate = this.formatDateForFilename(currentDate);
    saveAs(blob, 'MIS - ' + formattedDate + '.csv');
  }

  formatDateForFilename(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}-${month}-${year} (${hours}.${minutes})`;
  }

  //
  // addPackage() {
  //   const new_package = {
  //     id: this.add_packages.length + 1,
  //     length: null,
  //     breadth: null,
  //     height: null,
  //     weight_per_package: null,
  //     total_weight: null,
  //     package_no: null,
  //     volume_weight: null,
  //     chargeable_weight: null,
  //     totalvolumeweight_per_pack: null
  //   }
  //   this.add_packages.push(new_package);
  //   let j = 1;
  //   this.add_packages.forEach(element => {
  //     element.id = j;
  //     j++;
  //
  //   });
  // }
  // removeItemOnce(arr, value) {
  //   var index = value;
  //   if (index > -1) {
  //     arr.splice(index, 1);
  //   }
  //   return arr;
  // }
  //
  // deletePackage(id) {
  //   for (let index = 0; index < this.add_packages.length; index++) {
  //     if (this.add_packages[index].id == id) {
  //       this.removeItemOnce(this.add_packages, index)
  //     }
  //   }
  //   let j = 1;
  //   this.add_packages.forEach(element => {
  //     element.id = j;
  //     j++;
  //
  //   });
  //   let length = this.add_packages.length;
  //   this.addeachPackageCharged(length);
  // }
  // add_packages = [
  //   {
  //     id: 1,
  //     length: null,
  //     breadth: null,
  //     height: null,
  //     weight_per_package: null,
  //     total_weight: null,
  //     package_no: null,
  //     volume_weight: null,
  //     chargeable_weight: null,
  //     totalvolumeweight_per_pack: null
  //   }
  // ]
  // totalNoOfPackages;
  // totalWeight;
  // totalChargableWeight;
  // weightDivisor;
  // consignmentNo;
  // async addeachPackageCharged(id) {
  //   this.totalNoOfPackages = this.consignment_details.totalNoOfPackages;
  //   this.totalWeight = this.consignment_details.totalWeight;
  //   this.totalChargableWeight = this.consignment_details.totalChargableWeight;
  //   for (let index = 0; index < this.add_packages.length; index++) {
  //     this.add_packages[index].total_weight = Number(this.add_packages[index].package_no) * this.add_packages[index].weight_per_package;
  //     if (this.add_packages[index].id == id) {
  //       this.add_packages[index].volume_weight = Math.ceil(Number(this.add_packages[index].length) * Number(this.add_packages[index].breadth) * Number(this.add_packages[index].height) / Number(this.weightDivisor));
  //       // console.log(this.packages[index].volume_weight + "  This is volume weight")
  //
  //       if (this.add_packages[index].volume_weight > this.add_packages[index].weight_per_package) {
  //
  //         this.add_packages[index].chargeable_weight = Number(this.add_packages[index].volume_weight) * Number(this.add_packages[index].package_no);
  //       }
  //       else {
  //         this.add_packages[index].chargeable_weight = this.add_packages[index].total_weight;
  //       }
  //     }
  //     this.totalNoOfPackages = Number(this.totalNoOfPackages) + Number(this.add_packages[index].package_no);
  //     this.totalWeight = this.totalWeight + Number(this.add_packages[index].total_weight);
  //     this.totalChargableWeight = Math.ceil(this.totalChargableWeight + this.add_packages[index].chargeable_weight)
  //   }
  // }
  //
  // async addnewPackages() {
  //   let TOKEN = this.authService.getAuthToken();
  //
  //   let cons =
  //     {
  //       "totalNoOfPackages": this.totalNoOfPackages,
  //       "totalWeight": this.totalWeight,
  //       "totalChargableWeight": this.totalChargableWeight,
  //       "packages": this.add_packages,
  //     }
  //   let checkLength = this.add_packages.filter(eachObj => (
  //     eachObj.length == null
  //   ))
  //   let checkBreath = this.add_packages.filter(eachObj => (
  //     eachObj.breadth == null
  //   ))
  //   let checkHeight = this.add_packages.filter(eachObj => (
  //     eachObj.height == null
  //   ))
  //   let checkpack = this.add_packages.filter(eachObj => (
  //     eachObj.package_no == null
  //   ))
  //   let checkweight = this.add_packages.filter(eachObj => (
  //     eachObj.weight_per_package == null
  //   ))
  //   if (checkLength.length != 0) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'ERROR',
  //       // text: 'Package dimetion should not exceed 90cm X 90cm X 90cm '
  //       text: 'Enter Length',
  //       timer: 5000
  //
  //     });
  //     this.loading = false;
  //   } else if (checkBreath.length != 0) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'ERROR',
  //       // text: 'Package dimetion should not exceed 90cm X 90cm X 90cm '
  //       text: 'Enter Breath',
  //       timer: 5000
  //
  //     });
  //     this.loading = false;
  //   } else if (checkHeight.length != 0) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'ERROR',
  //       // text: 'Package dimetion should not exceed 90cm X 90cm X 90cm '
  //       text: 'Enter Height',
  //       timer: 5000
  //
  //     });
  //     this.loading = false;
  //   } else if (checkpack.length != 0) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'ERROR',
  //       // text: 'Package dimetion should not exceed 90cm X 90cm X 90cm '
  //       text: 'Enter No. Of Packages',
  //       timer: 5000
  //
  //     });
  //     this.loading = false;
  //   } else if (checkweight.length != 0) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'ERROR',
  //       // text: 'Package dimetion should not exceed 90cm X 90cm X 90cm '
  //       text: 'Enter Weight per package',
  //       timer: 5000
  //
  //     });
  //     this.loading = false;
  //   } else if (cons.totalChargableWeight > 1000) {
  //     Swal.fire({
  //       icon: 'warning',
  //       title: 'Odd Size Shipment',
  //       html: 'Email: <a href="#" id="contactEmail">balajiks@iclscm.com</a> <br> contact: +91 9121006802',
  //       timer: 5000
  //
  //     });
  //
  //     document.getElementById("contactEmail").addEventListener("click", function() {
  //       window.open("https://mail.google.com/mail/?view=cm&to=balajiks@iclscm.com");
  //     });
  //     this.loading = false;
  //   }
  //   else {
  //
  //     await fetch(API_URL + "/addpackage/" + this.consignment_id,
  //       {
  //         method: "POST",
  //         body: JSON.stringify(cons),
  //         headers: {
  //           "Content-Type": "application/json",
  //           "Authorization": "Bearer " + TOKEN
  //         }
  //       })
  //       .then((response) => response.json())
  //       .then((result) => {
  //         Swal.fire({
  //           position: 'center',
  //           icon: 'success',
  //           title: 'Packages Added',
  //           showConfirmButton: false,
  //           timer: 5000
  //         });
  //         // alert("Changes Saved");
  //         // this.getconsignmentsList();
  //         this.viewchange('view', this.consignment_id);
  //         // console.log(result);
  //       })
  //       .catch((error) => {
  //         Swal.fire({
  //           position: 'center',
  //           icon: 'error',
  //           title: 'Error',
  //           showConfirmButton: false,
  //           timer: 5000
  //         });
  //         console.error("Error", error);
  //         alert(error)
  //       });
  //   }
  // }
  //
  //
  //
  //
  // async deletePackages(id) {
  //   let TOKEN = this.authService.getAuthToken();
  //
  //   await fetch(API_URL + "/deletePackage/" + id,
  //     {
  //       method: "DELETE",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Authorization": "Bearer " + TOKEN
  //       }
  //     })
  //     .then((response) => response.json())
  //     .then((result) => {
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'success',
  //         title: 'Package Deleted',
  //         showConfirmButton: false,
  //         timer: 5000
  //       });
  //       // alert("Changes Saved");
  //       // this.getconsignmentsList();
  //       this.viewconsignmentById(this.consignment_id)
  //       this.viewchange('view', this.consignment_id);
  //       // console.log(result);
  //     })
  //     .catch((error) => {
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'error',
  //         title: 'Error',
  //         showConfirmButton: false,
  //         timer: 5000
  //       });
  //       console.error("Error", error);
  //       alert(error)
  //     });
  // }
  //
  // goto(filter: string) {
  //   this.route.navigate(['/corporatebooking'],
  //     {
  //       queryParams: {
  //         type: filter,
  //       },
  //     }
  //   );
  // }
  //
  // onKeyDown(event: KeyboardEvent) {
  //   // Allow backspace key to execute normally
  //   if (event.key === 'Backspace') {
  //     return;
  //   }
  //   // Allow only numbers
  //   if (isNaN(Number(event.key))) {
  //     event.preventDefault();
  //   }
  // }
  displayFn(city: Cities): String {
    return city?.name ? city.name : '';
  }
  displayFn1(city: Cities): String {
    return city?.name ? city.name : '';
  }
  //
  // shouldShowOption(cons_st: any): boolean {
  //   if (this.userDetails.userTypes[0].name == "Pickup Delivery Agent") {
  //     // Hide specific values for manager role, customize the condition as needed.
  //     return cons_st?.name != 'Cancelled';
  //   } else {
  //     // For other roles, you can define your own conditions.
  //     return true; // Show all options for other roles.
  //   }
  // }
  //
  // // gotoBillingInfo(id){
  // //   this.route.navigate(['/billingInfoPage',id])
  // // }
  // // gotoCostCenter(id){
  // //   this.route.navigate(['/costCenter',id])
  // // }
  //
  //
  //
  //
  // openBillDialog(id): void {
  //   const dialogRef = this.dialog.open(BillingOfflineDialog, {
  //     data: {consignmentId: id}
  //   });
  // }
  //
  // async gotoBillingInfo(id){
  //   // console.log("before------->")
  //   await this.commonApi.getBillingPriceByConsignmentId(id)
  //   // console.log("after------->")
  //   if (this.commonApi.isBillingCreated){
  //     this.route.navigate(['/billingInfoPage',id])
  //   }
  //   else{
  //     this.openBillDialog(id)
  //   }
  // }
  //
  // openCostDialog(id): void {
  //   const dialogRef = this.dialog.open(CostCenterOfflineDialog, {
  //     data: {consignmentId: id}
  //   });
  // }
  //
  // async gotoCostCenter(id){
  //   await this.commonApi.getBillingPriceByConsignmentId(id)
  //   if (this.commonApi.isBillingCreated){
  //     this.route.navigate(['/costCenter',id])
  //   }
  //   else{
  //     this.openCostDialog(id)
  //   }
  // }
  //
  //
  DeleteConsignment(consignment) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to Delete ' + consignment.consignment_no + ' ?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.Deletedata(consignment.id);
      }
    });
  }
  gotoConsignmentView(id: any, consignment_no: any): void {
    this.route.navigate(['/viewconsignment', id]);
  }

  gotoConsignmentEdit(id: any, consignment_no: any): void {
    this.route.navigate(['/consignmentEdit', id]);
  }

  gotoAceOnline() {
    this.route.navigate(['/iclproducts'],
      {
        queryParams: {
          type: 'Ace',
        },
      });
  }
}
