import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';
import { API_URL } from '../../../../../Global_API_URL.js';
import { Airport, CustomerList, individuallist } from '../../golbalInterfaces.js';
import { getAirports } from '../../getAirportAirlines.js';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { getCitiesById, getCountries, getPincodesById, getStatesById } from '../../getCities.js';
import { AuthService } from '../../../auth.service';
import { Location } from "@angular/common";

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';


@Component({
  selector: 'app-bookings-upload',
  templateUrl: './bookings-upload.component.html',
  styleUrls: ['./bookings-upload.component.sass'],
})
export class BookingsUploadComponent implements OnInit {
  constructor(private modalService: NgbModal, private authService: AuthService, private locationService: Location) {
    this.authService.getAuthToken();
  }

  async ngOnInit() {
    this.customerList = await this.getCustomerList();
    this.airports = await getAirports();
    // this.countries = getCountries();
    if (this.consignment_details.originLocation == null && this.consignment_details.destinationLocation == null) {
      this.myControl1.disable();
    } else if (this.consignment_details.originLocation != null && this.consignment_details.destinationLocation != null) {
      this.myControl1.disable();
    }
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.customerList.slice();
      }),
    );
    this.filteredAirports = this.myControl1.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this._filter1(name) : this.airports.slice())),
    );
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0].name == 'Admin' || this.userDetails.userTypes[0].name == 'Supervisor') {
      this.displayAdminandPDA = false;
    }

    this.getOfficeLocations();
  }

  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;

  isLiner = false;
  val = 1;

  userDetails
  business_states
  business_cities
  business_pincodes
  business_sel_country
  business_sel_state
  business_sel_pincodes
  user_sel_pincodes
  user_sel_state
  user_sel_city
  user_sel_country
  customer_with_id;
  customer_created = false;
  erroraddress = false;
  errorShipper = false;
  errorRepresentative = false;
  displayAdminandPDA = true;
  selectedAddress = null;
  user_states
  user_cities
  user_pincodes
  countries = getCountries();
  emailExist = false;
  phoneNoExist = false;
  CustomerExist = false;
  reprsentativeAddress;
  public Representativeload = false;
  representativeList = [];
  customerList: CustomerList[];
  IndividualList: individuallist[];
  airports: Airport[] = getAirports();
  show = false;
  filteredOptions: Observable<CustomerList[]>;
  filteredAirports: Observable<Airport[]>
  myControl = new FormControl();
  myControl1 = new FormControl();
  OfficesList
  public loadingSecound = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public loadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  consignment_details = {
    id: null,
    consignmentType: null,
    weightUnit: 1,
    currency: 1,
    weightDivisor: 6000,
    consignmentNo: null,
    airlinesId: null,
    isStopover: null,
    totalTransitTime: null,
    senderIndividual: null,
    isIndividualCustomer: null,
    paymentCompletedAt: null,
    isXrayScreening: null,
    isXrayCertification: null,
    isDryIce: null,
    totalWeight: null,
    totalChargableWeight: null,
    planSelected: null,
    senderId: null,
    pickupAddress: null,
    receiverId: null,
    isDocumentUploaded: null,
    isInsured: false,
    totalShippingCost: null,
    paymentMode: null,
    isPaymentCompleted: null,
    isActive: null,
    createdAt: null,
    updatedAt: null,
    createdBy: null,
    updatedBy: null,
    totalNoOfPackages: null,
    destinationLocation: null,
    consignmentSubType: null,
    originLocation: null,
    dimensionuUnit: 1,
    insuranceCoverage: 0,
    totalConsignmentValue: null,
    shippingCost: 0,
    invoiceCreatedDate: '',
    invoiceReferenceNo: null,
    productType: null,
    remarks: null,
  }
  indiviudal_user = {
    id: null,
    firstName: '',
    middleName: '',
    lastName: '',
    avatarUrl: '',
    email: '',
    emailForMasterGST: '',
    phone: '',
    whatsapp: '',
    designation: null,
    employmentNo: null,
    billingAddress: {
      pincode: {
        pin_number: ''
      },
      address: ''
    },
    isSuperUser: null,
    userName: '',
    passwordHash: null,
    lastLoggedInAt: null,
    lastLoggedInIp: null,
    userType: null,
    customerType: 2,
    orgCustomerId: { id: null },
    aadhaarNumber: null,
    drivingLicense: null,
    gstNumber: null,
    panNumber: null,
    gstDetails: null,
    panDetails: null,
    aadhaarDetails: null,
    isActive: true,
    createdAt: null,
    updatedAt: null,
    createdBy: null,
    updatedBy: null,
  }
  customer_shipper = {
    id: null,
    firstName: '',
    address_1: '',
    city_1: null,
    creditPeriod: null,
    credit_value: null,
    email_1: '',
    zip_code: '',
    isCreditExtended: null,
    isKycApproved: null,
    kycApprovedAt: null,
    office_location: null,
    lastName: '',
    phone_1: '',
    customerTypeId: 2,
    kycApprovedBy: null,
    isBusinessAccount: true,
    pan: '',
    gst: '',
    Id_type: null,
    Id_value: null,
    otp_varified: null,
    isSezCustomer: false,
  }
  createnewformreset() {
    this.indiviudal_user = {
      id: null,
      firstName: '',
      middleName: '',
      lastName: '',
      avatarUrl: '',
      email: '',
      emailForMasterGST: '',
      phone: '',
      whatsapp: '',
      designation: null,
      employmentNo: null,
      billingAddress: {
        pincode: {
          pin_number: ''
        },
        address: ''
      },
      isSuperUser: null,
      userName: '',
      passwordHash: null,
      lastLoggedInAt: null,
      lastLoggedInIp: null,
      userType: null,
      customerType: 2,
      orgCustomerId: { id: null },
      aadhaarNumber: null,
      drivingLicense: null,
      gstNumber: null,
      panNumber: null,
      gstDetails: null,
      panDetails: null,
      aadhaarDetails: null,
      isActive: true,
      createdAt: null,
      updatedAt: null,
      createdBy: null,
      updatedBy: null,
    }
    this.customer_shipper = {
      id: null,
      firstName: '',
      address_1: '',
      city_1: null,
      creditPeriod: null,
      credit_value: null,
      email_1: '',
      zip_code: '',
      isCreditExtended: null,
      isKycApproved: null,
      kycApprovedAt: null,
      office_location: null,
      lastName: '',
      phone_1: '',
      customerTypeId: 2,
      kycApprovedBy: null,
      isBusinessAccount: true,
      pan: '',
      gst: '',
      Id_type: null,
      Id_value: null,
      otp_varified: null,
      isSezCustomer: false,
    };
    this.business_sel_country = null;
    this.business_sel_state = null;
    this.user_sel_country = null;
    this.user_sel_state = null;
    this.customer_created = false;
    this.errorShipper = false;
    this.change_business();
  }
  resetform() {
    this.indiviudal_user = {
      id: null,
      firstName: '',
      middleName: '',
      lastName: '',
      avatarUrl: '',
      email: '',
      emailForMasterGST: '',
      phone: '',
      whatsapp: '',
      designation: null,
      employmentNo: null,
      billingAddress: {
        pincode: {
          pin_number: ''
        },
        address: ''
      },
      isSuperUser: null,
      userName: '',
      passwordHash: null,
      lastLoggedInAt: null,
      lastLoggedInIp: null,
      userType: null,
      customerType: 2,
      orgCustomerId: { id: null },
      aadhaarNumber: null,
      drivingLicense: null,
      gstNumber: null,
      panNumber: null,
      gstDetails: null,
      panDetails: null,
      aadhaarDetails: null,
      isActive: true,
      createdAt: null,
      updatedAt: null,
      createdBy: null,
      updatedBy: null,
    };
    this.user_sel_country = null;
    this.user_sel_state = null;
    this.errorRepresentative = false;
    this.change_business();
  }

  pageview = 'table';
  viewchanges(val: string) {
    this.pageview = val;
  }

  emailregex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/
  emailFormControl = new FormControl('', [
    Validators.pattern(this.emailregex),
  ]);

  panregex = /^[a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][0-9][0-9][0-9][0-9][a-zA-Z]$/
  PanFormControl = new FormControl('', [
    Validators.pattern(this.panregex),
  ]);
  gstregex = /^[0-9][0-9][a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][0-9][0-9][0-9][0-9][a-zA-Z][0-9a-zA-Z][a-zA-Z][0-9a-zA-Z]$/
  gstFormControl = new FormControl('', [
    Validators.pattern(this.gstregex),
  ]);

  change_business() {
    if (this.val == 1) {
      this.indiviudal_user.customerType = 2;
    } else {
      this.indiviudal_user.customerType = 1;
    }
  }

  open(content5) {
    this.modalService.open(content5, {
      size: 'lg',
    });
  }

  displayFn(user: CustomerList): String {
    return user && user.firstName ? user.firstName : '';
  }

  private _filter(name: String): CustomerList[] {
    const filterValue = name.toLowerCase();
    return this.customerList.filter((option) => option.firstName.toLowerCase().includes(filterValue));
  }

  private _filter1(name: String): Airport[] {
    const filterValue = name.toLowerCase();
    return this.airports.filter((option) => option.city.name.toLowerCase().includes(filterValue));
  }

  async getIndividaulStates() {
    this.user_states = getStatesById(this.user_sel_country);
    this.user_sel_state = null;
    // this.indiviudal_user.city = null;
  }

  async getIndividaulCities() {
    this.user_cities = getCitiesById(this.user_sel_state);
    this.user_sel_pincodes = null;
    // this.indiviudal_user.city = null;
  }

  async getIndividualPincodes() {
    this.user_pincodes = getPincodesById(this.user_sel_city);
    this.indiviudal_user.billingAddress.pincode.pin_number = null;
  }

  async getBusinessStates() {
    this.business_states = getStatesById(this.business_sel_country);
    this.business_sel_state = null;
    this.customer_shipper.city_1 = null;
  }

  async getBusinessCities() {
    this.business_cities = getCitiesById(this.business_sel_state);
    this.business_sel_pincodes = getPincodesById(this.customer_shipper.city_1);
    this.customer_shipper.city_1 = null;
  }

  async getBusinessPincodes() {
    this.business_pincodes = getPincodesById(this.customer_shipper.city_1);
    this.customer_shipper.zip_code = null;
  }

  async findbusiness() {
    const TOKEN = this.authService.getAuthToken();
    this.consignment_details.senderId = this.customer_shipper.id;
    if (this.customer_shipper.id != null || this.customer_shipper.id != undefined) {
      this.errorShipper = true;
    }
    await fetch(API_URL + '/org_users/' + this.customer_shipper.id,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
          'X-Active': 'true',
        },
      })
      .then((response) => response.json())
      .then((result) => {
        this.representativeList = result.data;
        this.show = true;
      });
  }

  async findindividual() {
    const TOKEN = this.authService.getAuthToken();
    this.errorShipper = true;
    this.errorRepresentative = true;
    await fetch(API_URL + '/org_users/' + this.indiviudal_user.id,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
          'X-Active': 'true',
        },
      })
      .then((response) => response.json())
      .then((result) => {
        this.representativeList = result.data;
        this.getAddressList(this.indiviudal_user.id);
        this.show = true;
      });
  }

  async finduser() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/app_users/' + this.consignment_details.senderIndividual,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      })
      .then((response) => response.json())
      .then((result) => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'User Found',
          showConfirmButton: false,
          timer: 5000,
        });
        this.indiviudal_user = result.data[0];
        if (this.indiviudal_user.customerType == 1) {
          this.val = 2;
        } else {
          this.val = 1;
          this.consignment_details.senderId = this.indiviudal_user.orgCustomerId.id;
        }
        this.customer_shipper = result.data[0].orgCustomerId;
        this.getAddressList(this.consignment_details.senderIndividual);
        this.consignment_details.senderIndividual = this.indiviudal_user.id;
      })
      .catch((error) => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'User Not Found',
          showConfirmButton: false,
          timer: 5000,
        });
      });
  }

  async getCustomerList() {
    const TOKEN = this.authService.getAuthToken();
    let businessList: CustomerList[];
    await fetch(API_URL + '/totalcustomers',
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      })
      .then((response) => response.json())
      .then(async (result) => {
        businessList = result;
      })
      .catch((error) => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'User Not Found',
          showConfirmButton: false,
          timer: 5000,
        });
      });
    return businessList;
  }

  async emailAppuser() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/appuser/email/' + this.indiviudal_user.email,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.json())
      .then((result) => {
        if (result.data[0] == null) {
          this.emailExist = false;
        } else {
          this.emailExist = true;
        }
      })
      .catch((error) => {
        console.error('Error' + error);
      });
  }

  async phoneNoExistAppuser() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/appuser/phone/' + this.indiviudal_user.phone,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.json())
      .then((result) => {
        if (result.data[0] == null) {
          this.phoneNoExist = false;
        } else {
          this.phoneNoExist = true;
        }
      })
      .catch((error) => {
        console.error('Error' + error);
      });
  }

  async nameExist() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/customer/name/' + this.customer_shipper.firstName,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      })
      .then((response) => response.json())
      .then((result) => {
        if (result.data.length == 0) {
          this.CustomerExist = false;
        } else {
          this.CustomerExist = true;
        }
      })
      .catch((error) => {
        console.error('Error' + error);
      });
  }

  async createRep() {
    const TOKEN = this.authService.getAuthToken();
    this.indiviudal_user.userName = this.indiviudal_user.email;
    this.indiviudal_user.orgCustomerId = this.consignment_details.senderId;
    if (this.indiviudal_user.firstName == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s First Name ',
        timer: 5000,

      });
    } else if (this.indiviudal_user.lastName == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Last Name ',
        timer: 5000,

      });
    } else if (this.emailExist == true) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Email Alredy Exist ',
        timer: 5000,

      });
    } else if (this.indiviudal_user.email == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s email ',
        timer: 5000,

      });
    } else if (!(this.emailregex.test(this.indiviudal_user.email))) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        showConfirmButton: false,
        text: 'Please Enter Valid Email',
        timer: 5000,

      });
    } else if (this.phoneNoExist == true) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Phone Number ',
        timer: 5000,

      });
    } else if (this.indiviudal_user.phone == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Phone Number ',
        timer: 5000,

      });
    } else if (isNaN(parseInt(this.indiviudal_user.phone)) == true || this.indiviudal_user.phone.length < 10) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Valid User\'s Phone Number',
        timer: 5000,

      });
    } else if (this.indiviudal_user.billingAddress.address == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Address ',
        timer: 5000,

      });
    } else if (this.user_sel_city == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select The User\'s City ',
        timer: 5000,

      });
    } else if (this.indiviudal_user.billingAddress.pincode.pin_number == '' || this.indiviudal_user.billingAddress.pincode.pin_number == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Zip Code ',
        timer: 5000,

      });
    } else {
      this.Representativeload = true;
      await fetch(API_URL + '/register',
        {
          method: 'POST',
          body: JSON.stringify(this.indiviudal_user),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then((result) => {
          Swal.fire({
            icon: 'success',
            title: 'Sender created',
            showConfirmButton: false,
            timer: 5000,
          });
          this.Representativeload = false;
          this.errorRepresentative = true;
          this.indiviudal_user = result.data[0];
          this.consignment_details.senderIndividual = this.indiviudal_user.id;
          this.customer_created = true;
          this.findbusiness();
        });
    }
  }

  async selectRep(id) {
    const TOKEN = this.authService.getAuthToken();
    this.consignment_details.senderIndividual = id;
    this.errorRepresentative = true;
    await fetch(API_URL + '/app_users/' + this.consignment_details.senderIndividual,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      })
      .then((response) => response.json())
      .then((result) => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Representative selected',
          showConfirmButton: false,
          timer: 5000,
        });
        this.indiviudal_user = result.data[0];
        this.getAddressList(id);
        this.customer_shipper = result.data[0].orgCustomerId;
      })
      .catch((error) => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'User Not Found',
          showConfirmButton: false,
          timer: 5000,
        });
      });
  }

  getAirportFromCity(city_id) {
    const airport = this.airports.filter((option) => {
      if (option.isActive && option.ob_active) {
        return option.city.name;
      } else {
        return 'City Not Serviceable';
      }
    });
    for (let i = 0; i < airport.length; i++) {
      if (airport[i].id == city_id) {
        return airport[i];
      }
    }
  }


  pickupLocation = '';
  selectAddress(id) {
    this.selectedAddress = id;
    const add = this.reprsentativeAddress.filter((option) => option.id == id);
    this.consignment_details.originLocation = this.getAirportFromCity(add[0]?.pincodeId?.cityId.obAirport.id);
    this.pickupLocation = add[0].id;
    if (this.pickupLocation != null || this.pickupLocation != undefined) {
      this.erroraddress = true;
      // console.log("Address ", add[0].id);
    }
  }

  async getAddressList(id) {
    const TOKEN = this.authService.getAuthToken();
    let addressList;
    if (id != null) {
      await fetch(API_URL + '/address/' + id,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then(async (result) => {
          addressList = result.data;
        })
        .catch((error) => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'User Not Found',
            showConfirmButton: false,
            timer: 5000,
          });
        });
    }
    this.reprsentativeAddress = addressList;
  }

  async getIndividualList() {
    const TOKEN = this.authService.getAuthToken();
    let individualList: individuallist[];
    await fetch(API_URL + '/individual_user',
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      })
      .then((response) => response.json())
      .then(async (result) => {
        individualList = result;
      })
      .catch((error) => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'User Not Found',
          showConfirmButton: false,
          timer: 5000,
        });
      });
    return individualList;
  }

  async createCustomer() {
    const TOKEN = this.authService.getAuthToken();
    this.indiviudal_user.userName = this.indiviudal_user.email;
    this.indiviudal_user.userType = 5;
    if (this.customer_shipper.firstName == '' && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Company Name ',
        timer: 5000,

      });
    } else if (this.CustomerExist == true) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Customer Name Already Exist',
        timer: 5000,

      });
    } else if (this.customer_shipper.email_1 == '' && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter Email Address ',
        timer: 5000,

      });
    } else if (!(this.emailregex.test(this.indiviudal_user.email)) && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        showConfirmButton: false,
        text: 'Please Enter Email Address ',
        timer: 5000,

      });
    } else if (this.customer_shipper.phone_1 == '' && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Company Phone Number ',
        timer: 5000,

      });
    } else if ((this.customer_shipper.phone_1).length < 10 && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Valid Company Phone Number',
        timer: 5000,

      });
    } else if (isNaN(parseInt(this.customer_shipper.phone_1)) == true && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Valid Company Phone Number',
        timer: 5000,

      });
    } else if (this.customer_shipper.address_1 == '' && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Company Address',
        timer: 5000,

      });
    } else if (this.customer_shipper.city_1 == null && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Company City',
        timer: 5000,

      });
    } else if (this.customer_shipper.zip_code == '' && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Company Zip Code',
        timer: 5000,

      });
    } else if (this.customer_shipper.office_location == '' && this.indiviudal_user.customerType == 2) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select the Billing Branch',
        timer: 5000,

      });
    } else if (this.indiviudal_user.firstName == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s First Name ',
        timer: 5000,

      });
    } else if (this.indiviudal_user.lastName == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Last Name ',
        timer: 5000,

      });
    } else if (this.emailExist == true) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Email Exist',
        timer: 5000,

      });
    } else if (this.indiviudal_user.email == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s email ',
        timer: 5000,

      });
    } else if (!(this.emailregex.test(this.indiviudal_user.email))) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        showConfirmButton: false,
        text: 'Please Enter Valid Email',
        timer: 5000,

      });
    } else if (this.phoneNoExist == true) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Phone No. Exist',
        timer: 5000,

      });
    } else if (this.indiviudal_user.phone == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Phone Number ',
        timer: 5000,

      });
    } else if (isNaN(parseInt(this.indiviudal_user.phone)) == true || this.indiviudal_user.phone.length < 10) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The Valid User\'s Phone Number',
        timer: 5000,

      });
    } else if (this.indiviudal_user.billingAddress.address == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Address ',
        timer: 5000,

      });
    } else if (this.user_sel_city == '') {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Select The User\'s City ',
        timer: 5000,

      });
    } else if (this.indiviudal_user.billingAddress.pincode.pin_number == '' || this.indiviudal_user.billingAddress.pincode.pin_number == null) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Please Enter The User\'s Zip Code ',
        timer: 5000,

      });
    } else {
      this.loadingSecound = true;
      this.Representativeload = true;
      if (this.indiviudal_user.customerType == 2) {
        await fetch(API_URL + '/customer',
          {
            method: 'POST',
            body: JSON.stringify(this.customer_shipper),
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + TOKEN,
            },
          })
          .then((response) => response.json())
          .then(async (result) => {
            this.customer_with_id = result.data[0];
            this.indiviudal_user.orgCustomerId = result.data[0].id;
            this.consignment_details.senderId = result.data[0].id;
            await fetch(API_URL + '/register',
              {
                method: 'POST',
                body: JSON.stringify(this.indiviudal_user),
                headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
                  'Authorization': 'Bearer ' + TOKEN,
                },
              })
              .then((response) => response.json())
              .then((res) => {
                Swal.fire({
                  icon: 'success',
                  title: 'Sender created',
                  showConfirmButton: false,
                  timer: 5000,
                });
                this.Representativeload = false;
                this.indiviudal_user = res.data[0];
                this.consignment_details.senderIndividual = this.indiviudal_user.id;
                this.customer_created = true;
              });
          })
          .catch((error) => {
            console.error('Error', error);
            this.Representativeload = false;
            alert(error);
          });
      } else {
        await fetch(API_URL + '/register',
          {
            method: 'POST',
            body: JSON.stringify(this.indiviudal_user),
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + TOKEN,
            },
          })
          .then((response) => response.json())
          .then((result) => {
            Swal.fire({
              icon: 'success',
              title: 'Sender created',
              showConfirmButton: false,
              timer: 5000,
            });
            this.Representativeload = false;
            this.indiviudal_user = result.data[0];
            this.consignment_details.senderIndividual = this.indiviudal_user.id;
            this.customer_created = true;
            this.findbusiness();
          })
          .catch((error) => {
            console.error('Error', error);
            this.Representativeload = false;
            alert(error);
          });
      }
      this.customerList = await this.getCustomerList();
      this.IndividualList = await this.getIndividualList();
      this.loadingSecound = false;
    }
  }

  csvfile;
  public Loading = false;
  getCorporateFiles(event) {
    this.csvfile = event.target.files[0];
  }

  async uploadCorporateDocument() {
    const TOKEN = this.authService.getAuthToken();
    this.Loading = true;
    if (this.csvfile == null) {
      this.showSweetAlert('Upload later', 'error');
      this.Loading = false; // Set Loading to false when there is no file to upload
    } else {
      const head: any = {
        'Authorization': 'Bearer ' + TOKEN,
        'Accept': 'application/json',
      };
      const formdata = new FormData();
      formdata.append('file', this.csvfile);
      formdata.append('senderId', this.consignment_details.senderId);
      formdata.append('senderIndividualId', this.consignment_details.senderIndividual);
      formdata.append('pickupLocation', this.pickupLocation);
      try {
        const response = await fetch(API_URL + '/bulkUpload/upload/offlinebookings', {
          method: 'POST',
          body: formdata,
          headers: head,
        });
        const responseBody = await response.json();
        if (response.status === 200) {
          this.showSweetAlert(`${this.csvfile.name} File Uploaded`, 'success', '/consignment');
        } else {
          const errorMessage = this.formatErrorMessage(responseBody.message);
          this.showSweetAlert(errorMessage, 'error', '/bookingsupload');
        }
      } catch (error) {
        console.error('Error', error);
        this.showSweetAlert(error.message || 'An error occurred', 'error');
      } finally {
        this.Loading = false; // Ensure Loading is set to false in all cases
      }
    }
  }

  formatErrorMessage(message: string): string {
    const lines = message.split('\n');
    const formattedLines = lines.map((line, index) => {
      if (index % 2 === 1) {
        return `<b>${line.trim()}</b>`;
      } else {
        return line.replace(/\{([^}]+)\}/g, (_, data) => `<b>{${data}}</b>`);
      }
    });
    return formattedLines.join('<br>');
  }

  showSweetAlert(message: string, icon: 'success' | 'error', redirect?: string) {
    Swal.fire({
      title: 'Alert',
      html: message,
      icon: icon,
      confirmButtonText: 'OK',
      showCloseButton: true,
      allowOutsideClick: false,
      width: '60%',
      customClass: {
        popup: 'custom-popup-class',
      },
    }).then((result) => {
      if (result.isConfirmed && redirect) {
        window.location.href = redirect;
      }
    });
  }

  async getOfficeLocations() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/office_locations',
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      })
      .then((response) => response.json())
      .then((json) => {
        this.OfficesList = json.data;
      });
    return this.OfficesList;
  }

  clearData() {
    this.customer_shipper = {
      id: null,
      firstName: '',
      address_1: '',
      city_1: null,
      creditPeriod: null,
      credit_value: null,
      email_1: '',
      zip_code: '',
      isCreditExtended: null,
      isKycApproved: null,
      kycApprovedAt: null,
      office_location: null,
      lastName: '',
      phone_1: '',
      customerTypeId: 2,
      kycApprovedBy: null,
      isBusinessAccount: true,
      pan: '',
      gst: '',
      Id_type: null,
      Id_value: null,
      otp_varified: null,
      isSezCustomer: false,
    };
  }

  goBack() {
    this.locationService.back();
  }

  downloadCSVFormat() {
    // const filePath = '/assets/file-format/Bookings Upload Format.csv';
    const filePath = '/assets/images/Shipment Ingestion.csv';
    const link = document.createElement('a');
    link.href = filePath;
    link.download = 'Bookings Upload Format.csv';
    link.click();
  }
}
