import {Cities, States} from '../../golbalInterfaces';
import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {API_URL} from '../../../../../Global_API_URL';
import Swal from 'sweetalert2';
import {getCities, getCitiesById, getCountries, getPincodesById, getStates, getStatesById} from '../../getCities.js';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ConfirmDialog} from '../../Components/confirmDialog/confirmDialog.component';
import {MatDialog} from '@angular/material/dialog';
import {AuthService} from '../../../auth.service';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import {HttpClient} from '@angular/common/http';
import {HttpService} from "../../../services/http.service";
import {Router} from "@angular/router";

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';
@Component({
  selector: 'app-retail-customer-table',
  templateUrl: './retail-customer-table.component.html',
  styleUrls: ['./retail-customer-table.component.sass'],
})
export class RetailCustomerTableComponent implements OnInit {
    @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
    @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
    public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
    public loading = false;
    public primaryColour = SecondaryBlue;
    public secondaryColour = SecondaryGrey;
    public coloursEnabled = false;
    public loadingTemplate: TemplateRef<any>;
    public config = {
      animationType: ngxLoadingAnimationTypes.none,
      primaryColour: this.primaryColour,
      secondaryColour: this.secondaryColour,
      tertiaryColour: this.primaryColour,
      backdropBorderRadius: '3px',
    };

  pageview = 'table';
  customer_with_id;
  user_id={
    id: null,
    first_name: null,
    middle_name: null,
    last_name: null,
    email: null,
    phone: null,
    whatsapp: null,
    address: null,
    country: null,
    state_id: null,
    city: null,
    zip_code: null,
    aadhaar_number: null,
    driving_license: null,
    pan_number: null,
    gst_number: null,
  }
  indiviudal_user = {
    'id': null,
    'first_name': '',
    'middle_name': '',
    'last_name': '',
    'avatar_url': '',
    'email': '',
    'phone': '',
    'whatsapp': '',
    'designation': null,
    'address': '',
    'city': {id: null},
    'zip_code': '',
    'office_location': null,
    'is_super_user': false,
    'user_name': '',
    'password_hash': null,
    'last_logged_in_at': null,
    'last_logged_in_ip': null,
    'is_active': true,
    'created_at': null,
    'updated_at': null,
    'created_by': null,
    'updated_by': null,
    'customer_type': 1,
    'org_customer_id': null,
    'customer_id': null,
    'aadhaar_number': null,
    'driving_license': null,
    'user_type': 5,
    'employment_no': null,
    'pan_number': null,
    'gst_number': null,
  }

  customer_shipper = {
    id: null,
    firstName: '',
    address1: '',
    city1: '',
    creditPeriod: '',
    credit_value: null,
    email1: '',
    zip_code: '',
    isCreditExtended: null,
    isKycApproved: null,
    kycApprovedAt: null,
    lastName: '',
    phone1: '',
    customerTypeId: 2,
    kycApprovedBy: null,
    isBusinessAccount: true,
    pan: '',
    gst: '',
    Id_type: null,
    Id_value: '',
    otp_varified: null,
  }
  user_cities
  user_states
  user_pincodes
  user_countries
  user_sel_state
  user_sel_country
  user_sel_pincodes


  business_cities
  business_states
  business_pincodes = []
  business_sel_country
  business_sel_state


  states = getStates();
  countries = getCountries();
  cities = getCities();
  filterUsersList = []

  displaytoAdmin = false;
  displaytoAccountant=false;
  userDetails;
  TOKEN;
  constructor(private matDialog: MatDialog, private authService : AuthService, private http: HttpClient, private httpService: HttpService, private route: Router) {
    this.TOKEN = this.authService.getAuthToken();
  }
  async ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0].name == 'Admin') {
      this.displaytoAdmin = true;
    } else if (this.userDetails.userTypes[0].name == 'Accountant') {
      this.displaytoAccountant = true;
    } else {
      this.displaytoAdmin = false;
    }
    this.filtersretail();
    this.states = await getStates();
    this.individuallist = this.getIndividualList();
  }



  gstPrefixCode;
  gstPrefixCodeLength = 2;
  stateDetails : any;
  getStateById(id) {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.TOKEN,
    };
    this.http.get(API_URL + '/states/' + id, {headers}).subscribe(
        (data:Object) => {
          this.stateDetails = data;
          this.gstPrefixCode = this.stateDetails?.data[0]?.gstPrefixCode;
          this.gstPrefixCodeLength = this.gstPrefixCode.length;
        },
    );
  }

  async getUserList() {
    const TOKEN = this.authService.getAuthToken();
    let users;
    await fetch(API_URL + '/customer_user',
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+TOKEN,
          },
        })

    // Converting received data to JSON
        .then((response) => response.json())
        .then((json) => {
          users = json;
        // console.log(users);
        });
    return users;
  }
  filterParams='';
  pageSizeOptions=[10, 30];
  pagenation= {
    col_name: 'id',
    page_size: 10,
    page_no: 0,
    sort_dir: 'DESC',
  }
  individuallist=null;

  filter() {
    this.filterParams='?';
    if (this.retailfilters.customer_id!='' && this.retailfilters.customer_id!= null) {
      this.filterParams = this.filterParams + 'firstName=' + this.retailfilters.customer_id+'&';
    }
    if (this.retailfilters.cityid != '' && this.retailfilters.cityid!= null) {
      this.filterParams = this.filterParams + 'cityid=' + this.retailfilters.cityid.id + '&';
    }
    if (this.retailfilters.stateid != '' && this.retailfilters.stateid != null) {
      this.filterParams = this.filterParams + 'stateid=' + this.retailfilters.stateid.id + '&';
    }
    if (this.retailfilters.email != null && this.retailfilters.email != '') {
      this.filterParams = this.filterParams + 'email=' + this.retailfilters.email + '&';
    }
    if (this.retailfilters.phone != null && this.retailfilters.phone != '') {
      this.filterParams = this.filterParams + 'phone=' + this.retailfilters.phone + '&';
    }
    if (this.retailfilters.status != 'all') {
      this.filterParams = this.filterParams + 'customerVerified=' + this.retailfilters.status + '&';
    }
    this.filterParams= this.filterParams+'page_no='+this.pagenation.page_no+'&page_size='+this.pagenation.page_size+'&col_name='+this.pagenation.col_name+'&sort_dir='+this.pagenation.sort_dir;
    return this.filterParams;
  }

  async getIndividualList() {
    const TOKEN = this.authService.getAuthToken();
    // console.log(API_URL)
    const api= '/Customer_User';
    const params = this.filter();
    await fetch(API_URL + api+params, {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })
    // Converting received data to JSON
        .then((response) => response.json())
        .then((json) => {
          this.individuallist = json;
        });
    return this.individuallist;
  }

  existingGst;
  existingPan;
  viewchange(val, user) {
    if (val == 'reset') {
      this.loading = false;
      this.pageview = val;
      this.user_id = user;
      this.userid = user;
      this.resetForm();
    } else {
      this.loading = false;
      this.pageview = val;
      this.user_id=user;
      this.userid = user;

      this.business_sel_country = user.city.state_id.country.id;
      this.business_sel_state = user.city.state_id.id;
      this.user_id.city = user.city.id;
      this.indiviudal_user.zip_code = user.zip_code;
      // console.log('Selected Zip Code:', this.indiviudal_user.zip_code);

      this.business_states = getStatesById(this.business_sel_country);
      this.business_cities = getCitiesById(this.business_sel_state);
      this.business_pincodes =getPincodesById(this.user_id.city);
      this.getStateById(this.business_sel_state);
      this.existingGst = user.gst;
      this.existingPan = user.pan;
    }
  }

  dataSource : any;
  city;

  retailfilters : any= {
    customer_id: '',
    email: null,
    phone: null,
    stateid: '',
    cityid: '',
    status: 'all'
  }

  displayedColumns = ['customer_id', 'email', 'phone', 'stateid', 'cityid', 'status', 'Active']

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  find() {
    this.filtersretail();
  }
  reset() {
    this.retailfilters.customer_id = '';
    this.retailfilters.cityid = '';
    this.retailfilters.email = '';
    this.retailfilters.phone = '';
    this.retailfilters.stateid = '';
    this.search='';
    this.search1='';
    this.search2='';
    this.filtersretail();
  }
  retail=null;
  async filtersretail() {
    const TOKEN = this.authService.getAuthToken();
    // console.log(API_URL)
    const params = this.filter();
    this.loading = true;
    const api = '/appusersfilters1';
    await fetch(API_URL + api + params, {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })

    // Converting received data to JSON
        .then((response) => response.json())
        .then((json) => {
          if (json.status == 400) {
            this.retail = [];
          } else {
            this.retail = json.data;
            this.filterUsersList = json.data.reverse();
            // Apply status filter locally
            if (this.retailfilters.status !== 'all') {
              this.filterUsersList = this.filterUsersList.filter((user) => {
                return this.retailfilters.status === 'verified' ? user.customerVerified : !user.customerVerified;
              });
            }
            // this.dataSource = new MatTableDataSource(json.data.reverse());
            this.dataSource = new MatTableDataSource(this.filterUsersList);
            // this.dataSource = new MatTableDataSource(json.data.reverse());
            // this.paginator =
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        });
    this.loading = false;
    return this.retail;
    // return loads;
  }

  async Deletedata(id) {
    const TOKEN = this.authService.getAuthToken();
    // console.log(id)
    const data = JSON.stringify({
      'isActive': 0,
    });
    await fetch(API_URL + '/app_users_isactive/' + id,
        {
          method: 'PATCH',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
          body: data,
        })

    // Converting received data to JSON
        .then((response) => response.text());
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'User Deactivated Successfully',
      timer: 5000,
    });
    // .then(result => console.log(result))
    this.filtersretail()
        .catch((error) => console.error('error', error));
  }
  async Restoredata(id) {
    const TOKEN = this.authService.getAuthToken();
    // console.log(id)
    const data = JSON.stringify({
      'isActive': 1,
    });
    await fetch(API_URL + '/app_users_isactive/' + id,
        {
          method: 'PATCH',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
          body: data,
        })

    // Converting received data to JSON
        .then((response) => response.text());
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'User activated Successfully',

      timer: 5000,
    });
    this.filtersretail()
        .catch((error) => console.error('error', error));
  }
  DeleteUser(user) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to deactivate ' + user.firstName + ' ?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.loading = true;
        this.Deletedata(user.id);
      }
    });
  }
  ActivateUser(user) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to activate ' + user.firstName + ' ?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.loading = true;
        this.Restoredata(user.id);
      }
    });
  }
  onKeyDown(event: KeyboardEvent) {
    // Allow backspace key to execute normally
    if (event.key === 'Backspace') {
      return;
    }
    // Allow only numbers
    if (isNaN(Number(event.key))) {
      event.preventDefault();
    }
  }

  userid: string;
  passwordFlag = false;
  retypePassword: string | null = null;
  newpassword: string | null = null;

  checkPassword(): void {
    this.passwordFlag = this.newpassword !== this.retypePassword;
  }

  newPassLengthCheck = false;

  checkNewPassword(): void {
    this.newPassLengthCheck = this.newpassword.length < 6;
  }

  resetPassword(): Promise<void> {
    this.checkPassword();
    if (this.newpassword === null || this.newpassword === '' || this.newpassword.trim() === '') {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Enter New Password',
        timer: 5000,
      });
      return;
    }

    if (this.retypePassword === null || this.retypePassword === '' || this.retypePassword.trim() === '') {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please re-enter the new password for confirmation',
        timer: 5000,
      });
      return;
    } else if (this.newpassword?.length < 6) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Password should have minimum of 6 characters',
        timer: 5000,
      });
      return;
    } else if (this.passwordFlag) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'New Password and Confirm Password should match',
        timer: 5000,
      });
      return;
    } else {
      this.loading = true;
      const requestBody = {
        email: this.userid,
        newpassword: this.newpassword,
      };

      this.httpService.post(API_URL + '/resetpassword', requestBody, null, null)
        .subscribe((result) => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Password Changed Successfully',
              timer: 5000,
            });
            this.viewchange('table', this.user_id.id);
            this.filtersretail();
            this.resetForm();
            this.loading = false;
          }, (error) => {
            this.loading = false;
            console.error('Error Resetting Password:', error);
          },
        );
    }
  }
  search
  searchByCons(con) {
    // console.log(con,this.filterUsersList)
    this.dataSource = new MatTableDataSource(this.filterUsersList.filter((cons) => cons.email.includes(con)));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  search1
  searchByCons1(con) {
    // console.log(con,this.filterUsersList)
    this.dataSource = new MatTableDataSource(this.filterUsersList.filter((cons) => cons.phone.includes(con)));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  search2
  searchByCons2(con) {
    // console.log(con,this.filterUsersList)
    this.dataSource = new MatTableDataSource(this.filterUsersList.filter((cons) => cons.firstName.toLowerCase().includes(con)));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  displayStateFn(state: States): String {
    return state.name ? state.name : '';
  }


  getCities(id) {
    this.cities = getCitiesById(id);
  }

  displayCityFn(city: Cities): String {
    return city.name ? city.name : '';
  }

  resetForm() {
    this.retypePassword = null;
    this.newpassword = null;
    this.passwordFlag=null;
  }

  gotoRetailCustomerCreatePage() {
   this.route.navigate(["/retailCustomerCreate"])
  }

  gotoRetailCustomerEditPage(id) {
   this.route.navigate(["/retailCustomerEdit",id])
  }

  applyFilter(){
    this.filtersretail();
  }

}
