<div *ngIf="loading" class="loader-container">
  <ngx-loading [show]="loading"
               [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
               [template]="loadingTemplate"></ngx-loading>
</div>
<div class="col-md-12">
  <container-element [ngSwitch]="pageview">
    <some-element *ngSwitchCase="'image4'">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title font-size-lg text-capitalize fw-Bold">
            Image Upload
          </div>
        </div>
        <div class="container p-4">
          <div class="row">
            <div class="col-md-10 center p-1">
              <label style="margin-bottom: 10px;">
                Image 4
              </label>
              <input class="form-control" type="file" (change)="getFiles($event)">
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>
            <div class="mb-2 me-2 btn btn-primary" (click)="uploadimage4()">Save</div>
          </div>
        </div>
      </div>
    </some-element>
    <some-element *ngSwitchCase="'pod'">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title font-size-lg text-capitalize fw-Bold">
            File Upload
          </div>
        </div>
        <div class="container p-4">
          <div class="row">
            <div class="col-md-10 center p-1">
              <label style="margin-bottom: 10px;">
                POD
              </label>
              <input class="form-control" type="file" (change)="getFiles($event)">
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>
            <div class="mb-2 me-2 btn btn-primary" (click)="uploadPOD()">Save</div>
          </div>
        </div>
      </div>
    </some-element>
    <some-element *ngSwitchCase="'datalogger'">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title font-size-lg text-capitalize fw-Bold">
            File Upload
          </div>
        </div>
        <div class="container p-4">
          <div class="row">
            <div class="col-md-10 center p-1">
              <label style="margin-bottom: 10px;">
                Data Logger
              </label>
              <input class="form-control" type="file" (change)="getFiles($event)">
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>
            <div class="mb-2 me-2 btn btn-primary" (click)="uploadDatafiles()">Save</div>
          </div>
        </div>
      </div>
    </some-element>
    <some-element *ngSwitchCase="'vehiclecalibration'">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title font-size-lg text-capitalize fw-Bold">
            File Upload
          </div>
        </div>
        <div class="container p-4">
          <div class="row">
            <div class="col-md-10 center p-1">
              <label style="margin-bottom: 10px;">
                Vehicle Calibration
              </label>
              <input class="form-control" type="file" (change)="getFiles($event)">
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>
            <div class="mb-2 me-2 btn btn-primary" (click)="uploadVechilefiles()">Save</div>
          </div>
        </div>
      </div>
    </some-element>
    <some-element *ngSwitchCase="'otherdocuments'">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title font-size-lg text-capitalize fw-Bold">
            File Upload
          </div>
        </div>
        <div class="container p-4">
          <div class="row">
            <div class="col-md-10 center p-1">
              <label style="margin-bottom: 10px;">
                Other Documents
              </label>
              <input class="form-control" type="file" (change)="getFiles($event)">
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>
            <div class="mb-2 me-2 btn btn-primary" (click)="uploadOthersfiles()">Save</div>
          </div>
        </div>
      </div>
    </some-element>
    <some-element *ngSwitchCase="'temp'">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title font-size-lg text-capitalize fw-Bold">
            File Upload
          </div>
        </div>
        <div class="container p-4">
          <div class="row">
            <div class="col-md-10 center p-1">
              <label style="margin-bottom: 10px;">
                Temperature Record
              </label>
              <input class="form-control" type="file">
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>
            <div class="mb-2 me-2 btn btn-primary" (click)="uploadTemp()">Save</div>
          </div>
        </div>
      </div>
    </some-element>
    <some-element *ngSwitchCase="'image3'">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title font-size-lg text-capitalize fw-Bold">
            Image Upload
          </div>
        </div>
        <div class="container p-4">
          <div class="row">
            <div class="col-md-10 center p-1">
              <label style="margin-bottom: 10px;">
                Image 3
              </label>
              <input class="form-control" type="file" (change)="getFiles($event)">
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>
            <div class="mb-2 me-2 btn btn-primary" (click)="uploadimage3()">Save</div>
          </div>
        </div>
      </div>
    </some-element>
    <some-element *ngSwitchCase="'image2'">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title font-size-lg text-capitalize fw-Bold">
            Image Upload
          </div>
        </div>
        <div class="container p-4">
          <div class="row">
            <div class="col-md-10 center p-1">
              <label style="margin-bottom: 10px;">
                Image 2
              </label>
              <input class="form-control" type="file" (change)="getFiles($event)">
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>
            <div class="mb-2 me-2 btn btn-primary" (click)="uploadimage2()">Save</div>
          </div>
        </div>
      </div>
    </some-element>
    <some-element *ngSwitchCase="'image1'">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title font-size-lg text-capitalize fw-Bold">
            Image Upload
          </div>
        </div>
        <div class="container p-4">
          <div class="row">
            <div class="col-md-10 center p-1">
              <label style="margin-bottom: 10px;">
                Image 1
              </label>
              <input class="form-control" type="file" (change)="getFiles($event)">
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="btn-actions-pane-right p-2">
            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>
            <div class="mb-2 me-2 btn btn-primary" (click)="uploadimage1()">Save</div>
          </div>
        </div>
      </div>
    </some-element>
    <some-element *ngSwitchCase="'view'">
      <div class="row">
        <div class="card col-md-12">
          <div class="card-header">

            <div class="card-header-title font-size-lg text-capitalize fw-Bold"><i class="fa fa-arrow-left" style="cursor: pointer" (click)="gotoConsignmentTablePage()"></i>
              Consignment No.
            </div>
            <div *ngFor="let con of consignmentlists?.data"
                 class="card-header-title font-size-lg text-capitalize fw-Bold text-primary mx-4">
              {{con?.consignmentNo}} ({{con?.consignmentType?.name}})
            </div>
            <div class="btn-actions-pane-right p-2" *ngFor="let con of consignmentlists.data">
<!--              <div class="mb-2 me-2 btn btn-primary" (click)="gotoBillingInfo(con.id)">-->
<!--                Billing Info</div>-->
              <div class="mb-2 me-2 btn btn-success" (click)="getRecord(con.id)">
                Download CN</div>
            </div>
          </div>
          <div class="container m-2">

            <div class="row">
              <div class="col-md-12">
                <div *ngFor="let con of consignmentlists.data">
                  <div class="row">
                    <div class="col-md-3 fw-bold">Customer Name</div>
                    <div class="col-md-3" *ngIf="userDetails.customerType == 1"> {{ con?.senderIndividual?.firstName + con?.senderIndividual?.lastName }} </div>
                    <div class="col-md-3" *ngIf="userDetails.customerType == 2"> {{con?.senderId?.firstName !=null
                      ? con?.senderId?.firstName : con?.senderIndividual?.firstName }} </div>
                    <div class="col-md-3 fw-bold border-start">Consignee Name</div>
                    <div class="col-md-3"> {{con?.receiverId?.firstName}}</div>
                  </div>
                  <mat-divider class="m-1"></mat-divider>
                  <div class="row ">
                    <div class="col-md-3 fw-bold">Origin</div>
                    <div class="col-md-3">{{ con?.originLocation?.city.name ||
                    (con?.pickupAddress?.pincodeId?.cityId?.name ||
                      'N/A')}}</div>
                    <div class="col-md-3 fw-bold border-start">Destination</div>
                    <div class="col-md-3">
                      {{ (con?.receiverId?.city?.name ||
                      'N/A') || con?.destinationLocation?.city.name }}
                    </div>
                  </div>
                  <mat-divider class="m-1"></mat-divider>
                  <div class="row ">
                    <div class="col-md-3 fw-bold">Consignment Type</div>
                    <div class="col-md-3"> {{con.consignmentType.name}} </div>
                    <div class="col-md-3 fw-bold border-start">Consignment SubType</div>
                    <div class="col-md-3"> {{con.consignmentSubType.description}} </div>
                  </div>
                  <mat-divider class="m-1"></mat-divider>
                  <div class="row ">
                    <div class="col-md-3 fw-bold">Product Mode</div>
                    <div class="col-md-3"> {{con?.series_ref?.product_mode}} </div>
                    <div class="col-md-3 fw-bold border-start">Product Type</div>
                    <div class="col-md-3"> {{con?.series_ref?.code}} </div>
                  </div>
                  <mat-divider class="m-1"></mat-divider>
                  <div class="row ">
                    <div class="col-md-3 fw-bold">Created At</div>
                    <div class="col-md-3"> {{ con?.createdAt | date:'dd-MM-yyyy HH:mm:ss' }} </div>
                    <div class="col-md-3 fw-bold border-start">Updated At</div>
                    <div class="col-md-3" *ngIf="con.updatedAt!=null"> {{ con?.updatedAt | date:'dd-MM-yyyy HH:mm:ss' }} </div>
                  </div>
                  <mat-divider class="m-1"></mat-divider>
                  <div class="row ">
                    <div class="col-md-3 fw-bold ">Consignment Value</div>
                    <div class="col-md-3"> INR {{con.consignmentValue}}</div>

                    <div class="col-md-3 fw-bold border-start ">Status</div>
                    <div class=" col-md-3">
                      <div
                        *ngIf="con?.trackingId?.status.id != 16 && con?.trackingId?.status.id!= 19 && con?.trackingId?.status.id!=18 && con?.trackingId?.status.id!=36"
                        class="badge bg-pill bg-primary">
                        {{con?.trackingId?.status?.name}}
                      </div>
                      <div *ngIf="con?.trackingId?.status.id == 16" class="badge bg-pill bg-success">
                        {{con?.trackingId?.status?.name}}
                      </div>
                      <div *ngIf="con?.trackingId?.status.id == 19" class="badge bg-pill bg-orange-400">
                        {{con?.trackingId?.status?.name}}
                      </div>
                      <div *ngIf="con?.trackingId?.status.id==18" class="badge bg-pill bg-danger">
                        {{con?.trackingId?.status?.name}}
                      </div>
                      <div *ngIf="con?.trackingId?.status.id==36" class="badge bg-pill bg-danger">
                        {{con?.trackingId?.status?.name}}
                      </div>
                    </div>
                    <!-- <div *ngIf="con.trackingId" class="badge bg-pill bg-primary col-md-3">
                      {{con.trackingId?.status?.name}}
                    </div> -->
                  </div>
                  <mat-divider class="m-1"></mat-divider>
                  <div class="row ">
                    <div class="col-md-3 fw-bold">Temperature Range</div>
                    <div *ngIf="con?.requiredTemperature?.range == null" class="col-md-3">
                      -
                    </div>
                    <div *ngIf="con?.requiredTemperature?.range != null" class="col-md-3"> {{con?.requiredTemperature?.range}}&deg;C </div>
                    <div class="col-md-3 fw-bold border-start">Invoice No</div>
                    <div *ngIf="invoiceId == null" class="col-md-3"> - </div>
                    <div *ngIf="billCreated && invoiceId != null" class="col-md-3"> {{InvoiceData.invoiceNo}} </div>
                  </div>
                  <mat-divider class="m-1"></mat-divider>
                  <!--                  <div class="row ">-->
                  <!--                    <div class="col-md-3 fw-bold">AWB Number</div>-->
                  <!--                    <div class="col-md-3"> {{con?.preManifestationId?.awb_number}} </div>-->
                  <!--                    <div class="col-md-3 fw-bold border-start">Manifest Id</div>-->
                  <!--                    <div class="col-md-3"> {{con?.preManifestationId?.manifestIdLeg1}} - {{con?.preManifestationId?.manifestIdLeg2}} </div>-->
                  <!--                  </div>-->
                  <!--                  <mat-divider class="mb-3"></mat-divider>-->
                  <div class="row ">
                    <div class="col-md-3 fw-bold">Container Size</div>
                    <div *ngIf="con?.containerSize?.name == null" class="col-md-3">
                      -
                    </div>
                    <div *ngIf="con?.containerSize?.name != null" class="col-md-3">
                      {{ con?.containerSize?.name}} </div>
                      <div class="col-md-3 fw-bold border-start">Commodity Type</div>
                      <div class="col-md-3"> {{con?.commodityType?.commodityType}} </div>
                  </div>
                  <mat-divider class="m-1"></mat-divider>
                  <div class="row ">
                    <div class="col-md-3 fw-bold">Tentative Pickup Date</div>
                    <div *ngIf="con?.tentative_pickup_time == null" class="col-md-3">
                      -
                    </div>
                    <div *ngIf="con?.tentative_pickup_time != null" class="col-md-3">
                      {{ con?.tentative_pickup_time | date:'dd-MM-yyyy' }} </div>
                    <div class="col-md-3 fw-bold border-start"></div>
                  </div>
                  <mat-divider class="mb-3"></mat-divider>
                  <div class="row">
                    <div class="text-primary fw-bold"> PACKAGES</div>
                  </div>
                  <mat-divider class="m-1"></mat-divider>
                  <table class="align-middle text-truncate  mb-0 table table-borderless table-hover">
                    <thead>
                    <tr>
                      <th class="text-center">Dimensions (L x B x H)</th>
                      <th class="text-center">Total Packages</th>
                      <th class="text-center">Weight Per Package</th>
                      <th class="text-center">Chargeable Weight</th>
                      <th class="text-center">Total Chargeable Weight</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let pack of con.packages">
                      <td class="text-center">
                        {{pack.length}}cm x {{pack.breadth}}cm x {{pack.height}}cm
                        <br />
                      </td>
                      <td class="text-center">
                        {{pack.package_no}}
                      </td>
                      <td class="text-center">
                        {{pack.weight_per_package}}Kg
                      </td>
                      <td class="text-center">
                        {{pack.total_weight}}Kg
                      </td>
                      <td class="text-center">
                        {{pack.chargeable_weight}}Kg
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <mat-divider class="m-1"></mat-divider>
                  <div class="row ">
                    <div class="col-md-3 fw-bold  ">Total Num of Packages</div>
                    <div class="col-md-3">
                      {{con.totalNoOfPackages}}
                    </div>
                  </div>
                  <mat-divider class="m-1"></mat-divider>
                  <div class="row ">
                    <div class="col-md-3 fw-bold ">Total Weight</div>
                    <div class="col-md-3"> {{con.totalWeight}}Kg </div>
                    <div class="col-md-3 fw-bold border-start ">Total Chargeable Weight</div>
                    <div class="col-md-3"> {{con.totalChargableWeight}}Kg </div>
                  </div>
                  <mat-divider class="m-1"></mat-divider>
                  <!-- <div class="row ">
                    <div class="col-md-3 fw-bold ">Taxable Amount</div>
                    <div class="col-md-3">
                      <span *ngIf="billCreated"> INR {{billingPriceTaxableAmount ? billingPriceTaxableAmount : 0}}</span>
                    </div>
                    <div class="col-md-3 fw-bold border-start">Grand Total</div>
                    <div class="col-md-3">
                      <span *ngIf="billCreated"> INR {{billingPriceGrandTotal ? billingPriceGrandTotal : 0}}</span>
                    </div>
                  </div> -->
                  <!-- <mat-divider class="mb-3"></mat-divider> -->
                  <div class="row">
                    <div class=" col-md-6 text-primary fw-bold"> SHIPPER ADDRESS</div>
                    <div class=" col-md-6 text-primary fw-bold"> CONSIGNEE ADDRESS</div>
                  </div>
                  <mat-divider class="m-1"></mat-divider>
                  <div class="row ">
                    <div class="col-md-3 fw-bold">Pickup Address</div>
                    <div class="col-md-3">{{con?.pickupAddress?.address}},
                      {{con?.pickupAddress?.pincodeId?.cityId?.name}},{{con?.pickupAddress?.pincodeId?.cityId?.state_id?.name}},
                      <br>
                      {{con?.pickupAddress?.pincodeId?.cityId?.state_id?.country?.name}},{{con?.pickupAddress?.pincodeId?.pin_number}}
                    </div>
                    <div class="col-md-3 fw-bold border-start ">Drop Address</div>
                    <div class="col-md-3">
                      {{con?.receiverId?.address + "," + con?.receiverId?.city?.name + "," +
                    con?.receiverId?.city?.state_id?.name}}
                      <br>
                      {{con?.receiverId?.city?.state_id?.country?.name}},{{con?.receiverId?.zipCode}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">

              <div class="btn-actions-pane-right p-2">
                <div class="mb-2 me-2 btn btn-danger" (click)="gotoConsignmentTablePage()">Back</div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
              <div class="card-header-title font-size-lg text-capitalize fw-Bold">
                   Documents Section
              </div>
          </div>
          <div class="row">
              <ng-container *ngFor="let documentTypeWithDocuments of uploadedDocuments">
                  <div class="col-md-12">
                      <div class="fw-bold h4 margin-h-center">
                          {{ getDocumentTypeName(documentTypeWithDocuments?.documentType?.id) }}
                      </div>
                  </div>

                  <!-- Display Uploaded Documents -->
                  <ng-container *ngFor="let document of documentTypeWithDocuments.documents">
                      <div class="col-md-4">
                          <div class="font-icon-wrapper">
                              <a (click)="downloadDocument(document.id)" matTooltip="Download">
                                  <i class="fa fa-fw text-success" matTooltip="Download" aria-hidden="true" style="font-size: 50px; border: none;"></i>
                              </a>
                          </div>
                      </div>
                  </ng-container>
              </ng-container>
          </div>
      </div>
  </div>

      <div class="row">
        <div class="col-md-7">
          <div class="row">

            <div class="card mt-2">
              <div class="card-header">
                <div class="card-header-title font-size-lg text-capitalize fw-Bold">
                  Images
                </div>
              </div>
              <div class="row ">
                <div class="col-md-6">
                  <div class="fw-bold h4 margin-h-center pt-4 text-center ">
                    Image 1
                    <a [href]="image_1" download="image_1.jpg"
                       *ngIf="consignmentlists.data[0].image1!=null">
                      <button class="btn-shadow btn btn-primary mt-2" matTooltip="Download"><i
                        class="pe-7s-download"></i></button>
                    </a>
                    <div class=" fw-normal text-danger mt-4" *ngIf="consignmentlists.data[0].image1==null"
                         style="font-size: 15px;">
                      Image 1 is not uploaded
                    </div>
                  </div>
                  <div class="row">
                    <div class="icon-wrapper-alt" *ngIf="consignmentlists.data[0].image1!=null">
                      <img [src]='image_1' (click)="open(content5, image_1)" style="height: 200px; width: 200px;"
                           alt="Image" class="rounded mx-auto d-block p-1" alt="Responsive image">
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="fw-bold h4 margin-h-center pt-4 text-center">
                    Image 2
                    <a [href]="image_2" download="image_2.jpg"
                       *ngIf="consignmentlists.data[0].image2!=null">
                      <button class="btn-shadow btn btn-primary mt-2" matTooltip="Download"><i
                        class="pe-7s-download"></i></button>
                    </a>
                    <div class=" fw-normal text-danger mt-4" *ngIf="consignmentlists.data[0].image2==null"
                         style="font-size: 15px;">
                      Image 2 is not uploaded
                    </div>
                  </div>
                  <div class="row">
                    <div class="icon-wrapper-alt" *ngIf="consignmentlists.data[0].image2!=null">
                      <img [src]='image_2' (click)="open(content5, image_2)" style="height: 200px; width: 200px;"
                           alt="Image" class="rounded mx-auto d-block p-1" alt="Responsive image">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-md-6">
                  <div class="fw-bold h4 margin-h-center pt-4 text-center">
                    Image 3
                    <a [href]="image_3" download="image_3.jpg"
                       *ngIf="consignmentlists.data[0].image3!=null">
                      <button class="btn-shadow btn btn-primary mt-2" matTooltip="Download"><i
                        class="pe-7s-download"></i></button>
                    </a>
                    <div class=" fw-normal text-danger mt-4" *ngIf="consignmentlists.data[0].image3==null"
                         style="font-size: 15px;">
                      Image 3 is not uploaded
                    </div>
                  </div>
                  <div class="row">
                    <div class="icon-wrapper-alt" *ngIf="consignmentlists.data[0].image3!=null">
                      <img [src]='image_3' (click)="open(content5, image_3)" style="height: 200px; width: 200px;"
                           alt="Image" class="rounded mx-auto d-block  p-1" alt="Responsive image">
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="fw-bold h4 margin-h-center pt-4 text-center">
                    Image 4
                    <a [href]="image_4" download="image_4.jpg"
                       *ngIf="consignmentlists.data[0].image4!=null">
                      <button class="btn-shadow btn btn-primary mt-2" matTooltip="Download"><i
                        class="pe-7s-download"></i></button>
                    </a>
                    <div class="fw-normal text-danger mt-4" *ngIf="consignmentlists.data[0].image4==null"
                         style="font-size: 15px;">
                      Image 4 is not uploaded
                    </div>
                  </div>
                  <div class="row">
                    <div class="icon-wrapper-alt" *ngIf="consignmentlists.data[0].image4!=null">
                      <img [src]='image_4' (click)="open(content5, image_4)" style="height: 200px; width: 200px;"
                           alt="Image" class="rounded mx-auto d-block p-1" alt="Responsive image">
                    </div>
                  </div>
                  <ng-template #content5 let-c="close" let-d="dismiss">
                    <div class="modal-header">
                      <h4 class="modal-title fw-bold">View Image</h4>
                      <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
                      </button>
                    </div>
                    <div class="modal-body">
                      <img [src]='largeImage' alt="Image" class="rounded mx-auto d-block w-100 p-5"
                           alt="Responsive image">

                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <div class="row">

            <div class="card mt-2">
              <div class="card-header">
                <div class="card-header-title font-size-lg text-capitalize fw-Bold">
                  Documents
                </div>
              </div>
              <div class="row ">
                <div class="col-md-6">
                  <div class="fw-bold h4 margin-h-center pt-4 text-center ">
                    Data Logger File

                    <div class="fw-normal text-danger mt-4" *ngIf="consignmentlists.data[0].dataLoggerFile==null"
                         style="font-size: 15px;">
                      DataLoggerFile is not uploaded
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 m-auto">
                      <button class="font-icon-wrapper" *ngIf="consignmentlists.data[0].dataLoggerFile!=null"
                              (click)="getDatalogger()"><i class="fa fa-fw text-success "
                                                                                      matTooltip="Download" aria-hidden="true" style="font-size: 50px;"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="fw-bold h4 margin-h-center pt-4 text-center">
                    Vehicle Calibration File

                    <div class="fw-normal text-danger mt-4"
                         *ngIf="consignmentlists.data[0].vehicleCalibrationFile==null" style="font-size: 15px;">
                      VehicleCalibrationFile is not uploaded
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 m-auto">
                      <button class="font-icon-wrapper" *ngIf="consignmentlists.data[0].vehicleCalibrationFile!=null"
                              (click)="getvehiclecalibration()"><i class="fa fa-fw text-success"
                                                                                              aria-hidden="true" matTooltip="Download" style="font-size: 50px;"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="fw-bold h4 margin-h-center pt-4 text-center">
                    Other Documents File

                    <div class="fw-normal text-danger mt-4" *ngIf="consignmentlists.data[0].otherDocumentsFile==null"
                         style="font-size: 15px;">
                      OtherDocumentsFile is not uploaded
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 m-auto">
                      <button class="font-icon-wrapper" *ngIf="consignmentlists.data[0].otherDocumentsFile!=null"
                              (click)="getotherdocuments()"><i class="fa fa-fw text-success"
                                                                                          aria-hidden="true" matTooltip="Download" style="font-size: 50px;"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="fw-bold h4 margin-h-center pt-4 text-center">
                    Temperature Record File

                    <div class="fw-normal text-danger mt-4" *ngIf="consignmentlists.data[0].temperatureRecordFile==null"
                         style="font-size: 15px;">
                      TemperatureRecordFile is not uploaded
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 m-auto">
                      <button class="font-icon-wrapper" *ngIf="consignmentlists.data[0].temperatureRecordFile!=null"
                              (click)="gettemp()"><i class="fa fa-fw text-success"
                                                                                aria-hidden="true" matTooltip="Download" style="font-size: 50px;"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="fw-bold h4 margin-h-center pt-4 text-center">
                    POD
                    <a [href]="POD" download="POD.jpg" *ngIf="consignmentlists.data[0].pod!=null">
                      <button class="btn-shadow btn btn-primary mt-2" matTooltip="Download"><i
                        class="pe-7s-download"></i></button>
                    </a>
                    <div class="fw-normal text-danger mt-4" *ngIf="consignmentlists.data[0].pod==null"
                         style="font-size: 15px;">
                      POD is not uploaded
                    </div>
                  </div>
                  <div class="row">
                    <div class="icon-wrapper-alt" *ngIf="consignmentlists.data[0].pod!=null">
                      <img [src]='POD' (click)="open(content5, POD)" style="height: 200px; width: 200px;" alt="Image"
                           class="rounded mx-auto d-block p-1" alt="Responsive image">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="card mt-2">
            <div class="card-header">
              <div class="card-header-title font-size-lg text-capitalize fw-bold">
                Tracking
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="row">
                  <div class="col-md-6">
                    <div *ngFor="let tra of consignmentlists.data[0].trackingId.trackingStatusComments;let i=index">
                      <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column" *ngIf="tra.status.activeForCustomer == true " >
                        <div class="vertical-timeline-item vertical-timeline-element"
                        >
                          <div>
                          <span class="vertical-timeline-element-icon bounce-in" *ngIf="tra.status.activeForCustomer == true ">
                            <i class="badge badge-dot badge-dot-xl bg-success"
                               [ngClass]="tra.createdAt == null?'bg-light':'bg-primary'"></i>
                          </span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title" *ngIf="tra.status.activeForCustomer == true ">{{tra.status.name}}</h4>
                              <p *ngIf="tra.status.activeForCustomer == true" >{{tra.comment}}</p>
                              <input matInput readonly [(ngModel)]="origin" *ngIf="tra.status.activeForCustomer == true && tra.status.id>0 && tra.status.id<11">
                              <input matInput readonly [(ngModel)]="destination" *ngIf="tra.status.activeForCustomer == true && tra.status.id>10 && tra.status.id<17">
                              <!-- <p class="" *ngIf="tra.status.activeForCustomer == true ">{{getTime(tra.createdAt)}}</p> -->
                              <p class="" *ngIf="tra.status.activeForCustomer == true ">{{ tra?.createdAt | date:'dd-MM-yyyy HH:mm' }}</p>
                              <!-- <p class="">{{getTime(tra.createdAt)}}</p> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-6 col-md-6">
          <div class="col-md-6">
            <div class="card-body" *ngIf="current_status_id>=16">
              <div class="fw-bold h4 margin-h-center pt-4 text-center">
                <b style="color: rgb(11, 11, 242);">POD</b>
              </div>
              <div>
                <img [src]='POD' style="height: 200px; width: 200px;" alt="Image" class="rounded mx-auto d-block p-1" alt="Responsive image">
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </some-element>
<!--    <some-element *ngSwitchCase="'edit'">-->
<!--      <div class="main-card mb-3 card">-->
<!--        <div class="card-header">-->
<!--          <div class="card-header-title font-size-lg text-capitalize fw-normal">-->
<!--            Edit Consignments-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="container ">-->
<!--          <div class="row">-->
<!--            <div class="col-md-2 fw-bold h4">Consignment Number</div>-->
<!--            <div class="col-md-2 h4 text-primary">{{consignment_details.consignmentNo}}</div>-->
<!--          </div>-->
<!--          <div class="row">-->
<!--            <div class="col-md-2">-->

<!--              <mat-form-field style="width: 90%;" appearance="standard">-->
<!--                <mat-label for="totalWeight">-->
<!--                  Invoice Reference No.<span class="text-danger">*</span>-->
<!--                </mat-label>-->
<!--                <input matInput [(ngModel)]="consignment_details.invoiceReferenceNo">-->
<!--              </mat-form-field>-->

<!--            </div>-->
<!--            <div class="col-md-2">-->

<!--              <mat-form-field style="width: 90%;" appearance="standard">-->
<!--                <mat-label>-->
<!--                  Invoice Date-->
<!--                </mat-label>-->
<!--                <input matInput [(ngModel)]="consignment_details.invoiceCreatedDate"-->
<!--                       [matDatepicker]="picker" disabled>-->
<!--                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
<!--                <mat-datepicker #picker disabled="false"></mat-datepicker>-->
<!--              </mat-form-field>-->

<!--            </div>-->
<!--            <div class="col-md-2">-->

<!--              <mat-form-field style="width: 90%;" appearance="standard">-->
<!--                <mat-label for="totalWeight">-->
<!--                  Consignment Value-->
<!--                </mat-label>-->
<!--                <input id="Consignment Value" matInput [(ngModel)]="consignment_details.totalConsignmentValue">-->
<!--              </mat-form-field>-->

<!--            </div>-->
<!--            <div class="col-md-2">-->
<!--              <mat-form-field appearance="standard">-->

<!--                <mat-label for="dimensionuUnits">-->
<!--                  Weight Divisor<span class="text-danger">*</span>-->
<!--                </mat-label>-->
<!--&lt;!&ndash;                <mat-select id="dimensionuUnits" [(ngModel)]="consignment_details.weightDivisor"&ndash;&gt;-->
<!--&lt;!&ndash;                            (ngModelChange)="weightDivisorChange()">&ndash;&gt;-->

<!--&lt;!&ndash;                  <mat-option *ngFor="let w_div of weightDivisorList" [value]="w_div">&ndash;&gt;-->
<!--&lt;!&ndash;                    {{w_div}}&ndash;&gt;-->
<!--&lt;!&ndash;                  </mat-option>&ndash;&gt;-->
<!--&lt;!&ndash;                </mat-select>&ndash;&gt;-->
<!--              </mat-form-field>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="row">-->
<!--            <div class="col-md-8 row">-->
<!--              <mat-form-field style="width: 90%;" appearance="standard">-->
<!--                <mat-label for="totalWeight">-->
<!--                  Remarks-->
<!--                </mat-label>-->
<!--                <textarea id="Remarks" matInput [(ngModel)]="consignment_details.remarks">-->
<!--                  </textarea>-->
<!--              </mat-form-field>-->

<!--            </div>-->
<!--            <div class="col-md-2">-->

<!--              <mat-form-field style="width: 90%;" appearance="standard">-->
<!--                <mat-label>-->
<!--                  Created Date-->
<!--                </mat-label>-->
<!--                <input id="createdAt" matInput [(ngModel)]="consignment_details.createdAt"-->
<!--                       [matDatepicker]="pickers" disabled>-->
<!--                <mat-datepicker-toggle matSuffix [for]="pickers"></mat-datepicker-toggle>-->
<!--                <mat-datepicker #pickers disabled="false"></mat-datepicker>-->
<!--              </mat-form-field>-->

<!--            </div>-->
<!--          </div>-->
<!--          <div class="row" style="width: 100%;">-->
<!--            <mat-divider></mat-divider>-->
<!--            <div class="table-responsive">-->
<!--              <div class="table-responsive">-->
<!--                <table class="table p-2 ">-->
<!--                  <thead>-->
<!--                  <tr>-->

<!--                    <th>-->
<!--                      #-->
<!--                    </th>-->
<!--                    <th style="min-width: 150px;">-->
<!--                      Package Dimentions-->
<!--                    </th>-->
<!--                    <th class="mx-w-150">-->
<!--                      No of Packages-->
<!--                    </th>-->
<!--                    <th class="mx-w-150">-->
<!--                      Weight per Package-->
<!--                    </th>-->
<!--                    <th class="mx-w-150">-->
<!--                      Volume Weight-->
<!--                    </th>-->
<!--                    <th class="mx-w-200">-->
<!--                      Total Packages Weight-->
<!--                    </th>-->
<!--                    <th class="mx-w-200">-->
<!--                      Chargeable Weight-->
<!--                    </th>-->
<!--                    <th>-->
<!--                    </th>-->
<!--                  </tr>-->
<!--                  </thead>-->
<!--                  <tbody>-->
<!--                  <tr *ngFor="let package of changeable_packages">-->
<!--                    <td>-->
<!--                      {{package.id}}-->
<!--                    </td>-->
<!--                    <td class="mx-w-150">-->
<!--                      <div class="row">-->
<!--                        <div class="col-md-4">-->
<!--                          <input type="text" placeholder="L" aria-label="Number" matInput [(ngModel)]="package.length"-->
<!--                                 min="1">-->
<!--&lt;!&ndash;                          <span matSuffix>{{selectedDimention}}</span>&ndash;&gt;-->
<!--                        </div>-->
<!--                        <div class="col-md-4">-->

<!--                          <input type="text" placeholder="B" aria-label="Number" matInput-->
<!--                                 [(ngModel)]="package.breadth" min="1">-->
<!--&lt;!&ndash;                          <span matSuffix>{{selectedDimention}}</span>&ndash;&gt;-->
<!--                        </div>-->
<!--                        <div class="col-md-4">-->
<!--                          <input type="text" placeholder="H" aria-label="Number" matInput [(ngModel)]="package.height"-->
<!--                                 min="1">-->
<!--&lt;!&ndash;                          <span matSuffix>{{selectedDimention}}</span>&ndash;&gt;-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </td>-->
<!--                    <td class="mx-w-150">-->
<!--                      <mat-form-field class="py-0" style="width: 90%;" appearance="standard">-->
<!--                        <input type="text" placeholder="No of Packages" aria-label="Number" matInput-->
<!--                               [(ngModel)]="package.package_no" min="1">-->

<!--                      </mat-form-field>-->
<!--                    </td>-->
<!--                    <td class="mx-w-150">-->

<!--                      <mat-form-field class="py-0" style="width: 90%;" appearance="standard">-->
<!--                        <input type="text" placeholder="package weight" aria-label="Number" matInput-->
<!--                               [(ngModel)]="package.weight_per_package" min="1">-->
<!--&lt;!&ndash;                        <span matSuffix>{{selectedWeight}}</span>&ndash;&gt;-->
<!--                      </mat-form-field>-->
<!--                    </td>-->
<!--                    <td class="mx-w-150">-->
<!--                      <mat-form-field class="py-0" style="width: 90%;" appearance="standard">-->
<!--                        <input type="text" placeholder="Volume Weight" matInput disabled-->
<!--                               [(ngModel)]="package.volume_weight">-->
<!--                      </mat-form-field>-->
<!--                    </td>-->
<!--                    <td class="mx-w-200">-->
<!--                      <mat-form-field class="py-0" style="width: 90%;" appearance="standard">-->
<!--                        <input placeholder="Total Package Weight" matInput disabled-->
<!--                               [(ngModel)]="package.total_weight">-->
<!--&lt;!&ndash;                        <span matSuffix>{{selectedWeight}}</span>&ndash;&gt;-->

<!--                      </mat-form-field>-->
<!--                    </td>-->
<!--                    <td class="mx-w-200">-->
<!--                      <mat-form-field class="py-0" style="width: 90%;" appearance="standard">-->
<!--                        <input placeholder="Chargeable Weight" matInput disabled-->
<!--                               [(ngModel)]="package.chargeable_weight">-->
<!--&lt;!&ndash;                        <span matSuffix>{{selectedWeight}}</span>&ndash;&gt;-->

<!--                      </mat-form-field>-->
<!--                    </td>-->
<!--                  </tr>-->
<!--                  <tr>-->
<!--                    <td class="mx-w-150">-->
<!--                    </td>-->
<!--                    <td class="mx-w-150">-->
<!--                    </td>-->
<!--                    <th class="mx-w-150">-->
<!--                      <mat-label for="totalWeight">-->
<!--                        Total No. Packages-->
<!--                      </mat-label>-->
<!--                      <mat-form-field style="width: 90%;" appearance="standard">-->
<!--                        <input id="total_no_packages" matInput disabled value="Total No. Packages"-->
<!--                               [(ngModel)]="consignment_details.totalNoOfPackages">-->
<!--                      </mat-form-field>-->
<!--                    </th>-->


<!--                    <td>-->
<!--                    </td>-->
<!--                    <td>-->

<!--                    </td>-->
<!--                    <th class="mx-w-150">-->
<!--                      <mat-label for="totalWeight">-->
<!--                        Total Weight-->
<!--                      </mat-label>-->
<!--                      <mat-form-field style="width: 90%;" appearance="standard">-->
<!--                        <input id="totalWeight" matInput disabled value="Google"-->
<!--                               [(ngModel)]="consignment_details.totalWeight">-->
<!--&lt;!&ndash;                        <span matSuffix>{{ selectedWeight}}</span>&ndash;&gt;-->

<!--                      </mat-form-field>-->
<!--                    </th>-->
<!--                    <th class="mx-w-200">-->
<!--                      <mat-label for="totalWeight">-->
<!--                        Total Chargeable Weight-->
<!--                      </mat-label>-->
<!--                      <mat-form-field style="width: 90%;" appearance="standard">-->
<!--                        <input id="total_charged" matInput disabled value="Google"-->
<!--                               [(ngModel)]="consignment_details.totalChargableWeight">-->
<!--&lt;!&ndash;                        <span matSuffix>{{selectedWeight}}</span>&ndash;&gt;-->

<!--                      </mat-form-field>-->
<!--                    </th>-->
<!--                  </tr>-->
<!--                  </tbody>-->
<!--                </table>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="row p-2">-->
<!--              <div class="col-md-2 m-auto">-->
<!--                <div class="btn btn-primary btn-lg m-2" (click)="saveChanges()">-->
<!--                  Save-->
<!--                </div>-->
<!--                <div class="btn btn-danger btn-lg m-2" (click)="viewchange('table',0)">-->
<!--                  Cancel-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </some-element>-->
  </container-element>
</div>
