import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { AirlinePromotionsService } from '../../../modules/airline-promotions/airline-promotions.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ConfirmDialog } from '../../Components/confirmDialog/confirmDialog.component';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-airline-promotions',
  templateUrl: './airline-promotions.component.html',
  styleUrls: ['./airline-promotions.component.sass'],
})
export class AirlinePromotionsComponent implements OnInit {
  public loadingTemplate: TemplateRef<any>;
  public readonly ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageEvent: PageEvent;

  isLoading: boolean = true;

  constructor(private service: AirlinePromotionsService, private router: Router, private matDialog: MatDialog) { }

  ngOnInit(): void {
    this.getAllAilinePromotions();
  }


  getAllAilinePromotions() {
    this.isLoading = true;
    this.service.getAllAirlinePromotions(this.paginatorDetails.pageNo, this.paginatorDetails.pageSize, 'id', 'DESC')
      .subscribe((response) => {
        this.airlinePromotionsList = new MatTableDataSource<any>(response.data.content);

        this.paginatorDetails.pageNo = response.data.page.number;
        this.paginatorDetails.totalPages = response.data.page.totalPages;
        this.paginatorDetails.totalElements = response.data.page.totalElements;

        this.airlinePromotionsList.paginator = this.paginator;
        this.airlinePromotionsList.sort = this.sort;
        this.isLoading = false;
      },
        (error) => {
          this.isLoading = false;
        });
  }

  paginatorSizeOptions = [5, 10, 20, 30];

  airlinePromotionsList: MatTableDataSource<any> = new MatTableDataSource<any>();

  paginatorDetails = {
    pageNo: 0,
    pageSize: this.paginatorSizeOptions[0],
    totalPages: 0,
    totalElements: 0,

  }


  displayedColumns: string[] = ['S.No.', 'airline', 'flights', 'product', 'cnType', 'cnSubtype', 'validFrom', 'validUpto', 'startTime', 'endTime', 'actions'];

  ngAfterViewInit() {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.airlinePromotionsList.filter = filterValue.trim().toLowerCase();

    if (this.airlinePromotionsList.paginator) {
      this.airlinePromotionsList.paginator.firstPage();
    }
  }

  onPaginateChange(event: PageEvent) {
    const page = event.pageIndex;
    const size = event.pageSize;

    this.isLoading = true;
    this.service.getAllAirlinePromotions(page, size, null, null)
      .subscribe((response) => {
        this.airlinePromotionsList = new MatTableDataSource<any>(response.data.content);
        this.paginatorDetails.pageNo = response.data.page.number;
        this.paginatorDetails.totalPages = response.data.page.totalPages;
        this.paginatorDetails.totalElements = response.data.page.totalElements;
        this.isLoading = false;
      },
        (error) => {
          this.isLoading = false;
        });
  }

  sortData(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      return;
    }
    this.isLoading = true;
    this.service.getAllAirlinePromotions(this.paginatorDetails.pageNo, this.paginatorDetails.pageSize, sort.active, sort.direction.toUpperCase())
      .subscribe((response) => {
        this.airlinePromotionsList = new MatTableDataSource<any>(response.data.content);
        this.paginatorDetails.pageNo = response.data.page.number;
        this.paginatorDetails.totalPages = response.data.page.totalPages;
        this.paginatorDetails.totalElements = response.data.page.totalElements;
      },
        (error) => {
          this.isLoading = false;
        });
  }

  formatTime(timeInMinutes: number): string {
    if (!timeInMinutes && timeInMinutes !== 0) {
      return '';
    }
    const hours = Math.floor(timeInMinutes / 60);
    const minutes = timeInMinutes % 60;
    const formattedHours = ('0' + hours).slice(-2);
    const formattedMinutes = ('0' + minutes).slice(-2);
    return `${formattedHours}:${formattedMinutes}`;
  }

  goToCreateNewAirlinePromotion() {
    this.router.navigate(['/createAirlinePromotions']);
  }

  deleteAirlinePromotion(id, i) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to Delete ' + i + ' ?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.isLoading = true;
        this.service.patchAirlinePromotion(id, { isActive: false }).subscribe((response) => {
          this.getAllAilinePromotions();
          Swal.fire({
            icon: 'success',
            title: 'Successfully Deleted',
            timer: 5000,
          });
          this.isLoading = false;
        },
          (error) => {
            this.isLoading = false;
          });
      }
    });
  }

  viewAirlinePromotion(id: number): void {
    if (id !== null && id !== undefined) {
      this.router.navigate(['/viewAirlinePromotions', id]);
    } else {
      console.error('Invalid ID:', id);
    }
  }

}
