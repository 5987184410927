<ngx-loading [show]="isLoading"
             [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: '#0000ff', secondaryColour: '#00f', backdropBorderRadius: '3px'}"
             [template]="loadingTemplate"></ngx-loading>

<ng-template #loadingTemplate>
  <div class="custom-loader">
    <div class="spinner"></div>
    <p>Loading...</p>
  </div>
</ng-template>

<div style="width: 100%; display: flex; flex-direction: row" cols="4" rowHeight="1rem">
  <div>
    <mat-card-header style="font-size: 2rem">Airline Promotions</mat-card-header>
  </div>
  <div class="btn-actions-pane-right p-2">
    <button class="float-end" mat-raised-button color="primary" (click)="goToCreateNewAirlinePromotion()">Create
      New
    </button>
  </div>
</div>
<mat-card>
  <!--  <mat-form-field style="width: 30%">-->
  <!--    <mat-label>Filter</mat-label>-->
  <!--    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>-->
  <!--  </mat-form-field>-->

  <ng-container *ngIf="airlinePromotionsList.data.length > 0; else noRecordsMessage">

<!--    <div class="mat-elevation-z8">-->
      <mat-table [dataSource]="airlinePromotionsList" style="width: 100%" matSort (matSortChange)="sortData($event)">

        <!-- S.No. Column -->
        <ng-container matColumnDef="S.No.">
          <mat-header-cell *matHeaderCellDef mat-sort-header> S.No.</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index"> {{ paginator?.pageIndex * paginator?.pageSize + i + 1 }}
          </mat-cell>
        </ng-container>

        <!-- Airline Column -->
        <ng-container matColumnDef="airline">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Airline</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.airline.name }}</mat-cell>
        </ng-container>

        <!-- Flights Column -->
        <ng-container matColumnDef="flights">
          <mat-header-cell *matHeaderCellDef> Flights</mat-header-cell>
          <mat-cell *matCellDef="let element" [matTooltip]="element.flights"> {{ element.flights | slice:0:13 }}
            {{ element.flights.length > 13 ? "..." : "" }}
          </mat-cell>
        </ng-container>

        <!-- Product Column -->
        <ng-container matColumnDef="product">
          <mat-header-cell *matHeaderCellDef> Product</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.productType?.name ? element.productType?.name : "All" }}
          </mat-cell>
        </ng-container>

        <!-- CN Type Column -->
        <ng-container matColumnDef="cnType">
          <mat-header-cell *matHeaderCellDef> CN Type</mat-header-cell>
          <mat-cell
            *matCellDef="let element"> {{ element.consignmentType?.name ? element.consignmentType?.name : "All" }}
          </mat-cell>
        </ng-container>

        <!-- CN Subtype Column -->
        <ng-container matColumnDef="cnSubtype">
          <mat-header-cell *matHeaderCellDef> CN Subtype</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{
              element.consignmentSubtype?.name ? element.consignmentSubtype?.name :
                "All"
            }}
          </mat-cell>
        </ng-container>

        <!-- Valid From Column -->
        <ng-container matColumnDef="validFrom">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Valid From</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.startDate | date: 'dd/MM/yyyy' }}</mat-cell>
        </ng-container>

        <!-- Valid Upto Column -->
        <ng-container matColumnDef="validUpto">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Valid Upto</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.endDate | date: 'dd/MM/yyyy' }}</mat-cell>
        </ng-container>

        <!-- Start Time Column -->
        <ng-container matColumnDef="startTime">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Start Time</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ formatTime(element.startTime) }}</mat-cell>
        </ng-container>

        <!-- End Time Column -->
        <ng-container matColumnDef="endTime">
          <mat-header-cell *matHeaderCellDef mat-sort-header> End Time</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ formatTime(element.endTime) }}</mat-cell>
        </ng-container>


        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index">
            <button mat-icon-button color="primary" aria-label="Example icon button with a heart icon"
                    (click)="viewAirlinePromotion(element.id)">
              <mat-icon>visibility</mat-icon>
            </button>
            <button mat-icon-button color="warn" aria-label="Example icon button with a heart icon"
                    (click)="deleteAirlinePromotion(element.id, paginator.pageIndex * paginator.pageSize + i + 1)">
              <mat-icon>delete_outline</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

      </mat-table>
      <mat-paginator [length]="paginatorDetails?.totalElements" [pageSize]="paginatorDetails?.pageSize"
                     [pageSizeOptions]="[5, 10, 20, 30]" (page)="pageEvent = $event; onPaginateChange($event)">
      </mat-paginator>

<!--    </div>-->

  </ng-container>
  <ng-template #noRecordsMessage>
    <h1 class="text-center ">No Records Available..!</h1>
  </ng-template>


</mat-card>
