import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from "ngx-loading";
import Swal from "sweetalert2";
import {HttpService} from "../../../services/http.service";
import {CustomerVerificationService} from "../../../shared/customer-verification/customer-verification.service";
import {API_URL} from "../../../../../Global_API_URL";

const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-user-management-create',
  templateUrl: './user-management-create.component.html',
  styleUrls: ['./user-management-create.component.sass']
})
export class UserManagementCreateComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;

  constructor(private httpService: HttpService, public customerVerification: CustomerVerificationService) {
  }

  ngOnInit(): void {
    this.resetUser();
    this.getuserRoles();
    this.customerVerification.getOfficeLocations();
    setTimeout(() => {
      this.loading = false; // Set loading to false after 2 seconds
    }, 2000);
  }


  userRoles: any;

  customer_shipper = {
    id: null,
    firstName: '',
    middleName: '',
    lastName: '',
    avatarUrl: '',
    email: '',
    phone: '',
    whatsapp: '',
    designation: null,
    userTypes: [{id: null}],
    employmentNo: null,
    officeLocation: {id: null},
    billingAddress: {
      pincode: {
        pin_number: ''
      },
      address: ''
    },
    isSuperUser: null,
    userName: '',
    passwordHash: null,
    lastLoggedInAt: null,
    lastLoggedInIp: null,
    isActive: true,
    createdAt: null,
    updatedAt: null,
    createdBy: null,
    updatedBy: null,
  }

  createUser() {
    if (this.customer_shipper.firstName == null || this.customer_shipper.firstName == '' || this.customer_shipper.firstName.trim().length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The First Name',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.lastName == null || this.customer_shipper.lastName == '' || this.customer_shipper.lastName.trim().length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Last Name',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.email == null || this.customer_shipper.email == '' || this.customer_shipper.email.trim().length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Email',
        timer: 5000,
      });
      return;
    } else if (!(this.customerVerification.emailregex.test(this.customer_shipper.email))) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter A Valid Email',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.emailExist) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Email Already Exists',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.phone == null || this.customer_shipper.phone == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Phone No.',
        timer: 5000,
      });
      return;
    } else if ((this.customer_shipper.phone).length < 10) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Valid Phone No.',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.phoneNoExist) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Phone No. Already Exists',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper?.whatsapp?.length > 0 && this.customer_shipper?.whatsapp?.length < 10) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Valid Whatsapp No.',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.billingAddress.address == null || this.customer_shipper.billingAddress.address == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Address',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.user_sel_country == null || this.customerVerification.user_sel_country == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The Country',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.user_sel_state == null || this.customerVerification.user_sel_state == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The State',
        timer: 5000,
      });
      return;
    } else if (this.customerVerification.user_sel_city == null || this.customerVerification.user_sel_city == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The City',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.billingAddress.pincode.pin_number == null || this.customer_shipper.billingAddress.pincode.pin_number == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The Zip Code',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.userTypes[0].id == null || this.customer_shipper.userTypes[0].id == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The User Role',
        timer: 5000,
      });
      return;
    } else if (this.customer_shipper.officeLocation.id == null || this.customer_shipper.officeLocation.id == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The Office Location',
        timer: 5000,
      });
      return;
    } else {
      this.loading = true;
      this.httpService.post(API_URL + '/user', this.customer_shipper, null, null)
        .subscribe((result) => {
          this.loading = false;
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'User Created Successfully',
            timer: 5000,
          }).then(() => {
            this.goBackToUserTablePage();
          });
        }, (error) => {
          this.loading = false;
          console.error('Error', error);
        });
    }
  }

  getuserRoles() {
    let userRoles;
    let array = [];
    this.httpService.get(API_URL + '/users_types', null, null)
      .subscribe(response => {
        array = Array.from(response.data);
        userRoles = response;
        userRoles.data = [];
        array.forEach((element) => {
          if (element.id != 5) {
            userRoles.data.push(element);
          }
        });
        this.userRoles = userRoles.data;
      })
  }

  resetUser(){
    this.customerVerification.emailExist = false;
    this.customerVerification.phoneNoExist = false;
    this.customerVerification.officesList = null;
    this.userRoles = null;
    this.customerVerification.user_sel_pincodes = null;
    this.customerVerification.user_sel_city = null;
    this.customerVerification.user_sel_state = null;
    this.customerVerification.user_sel_country = null;
    this.customer_shipper = {
      id: null,
      firstName: '',
      middleName: '',
      lastName: '',
      avatarUrl: '',
      email: '',
      phone: '',
      whatsapp: '',
      designation: null,
      userTypes: [{id: null}],
      employmentNo: null,
      officeLocation: {id: null},
      billingAddress: {
        pincode: {
          pin_number: ''
        },
        address: ''
      },
      isSuperUser: null,
      userName: '',
      passwordHash: null,
      lastLoggedInAt: null,
      lastLoggedInIp: null,
      isActive: true,
      createdAt: null,
      updatedAt: null,
      createdBy: null,
      updatedBy: null,
    }
  }

  goBackToUserTablePage(){
    history.back();
  }

}
