import {Component, Inject, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {API_URL} from '../../../../../Global_API_URL';
import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatPaginator, MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import Swal from 'sweetalert2';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DomSanitizer} from '@angular/platform-browser';
import {DatePipe} from '@angular/common';
import {HideurlService} from 'src/app/shared/hideurl.service';
import {AuthService} from '../../../auth.service';
import {HttpService} from '../../../services/http.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BillingService} from '../../../modules/billingInfo/service/billing.service';
import {map} from 'rxjs/operators';
import {saveAs} from 'file-saver';


const SecondaryGrey = '#ccc';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-create-invoice',
  templateUrl: './create-invoice.component.html',
  styleUrls: ['./create-invoice.component.sass'],
})
export class CreateInvoiceComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('selectAllCheckbox') selectAllCheckbox: MatCheckbox;
  constructor(private datePipe: DatePipe, private _liveAnnouncer: LiveAnnouncer, private paginatorIntl: MatPaginatorIntl, private httpClient: HttpClient, private route: Router, private ActivatedRoute: ActivatedRoute, public dialog: MatDialog, private sanitizer: DomSanitizer, private modalService: NgbModal, private formBuilder: FormBuilder, private hideurl: HideurlService, private authService: AuthService, private httpService: HttpService) {
    this.authService.getAuthToken();
    this.paginatorIntl.itemsPerPageLabel = 'Items per page:';
    this.paginatorIntl.nextPageLabel = 'Next page';
    this.paginatorIntl.previousPageLabel = 'Previous page';
    this.paginatorIntl.getRangeLabel = this.customRangeLabel.bind(this);
    this.filterForm = this.formBuilder.group({
      fromDate: [''],
      toDate: [''],
      weightFrom: [null],
      weightTo: [null],
    });
  }

  customRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return `0 of ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} – ${endIndex} of ${length}`;
  }

  ngOnInit():void {
    this.fetchInvoices().subscribe();
    this.getAllCustomers();
    this.getIndividualList();
    this.getAllProductTypes();
    this.getAllProductModes();

    setTimeout(() => {
      this.loading = false; // Set loading to false after 2 seconds
    }, 2000);
  }

  pageview = 'table';
  sender_id = '';
  customers = []
  dataSource = new MatTableDataSource<any>([]);
  filterParams = '';
  showFirstLastButtons;
  pageSizeOptions = [5, 10, 15, 20, 50, 100, 200, 500, 1000];

  displayedColumns: string[] = [
    'select',
    'consignmentNo',
    'customerName',
    'origin',
    'destination',
    'productType',
    'productMode',
    'totalWeight',
    'isInvoiceGenerated',
    'createdAt',
  ];

  selectedRows: any[] = [];

  selectedRecordCount: number = 0;

  selectAll(event: MatCheckboxChange) {
    if (event.checked) {
      this.loading = true;
      // Fetch all invoices from the server
      this.fetchInvoices(0, this.totalItems, 'desc', 'createdAt', this.filterForm.get('fromDate').value, this.filterForm.get('toDate').value, this.weightFrom, this.weightTo)
          .subscribe((response) => {
          // Select all rows
            this.selectedRows = this.invoices.slice();

            // Get the IDs of all selected invoices
            this.selectedInvoiceIds = this.selectedRows.map((invoice) => invoice.id);
            this.selectedRecordCount = this.selectedRows.length;
            this.loading = false;

            // Update the state of selectAllCheckbox
            this.selectAllCheckbox.checked = true;
            this.selectAllCheckbox.indeterminate = false;
          });
    } else {
      this.selectedRows = []; // Deselect all rows
      this.selectedInvoiceIds = []; // Deselect all IDs
      this.selectedRecordCount = 0;

      // Update the state of selectAllCheckbox
      this.selectAllCheckbox.checked = false;
      this.selectAllCheckbox.indeterminate = false;
    }

    // Check if all individual checkboxes are selected
    const allSelected = this.selectedRows.length === this.invoices.length;
    this.selectAllCheckbox.checked = allSelected;
    this.selectAllCheckbox.indeterminate = !allSelected && this.selectedRows.length > 0; // Set indeterminate if some but not all are selected
  }

  toggleSelection(row: any, checkbox: HTMLInputElement) {
    if (this.selectedRows[0]?.customer_name != undefined) {
      if (this.selectedRows[0]?.customer_name != row?.customer_name) {
        Swal.fire({
          title: 'Error',
          text: 'Please select consignments from the same customer.',
          icon: 'error',
          timer: 5000,
        }).then(() => {
          // Reset the checkbox state
          checkbox.checked = false;
        });
      } else {
        const index = this.selectedRows.indexOf(row);
        if (index >= 0) {
          this.selectedRows.splice(index, 1); // Remove row from selectedRows if already selected
          const idIndex = this.selectedInvoiceIds.indexOf(row.id);
          if (idIndex >= 0) {
            this.selectedInvoiceIds.splice(idIndex, 1); // Remove ID from selectedInvoiceIds if already selected
          }
        } else {
          this.selectedRows.push(row); // Add row to selectedRows if not already selected
          this.selectedInvoiceIds.push(row.id); // Add ID to selectedInvoiceIds if not already selected
        }
        // Check if all individual checkboxes are selected
        const allSelected = this.selectedRows.length === this.invoices.length;
        const someSelected = this.selectedRows.length > 0 && !allSelected; // Some but not all are selected

        if (allSelected) {
          this.selectAllCheckbox.checked = true;
          this.selectAllCheckbox.indeterminate = false;
        } else if (someSelected) {
          this.selectAllCheckbox.checked = false;
          this.selectAllCheckbox.indeterminate = true;
        } else {
          this.selectAllCheckbox.checked = false;
          this.selectAllCheckbox.indeterminate = false;
        }
      }
    } else {
      const index = this.selectedRows.indexOf(row);
      if (index >= 0) {
        this.selectedRows.splice(index, 1); // Remove row from selectedRows if already selected
        const idIndex = this.selectedInvoiceIds.indexOf(row.id);
        if (idIndex >= 0) {
          this.selectedInvoiceIds.splice(idIndex, 1); // Remove ID from selectedInvoiceIds if already selected
        }
      } else {
        this.selectedRows.push(row); // Add row to selectedRows if not already selected
        this.selectedInvoiceIds.push(row.id); // Add ID to selectedInvoiceIds if not already selected
      }
      // Check if all individual checkboxes are selected
      const allSelected = this.selectedRows.length === this.invoices.length;
      const someSelected = this.selectedRows.length > 0 && !allSelected; // Some but not all are selected

      if (allSelected) {
        this.selectAllCheckbox.checked = true;
        this.selectAllCheckbox.indeterminate = false;
      } else if (someSelected) {
        this.selectAllCheckbox.checked = false;
        this.selectAllCheckbox.indeterminate = true;
      } else {
        this.selectAllCheckbox.checked = false;
        this.selectAllCheckbox.indeterminate = false;
      }
    }
  }

  isSelected(invoiceId: number): boolean {
    return this.selectedInvoiceIds.includes(invoiceId);
  }

  onCustomerTypeChange() {
    this.selectedRows = []; // Deselect all rows
    this.selectedInvoiceIds = []; // Deselect all IDs
    this.selectedRecordCount = 0;

    if (this.invoices.length > 0) {
      // Update the state of selectAllCheckbox
      this.selectAllCheckbox.checked = false;
      this.selectAllCheckbox.indeterminate = false;
    }

    // To set the all fields to null before change the customer type
    this.filterForm.get('fromDate').reset('');
    this.filterForm.get('toDate').reset('');
    this.sender_id = '';
    this.productType=null;
    this.productMode=null;
    this.weightFrom = undefined;
    this.weightTo = undefined;
    this.paginator.pageIndex = 0;

    if (this.customerType === 'customer') {
      this.getAllCustomers();
    } else if (this.customerType === 'retail') {
      this.getIndividualList();
    }
    this.sender_id = '';
    this.loading = true;
    this.fetchInvoices()
        .subscribe((response) => {
          this.loading = false;
        });
  }

  corporateCustomers: any[] = [];
  individualCustomers: any[] = [];
  productTypes: any[] = [];
  productModes: any[]=[];
  productType:any;
  productMode:any;

  getAllCustomers() {
    this.httpService.get(API_URL + '/totalcustomers/', null, null)
        .subscribe((response) => {
          this.corporateCustomers = response.map((customer: any) => ({
            id: customer.id,
            firstName: customer.firstName, // Adjust property names if needed
          }));
        }, (error) => {
          this.corporateCustomers = []; // Reset array in case of an error
        });
  }
  getAllProductTypes() {
    const api= '/getOrderTypes';
    this.httpService.get(API_URL + api, null, null)
        .subscribe((json: any) => {
          this.productTypes = json.data;
        });
  }
  getAllProductModes() {
    const api= '/getProductModes';
    this.httpService.get(API_URL + api, null, null)
        .subscribe((json: any) => {
          this.productModes = json.data;
        });
  }

  onProductFilter() {
    this.selectedRows = []; // Deselect all rows
    this.selectedInvoiceIds = []; // Deselect all IDs
    this.selectedRecordCount = 0;

    if (this.invoices.length > 0) {
      // Update the state of selectAllCheckbox
      this.selectAllCheckbox.checked = false;
      this.selectAllCheckbox.indeterminate = false;
    }

    this.paginator.pageIndex=0;
    this.fetchInvoices(0, 10, 'desc', 'createdAt', this.filterForm.get('fromDate').value, this.filterForm.get('toDate').value, this.weightFrom, this.weightTo, this.productType, this.productMode).subscribe();
  }

  getIndividualList() {
    this.httpService.get(API_URL + '/individual_user', null, null)
        .subscribe((response) => {
          this.individualCustomers = response.map((user: any) => ({
            id: user.id,
            firstName: user.firstName, // Adjust property names if needed
          }));
        }, (error) => {
          this.individualCustomers = []; // Reset array in case of an error
        });
  }

  onCustomerSelected() {
    this.selectedRows = []; // Deselect all rows
    this.selectedInvoiceIds = []; // Deselect all IDs
    this.selectedRecordCount = 0;

    if (this.invoices.length > 0) {
      // Update the state of selectAllCheckbox
      this.selectAllCheckbox.checked = false;
      this.selectAllCheckbox.indeterminate = false;
    }

    this.paginator.pageIndex = 0;
    this.loading = true;
    this.fetchInvoices()
        .subscribe((response) => {
          this.loading = false;
        });
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  onPaginateChange(event: PageEvent): void {
    this.pageNo = event.pageIndex; // Update the pageIndex from the emitted event
    this.pageSize = event.pageSize; // Update the pageSize as well if needed
    this.fetchInvoices(event.pageIndex, event.pageSize, 'desc', 'createdAt', this.filterForm.get('fromDate').value, this.filterForm.get('toDate').value, this.weightFrom, this.weightTo, this.productType, this.productMode).subscribe(); // Fetch invoices based on the updated pagination values
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  formatDate(date) {
    const date2 = new Date(date);
    return (
      [
        date2.getFullYear(),
        this.padTo2Digits(date2.getMonth() + 1),
        this.padTo2Digits(date2.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date2.getHours()),
        this.padTo2Digits(date2.getMinutes()),
      ].join(':')
    );
  }

  userDetails;

  invoices: any[] = [];
  totalItems: number = 0;
  pageSize: number = 10; // Set your default page size
  pageNo: number = 0;
  customerType: string = 'customer';
  weightFrom: number;
  weightTo: number;

  fetchInvoices(pageNo = 0, pageSize = 10, sortOrder = 'desc', sortOn = 'createdAt', fromDate?: Date, toDate?: Date, weightFrom?: number, weightTo?: number, productType?: string, productMode?: string) {
    let url = `${API_URL}/invoicestogenerate`;
    const queryParams = new URLSearchParams();
    queryParams.set('pageNo', pageNo.toString());
    queryParams.set('pageSize', pageSize.toString());
    queryParams.set('sortOn', 'createdAt');
    queryParams.set('sortOrder', 'desc');

    if (fromDate && toDate) {
      queryParams.set('from', fromDate.toISOString());
      queryParams.set('to', toDate.toISOString());
    }
    if (weightFrom !== undefined) {
      queryParams.set('weightFrom', weightFrom?.toString());
    }
    if (weightTo !== undefined) {
      queryParams.set('weightTo', weightTo?.toString());
    }
    if (this.customerType === 'customer') {
      const selectedCustomerId = this.sender_id;
      queryParams.set('senderId', selectedCustomerId);
      queryParams.set('isRetail', '0');
    } else if (this.customerType === 'retail') {
      const selectedCustomerId = this.sender_id;
      queryParams.set('senderId', selectedCustomerId);
      queryParams.set('isRetail', '1');
    }
    if (this.filterParams !== '') {
      queryParams.append('filterParams', this.filterParams);
    }
    if (productType) {
      queryParams.set('productType', productType);
    }
    if (productMode) {
      queryParams.set('productMode', productMode);
    }

    url += `?${queryParams.toString()}`;
    return this.httpService.get(url, null, null)
        .pipe(map(((response) => {
          if ('data' in response && Array.isArray(response['data'])) {
            this.invoices = response['data'];
            this.totalItems = response['totalElements'] ?? 0; // Use the provided totalItems or default to 0
          }
          return this.invoices;
        })));
  }

  onDateChange(dateType: 'fromDate' | 'toDate') {
    this.paginator.pageIndex=0;
    this.selectedRows = []; // Deselect all rows
    this.selectedInvoiceIds = []; // Deselect all IDs
    this.selectedRecordCount = 0;

    if (this.invoices.length > 0) {
      // Update the state of selectAllCheckbox
      this.selectAllCheckbox.checked = false;
      this.selectAllCheckbox.indeterminate = false;
    }

    const fromDate = this.filterForm.get('fromDate').value;
    const toDate = this.filterForm.get('toDate').value;
    const weightFrom = this.weightFrom;
    const weightTo = this.weightTo;
    this.filterForm.patchValue({
      weightFrom: weightFrom,
      weightTo: weightTo,
    });
    this.fetchInvoices(0, 10, 'desc', 'createdAt', fromDate, toDate, weightFrom, weightTo, this.productType, this.productMode).subscribe();
  }

  onWeightChange() {
    this.selectedRows = []; // Deselect all rows
    this.selectedInvoiceIds = []; // Deselect all IDs
    this.selectedRecordCount = 0;

    if (this.invoices.length > 0) {
      // Update the state of selectAllCheckbox
      this.selectAllCheckbox.checked = false;
      this.selectAllCheckbox.indeterminate = false;
    }
    this.paginator.pageIndex = 0;

    const weightFrom = this.weightFrom;
    const weightTo = this.weightTo;

    this.filterForm.patchValue({
      weightFrom: weightFrom,
      weightTo: weightTo,
    });

    this.fetchInvoices(0, 10, 'desc', 'createdAt', this.filterForm.get('fromDate').value, this.filterForm.get('toDate').value, weightFrom, weightTo, this.productType, this.productMode).subscribe();
  }

  filterForm: FormGroup;

  selectedInvoiceIds: number[] = []; // To store selected invoice IDs

  generateInvoices() {
    this.loading = true;
    let isSame = 0;
    let billCostCreated = 0;
    let gstSame = 0;
    const sezSame = 0;
    const customerName = this.selectedRows[0].customer_name;
    const gstType = this.selectedRows[0].billingIsGst;
    const sezCustomer = this.selectedRows[0].billingTaxablePercentage;
    this.selectedRows.slice(1).forEach((row) => {
      if (customerName != row.customer_name) {
        isSame += 1;
      }
      if (row.billingTaxableAmount <= 0 || row.costTaxableAmount <= 0 || row.billingTaxableAmount == null || row.costTaxableAmount == null) {
        billCostCreated += 1;
      }
      if (gstType != row.billingIsGst) {
        gstSame += 1;
      }
      // if (sezCustomer != row.billingTaxablePercentage){
      //   sezSame += 1;
      // }
    });

    if (this.selectedRows.length == 1) {
      if (this.selectedRows[0].billingTaxableAmount <= 0 || this.selectedRows[0].costTaxableAmount <= 0 || this.selectedRows[0].billingTaxableAmount == null || this.selectedRows[0].costTaxableAmount == null) {
        billCostCreated += 1;
      }
    }

    if (isSame > 0) {
      this.loading = false;
      Swal.fire({
        title: 'Error',
        text: 'Please select consignments from the same customer.',
        icon: 'error',
        timer: 5000,
      });
    } else if (gstSame > 0) {
      this.loading = false;
      Swal.fire({
        title: 'Error',
        text: 'Some selected consignments have different GST.',
        icon: 'error',
        timer: 5000,
      });
    }
    // else if (sezSame > 0){
    //   this.loading = false;
    //   Swal.fire({
    //     title: 'Error',
    //     text: 'Customer updated the SEZ status',
    //     icon: 'error',
    //     timer: 5000
    //   })
    // }
    else if (billCostCreated > 0) {
      this.loading = false;
      Swal.fire({
        title: 'Error',
        text: 'Some selected consignments have no billing info or cost center.',
        icon: 'error',
        timer: 5000,
      });
    } else {
      const selectedIdsPayload = this.selectedInvoiceIds.map((id) => ({'consignmentId': id}));
      this.httpService.post(`${API_URL}/invoices?preview=false`, {billingPrices: selectedIdsPayload}, null, null)
          .subscribe((response) => {
            this.loading = false;
            Swal.fire({
              title: 'Invoice Processing',
              text: 'Invoice generation successful.',
              icon: 'success',
              timer: 5000,
            }).then(() => {
              this.refreshPage();
            });
          }, (error) => {
            this.loading = false;
            Swal.fire({
              title: 'Warning',
              text: 'Error While Generating Invoices',
              icon: 'warning',
              timer: 5000,
            });
          });
    }
  }

  pdfSrc: string ;
  fileName: string;
  isPdfSrc = false;
  generateInvoicesPreview() {
    this.loading = true;
    let isSame = 0;
    let billCostCreated = 0;
    let gstSame = 0;
    const sezSame = 0;
    const customerName = this.selectedRows[0].customer_name;
    const productType = this.selectedRows[0].product_type;
    const productMode = this.selectedRows[0].product_mode;
    const gstType = this.selectedRows[0].billingIsGst;
    const sezCustomer = this.selectedRows[0].billingTaxablePercentage;

    this.selectedRows.slice(1).forEach((row) => {
      if (customerName != row.customer_name || productType != row.product_type || productMode != row.product_mode) {
        isSame += 1;
      }
      if (row.billingTaxableAmount <= 0 || row.costTaxableAmount <= 0 || row.billingTaxableAmount == null || row.costTaxableAmount == null) {
        billCostCreated += 1;
      }
      if (gstType != row.billingIsGst) {
        gstSame += 1;
      }
      // if (sezCustomer != row.billingTaxablePercentage){
      //   sezSame += 1;
      // }
    });

    if (this.selectedRows.length == 1) {
      if (this.selectedRows[0].billingTaxableAmount <= 0 || this.selectedRows[0].costTaxableAmount <= 0 || this.selectedRows[0].billingTaxableAmount == null || this.selectedRows[0].costTaxableAmount == null) {
        billCostCreated += 1;
      }
    }

    if (isSame > 0) {
      this.loading = false;
      Swal.fire({
        title: 'Error',
        text: 'Please select same type of consignments.',
        icon: 'error',
        timer: 5000,
      });
    } else if (gstSame > 0) {
      this.loading = false;
      Swal.fire({
        title: 'Error',
        text: 'Some selected consignments have different GST.',
        icon: 'error',
        timer: 5000,
      });
    }
    // else if (sezSame > 0){
    //   this.loading = false;
    //   Swal.fire({
    //     title: 'Error',
    //     text: 'Customer updated the SEZ status',
    //     icon: 'error',
    //     timer: 5000
    //   })
    // }
    else if (billCostCreated > 0) {
      this.loading = false;
      Swal.fire({
        title: 'Error',
        text: 'Some selected consignments have no billing info or cost center.',
        icon: 'error',
        timer: 5000,
      });
    } else {
      const selectedIdsPayload = this.selectedInvoiceIds.map((id) => ({'consignmentId': id}));

      this.httpService.post(`${API_URL}/invoices?preview=true`, {billingPrices: selectedIdsPayload}, null, null)
          .subscribe((response) => {
            this.loading = false;
            this.pdfSrc = 'data:application/pdf;base64,' + response.htmlPreview;
            this.fileName = response.fileName;
            this.isPdfSrc = true;
            this.openPreviewDialog(this.pdfSrc, this.fileName, response.billingPrices, response.sgst, response.cgst, response.igst);
          });
    }
  }


  gotoConsignmentView(id: any, consignment_no: any): void {
    this.route.navigate(['/viewconsignment', id]);
  }

  resetForm() {
    this.filterForm.get('fromDate').reset('');
    this.filterForm.get('toDate').reset('');
    this.weightFrom = null;
    this.weightTo = null;
    this.sender_id = '';
    this.customerType = 'customer';
    this.onCustomerTypeChange();
  }

  openPreviewDialog(pdfSrc, fileName, billingPrices, SGST, CGST, IGST) {
    const dialogRef = this.dialog.open(InvoicePreview, {
      data: [pdfSrc, fileName, billingPrices, SGST, CGST, IGST],
    });
  }

  refreshPage() {
    window.location.reload();
  }
}


// ******************************************************************************************************************


@Component({
  selector: 'app-invoice-preview',
  templateUrl: './invoice-preview.html',
})
export class InvoicePreview implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;

  pdfSrc;
  fileName;
  billingPrices;
  SGST;
  CGST;
  IGST;
  constructor(
    public commonApi: BillingService,
    public dialogRef: MatDialogRef<InvoicePreview>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.pdfSrc = data[0];
    this.fileName = data[1].replace(/\./g, '_'); // Replace all dots with underscores
    this.billingPrices = data[2];
    this.SGST = data[3];
    this.CGST = data[4];
    this.IGST = data[5];
  }
  ngOnInit(): void {
    setTimeout( () => {
      this.loading = false;
    }, 2000);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  downloadPDF() {
    this.pdfSrc = this.pdfSrc.replace('data:application/pdf;base64,', '');
    // Convert the Base64 string to a Blob
    const byteCharacters = atob(this.pdfSrc);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: 'application/pdf'});

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link in the DOM
    const link = document.createElement('a');
    link.href = url;
    // Create a sanitized file name for the download
    // const sanitizedFileName = this.fileName.replace(/\./g, '_'); // Replace all dots with underscores
    link.download = 'Invoice_Preview_'+this.fileName;

    // Trigger a click event on the link to initiate the download
    link.click();

    // Clean up resources
    URL.revokeObjectURL(url);

    this.onNoClick();
  }

  exportToCSV() {
    const csvData = [];
    const headers = ['S.No', 'Date', 'Reference No', 'ICL CN No', 'AWB No', 'Origin', 'Destination', 'No Of Pkgs', 'Charged Weight(kg)',
      'Freight Charges', 'AHC/THC Charges', 'AWB/CN Charges', 'Peak/Handling Charges', 'Remote/Pickup Charges', 'Remote/Delivery Charges',
      'Fuel Sur Charges', 'Packing Material Charges', 'Data Logger Charges', 'DG/Misc Charges', 'Discount Amount', 'FOV', 'Taxable Amount'];


    csvData.push(headers);

    function formatForCSV(value) {
      if (value === null || value === undefined) {
        return '';
      }
      const escapedValue = value.toString().replace(/"/g, '""');
      if (escapedValue.includes(',') || escapedValue.includes('\n')) {
        return `"${escapedValue}"`;
      }
      return escapedValue;
    }

    let count = 0;
    let totalNoOfPcks = 0;
    let totalChargableWeight = 0;
    let totalFreightCharges = 0;
    let totalAhcCharges = 0;
    let totalMawbCharges = 0;
    let totalIclHandlingCharges = 0;
    let totalPickupCharges = 0;
    let totalDeliveryCharges = 0;
    let totalFuelSurcharges = 0;
    let totalPackingMaterialCharges = 0;
    let totalDataLoggerCharges = 0;
    let totalWarehouseCharges = 0;
    let totalDiscountedAmount = 0;
    let totalInsurance = 0;
    let totalTaxableAmount = 0;

    this.billingPrices.forEach((row) => {
      count+=1;
      const createdAt = this.formatDate(row.consignment?.created_at);
      totalNoOfPcks += row.consignment.no_of_packages;
      totalChargableWeight += row.consignment.total_chargable_weight ? row.consignment.total_chargable_weight : 0;
      totalFreightCharges += row.freightCharges != null ? row.freightCharges : 0;
      totalAhcCharges += row.ahcCharges != null ? row.ahcCharges : 0;
      totalMawbCharges += (row.mawbCharges != null ? row.mawbCharges : 0) + (row.iclCnCharges != null ? row.iclCnCharges : 0);
      totalIclHandlingCharges += (row.iclHandlingCharges ? row.iclHandlingCharges : 0) + (row.peakHandlingCharges ? row.peakHandlingCharges : 0) + (row.loadingCharges ? row.loadingCharges : 0) + (row.unloadingCharges ? row.unloadingCharges : 0);
      totalPickupCharges += row.pickupCharges != null ? row.pickupCharges : 0;
      totalDeliveryCharges += row.deliveryCharges != null ? row.deliveryCharges : 0;
      totalFuelSurcharges += row.fuelSurcharges != null ? row.fuelSurcharges : 0;
      totalPackingMaterialCharges += row.packingMaterialCharges != null ? row.packingMaterialCharges : 0;
      totalDataLoggerCharges += row.dataLoggerCharges != null ? row.dataLoggerCharges : 0;
      totalWarehouseCharges += (row.warehouseCharges ? row.warehouseCharges : 0) + (row.detentionCharges ? row.detentionCharges : 0) + (row.dgCharges ? row.dgCharges : 0) + (row.demurrageCharges ? row.demurrageCharges : 0) + (row.miscellaneousCharges ? row.miscellaneousCharges : 0);
      totalDiscountedAmount += row.discountedAmount != null ? row.discountedAmount : 0;
      totalInsurance += row.insurance != null ? row.insurance : 0;
      totalTaxableAmount += row.taxableAmount;

      const data = [
        count,
        formatForCSV(createdAt),
        formatForCSV(row.consignment.invoiceReferenceNo),
        formatForCSV(row.consignment.consignment_no),
        formatForCSV(row.consignment.product_mode == 'ATA' ? (row.consignment.awb_number != null ? row.consignment.awb_number.slice(3) : '' ) : '-'),
        formatForCSV(row.consignment.cityname),
        formatForCSV(row.consignment.receivercity),
        formatForCSV(row.consignment.no_of_packages ? row.consignment.no_of_packages : 0),
        formatForCSV(row.consignment.total_chargable_weight ? row.consignment.total_chargable_weight : 0),
        formatForCSV(this.formatDecimal(row.freightCharges != null ? row.freightCharges : 0, 1, 2)),
        formatForCSV(this.formatDecimal(row.ahcCharges != null ? row.ahcCharges : 0, 1, 2)),
        formatForCSV(this.formatDecimal(((row.mawbCharges != null ? row.mawbCharges : 0) + (row.iclCnCharges != null ? row.iclCnCharges : 0)), 1, 2)),
        formatForCSV(this.formatDecimal(((row.iclHandlingCharges ? row.iclHandlingCharges : 0) + (row.peakHandlingCharges ? row.peakHandlingCharges : 0) + (row.loadingCharges ? row.loadingCharges : 0) + (row.unloadingCharges ? row.unloadingCharges : 0)), 1, 2)),
        formatForCSV(this.formatDecimal(row.pickupCharges != null ? row.pickupCharges : 0, 1, 2)),
        formatForCSV(this.formatDecimal(row.deliveryCharges != null ? row.deliveryCharges : 0, 1, 2)),
        formatForCSV(this.formatDecimal(row.fuelSurcharges != null ? row.fuelSurcharges : 0, 1, 2)),
        formatForCSV(this.formatDecimal(row.packingMaterialCharges != null ? row.packingMaterialCharges : 0, 1, 2)),
        formatForCSV(this.formatDecimal(row.dataLoggerCharges != null ? row.dataLoggerCharges : 0, 1, 2)),
        formatForCSV(this.formatDecimal(((row.warehouseCharges ? row.warehouseCharges : 0) + (row.detentionCharges ? row.detentionCharges : 0) + (row.dgCharges ? row.dgCharges : 0) + (row.demurrageCharges ? row.demurrageCharges : 0) + (row.miscellaneousCharges ? row.miscellaneousCharges : 0)), 1, 2)),
        formatForCSV(this.formatDecimal(row.discountedAmount != null ? row.discountedAmount : 0, 1, 2)),
        formatForCSV(this.formatDecimal(row.insurance != null ? row.insurance : 0, 1, 2)),
        formatForCSV(this.formatDecimal(row.taxableAmount, 1, 2)),
      ];
      csvData.push(data);
    });

    const totals = [
      '', '', '', 'Total', '', '', '',
      totalNoOfPcks,
      totalChargableWeight,
      totalFreightCharges,
      totalAhcCharges,
      totalMawbCharges,
      totalIclHandlingCharges,
      totalPickupCharges,
      totalDeliveryCharges,
      totalFuelSurcharges,
      totalPackingMaterialCharges,
      totalDataLoggerCharges,
      totalWarehouseCharges,
      totalDiscountedAmount,
      totalInsurance,
      totalTaxableAmount,
    ];
    let totalAmount = totalTaxableAmount;
    if ((this.SGST+this.CGST+this.IGST) != 0) {
      totalAmount = (18*totalTaxableAmount)/100+totalTaxableAmount;
    }

    csvData.push(totals);
    csvData.push(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 'Taxable Total Amount(INR)', totalTaxableAmount]);
    csvData.push(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 'SGST', this.SGST, (this.SGST*totalTaxableAmount)/100]);
    csvData.push(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 'CGST', this.CGST, (this.CGST*totalTaxableAmount)/100]);
    csvData.push(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 'IGST', this.IGST, (this.IGST*totalTaxableAmount)/100]);
    csvData.push(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 'Total Amount(INR)', totalAmount]);

    const roundOff = Math.round(totalAmount)-totalAmount;

    if (roundOff >= 0) {
      csvData.push(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 'Round up', roundOff]);
    } else {
      csvData.push(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 'Round off', roundOff]);
    }

    csvData.push(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 'Grand Total(INR)', totalAmount+roundOff]);

    const csv = csvData.map((e) => e.join(',')).join('\n');
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'});
    // const currentDate = new Date();
    // const formattedDate = this.formatDateForFilename(currentDate);
    saveAs(blob, 'InvoiceXLSheet - ' + this.fileName + '.csv');
  }

  // formatDateForFilename(date: Date): string {
  //   const day = String(date.getDate()).padStart(2, '0');
  //   const month = String(date.getMonth() + 1).padStart(2, '0');
  //   const year = date.getFullYear();
  //   const hours = String(date.getHours()).padStart(2, '0');
  //   const minutes = String(date.getMinutes()).padStart(2, '0');
  //   return `${day}-${month}-${year} (${hours}.${minutes})`;
  // }

  formatDate(date) {
    const date2 = new Date(date);
    return (
      [
        date2.getFullYear(),
        this.padTo2Digits(date2.getMonth() + 1),
        this.padTo2Digits(date2.getDate()),
      ].join('-')
      // +
      // ' ' +
      // [
      //   this.padTo2Digits(date2.getHours()),
      //   this.padTo2Digits(date2.getMinutes())
      // ].join(':')
    );
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  formatDecimal(value: number, minDecimals: number, maxDecimals: number): string {
    return value.toFixed(Math.max(minDecimals, maxDecimals));
  }
}
