import {Injectable} from '@angular/core';
import {HttpService} from '../../services/http.service';
import {API_URL} from '../../../../Global_API_URL';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AirlinePromotionsService {
  constructor(private httpService: HttpService) { }

  getAllAirlinePromotions(pageNo, pageSize, sortBy, direction) {
    let params: HttpParams = new HttpParams();
    if (pageNo!=null) {
      params = params.set('pageNo', pageNo);
    }

    if (pageSize!=null) {
      params =params.set('pageSize', pageSize);
    }

    if (sortBy!=null) {
      params =params.set('sortBy', sortBy);
    }

    if (direction!=null) {
      params = params.set('direction', direction);
    }

    return this.httpService.get(API_URL +'/airline-promotions', params, null);
  }

  postAirlinePromotion(body) {
    return this.httpService.post(API_URL +'/airline-promotions', body, null, null);
  }

  patchAirlinePromotion(id, body) {
    return this.httpService.patch(API_URL +'/airline-promotions/plan/'+id, body, null, null);
  }
}
