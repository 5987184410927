import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { getStates, getCountries, getCities, getStatesById, getCitiesById } from '../../getCities.js';
import { getAirlines, getAirports } from '../../getAirportAirlines.js';
import { API_URL } from '../../../../../Global_API_URL';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatTableDataSource } from '@angular/material/table';
import { saveAs } from 'file-saver';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../../auth.service';
import { Airport, Cities, States } from '../../golbalInterfaces.js';
import { FormControl } from '@angular/forms';
const PrimaryWhite = '#fff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#d92550';
const SecondaryBlue = '#3f6ad8';
const FILTERS_STORAGE_KEY = 'cities_filters';
const PAGINATION_STORAGE_KEY = 'cities_pagination';

@Component({
  selector: 'app-table-cities',
  templateUrl: './table-cities.component.html',
  styleUrls: ['./table-cities.component.sass'],
})
export class TableCitiesComponent implements OnInit {
  constructor(private _liveAnnouncer: LiveAnnouncer, private router: Router, private authService: AuthService) {
    this.authService.getAuthToken();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Load saved filters whenever navigation ends
        this.loadSavedFilters();
      }
    });
    window.addEventListener('beforeunload', () => {
      localStorage.removeItem(FILTERS_STORAGE_KEY);
      localStorage.removeItem(PAGINATION_STORAGE_KEY);
    });
  }
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public loadingThird = false;
  public loadingSecound = false;
  public loadingForth = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public Representativeload = false;
  public loadingTemplate: TemplateRef<any>;
  public config = {
    animationType: ngxLoadingAnimationTypes.none,
    primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour,
    tertiaryColour: this.primaryColour,
    backdropBorderRadius: '3px',
  };
  pageview = 'table';
  nameExist = false;
  // states = this.getStates();
  countries = getCountries();
  // cities = this.getCities();
  airports = getAirports();
  states1;
  city;
  citiesList;
  userDetails;
  displaytoAccountant = false;

  pagination = {
    sortBy: 'id',
    pageSize: 10,
    pageNo: 0,
    direction: 'DESC',
  };
  filterParams = '';
  state_id: any = '';
  supported_airport_id = '';
  is_door_supported = '';
  is_air_supported = '';
  ibAirport: any = '';
  obAirport: any = '';
  cityId: any = '';
  stateId: any = '';
  CitiesList = null;
  statesList = null;
  displayedColumns = ['City', 'state', 'country', 'ibairport', 'obairport', 'originairport', 'Doorsupported', 'Active'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource;
  showFirstLastButtons;
  pageSizeOptions = [10, 30];
  supporte_airport_id = null;
  ib_airport_id = null;
  ob_airport_id = null;
  origin_airport = null;

  editparams = {
    id: null,
    cityName: null,
    stateId: null,
    countryId: null,
    isActive: null,
    isAirSupported: null,
    isDoorSupported: null,
    supportedAirportId: null,
    activeIb: null,
    activeOb: null,
    obAirport: null,
    ibAirport: null,
    originAirport: null,
  };
  edit = {
    isAirSupported: null,
    isDoorSupported: null,
    activeIb: null,
    activeOb: null,
  };

  cityid;

  filteredAirports: Observable<Airport[]>;
  filteredAirports2: Observable<Airport[]>;
  myControl = new FormControl();
  myControl2 = new FormControl();
  airportssearch: Airport[] = getAirports();
  airportssearch2: Airport[] = getAirports();
  async ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails.userTypes[0].name == 'Accountant') {
      this.displaytoAccountant = true;
    }
    await this.getCities();
    this.citiesList = getCities();
    this.city = getCitiesById();
    this.statesList = getStates();
    this.airportssearch = await getAirports();
    this.airportssearch2 = await getAirports();
    this.filteredAirports = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this._filter(name) : this.airportssearch.slice())),
    );
    this.filteredAirports2 = this.myControl2.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this._filter2(name) : this.airportssearch2.slice())),
    );
  }

  getCities1(id) {
    this.citiesList = getCitiesById(id);
  }
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  viewchange(val) {
    this.pageview = val;
    this.nameExist = false;
    this.editparams = {
      id: null,
      cityName: null,
      stateId: null,
      countryId: null,
      isActive: null,
      isAirSupported: null,
      isDoorSupported: null,
      supportedAirportId: null,
      activeIb: null,
      activeOb: null,
      obAirport: null,
      ibAirport: null,
      originAirport: null,
    };
    this.edit = {
      isAirSupported: null,
      isDoorSupported: null,
      activeIb: null,
      activeOb: null,
    };
    this.getCities();
  }
  viewchange1(val, con) {
    this.airports;
    this.editparams.id = con.id;
    this.editparams.cityName = con.name;
    this.editparams.supportedAirportId = con.supportedAirportId;
    this.editparams.countryId = con.state_id.country.id;
    this.getRecverStates();
    this.editparams.stateId = con.state_id.id;
    this.editparams.activeIb = con.activeIb;
    this.editparams.activeOb = con.activeOb;
    this.editparams.isAirSupported = con.isAirportSupported;
    this.editparams.isDoorSupported = con.isDoorSupported;
    this.supporte_airport_id = con.supportedAirportId?.id;
    this.editparams.ibAirport = con.ibAirport?.id;
    this.editparams.obAirport = con.obAirport?.id;
    this.ib_airport_id = con.ibAirport?.id;
    this.ob_airport_id = con.obAirport?.id;
    this.origin_airport = con.originAirport?.id;
    if (con.activeIb == true) {
      this.editparams.activeIb = true;
      this.edit.activeIb = '1';
    } else {
      this.editparams.activeIb = false;
      this.edit.activeIb = '0';
    }

    if (con.activeOb == true) {
      this.editparams.activeOb = true;
      this.edit.activeOb = '1';
    } else {
      this.editparams.activeOb = false;
      this.edit.activeOb = '0';
    }

    if (con.isAirportSupported == true) {
      this.editparams.isAirSupported = true;
      this.edit.isAirSupported = '1';
    } else {
      this.editparams.isAirSupported = false;
      this.edit.isAirSupported = '0';
    }

    if (con.isDoorSupported == true) {
      this.editparams.isDoorSupported = true;
      this.edit.isDoorSupported = '1';;
    } else {
      this.editparams.isDoorSupported = false;
      this.edit.isDoorSupported = '0';
    }
    this.currentCityName = con.name
    this.pageview = val;
  }
  async getStates() {
    const TOKEN = this.authService.getAuthToken();
    let consignment;
    await fetch(API_URL + '/states', {
      method: 'GET', headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + TOKEN,
      },
    })

      // Converting received data to JSON
      .then((response) => response.json())
      .then((json) => {
        this.statesList = json.data;
      });
  }
  async getRecverStates() {
    this.states1 = getStatesById(this.editparams.countryId);
    this.editparams.stateId = '';
  }
  async getRecverStates1(event: any) {
    this.states1 = getStatesById(event);
    this.editparams.stateId = '';
  }
  async activeibobcity() {
    // Convert strings "1" or "0" to boolean true or false
    if (this.edit.activeIb === '1' || this.edit.activeIb === '0') {
      this.editparams.activeIb = this.edit.activeIb === '1' ? true : false;
    } else {
      this.editparams.activeIb = null; // Set to null if not provided or invalid
    }

    if (this.edit.activeOb === '1' || this.edit.activeOb === '0') {
      this.editparams.activeOb = this.edit.activeOb === '1' ? true : false;
    } else {
      this.editparams.activeOb = null; // Set to null if not provided or invalid
    }

    // Validate isAirSupported and isDoorSupported
    this.editparams.isAirSupported = this.edit.isAirSupported === '1' ? true : false;
    if (this.edit.isDoorSupported === '1' || this.edit.isDoorSupported === '0') {
      this.editparams.isDoorSupported = this.edit.isDoorSupported === '1' ? true : false;
    } else {
      this.editparams.isDoorSupported = null; // Set to null if not provided or invalid
    }
  }


  async Createcity() {
    const TOKEN = this.authService.getAuthToken();
    await this.activeibobcity(); // Await the asynchronous function call
    const city = {
      name: this.editparams.cityName,
      active_ib: this.editparams.activeIb,
      active_ob: this.editparams.activeOb,
      // is_air_supported: this.editparams.isAirSupported,
      is_door_supported: this.editparams.isDoorSupported,
      // supported_airport_id: {id : this.editparams.supportedAirportId},
      state_id: { id: this.editparams.stateId },
      ob_airport: { id: this.editparams.obAirport },
      ib_airport: { id: this.editparams.ibAirport },
      origin_airport: { id: this.editparams.originAirport },
    };

    // Validations
    if (!this.editparams.cityName) {
      this.showValidationError('Please Enter City Name.');
    } else if (this.nameExist == true) {
      this.showValidationError('City Name Already Exists.')
    } else if (!this.editparams.countryId) {
      this.showValidationError('Please Select Country.');
    } else if (!this.editparams.stateId) {
      this.showValidationError('Please Select State.');
    } else if (!this.editparams.ibAirport) {
      this.showValidationError('Please Select IB Airport.');
    } else if (!this.editparams.obAirport) {
      this.showValidationError('Please Select OB Airport.');
    } else if (this.editparams.isDoorSupported === null && this.editparams.isDoorSupported !== false) {
      this.showValidationError('Please select either \'Active\' or \'Inactive\' for the Door Supported.');
    } else if (this.editparams.activeIb === null && this.editparams.activeIb !== false) {
      this.showValidationError('Please select either \'Active\' or \'Inactive\' for the IB Airport.');
    } else if (this.editparams.activeOb === null && this.editparams.activeOb !== false) {
      this.showValidationError('Please select either \'Active\' or \'Inactive\' for the OB Airport.');
    } else {
      // All validations passed, proceed with creating city
      try {
        const response = await fetch(API_URL + '/postCity', {
          method: 'POST',
          body: JSON.stringify(city),
          headers: {
            'Authorization': 'Bearer ' + TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });
        if (response.ok) {
          const result = await response.json();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'City created successfully',
            showConfirmButton: false,
            timer: 5000,
          });
          this.editparams = {
            id: null,
            cityName: null,
            stateId: null,
            countryId: null,
            isActive: null,
            isAirSupported: null,
            isDoorSupported: null,
            supportedAirportId: null,
            activeIb: null,
            activeOb: null,
            obAirport: null,
            ibAirport: null,
            originAirport: null,
          };
          this.getCities();
          this.viewchange('table');
        } else {
          throw new Error('Failed to create city');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  }

  showValidationError(message) {
    Swal.fire({
      icon: 'warning',
      title: 'WARNING',
      text: message,
      // timer: 5000
    });
  }

  currentCityName: string | null = null;
  async Updatecity() {
    const TOKEN = this.authService.getAuthToken();
    this.activeibobcity();
    const city = {
      name: this.editparams.cityName,
      active_ib: this.editparams.activeIb,
      active_ob: this.editparams.activeOb,
      is_air_supported: this.editparams.isAirSupported,
      is_door_supported: this.editparams.isDoorSupported,
      supported_airport_id: this.editparams.supportedAirportId?.id,
      state_id: { id: this.editparams.stateId },
      ob_airport: { id: this.ob_airport_id },
      ib_airport: { id: this.ib_airport_id },
      origin_airport: { id: this.origin_airport },
    };
    // Validations
    if (!this.editparams.cityName) {
      this.showValidationError('Please Enter City Name.');
    } else if (this.nameExist == true) {
      this.showValidationError('City Name Already Exists.');
    } else if (!this.editparams.countryId) {
      this.showValidationError('Please Select Country.');
    } else if (!this.editparams.stateId) {
      this.showValidationError('Please Select State.');
    } else if (!this.editparams.ibAirport) {
      this.showValidationError('Please Select IB Airport.');
    } else if (!this.editparams.obAirport) {
      this.showValidationError('Please Select OB Airport.');
    } else if (this.editparams.isDoorSupported === null && this.editparams.isDoorSupported !== false) {
      this.showValidationError('Please select either \'Active\' or \'Inactive\' for the Door Supported.');
    } else if (this.editparams.activeIb === null && this.editparams.activeIb !== false) {
      this.showValidationError('Please select either \'Active\' or \'Inactive\' for the IB Airport.');
    } else if (this.editparams.activeOb === null && this.editparams.activeOb !== false) {
      this.showValidationError('Please select either \'Active\' or \'Inactive\' for the OB Airport.');
    } else {
      if (this.editparams.supportedAirportId) {
        city.supported_airport_id = this.editparams.supportedAirportId.id;
      }
      // if (this.editparams.ibAirport) {
      //   city.ib_airport = this.editparams.ibAirport.id;
      // }
      // if (this.editparams.obAirport) {
      //   city.ob_airport = this.editparams.obAirport.id;
      // }
      // if (this.editparams.originAirport) {
      //   city.origin_airport = this.editparams.originAirport.id;
      // }
      try {
        const response = await fetch(API_URL + '/updateCity/' + this.editparams.id, {
          method: 'PATCH',
          body: JSON.stringify(city),
          headers: {
            'Authorization': 'Bearer ' + TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });
        if (response.ok) {
          const result = await response.json();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'City updated successfully',
            showConfirmButton: false,
            timer: 5000,
          });
          this.editparams = {
            id: null,
            cityName: null,
            stateId: null,
            countryId: null,
            isActive: null,
            isAirSupported: null,
            isDoorSupported: null,
            supportedAirportId: null,
            activeIb: null,
            activeOb: null,
            ibAirport: null,
            obAirport: null,
            originAirport: null,
          };
          this.getCities();
          this.viewchange('table');
        } else {
          throw new Error('Failed to update city');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  }

  async activestatuscity(id, status) {
    const TOKEN = this.authService.getAuthToken();
    const pin = {
      is_active: status,
    };
    await fetch(API_URL + '/updateCity/isActive/' + id, {
      method: 'PATCH',
      body: JSON.stringify(pin),
      headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((result) => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'city status changed successfully',
          showConfirmButton: false,
          timer: 5000,
        });
        this.getCities();
        this.viewchange('table');
      })
      .catch((error) => console.error('error', error));
  }
  tablefilter() {
    this.pagination.pageNo = 0;
    this.pagination.direction = 'DESC';
    this.getCities();
  }

  filter() {
    this.filterParams = '?';
    if (this.is_air_supported != '') {
      this.filterParams = this.filterParams + 'isAirSupported=' + this.is_air_supported + '&';
    }
    if (this.is_door_supported != '') {
      this.filterParams = this.filterParams + 'isDoorSupported=' + this.is_door_supported + '&';
    }
    if (this.ibAirport != '') {
      this.filterParams = this.filterParams + 'ibAirport=' + this.ibAirport.id + '&';
    }
    if (this.obAirport != '') {
      this.filterParams = this.filterParams + 'obAirport=' + this.obAirport.id + '&';
    }
    if (this.cityId != '') {
      this.filterParams = this.filterParams + 'id=' + this.cityId.id + '&';
    }
    if (this.state_id != '') {
      this.filterParams = this.filterParams + 'stateId=' + this.state_id.id + '&';
    }
    this.filterParams = this.filterParams + 'pageNo=' + this.pagination.pageNo + '&pageSize=' + this.pagination.pageSize;
    return this.filterParams;
  }

  handlePageEvent(event: PageEvent) {
    this.pagination.pageNo = event.pageIndex;
    this.pagination.pageSize = event.pageSize;
    this.getCities(); // Make sure to fetch data after updating pagination
  }
  // async getCities() {
  //   let TOKEN = this.authService.getAuthToken();
  //   this.loading = true;
  //   let array = []
  //   let api = "/citiesfilters"
  //   let params = this.filter()
  //   await fetch(API_URL + api + params, {
  //     method: "GET", headers: {
  //       "Accept": "application/json",
  //       "Authorization": "Bearer " + TOKEN
  //     }
  //   })

  //     // Converting received data to JSON
  //     .then(response => response.json())
  //     .then(json => {
  //       if (json.status == 400) {
  //         this.CitiesList = []
  //       }
  //       else {

  //         this.CitiesList = json.data;
  //         array = Array.from(json.data)
  //         if (this.cityid != "" && this.cityid != null) {
  //           json.data = []
  //           array.forEach(element => {
  //             if (element.name == this.cityid) {
  //               json.data.push(element);
  //             }
  //           });
  //         }
  //         this.dataSource = new MatTableDataSource(json.data);
  //         // this.paginator =
  //         this.dataSource.paginator = this.paginator;
  //         this.dataSource.sort = this.sort;
  //       }
  //     });
  //   this.loading = false;
  //   return this.CitiesList;
  //   // return loads;
  // }

  async getCities() {
    this.loading = true;
    const TOKEN = this.authService.getAuthToken();
    const api = '/citiesPage';
    const params = this.filter(); // Get filter parameters
    try {
      const response = await fetch(API_URL + api + params, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      });
      if (response.ok) {
        const json = await response.json();
        this.CitiesList = json; // Assign entire response data to CitiesList
        this.saveFiltersAndPaginationToStorage();
      } else {
        console.error('Failed to fetch data:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      this.loading = false;
    }
  }

  // async NameExist() {
  //   const TOKEN = this.authService.getAuthToken();
  //   await fetch(API_URL + '/cities/name/active/' + this.editparams.cityName,
  //       {
  //         method: 'GET',
  //         headers: {
  //           'Accept': 'application/json',
  //           'Authorization': 'Bearer ' + TOKEN,
  //           'Content-Type': 'application/json',
  //         },
  //       })
  //       .then((response) => response.json())
  //       .then((result) => {
  //         if (result.data[0] == null) {
  //           this.nameExist = false;
  //         } else {
  //           this.nameExist = true;
  //         }
  //       // alert("CustomerExist "+this.phoneNoExist);
  //       })
  //       .catch((error) => {
  //         console.error('Error' + error);
  //       });
  // }

  async nameExistCreate() {

    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/cities/name/active/' + this.editparams.cityName, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status === 200) {
          this.nameExist = true;
        } else if (response.status === 400) {
          this.nameExist = false;
        }
        return response.json();
      })
      .then((result) => {
        // Additional logic can go here if needed
      })
      .catch((error) => {
        console.error('Error' + error);
      });
  }

  async nameExistUpdate() {
    if (this.currentCityName != this.editparams.cityName) {
      const TOKEN = this.authService.getAuthToken();
      await fetch(API_URL + '/cities/name/active/' + this.editparams.cityName, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (response.status === 200) {
            this.nameExist = true;
          } else if (response.status === 400) {
            this.nameExist = false;
          }
          return response.json();
        })
        .then((result) => {
          // Additional logic can go here if needed
        })
        .catch((error) => {
          console.error('Error' + error);
        });
    }
    else {
      this.nameExist = false;
    }
  }

  async reset() {
    this.is_air_supported = '';
    this.is_door_supported = '';
    this.ibAirport = '';
    this.obAirport = '';
    this.cityId = '';
    this.state_id = '';
    this.cityid = '';
    this.tablefilter();
    this.saveFiltersAndPaginationToStorage();
  }

  exportToCSV() {
    const csvData = [];
    const headers = ['City Name', 'State', 'Country', 'Is Active', 'IB Airport', 'OB Airport', 'Door Supported IB', 'Door Supported OB'];
    csvData.push(headers);
    let isActive;
    let ibAirport;
    let obAirport;
    let activeIb;
    let activeOb;

    const TOKEN = this.authService.getAuthToken();
    const api = '/citiesPage';
    const params = this.filterWithoutPagination();

    const fetchAllPages = async () => {
      let allData = [];
      let currentPage = 0;
      let totalPages = 1; // Initial value, will be updated by the API response

      while (currentPage < totalPages) {
        const response = await fetch(`${API_URL}${api}${params}&pageNo=${currentPage}&pageSize=10`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const json = await response.json();
        totalPages = json.totalPages; // Update totalPages with the actual value from the API
        allData = [...allData, ...json.content];
        currentPage++;
      }

      return allData;
    };

    fetchAllPages()
      .then((data) => {
        if (data && data.length > 0) {
          data.forEach((row) => {
            if (row.activeIb == false) {
              activeIb = 'No';
            } else {
              activeIb = 'Yes';
            }

            if (row.activeOb == false) {
              activeOb = 'No';
            } else {
              activeOb = 'Yes';
            }

            if (row.isActive == false) {
              isActive = 'No';
            } else {
              isActive = 'Yes';
            }

            if (row.ibAirport != null) {
              ibAirport = row.ibAirport.iataCode;
            } else {
              ibAirport = '-';
            }

            if (row.obAirport != null) {
              obAirport = row.obAirport.iataCode;
            } else {
              obAirport = '-';
            }

            const data = [`"${row.name}"`, row.state_id.name, row.state_id.country.name, isActive, `"${ibAirport}"`, `"${obAirport}"`, activeIb, activeOb];
            csvData.push(data);
          });

          const csv = csvData.map((e) => e.join(',')).join('\n');
          const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
          saveAs(blob, 'CityDetails.csv');
        } else {
          console.error('No data to export');
          Swal.fire({
            title: 'Error',
            text: 'No data to export.',
            icon: 'error',
            confirmButtonText: 'Ok',
          });
        }
      })
      .catch((error) => {
        console.error('Error exporting CSV:', error);
        Swal.fire({
          title: 'Error',
          text: 'Failed to export CSV. Please try again.',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
      });
  }

  filterWithoutPagination() {
    let filterParams = '?';
    if (this.is_air_supported != '') {
      filterParams = filterParams + 'isAirSupported=' + this.is_air_supported + '&';
    }
    if (this.is_door_supported != '') {
      filterParams = filterParams + 'isDoorSupported=' + this.is_door_supported + '&';
    }
    if (this.ibAirport != '') {
      filterParams = filterParams + 'ibAirport=' + this.ibAirport.id + '&';
    }
    if (this.obAirport != '') {
      filterParams = filterParams + 'obAirport=' + this.obAirport.id + '&';
    }
    if (this.cityId != '') {
      filterParams = filterParams + 'id=' + this.cityId.id + '&';
    }
    if (this.state_id != '') {
      filterParams = filterParams + 'stateId=' + this.state_id.id + '&';
    }
    return filterParams;
  }

  navigateToGS() {
    this.router.navigate(['groundservices']);
  }
  displayFn(airport1: Airport): String {
    return airport1 && airport1?.city?.name ? airport1.city.name : '';
  }
  displayFn1(airport2: Airport): String {
    return airport2 && airport2?.city?.name ? airport2.city.name : '';
  }
  displayFn2(city: Cities): String {
    return city?.name ? city.name : '';
  }

  displayStateFn(state: States): String {
    return state.name ? state.name : '';
  }

  private _filter(name: String): Airport[] {
    const filterValue = name.toLowerCase();
    return this.airportssearch.filter((option) => option.city.name.toLowerCase().includes(filterValue));
  }

  private _filter2(name: String): Airport[] {
    const filterValue2 = name.toLowerCase();
    return this.airportssearch2.filter((option) => option.city.name.toLowerCase().includes(filterValue2));
  }

  loadSavedFilters() {
    const savedFilters = JSON.parse(localStorage.getItem(FILTERS_STORAGE_KEY));
    const savedPagination = JSON.parse(localStorage.getItem(PAGINATION_STORAGE_KEY));
    if (savedFilters) {
      this.applySavedFilters(savedFilters);
    }
    if (savedPagination) {
      this.pagination = savedPagination;
    }
  }

  applySavedFilters(savedFilters: any) {
    this.state_id = savedFilters.state_id;
    this.is_door_supported = savedFilters.is_door_supported;
    this.ibAirport = savedFilters.ibAirport;
    this.obAirport = savedFilters.obAirport;
    this.cityId = savedFilters.cityId;
  }

  saveFiltersAndPaginationToStorage() {
    const filters = {
      state_id: this.state_id,
      is_door_supported: this.is_door_supported,
      ibAirport: this.ibAirport,
      obAirport: this.obAirport,
      cityId: this.cityId,
    };
    localStorage.setItem(FILTERS_STORAGE_KEY, JSON.stringify(filters));
    localStorage.setItem(PAGINATION_STORAGE_KEY, JSON.stringify(this.pagination));
  }
}
