<div class="col-md-12">
	<container-element [ngSwitch]="pageview">
		<some-element *ngSwitchCase="'table'">
			<div class="card">
				<div class="card-header">
					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						<b style="color: blue;">AIRPORTS</b>
					</div>
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-primary" (click)="viewchange('add')">Add</div>
					</div>
				</div>
				<div class="row p-4">
					<div class="col-md-2 row">
						<mat-form-field appearance="standard">
							<mat-label>State Name</mat-label>
							<mat-select matNativeControl id="" [(ngModel)]="stateid"
								(blur)="getIndividualCities()">
								<mat-option value="">
									All
								</mat-option>
								<mat-option *ngFor="let sta of state" [value]="sta.id">
									{{sta.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-2 row">
						<mat-form-field appearance="standard">
							<mat-label>City Name</mat-label>
							<mat-select matNativeControl id="" [(ngModel)]="cityid">
								<mat-option value="">
									All
								</mat-option>
								<mat-option *ngFor="let cit of city1" [value]="cit.id">
									{{cit.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<!-- <div class="col-md-2 row">
						<mat-form-field appearance="standard">
							<mat-label>Airport Name</mat-label>
							<mat-select matNativeControl id="" [(ngModel)]="airid">
								<mat-option value="">
									All
								</mat-option>
								<mat-option *ngFor="let air of airportlist.data" [value]="air.id">
									{{air.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div> -->
					<div class="col-md-3 row">
                        <mat-form-field appearance="standard">
                            <mat-label>Airport Name</mat-label>
                            <input matInput placeholder="Search Airport" [(ngModel)]="airid" [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let air of airportlist?.data | filter: airid" [value]="air.name">
                                    {{air.city.name}}-{{air.iataCode}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
					<div class="col-md-1 mt-3">
						<button class="btn btn-primary" (click)="tablefilter()">Find</button>
					</div>
					<div class="col-md-1 mt-3">
						<button class="btn btn-secondary" (click)="reset()">Reset</button>
					</div>
					<div class="col-md-1 mt-3">
						<button class="btn btn-success" (click)="exportToCSV()">Report</button>
					</div>
				</div>
				<!-- <div class="container">
					<div class="row">
						<div class="">
							<div class="table-responsive">
								<table class="align-middle text-truncate mb-0 table table-borderless table-hover">
									<thead>
										<tr>
											<th class="text-center">City</th>
											<th class="text-center">State</th>
											<th class="text-center">Airport Name</th>
											<th class="text-center">IATA</th>
											<th class="text-center">ICAO</th>
											<th class="text-center">Action</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let air of AirportsList"
											[ngClass]="air.isActive == false ? 'red':'white'">
											<td class="text-center" style="max-width: 100px; overflow:hidden">

													{{air.city.name}}

											</td>
											<td class="text-center" style="max-width: 100px; overflow:hidden">

													{{air.city.state_id.name}}

											</td>
											<td class="text-center" style="max-width: 120px; overflow:hidden">
												<a [routerLink]="" matTooltip="{{air.name}}"
													(click)="viewsection('view',air)">
													{{air.name}}
												</a>
											</td>
											<td class="text-center">

													{{air.iataCode}}

											</td>
											<td class="text-center">

													{{air.icaoCode}}

											</td>
											<td class="text-center">
												<div role="group" class="btn-group-sm btn-group m-1" *ngIf="air.isActive==1">
													<button class="btn-shadow btn btn-primary" matTooltip="Edit"
														(click)="viewchange1('edit',air)"><i
															class="lnr-pencil"></i></button>
												</div>

												<div role="group" *ngIf="air.isActive==1"
													class="btn-group-sm btn-group">
													<button class="btn-shadow btn btn-danger"
														(click)="ActiveAirport(air.id,0)"><i
															class="pe-7s-trash"></i></button>
												</div>
												<div role="group" *ngIf="air.isActive==0"
													class="btn-group-sm btn-group">
													<button class="btn-shadow btn btn-primary"
														(click)="ActiveAirport(air.id,1)"><i
															class="fa fa-undo"></i></button>
												</div>



											</td>
										</tr>
									</tbody>
								</table>
								<mat-paginator (page)="handlePageEvent($event)" [length]="AirportsList?.totalElements"
									[pageSize]="AirportsList?.numberOfElements"
									[showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions"
									[pageIndex]="AirportsList?.number" aria-label="Select page">
								</mat-paginator>
							</div>
						</div>
					</div>
				</div> -->
				<div class="table-responsive">
					<table class="w-100" mat-table [dataSource]="dataSource" matSort
						(matSortChange)="announceSortChange($event)">
						<ng-container matColumnDef="state">
							<th mat-header-cell *matHeaderCellDef
								sortActionDescription="Sort by state" style="width: 16%;">State</th>
							<td class="text-left" mat-cell *matCellDef="let element">{{element.city.state_id.name}}</td>
						</ng-container>
                        <ng-container matColumnDef="city">
							<th mat-header-cell *matHeaderCellDef
								sortActionDescription="Sort by city">City</th>
							<td class="text-left" mat-cell *matCellDef="let element">
								{{element.city.name}} </td>
						</ng-container>
						<ng-container matColumnDef="airportname">
							<th mat-header-cell *matHeaderCellDef
								sortActionDescription="Sort by airportname">
								Airport</th>
							<td class="text-left" mat-cell *matCellDef="let element"><a [routerLink]="" matTooltip="{{element.name}}"(click)="viewsection('view',element)">
								{{element.name}} </a>
							</td>
						</ng-container>

						<ng-container matColumnDef="iata">
							<th  class="text-center" mat-header-cell *matHeaderCellDef
								sortActionDescription="Sort by iata">iata Code</th>
							<td class="text-center" mat-cell *matCellDef="let element">{{element.iataCode}}
							</td>
						</ng-container>

						<ng-container matColumnDef="ib_active">
							<th  class="text-center" mat-header-cell *matHeaderCellDef
								sortActionDescription="Sort by ib_active">IB Supported</th>
							<td class="text-center" mat-cell *matCellDef="let element">
								<div *ngIf="element.ib_active==true">Yes</div>
								<div *ngIf="element.ib_active==false">No</div>
							</td>
						</ng-container>

						<ng-container matColumnDef="ob_active">
							<th  class="text-center" mat-header-cell *matHeaderCellDef
								sortActionDescription="Sort by ob_active">OB Supported</th>
							<td class="text-center" mat-cell *matCellDef="let element">
								<div *ngIf="element.ob_active==true">Yes</div>
								<div *ngIf="element.ob_active==false">No</div>
							</td>
						</ng-container>

						<!-- <ng-container matColumnDef="icao">
							<th  class="text-center" mat-header-cell *matHeaderCellDef
								sortActionDescription="Sort by icao">icao Code</th>
							<td class="text-center" mat-cell *matCellDef="let element">{{element.iataCode}}
							</td>
						</ng-container> -->

						<ng-container matColumnDef="Active">
							<th mat-header-cell *matHeaderCellDef > Action </th>
							<td mat-cell *matCellDef="let element">
								<div role="group" class="btn-group-sm btn-group m-1" *ngIf="element.isActive==1">
									<button class="btn-shadow btn btn-primary" matTooltip="Edit"
										(click)="viewchange1('edit',element)"><i
											class="lnr-pencil"></i></button>
								</div>

								<div role="group" *ngIf="(element.isActive==1) && !displaytoAccountant"
									class="btn-group-sm btn-group">
									<button class="btn-shadow btn btn-danger"
										(click)="DeActivateAirport(element,0)"><i
											class="pe-7s-trash"></i></button>
								</div>
								<div role="group" *ngIf="(element.isActive==0) && !displaytoAccountant "
									class="btn-group-sm btn-group">
									<button class="btn-shadow btn btn-primary"
										(click)="ActivateAirport(element,1)"><i
											class="fa fa-undo"></i></button>
								</div>
							</td>
						</ng-container>
						<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
						<tr mat-row *matRowDef="let row; columns: displayedColumns;"
						[ngClass]="row.isActive==false? 'red':'white'"></tr>
					</table>
					<mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
					</mat-paginator>
				</div>
			</div>
		</some-element>
		<some-element *ngSwitchCase="'add'">
			<div class="card">
				<div class="card-header">
					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						<b style="color: blue;">ADD AIRPORT</b>
					</div>
				</div>
				<div class="container">
					<div class="row">
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Airport Name<span class="text-danger">*</span>
								</mat-label>
								<input type="text" placeholder="Airport Name" aria-label="Number"
									[(ngModel)]="addairport.name" matInput>
							</mat-form-field>
						</div>

						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									IATA<span class="text-danger">*</span>
								</mat-label>
								<input type="text" placeholder="IATA" [(ngModel)]="addairport.iataCode"
									aria-label="Number" matInput>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									ICAO<span class="text-danger">*</span>
								</mat-label>
								<input type="text" placeholder="ICAO" [(ngModel)]="addairport.icaoCode"
									aria-label="Number" matInput>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>Country<span class="text-danger">*</span></mat-label>
								<mat-select matNativeControl  [(ngModel)]="addairport.country">
									<mat-option *ngFor="let con of countries |async" [value]="con.id" (click)="getaddRecverStates()">
										{{con.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>State<span class="text-danger">*</span></mat-label>
								<mat-select matNativeControl [(ngModel)]="addairport.stateid">
									<mat-option *ngFor="let con of addstates |async" [value]="con.id" (click)="getaddReceverCities()">
										{{con.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>City<span class="text-danger">*</span></mat-label>
								<mat-select matNativeControl  [(ngModel)]="addairport.cityid.id">
									<mat-option *ngFor="let cit of addcities |async" [value]="cit.id">
										{{cit.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Latitude
								</mat-label>
								<input type="text" placeholder="Latitude" aria-label="Number"
									[(ngModel)]="addairport.latitude" matInput>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Longitude
								</mat-label>
								<input type="text" placeholder="Longitude" aria-label="Number"
									[(ngModel)]="addairport.longitude" matInput>
							</mat-form-field>
						</div>
						<!-- <div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									IB Supported<span class="text-danger">*</span>
								</mat-label>
								<input type="text" placeholder="ICAO" [(ngModel)]="addairport.ib_active"
									aria-label="Number" matInput>
							</mat-form-field>
						</div> -->
						<div class="col-md-5">
                            <h6><b>IB Supported<span class="text-danger">*</span></b></h6>
                            <mat-radio-group aria-label="Select IB" [(ngModel)]="edit.ib_active">
                                <mat-radio-button value=1>Active</mat-radio-button>
                                <br>
                                <mat-radio-button value=0>In Active</mat-radio-button>
                            </mat-radio-group>
                        </div>
						<!-- <div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									OB Supported<span class="text-danger">*</span>
								</mat-label>
								<input type="text" placeholder="ICAO" [(ngModel)]="addairport.ob_active"
									aria-label="Number" matInput>
							</mat-form-field>
						</div> -->
						<div class="col-md-5">
                            <h6><b>OB Supported<span class="text-danger">*</span></b></h6>
                            <mat-radio-group aria-label="Select OB" [(ngModel)]="edit.ob_active">
                                <mat-radio-button value=1>Active</mat-radio-button>
                                <br>
                                <mat-radio-button value=0>In Active</mat-radio-button>
                            </mat-radio-group>
                        </div>
					</div>
				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table');tablefilter()">Cancel</div>
						<div class="mb-2 me-2 btn btn-primary" (click)="createAirport()">Add</div>
					</div>
				</div>
			</div>
		</some-element>
		<some-element *ngSwitchCase="'edit'">
			<div class="card">
				<div class="card-header">
					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						<b style="color: blue;">EDIT AIRPORT DETAILS</b>
					</div>
				</div>
				<div class="container">
					<div class="row">
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Airport Name<span class="text-danger">*</span>
								</mat-label>
								<input type="text" placeholder="Airport Name" [(ngModel)]="newairport.name"
									aria-label="Number" matInput>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									IATA<span class="text-danger">*</span>
								</mat-label>
								<input type="text" placeholder="Airport Name" [(ngModel)]="newairport.iataCode"
									aria-label="Number" matInput>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									ICAO<span class="text-danger">*</span>
								</mat-label>
								<input type="text" placeholder="Airport Name" [(ngModel)]="newairport.icaoCode"
									aria-label="Number" matInput>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>Country<span class="text-danger">*</span></mat-label>
								<mat-select matNativeControl id="" [(ngModel)]="newairport.country"
									(click)="getRecverStates()">
									<mat-option *ngFor="let con of countries |async" [value]="con.id">
										{{con.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>State<span class="text-danger">*</span></mat-label>
								<mat-select matNativeControl [(ngModel)]="newairport.stateid"
									(click)="getReceverCities()">
									<mat-option *ngFor="let con of states1 |async" [value]="con.id">
										{{con.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>City<span class="text-danger">*</span></mat-label>

								<mat-select matNativeControl [(ngModel)]="newairport.cityid.id">
									<mat-option>All</mat-option>
									<mat-option *ngFor="let cit of cities1 |async" [value]="cit.id">
										{{cit.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Latitude
								</mat-label>
								<input type="text" placeholder="Latitude" [(ngModel)]="newairport.latitude"
									aria-label="Number" matInput>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									Longitude
								</mat-label>
								<input type="text" placeholder="Longitude" [(ngModel)]="newairport.longitude"
									aria-label="Number" matInput>
							</mat-form-field>
						</div>
						<!-- <div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									IB Supported<span class="text-danger">*</span>
								</mat-label>
								<input type="text" placeholder="Airport Name" [(ngModel)]="newairport.ib_active"
									aria-label="Number" matInput>
							</mat-form-field>
						</div> -->
						<div class="col-md-5">
                            <h6><b>IB Supported<span class="text-danger">*</span></b></h6>
                            <mat-radio-group aria-label="Select IB" [(ngModel)]="edit.ib_active">
                                <mat-radio-button value=1>Active</mat-radio-button>
                                <br>
                                <mat-radio-button value=0>In Active</mat-radio-button>
                            </mat-radio-group>
                        </div>
						<!-- <div class="col-md-4">
							<mat-form-field appearance="standard">
								<mat-label>
									OB Supported<span class="text-danger">*</span>
								</mat-label>
								<input type="text" placeholder="Airport Name" [(ngModel)]="newairport.ob_active"
									aria-label="Number" matInput>
							</mat-form-field>
						</div> -->
						<div class="col-md-5">
                            <h6><b>OB Supported<span class="text-danger">*</span></b></h6>
                            <mat-radio-group aria-label="Select OB" [(ngModel)]="edit.ob_active">
                                <mat-radio-button value=1>Active</mat-radio-button>
                                <br>
                                <mat-radio-button value=0>In Active</mat-radio-button>
                            </mat-radio-group>
                        </div>
					</div>
				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table');tablefilter()">Cancel</div>
						<div class="mb-2 me-2 btn btn-primary" (click)="editAirport()">Save</div>
					</div>
				</div>
			</div>
		</some-element>
		<some-element *ngSwitchCase="'view'">
			<div class="card">
				<div class="card-header">
					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						View Airport
					</div>
				</div>
				<div class="container m-2">
					<div class="row ">
						<div class="col-md-3 fw-bold">Airport Name</div>
						<div class="col-md-5"> {{viewairport.name}} </div>
					</div>
					<div class="row ">
						<div class="col-md-3 fw-bold">IATA</div>
						<div class="col-md-5"> {{viewairport.iata}} </div>
					</div>
					<div class="row ">
						<div class="col-md-3 fw-bold">ICAO</div>
						<div class="col-md-5"> {{viewairport.icaoCode}} </div>
					</div>
					<div class="row ">
						<div class="col-md-3 fw-bold">IB Supported</div>
						<div class="col-md-5"> {{viewairport.ib_active}} </div>
					</div>
					<div class="row ">
						<div class="col-md-3 fw-bold">OB Supported</div>
						<div class="col-md-5"> {{viewairport.ob_active}} </div>
					</div>
					<div class="row ">
						<div class="col-md-3 fw-bold">Country </div>
						<div class="col-md-5"> {{viewairport.country}} </div>
					</div>
					<div class="row ">
						<div class="col-md-3 fw-bold">State </div>
						<div class="col-md-5"> {{viewairport.state}} </div>
					</div>
					<div class="row ">
						<div class="col-md-3 fw-bold">City</div>
						<div class="col-md-5"> {{viewairport.city}} </div>
					</div>
					<div class="row ">
						<div class="col-md-3 fw-bold">Latitude</div>
						<div class="col-md-5"> {{viewairport.latitude}} </div>
					</div>
					<div class="row ">
						<div class="col-md-3 fw-bold">Longitude</div>
						<div class="col-md-5"> {{viewairport.longitude}} </div>
					</div>
				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table');tablefilter()">Back</div>
					</div>
				</div>
			</div>
		</some-element>
	</container-element>
</div>
