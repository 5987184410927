<div *ngIf="loading" class="loader-container">
  <ngx-loading [show]="loading"
               [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
               [template]="loadingTemplate"></ngx-loading>
</div>
<div class="col-md-12">
    <container-element [ngSwitch]="pageview">
        <some-element *ngSwitchCase="'pod'">
            <div class="card">
                <div class="card-header">
                    <div class="card-header-title font-size-lg text-capitalize fw-Bold">
                        File Upload
                    </div>
                </div>
                <div class="container p-4">
                    <div class="row">
                        <div class="col-md-10 center p-1">
                            <label style="margin-bottom: 10px;">
                                POD
                            </label>
                            <input class="form-control" type="file" (change)="getFiles($event)">
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="btn-actions-pane-right p-2">
                        <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>
                        <div class="mb-2 me-2 btn btn-primary" (click)="uploadPOD()">Save</div>
                    </div>
                </div>
            </div>
        </some-element>


        <some-element *ngSwitchCase="'datalogger'">
            <div class="card">
                <div class="card-header">
                    <div class="card-header-title font-size-lg text-capitalize fw-Bold">
                        File Upload
                    </div>
                </div>
                <div class="container p-4">
                    <div class="row">
                        <div class="col-md-10 center p-1">
                            <label style="margin-bottom: 10px;">
                                Data Logger
                            </label>
                            <input class="form-control" type="file" (change)="getFiles($event)">
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="btn-actions-pane-right p-2">
                        <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>
                        <div class="mb-2 me-2 btn btn-primary" (click)="uploadDatafiles()">Save</div>
                    </div>
                </div>
            </div>
        </some-element>


        <some-element *ngSwitchCase="'vehiclecalibration'">
            <div class="card">
                <div class="card-header">
                    <div class="card-header-title font-size-lg text-capitalize fw-Bold">
                        File Upload
                    </div>
                </div>
                <div class="container p-4">
                    <div class="row">
                        <div class="col-md-10 center p-1">
                            <label style="margin-bottom: 10px;">
                                Vehicle Calibration
                            </label>
                            <input class="form-control" type="file" (change)="getFiles($event)">
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="btn-actions-pane-right p-2">
                        <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>
                        <div class="mb-2 me-2 btn btn-primary" (click)="uploadVechilefiles()">Save</div>
                    </div>
                </div>
            </div>
        </some-element>


        <some-element *ngSwitchCase="'otherdocuments'">
            <div class="card">
                <div class="card-header">
                    <div class="card-header-title font-size-lg text-capitalize fw-Bold">
                        File Upload
                    </div>
                </div>
                <div class="container p-4">
                    <div class="row">
                        <div class="col-md-10 center p-1">
                            <label style="margin-bottom: 10px;">
                                Other Documents
                            </label>
                            <input class="form-control" type="file" (change)="getFiles($event)">
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="btn-actions-pane-right p-2">
                        <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>
                        <div class="mb-2 me-2 btn btn-primary" (click)="uploadOthersfiles()">Save</div>
                    </div>
                </div>
            </div>
        </some-element>


        <some-element *ngSwitchCase="'temp'">
            <div class="card">
                <div class="card-header">
                    <div class="card-header-title font-size-lg text-capitalize fw-Bold">
                        File Upload
                    </div>
                </div>
                <div class="container p-4">
                    <div class="row">
                        <div class="col-md-10 center p-1">
                            <label style="margin-bottom: 10px;">
                                Temperature Record
                            </label>
                            <input class="form-control" type="file" (change)="getFiles($event)">
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="btn-actions-pane-right p-2">
                        <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>
                        <div class="mb-2 me-2 btn btn-primary" (click)="uploadTemp()">Save</div>
                    </div>
                </div>
            </div>
        </some-element>


        <some-element *ngSwitchCase="'image4'">
            <div class="card">
                <div class="card-header">
                    <div class="card-header-title font-size-lg text-capitalize fw-Bold">
                        Image Upload
                    </div>
                </div>
                <div class="container p-4">
                    <div class="row">
                        <div class="col-md-10 center p-1">
                            <label style="margin-bottom: 10px;">
                                Image 4
                            </label>
                            <input class="form-control" type="file" (change)="getFiles($event)">
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="btn-actions-pane-right p-2">
                        <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>
                        <div class="mb-2 me-2 btn btn-primary" (click)="uploadimage4()">Save</div>
                    </div>
                </div>
            </div>
        </some-element>


        <some-element *ngSwitchCase="'image3'">
            <div class="card">
                <div class="card-header">
                    <div class="card-header-title font-size-lg text-capitalize fw-Bold">
                        Image Upload
                    </div>
                </div>
                <div class="container p-4">
                    <div class="row">
                        <div class="col-md-10 center p-1">
                            <label style="margin-bottom: 10px;">
                                Image 3
                            </label>
                            <input class="form-control" type="file" (change)="getFiles($event)">
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="btn-actions-pane-right p-2">
                        <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>
                        <div class="mb-2 me-2 btn btn-primary" (click)="uploadimage3()">Save</div>
                    </div>
                </div>
            </div>
        </some-element>


        <some-element *ngSwitchCase="'image2'">
            <div class="card">
                <div class="card-header">
                    <div class="card-header-title font-size-lg text-capitalize fw-Bold">
                        Image Upload
                    </div>
                </div>
                <div class="container p-4">
                    <div class="row">
                        <div class="col-md-10 center p-1">
                            <label style="margin-bottom: 10px;">
                                Image 2
                            </label>
                            <input class="form-control" type="file" (change)="getFiles($event)">
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="btn-actions-pane-right p-2">
                        <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>
                        <div class="mb-2 me-2 btn btn-primary" (click)="uploadimage2()">Save</div>
                    </div>
                </div>
            </div>
        </some-element>


        <some-element *ngSwitchCase="'image1'">
            <div class="card">
                <div class="card-header">
                    <div class="card-header-title font-size-lg text-capitalize fw-Bold">
                        Image Upload
                    </div>
                </div>
                <div class="container p-4">
                    <div class="row">
                        <div class="col-md-10 center p-1">
                            <label style="margin-bottom: 10px;">
                                Image 1
                            </label>
                            <input class="form-control" type="file" (change)="getFiles($event)">
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="btn-actions-pane-right p-2">
                        <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view')">Cancel</div>
                        <div class="mb-2 me-2 btn btn-primary" (click)="uploadimage1()">Save</div>
                    </div>
                </div>
            </div>
        </some-element>


        <some-element *ngSwitchCase="'view'">
            <div class="row">
                <div class="card col-md-12">
                    <div class="card-header">

                        <div class="card-header-title font-size-lg text-capitalize fw-Bold"><i class="fa fa-arrow-left"
                                style="cursor: pointer" (click)="viewchange1()"></i>
                            Consignment No.
                        </div>
                        <div *ngFor="let con of consignmentlists.data"
                            class="card-header-title font-size-lg text-capitalize fw-Bold text-primary mx-4">
                            {{con.consignmentNo}} ({{con.consignmentType.name}})
                        </div>

                        <div class="btn-actions-pane-right p-2" *ngFor="let con of consignmentlists.data">
<!--                          <div *ngIf="con[0].isCoperateBooking== true">-->
                            <button *ngIf="con.isCoperateBooking== true && (displaytoAdmin || displaytoSupervisor || displaytoProcessingAgent)" class="mb-2 me-2 btn btn-primary" (click)="gotoConsignmentEdit(con.id, con.consignmentNo)" [disabled]="billCreated && invoiceId != null">
                              EDIT</button>
<!--                          </div>-->

                            <div *ngIf="displaytoAdmin || displaytoSupervisor || displaytoAccountant" class="mb-2 me-2 btn btn-primary" (click)="gotoBillingInfo(con.id)">
                                Billing Info</div>
                            <div *ngIf="displaytoAdmin || displaytoSupervisor || displaytoAccountant" class="mb-2 me-2 btn btn-primary" (click)="gotoCostCenter(con.id)">
                                Cost Center</div>
                            <div class="mb-2 me-2 btn btn-success" (click)="getRecord(con.id)">
                                Download CN</div>
                        </div>
                    </div>
                    <div class="container m-2">

                        <div class="row">
                            <div class="col-md-12">
                                <div *ngFor="let con of consignmentlists.data">
                                    <div class="row">
                                        <div class="col-md-3 fw-bold">Customer Name</div>
                                        <div class="col-md-3"> {{con?.senderId?.firstName !=null ? con?.senderId?.firstName : con?.senderIndividual?.firstName + con?.senderIndividual?.lastName }} </div>
                                        <!-- <div class="col-md-3 fw-bold border-start">Consignment Type</div>
                      <div class="col-md-3"> {{con.consignmentType.details}} </div> -->
                                        <div class="col-md-3 fw-bold border-start">Consignee Name</div>
                                        <div class="col-md-3"> {{con?.receiverId?.firstName}}</div>
                                    </div>
                                    <mat-divider class="m-1"></mat-divider>

                                  <div class="row">
                                    <div class="col-md-3 fw-bold">Origin</div>
                                    <div class="col-md-3">{{ con?.originLocation?.city.name ||
                                    (con?.pickupAddress?.pincodeId?.cityId?.name ||
                                      'N/A')}}</div>
                                    <div class="col-md-3 fw-bold border-start">Destination</div>
                                    <div class="col-md-3">
                                      {{ con?.destinationLocation?.city.name || (con?.receiverId?.city?.name ||
                                      'N/A') }}
                                    </div>
                                  </div>
                                    <mat-divider class="m-1"></mat-divider>
                                    <div class="row ">
                                        <div class="col-md-3 fw-bold">Consignment Type</div>
                                            <div class="col-md-3"> {{con.consignmentType.name}} </div>
                                            <div class="col-md-3 fw-bold border-start">Consignment SubType</div>
                                            <div class="col-md-3"> {{con.consignmentSubType.description}} </div>
                                        </div>
                                      <mat-divider class="m-1"></mat-divider>
                                    <div class="row ">
                                        <div class="col-md-3 fw-bold">Product Mode</div>
                                        <div class="col-md-3"> {{con?.series_ref?.product_mode}} </div>
                                        <div class="col-md-3 fw-bold border-start">Product Type</div>
                                        <div class="col-md-3"> {{con?.series_ref?.code}} </div>
                                    </div>
                                    <mat-divider class="m-1"></mat-divider>
                                    <div class="row ">
                                        <div class="col-md-3 fw-bold">Created At</div>
                                        <div class="col-md-3"> {{ con?.createdAt | date:'dd-MM-yyyy HH:mm:ss' }} </div>
                                        <div class="col-md-3 fw-bold border-start">Updated At</div>
                                        <div class="col-md-3" *ngIf="con?.updatedAt!=null">
                                            {{ con?.updatedAt | date:'dd-MM-yyyy HH:mm:ss' }} </div>
                                    </div>
                                    <mat-divider class="m-1"></mat-divider>
                                    <div class="row ">
                                        <div class="col-md-3 fw-bold ">Consignment Value</div>
                                        <div class="col-md-3"> INR {{con?.consignmentValue}}</div>

                                        <div class="col-md-3 fw-bold border-start ">Status</div>
                                        <div class=" col-md-3">
<!--                                          <div *ngIf="isInvoiced != null && isInvoiced"-->
<!--                                               class="badge bg-pill bg-primary">-->
<!--                                            Invoiced-->
<!--                                          </div>-->
<!--                                          <div *ngIf="!isInvoiced">-->
                                            <div *ngIf="con?.trackingId?.status.id != 16 && con?.trackingId?.status.id!= 19 && con?.trackingId?.status.id!=18 && con?.trackingId?.status.id!=36"
                                                class="badge bg-pill bg-primary">
                                                {{con?.trackingId?.status?.name}}
                                            </div>
<!--                                          </div>-->
<!--                                          <div *ngIf="!isInvoiced">-->
                                            <div *ngIf="con?.trackingId?.status.id == 16"
                                                class="badge bg-pill bg-success">
                                                {{con?.trackingId?.status?.name}}
                                            </div>
<!--                                          </div>-->
<!--                                            <div *ngIf="!isInvoiced">-->
                                              <div *ngIf="con?.trackingId?.status.id == 19"
                                                class="badge bg-pill bg-orange-400">
                                                {{con?.trackingId?.status?.name}}
                                              </div>
<!--                                            </div>-->
<!--                                              <div *ngIf="!isInvoiced">-->
                                                <div *ngIf="con?.trackingId?.status.id==18" class="badge bg-pill bg-danger">
                                                {{con?.trackingId?.status?.name}}
                                                </div>
<!--                                              </div>-->
                                                <div *ngIf="con?.trackingId?.status.id==36" class="badge bg-pill bg-danger">
                                                  {{con?.trackingId?.status?.name}}
                                                </div>
                                        </div>
                                    </div>
                                  <mat-divider class="m-1"></mat-divider>
                                  <div class="row ">
                                    <div class="col-md-3 fw-bold">Temperature Range</div>
                                    <div *ngIf="con?.requiredTemperature?.range == null" class="col-md-3">
                                      -
                                    </div>
                                    <div *ngIf="con?.requiredTemperature?.range != null" class="col-md-3"> {{con?.requiredTemperature?.range}}&deg;C </div>
                                    <div class="col-md-3 fw-bold border-start">Invoice No</div>
                                    <div *ngIf="invoiceId == null" class="col-md-3"> - </div>
                                    <div *ngIf="billCreated && invoiceId != null" class="col-md-3"> {{InvoiceData.invoiceNo}} </div>
                                  </div>
                                  <mat-divider class="mb-3"></mat-divider>
                                  <div class="row ">
                                    <div class="col-md-3 fw-bold">Container Size</div>
                                    <div *ngIf="con?.containerSize?.name == null" class="col-md-3">
                                      -
                                    </div>
                                    <div *ngIf="con?.containerSize?.name != null" class="col-md-3">
                                      {{ con?.containerSize?.name}} </div>
                                    <div class="col-md-3 fw-bold border-start">Commodity Type</div>
                                    <div class="col-md-3"> {{con?.commodityType?.commodityType}} </div>
                                  </div>
                                  <mat-divider class="m-1"></mat-divider>
                                  <div class="row ">
                                    <div class="col-md-3 fw-bold">AWB Number</div>
                                    <div class="col-md-3"> {{con?.finalManifestationId?.awbNumber}} </div>
                                    <div class="col-md-3 fw-bold border-start">Manifest Id</div>
                                    <!--<div *ngIf="con?.finalManifestationId===null && con?.manifestationId===null" class="col-md-3"> {{con?.preManifestationId?.manifestIdLeg1}} - -->
                                    <!--    {{con?.preManifestationId?.manifestIdLeg2}} </div>-->
                                    <div *ngIf="con?.finalManifestationId===null && con?.manifestationId!=null" class="col-md-3"> {{con?.manifestationId?.manifestIdLeg1}} -
                                      {{con?.manifestationId?.manifestIdLeg2}} </div>
                                    <div *ngIf="con?.finalManifestationId!=null" class="col-md-3"> {{con?.finalManifestationId?.manifestIdLeg1}} -
                                      {{con?.finalManifestationId?.manifestIdLeg2}} </div>
                                  </div>

                                  <mat-divider class="m-1"></mat-divider>
                                  <div class="row ">
                                    <div class="col-md-3 fw-bold">Tentative Pickup Date</div>
                                    <div *ngIf="con?.tentative_pickup_time == null" class="col-md-3">
                                      -
                                    </div>
                                    <div *ngIf="con?.tentative_pickup_time != null" class="col-md-3">
                                      {{ con?.tentative_pickup_time | date:'dd-MM-yyyy' }} </div>
                                    <div class="col-md-3 fw-bold border-start"></div>
                                  </div>
                                    <mat-divider class="mb-3"></mat-divider>

                                    <div class="row">
                                        <div class="text-primary fw-bold"> PACKAGES
<!--                                            <div class="btn float-end btn-primary"-->
<!--                                                *ngIf="con.series_ref.product_type != 'ACE'"-->
<!--                                                (click)="addpackageviewchange('addpack',con)"><i class="fa fa-plus"></i>-->
<!--                                            </div>-->
                                        </div>
                                    </div>
                                    <mat-divider class="m-1"></mat-divider>
                                    <table class="align-middle text-truncate mb-0 table table-borderless table-hover">
                                        <thead>
                                            <tr>
                                                <th class="text-center">
<!--                                                  <div style="display: flex; flex-direction: column">-->
<!--                                                    <div>-->
                                                      Dimensions (L x B x H)
<!--                                                    </div>-->
<!--                                                    <div>-->
<!--                                                      L*B*H-->
<!--                                                    </div>-->
<!--                                                  </div>-->
                                                </th>
                                                <th class="text-center">Total Packages</th>
                                                <th class="text-center">Weight Per Package</th>
                                                <!-- <th class="text-center">Chargeable Weight</th> -->
                                                <th class="text-center">Total Chargeable Weight</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let pack of con.packages">
                                                <!-- <td colspan="2">	</td> -->
                                                <td class="text-center">
                                                    <!-- <div *ngFor="let pack of manifest_view.packages"> -->
                                                    {{pack?.length}}cm x {{pack?.breadth}}cm x {{pack?.height}}cm
                                                    <br />
                                                    <!-- </div> -->
                                                </td>
                                                <td class="text-center">
                                                    {{pack?.package_no}}
                                                </td>
                                                <td class="text-center">
                                                    {{pack?.weight_per_package}}Kg
                                                </td>
                                                <!-- <td class="text-center">
                          {{pack.total_weight}}
                        </td> -->
                                                <td class="text-center">
                                                    {{pack?.chargeable_weight}}Kg
                                                </td>
                                                <td class="mx-w-150 align-middle">
<!--                                                    <button class="btn btn-danger" (click)="deletePackages(pack?.id)">-->
<!--                                                        <i class="pe-7s-trash">-->
<!--                                                        </i>-->
<!--                                                    </button>-->
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <mat-divider class="m-1"></mat-divider>
                                    <div class="row ">
                                        <div class="col-md-3 fw-bold  ">Total Num of Packages</div>
                                        <div class="col-md-3">
                                            {{con?.totalNoOfPackages}}
                                        </div>
                                        <div *ngIf="con?.required_temperature != null" class="col-md-3 fw-bold  ">
                                            Temperature of Packages
                                        </div>
                                        <div class="col-md-3">
                                            {{con?.required_temperature}}
                                        </div>
                                    </div>
                                    <mat-divider class="m-1"></mat-divider>
                                    <div class="row ">
                                        <div class="col-md-3 fw-bold ">Total Weight</div>
                                        <div class="col-md-3"> {{con?.totalWeight}}Kg</div>
                                        <div class="col-md-3 fw-bold border-start ">Total Chargeable Weight</div>
                                        <div class="col-md-3"> {{con?.totalChargableWeight}}Kg</div>
                                    </div>
                                    <mat-divider class="m-1"></mat-divider>
                                    <!-- <div class="row ">
                                        <div class="col-md-3 fw-bold ">Taxable Amount</div>
                                        <div class="col-md-3">
                                          <span *ngIf="billCreated"> INR {{billingPriceTaxableAmount?billingPriceTaxableAmount:0}}</span>
                                        </div>
                                        <div class="col-md-3 fw-bold border-start">Grand Total</div>
                                        <div class="col-md-3">
                                          <span *ngIf="billCreated"> INR {{billingPriceGrandTotal?billingPriceGrandTotal:0}}</span>
                                        </div>
                                    </div> -->
                                    <!-- <mat-divider class="mb-3"></mat-divider> -->
                                    <div class="row">
                                        <div class=" col-md-6 text-primary fw-bold"> SHIPPER ADDRESS</div>
                                        <div class=" col-md-6 text-primary fw-bold"> CONSIGNEE ADDRESS</div>
                                    </div>
                                    <mat-divider class="m-1"></mat-divider>
                                  <div class="row ">
                                    <div class="col-md-3 fw-bold">Pickup Address</div>
                                    <div class="col-md-3">{{con?.pickupAddress?.address}},
                                      {{con?.pickupAddress?.pincodeId?.cityId?.name}},{{con?.pickupAddress?.pincodeId?.cityId?.state_id?.name}},
                                      <br>
                                      {{con?.pickupAddress?.pincodeId?.cityId?.state_id?.country?.name}},{{con?.pickupAddress?.pincodeId?.pin_number}}
                                    </div>

                                    <div class="col-md-3 fw-bold border-start ">Drop Address</div>
                                    <div class="col-md-3">
                                      {{con?.receiverId?.address + "," + con?.receiverId?.city?.name + "," +
                                    con?.receiverId?.city?.state_id?.name}},
                                      <br>
                                      {{con?.receiverId?.city?.state_id?.country?.name}},{{con?.receiverId?.zipCode}}

                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">

                            <div class="btn-actions-pane-right p-2">
                                <div class="mb-2 me-2 btn btn-danger" (click)="viewchange1()">Back</div>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="card">
                    <div class="card-header">
                        <div class="card-header-title font-size-lg text-capitalize fw-Bold">
                          <h3><b>Documents Section</b></h3>
                        </div>
                    </div>
                   <div class="row">
                       <ng-container *ngFor="let documentTypeWithDocuments of uploadedDocuments">
                           <div class="col-md-12">
                               <div class="fw-bold h4 margin-h-center">
                                   {{ getDocumentTypeName(documentTypeWithDocuments?.documentType?.id) }}
                               </div>
                           </div>

                           <ng-container *ngIf="documentTypeWithDocuments.documents.length > 0">
                            <ng-container *ngFor="let document of documentTypeWithDocuments.documents">
                                <div class="col-md-4">
                                    <div class="font-icon-wrapper">
                                        <a (click)="downloadDocument(document.id)" matTooltip="Download">
                                            <i class="fa fa-fw text-success" aria-hidden="true" style="font-size: 50px; border: none;"></i>
                                        </a>
                                    </div>
                                    <div>Uploaded At : {{ convertTime(document.fileName) }}</div><br>
                                </div>
                            </ng-container>

                            <div class="col-md-1" (click)="showUploadSection1 = true; documentTypeWithDocuments.documents.fileOpen = !documentTypeWithDocuments.documents.fileOpen" style="cursor: pointer;">
                                <div class="font-icon-wrapper" *ngIf="!documentTypeWithDocuments.documents.fileOpen" matTooltip="Update">
                                    <a> <i class="fa fa-pencil" aria-hidden="true" style="font-size: 20px;"></i> </a>
                                </div>
                            </div>

                            <div class="col-md-12" *ngIf="showUploadSection1 && documentTypeWithDocuments.documents.fileOpen">
                                <!-- Update upload UI here -->
                                <div class="card">
                                    <div class="card-header">
                                        <div class="card-header-title font-size-lg text-capitalize fw-Bold">
                                            File Upload
                                        </div>
                                    </div>
                                    <div class="container p-4">
                                        <div class="row">
                                            <div class="col-md-10 center p-1">
                                                <input class="form-control" type="file" (change)="getFiles($event, documentTypeWithDocuments)">
                                            </div>
                                            <div class="card-footer">
                                                <div class="btn-actions-pane-right p-2">
                                                    <div class="mb-2 me-2 btn btn-danger" (click)="showUploadSection1 = false; documentTypeWithDocuments.documents.fileOpen = false; viewConsignmentDocumentsById(unqid)">
                                                        Cancel
                                                    </div>
                                                    <div class="mb-2 me-2 btn btn-primary" (click)="updateUploadDocument(documentTypeWithDocuments)">
                                                        Save
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-1" *ngIf="!documentTypeWithDocuments.documents.fileOpen">
                                <div class="font-icon-wrapper" (click)="showUploadSection1 = true; documentTypeWithDocuments.documents.fileOpen = !documentTypeWithDocuments.documents.fileOpen" matTooltip="Upload">
                                    <i class="fa fa-pencil" aria-hidden="true" style="font-size: 50px;"></i>
                                </div>
                            </div> -->

                            <!-- <ng-container *ngIf="documentTypeWithDocuments.documents.length != 0">
                                <div class="col-md-12" *ngIf="showUploadSection && documentTypeWithDocuments.documents.fileOpen">
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="card-header-title font-size-lg text-capitalize fw-Bold">
                                                File Upload
                                            </div>
                                        </div>
                                        <div class="container p-4">
                                            <div class="row">
                                                <div class="col-md-10 center p-1">
                                                    <input class="form-control" type="file" (change)="getFiles($event,documentTypeWithDocuments)">
                                                </div>
                                                <div class="card-footer">
                                                    <div class="btn-actions-pane-right p-2">
                                                        <div class="mb-2 me-2 btn btn-danger" (click)="showUploadSection = false;documentTypeWithDocuments.documents.fileOpen = false">
                                                            Cancel
                                                        </div>
                                                        <div class="mb-2 me-2 btn btn-primary" (click)="updateUploadDocument(documentTypeWithDocuments)">
                                                            Save
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4" *ngIf="!documentTypeWithDocuments.documents.fileOpen">
                                    <div class="font-icon-wrapper" (click)="showUploadSection = true; documentTypeWithDocuments.documents.fileOpen = !documentTypeWithDocuments.documents.fileOpen" matTooltip="Upload">
                                        <i class="fa fa-fw" aria-hidden="true" style="font-size: 50px;"></i>
                                    </div>
                                </div>
                            </ng-container> -->
                        </ng-container>

<!--                            &lt;!&ndash; Document Upload Section &ndash;&gt;-->
                           <ng-container *ngIf="documentTypeWithDocuments.documents.length === 0">
                               <div class="col-md-12" *ngIf="showUploadSection && documentTypeWithDocuments.documents.fileOpen">
                                   <div class="card">
                                       <div class="card-header">
                                           <div class="card-header-title font-size-lg text-capitalize fw-Bold">
                                               File Upload
                                           </div>
                                       </div>
                                       <div class="container p-4">
                                           <div class="row">
                                               <div class="col-md-10 center p-1">
                                                   <input class="form-control" type="file" (change)="getFiles($event,documentTypeWithDocuments)">
                                               </div>
                                               <div class="card-footer">
                                                   <div class="btn-actions-pane-right p-2">
                                                       <div class="mb-2 me-2 btn btn-danger" (click)="showUploadSection = false;documentTypeWithDocuments.documents.fileOpen = false; viewConsignmentDocumentsById(unqid)">
                                                           Cancel
                                                       </div>
                                                       <div class="mb-2 me-2 btn btn-primary" (click)="uploadDocument(documentTypeWithDocuments)">
                                                           Save
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                               </div>

                               <div class="col-md-4" *ngIf="!documentTypeWithDocuments.documents.fileOpen">
                                   <div class="font-icon-wrapper" (click)="showUploadSection = true; documentTypeWithDocuments.documents.fileOpen = !documentTypeWithDocuments.documents.fileOpen" matTooltip="Upload">
                                       <i class="fa fa-fw" aria-hidden="true" style="font-size: 50px;"></i>
                                   </div>
                               </div>
                           </ng-container>

<!--                            &lt;!&ndash; Display Uploaded Documents &ndash;&gt;-->
                           <!-- <ng-container *ngFor="let document of documentTypeWithDocuments.documents">
                               <div class="col-md-4">
                                   <div class="font-icon-wrapper">
                                       <a (click)="downloadDocument(document.id)" matTooltip="Download">
                                           <i class="fa fa-fw text-success" matTooltip="Download" aria-hidden="true" style="font-size: 50px; border: none;"></i>
                                       </a>
                                   </div>
                               </div>
                           </ng-container> -->
                       </ng-container>
                   </div>
                </div>
                <!-- <div class="card col-md-4">
            <div class="card-header">

              <div class="card-header-title font-size-lg text-capitalize fw-Bold">
                Consignment Note
              </div>
            </div>
            <div class="row">
              <div class="fw-bold h4 margin-h-center pt-4 text-center ">
                Download Consignment Note
              </div>
              <div class="col-md-4 m-auto" *ngFor="let con of consignmentlists.data">
                <button class="font-icon-wrapper" (click)="getRecord(con.id)"><i class="fa fa-fw text-success "
                    matTooltip="Download" aria-hidden="true" style="font-size: 50px;"></i>
                </button>
              </div>
            </div>
          </div> -->
            </div>
            <div class="row">
                <div class="col-md-7">
                    <div class="row">

                        <div class="card mt-2">
                            <div class="card-header">
                                <div class="card-header-title font-size-lg text-capitalize fw-Bold">
                                    Images
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-6">
                                    <div class="fw-bold h4 margin-h-center pt-4 text-center ">
                                        Image 1
                                        <button class="fa fa-pencil text-primary btn"
                                            *ngIf="consignmentlists?.data[0]?.image1!=null && consignmentlists?.data[0]?.image1 != undefined && displaytoAdmin"
                                            (click)="viewchange('image1',consignment_id)">
                                        </button>
                                        <a [href]="image_1" download="image_1.jpg"
                                            *ngIf="consignmentlists.data[0].image1!=null">
                                            <button class="btn-shadow btn btn-primary mt-2" matTooltip="Download"><i
                                                    class="pe-7s-download"></i></button>
                                        </a>
                                    </div>
                                    <div class="row">
                                        <div class="icon-wrapper-alt" *ngIf="consignmentlists.data[0].image1!=null">
                                            <img [src]='image_1' (click)="open(content5, image_1,'image1')"
                                                style="height: 200px; width: 200px;" alt="Image"
                                                class="rounded mx-auto d-block p-1" alt="Responsive image">
                                        </div>
                                        <div class="col-md-4 m-auto">
                                            <button class="btn-shadow btn btn-primary material-icons blue-color ms-5"
                                                *ngIf="consignmentlists.data[0].image1==null" style="font-size: 23px;"
                                                (click)="viewchange('image1',consignment_id)">
                                                file_upload
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="fw-bold h4 margin-h-center pt-4 text-center">
                                        Image 2
                                        <button class="fa fa-pencil text-primary btn"
                                            *ngIf="consignmentlists.data[0].image2!=null && displaytoAdmin"
                                            (click)="viewchange('image2',consignment_id)">

                                        </button>
                                        <a [href]="image_2" download="image_2.jpg"
                                            *ngIf="consignmentlists.data[0].image2!=null">
                                            <button class="btn-shadow btn btn-primary mt-2" matTooltip="Download"><i
                                                    class="pe-7s-download"></i></button>
                                        </a>
                                    </div>
                                    <div class="row">
                                        <div class="icon-wrapper-alt" *ngIf="consignmentlists.data[0].image2!=null">
                                            <img [src]='image_2' (click)="open(content5, image_2,'image2')"
                                                style="height: 200px; width: 200px;" alt="Image"
                                                class="rounded mx-auto d-block p-1" alt="Responsive image">
                                            <!-- <a [href]="image_2" download="image_2.jpg">
                            <button class="btn btn-primary mt-2">Download</button>
                          </a> -->
                                        </div>
                                        <div class="col-md-4 m-auto">
                                            <button class="btn-shadow btn btn-primary material-icons blue-color ms-5"
                                                *ngIf="consignmentlists.data[0].image2==null" style="font-size: 23px;"
                                                (click)="viewchange('image2',consignment_id)">
                                                file_upload
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-5">
                                <div class="col-md-6">
                                    <div class="fw-bold h4 margin-h-center pt-4 text-center">
                                        Image 3
                                        <button class="fa fa-pencil text-primary btn"
                                            *ngIf="consignmentlists.data[0].image3!=null && displaytoAdmin"
                                            (click)="viewchange('image3',consignment_id)">

                                        </button>
                                        <a [href]="image_3" download="image_3.jpg"
                                            *ngIf="consignmentlists.data[0].image3!=null">
                                            <button class="btn-shadow btn btn-primary mt-2" matTooltip="Download"><i
                                                    class="pe-7s-download"></i></button>
                                        </a>
                                    </div>
                                    <div class="row">
                                        <div class="icon-wrapper-alt" *ngIf="consignmentlists.data[0].image3!=null">
                                            <img [src]='image_3' (click)="open(content5, image_3,'image3')"
                                                style="height: 200px; width: 200px;" alt="Image"
                                                class="rounded mx-auto d-block  p-1" alt="Responsive image">
                                            <!-- <a [href]="image_3" download="image_3.jpg">
                            <button class="btn btn-primary mt-2">Download</button>
                          </a> -->
                                        </div>
                                        <div class="col-md-4 m-auto">
                                            <button class="btn-shadow btn btn-primary material-icons blue-color ms-5"
                                                *ngIf="consignmentlists.data[0].image3==null" style="font-size: 23px;"
                                                (click)="viewchange('image3',consignment_id)">
                                                file_upload
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="fw-bold h4 margin-h-center pt-4 text-center">
                                        Image 4
                                        <button class="fa fa-pencil text-primary btn"
                                            *ngIf="consignmentlists.data[0].image4!=null && displaytoAdmin"
                                            (click)="viewchange('image4',consignment_id)">
                                        </button>
                                        <a [href]="image_4" download="image_4.jpg"
                                            *ngIf="consignmentlists.data[0].image4!=null">
                                            <button class="btn-shadow btn btn-primary mt-2" matTooltip="Download"><i
                                                    class="pe-7s-download"></i></button>
                                        </a>
                                    </div>
                                    <div class="row">
                                        <div class="icon-wrapper-alt" *ngIf="consignmentlists.data[0].image4!=null">
                                            <img [src]='image_4' (click)="open(content5, image_4,'image4')"
                                                style="height: 200px; width: 200px;" alt="Image"
                                                class="rounded mx-auto d-block p-1" alt="Responsive image">
                                            <!-- <a [href]="image_4" download="image_4.jpg">
                            <button class="btn btn-primary mt-2">Download</button>
                          </a> -->
                                        </div>
                                        <div class="col-md-4 m-auto">
                                            <button class="btn-shadow btn btn-primary material-icons blue-color  ms-5"
                                                *ngIf="consignmentlists.data[0].image4==null" style="font-size: 23px;"
                                                (click)="viewchange('image4',consignment_id)">
                                                file_upload
                                            </button>
                                        </div>
                                    </div>
                                    <ng-template #content5 let-c="close" let-d="dismiss">
                                        <div class="modal-header">
                                            <h4 class="modal-title fw-bold">View Image</h4>
                                            <div *ngIf="image1view" style="margin-left: 350px;margin-top: 5px;"><b>Uploaded At : {{ uploadedTimeStamp1 | date:'dd-MM-yyyy HH:mm:ss' }}</b></div>
                                            <!-- Display upload date and time for image 2  -->
                                            <div *ngIf="image2view" style="margin-left: 350px;margin-top: 5px;"><b>Uploaded At : {{ uploadedTimeStamp2 | date:'dd-MM-yyyy HH:mm:ss' }}</b></div>
                                           <!-- Display upload date and time for image 3  -->
                                            <div *ngIf="image3view" style="margin-left: 350px;margin-top: 5px;"><b>Uploaded At : {{ uploadedTimeStamp3 | date:'dd-MM-yyyy HH:mm:ss' }}</b></div>
                                            <!-- Display upload date and time for image 4  -->
                                            <div  *ngIf="image4view" style="margin-left: 350px;margin-top: 5px;"><b>Uploaded At : {{ uploadedTimeStamp4 | date:'dd-MM-yyyy HH:mm:ss' }}</b></div>

                                            <div  *ngIf="podview" style="margin-left: 350px;margin-top: 5px;"><b>Uploaded At : {{ uploadedTimeStamppod | date:'dd-MM-yyyy HH:mm:ss' }}</b></div>

                                            <button type="button" class="btn-close" aria-label="Close"
                                                (click)="d('Cross click')">
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <img [src]='largeImage' alt="Image"
                                                class="rounded mx-auto d-block w-100 p-5" alt="Responsive image">

                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-danger"
                                                (click)="c('Close click')">Close</button>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">

                        <div class="card mt-2">
                            <div class="card-header">
                                <div class="card-header-title font-size-lg text-capitalize fw-Bold">
                                    Documents
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-6">
                                    <div class="fw-bold h4 margin-h-center pt-4 text-center ">
                                        Data Logger File
                                        <button class="fa fa-pencil text-primary btn"
                                            *ngIf="consignmentlists.data[0].dataLoggerFile!=null && displaytoAdmin"
                                            (click)="viewchange('datalogger',consignment_id)">
                                        </button>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 m-auto">
                                            <button class="font-icon-wrapper"
                                                *ngIf="consignmentlists.data[0].dataLoggerFile==null"
                                                matTooltip="Upload" (click)="viewchange('datalogger',consignment_id)"><i
                                                    class="fa fa-fw " aria-hidden="true" style="font-size: 50px;"></i>
                                            </button>
                                            <button class="font-icon-wrapper"
                                                *ngIf="consignmentlists.data[0].dataLoggerFile!=null"
                                                (click)="getDatalogger(consignmentlists.data[0].id)"><i
                                                    class="fa fa-fw text-success " matTooltip="Download"
                                                    aria-hidden="true" style="font-size: 50px;"></i>
                                            </button>

                                        </div>
                                        <div *ngIf="dataLoggerView" style="margin-left: 30px;">Uploaded At : {{ dataLoggerTimeStamp | date:'dd-MM-yyyy HH:mm:ss' }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="fw-bold h4 margin-h-center pt-4 text-center">
                                        Vehicle Calibration File
                                        <button class="fa fa-pencil text-primary btn"
                                            *ngIf="consignmentlists.data[0].vehicleCalibrationFile!=null && displaytoAdmin"
                                            (click)="viewchange('vehiclecalibration',consignment_id)">

                                        </button>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 m-auto">
                                            <button class="font-icon-wrapper"
                                                *ngIf="consignmentlists.data[0].vehicleCalibrationFile==null"
                                                matTooltip="Upload"
                                                (click)="viewchange('vehiclecalibration',consignment_id)"><i
                                                    class="fa fa-fw" aria-hidden="true" style="font-size: 50px;"></i>
                                            </button>
                                            <button class="font-icon-wrapper"
                                                *ngIf="consignmentlists.data[0].vehicleCalibrationFile!=null"
                                                (click)="getvehiclecalibration(consignmentlists.data[0].id)"><i
                                                    class="fa fa-fw text-success" aria-hidden="true"
                                                    matTooltip="Download" style="font-size: 50px;"></i>
                                            </button>
                                        </div>
                                        <div *ngIf="vehiclecalibrationView" style="margin-left: 30px;">Uploaded At : {{ vehiclecalibrationTimeStamp | date:'dd-MM-yyyy HH:mm:ss' }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="fw-bold h4 margin-h-center pt-4 text-center">
                                        Other Documents File
                                        <button class="fa fa-pencil text-primary btn"
                                            *ngIf="consignmentlists.data[0].otherDocumentsFile!=null && displaytoAdmin"
                                            (click)="viewchange('otherdocuments',consignment_id)">

                                        </button>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 m-auto">
                                            <button class="font-icon-wrapper"
                                                *ngIf="consignmentlists.data[0].otherDocumentsFile ==null"
                                                matTooltip="Upload"
                                                (click)="viewchange('otherdocuments',consignment_id)"><i
                                                    class="fa fa-fw" aria-hidden="true" style="font-size: 50px;"></i>
                                            </button>
                                            <button class="font-icon-wrapper"
                                                *ngIf="consignmentlists.data[0].otherDocumentsFile!=null"
                                                (click)="getotherdocuments(consignmentlists.data[0].id)"><i
                                                    class="fa fa-fw text-success" aria-hidden="true"
                                                    matTooltip="Download" style="font-size: 50px;"></i>
                                            </button>
                                        </div>
                                        <div *ngIf="otherDocumentsTimeView" style="margin-left: 30px;">Uploaded At : {{ otherDocumentsTimeStamp | date:'dd-MM-yyyy HH:mm:ss' }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="fw-bold h4 margin-h-center pt-4 text-center">
                                        Temperature Record File
                                        <button class="fa fa-pencil text-primary btn"
                                            *ngIf="consignmentlists.data[0].temperatureRecordFile!=null && displaytoAdmin"
                                            (click)="viewchange('temp',consignment_id)">

                                        </button>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 m-auto">
                                            <button class="font-icon-wrapper"
                                                *ngIf="consignmentlists.data[0].temperatureRecordFile==null"
                                                matTooltip="Upload" (click)="viewchange('temp',consignment_id)"><i
                                                    class="fa fa-fw" aria-hidden="true" style="font-size: 50px;"></i>
                                            </button>
                                            <button class="font-icon-wrapper"
                                                *ngIf="consignmentlists.data[0].temperatureRecordFile!=null"
                                                (click)="gettemp(consignmentlists.data[0].id)"><i
                                                    class="fa fa-fw text-success" aria-hidden="true"
                                                    matTooltip="Download" style="font-size: 50px;"></i>
                                            </button>
                                        </div>
                                        <div *ngIf="temperatureTimeView" style="margin-left: 30px;">Uploaded At : {{ temperatureTimeStamp | date:'dd-MM-yyyy HH:mm:ss' }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="fw-bold h4 margin-h-center pt-4 text-center">
                                        POD

                                        <button class="fa fa-pencil text-primary btn"
                                            *ngIf="consignmentlists.data[0].pod!=null && displaytoAdmin"
                                            (click)="viewchange('pod',consignment_id)">

                                        </button>
                                        <a [href]="POD" download="POD.jpg" *ngIf="consignmentlists.data[0].pod!=null">
                                            <button class="btn-shadow btn btn-primary mt-2" matTooltip="Download"><i
                                                    class="pe-7s-download"></i></button>
                                        </a>
                                    </div>
                                    <div class="row">
                                        <div class="icon-wrapper-alt" *ngIf="consignmentlists.data[0].pod!=null">
                                            <img [src]='POD' (click)="open(content5, POD,'pod')"
                                                style="height: 200px; width: 200px;" alt="Image"
                                                class="rounded mx-auto d-block p-1" alt="Responsive image">
                                        </div>
                                        <div class="col-md-4 m-auto p-3">
                                            <button class="btn-shadow btn btn-primary material-icons blue-color  ms-5"
                                                *ngIf="consignmentlists.data[0].pod==null" style="font-size: 23px;"
                                                (click)="viewchange('pod',consignment_id)">
                                                file_upload
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="card mt-2">
                        <div class="card-header">
                            <div class="card-header-title font-size-lg text-capitalize fw-bold">
                                Tracking
                            </div>
                            <div class="btn-actions-pane-right p-2"
                                *ngIf="consignmentlists?.data[0]?.trackingId?.status.id != 18 && consignmentlists?.data[0]?.trackingId?.status.id != 36">
                                <div class="mb-2 me-2 btn btn-primary"
                                    (click)="viewchange('statusupload',consignment_id)">Add Status</div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div
                                            class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                                            <div class="vertical-timeline-item vertical-timeline-element"
                                                *ngFor="let tra of consignmentlists.data[0].trackingId.trackingStatusComments;let i=index">
                                                <div>
                                                    <span class="vertical-timeline-element-icon bounce-in">
                                                        <i class="badge badge-dot badge-dot-xl bg-success"
                                                            [ngClass]="tra.updatedAt == null?'bg-light':'bg-primary'"></i>
                                                    </span>
                                                    <div class="vertical-timeline-element-content bounce-in">
                                                        <h4 class="timeline-title">{{tra?.status?.name}}</h4>
                                                        <p>{{tra?.comment}}</p>
                                                        <input style="border: none;" matInput readonly
                                                            [(ngModel)]="trackorigin"
                                                            *ngIf="tra.status.id>0 && tra.status.id<11">
                                                        <input type="datetime" style="border: none;" matInput readonly
                                                            [(ngModel)]="trackdestination"
                                                            *ngIf="tra.status.id>10 && tra.status.id<17">
                                                        <div>{{ tra?.updatedAt | date:'dd-MM-yyyy HH:mm:ss' }} ({{tra?.createdby_name}})
                                                        </div>
                                                        <div class="text-primary btn lnr-pencil"
                                                            (click)="viewchange('Date',tra)" *ngIf="displaytoAdmin">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
          <div class=" card col-md-8 p-3">
            <div class="card-header">
              <div class="card-header-title font-size-lg text-capitalize fw-bold">
                Tracking
              </div>
              <div class="btn-actions-pane-right p-2">
                <div class="mb-2 me-2 btn btn-primary" (click)="viewchange('statusupload',consignment_id)">Add
                  Status</div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="row">
                  <div class="col-md-6">
                    <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                      <div class="vertical-timeline-item vertical-timeline-element"
                        *ngFor="let tra of consignmentlists.data[0].trackingId.trackingStatusComments;let i=index">
                        <div>
                          <span class="vertical-timeline-element-icon bounce-in">
                            <i class="badge badge-dot badge-dot-xl bg-success"
                              [ngClass]="tra.createdAt == null?'bg-light':'bg-primary'"></i>
                          </span>
                          <div class="vertical-timeline-element-content bounce-in">
                            <h4 class="timeline-title">{{tra.status.name}}</h4>
                            <p>{{tra.comment}}</p>
                            <p class="">{{getTime(tra.createdAt)}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        </some-element>
        <some-element *ngSwitchCase="'statusupload'">
          <div *ngIf="loading" class="loader-container">
            <ngx-loading [show]="loading"
                         [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
                         [template]="loadingTemplate"></ngx-loading>
          </div>
            <div class="col-md-8">
                <div class="card p-4">
                    <div class="card-header">
                        <div class="card-header-title font-size-lg text-capitalize fw-bold">
                            Update Tracking Status
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6 row" style="width: 400px;">
                                <mat-form-field appearance="standard">
                                    <mat-label>
                                        Status
                                    </mat-label>
                                    <select matNativeControl id="" [(ngModel)]="consignment_sts_update.status">
                                        <ng-container *ngFor="let cons_st of consignment_status">
                                            <option [value]="cons_st?.id" *ngIf="shouldShowOption(cons_st)">
                                                {{ cons_st?.name }}
                                            </option>
                                        </ng-container>
                                    </select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 row" style="width: 400px;">
                                <mat-form-field appearance="standard">
                                    <mat-label>
                                        Text
                                    </mat-label>
                                    <input placeholder="Text" matInput [(ngModel)]="consignment_sts_update.comment">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md- center-elem pl-0">
                                <button class="btn btn-primary " (click)="update_con_status()">Update Status</button>
                            </div>

                        </div>

                    </div>
                    <div class="card-footer">

                        <div class="btn-actions-pane-right p-2">
                            <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',consignment_id)">Back</div>
                        </div>
                    </div>
                </div>
            </div>
        </some-element>

      <some-element *ngSwitchCase="'Date'">
        <div class="card col-md-6">
          <div class="card-header">
            <div class="card-header-title font-size-lg text-capitalize fw-Bold">
              Update Date & Time
            </div>
          </div>
          <div class="container p-4">
            <div class="row">
              <div class="col-md-6 row">
                <mat-form-field appearance="standard">
                  <mat-label>Update Date & Time</mat-label>
                  <input type="datetime-local" placeholder="ArrivalTime"
                         [(ngModel)]="status_update.updatedAt" aria-label="number" matInput>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="btn-actions-pane-right p-2">
              <div class="mb-2 me-2 btn btn-danger" (click)="viewchange('view',0)">Cancel</div>
              <div class="mb-2 me-2 btn btn-primary" (click)="updateDateTime()">Update</div>
            </div>
          </div>
        </div>
      </some-element>

      <!--        <some-element *ngSwitchCase="'edit'">-->
<!--            <div class="main-card mb-3 card">-->
<!--                <div class="card-header">-->
<!--                    <div class="card-header-title font-size-lg text-capitalize fw-normal">-->
<!--                        Edit Consignments-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="container ">-->
<!--                    <div class="row">-->
<!--                        <div class="col-md-2 fw-bold h4">Consignment Number</div>-->
<!--                        <div class="col-md-2 h4 text-primary">{{consignment_details.consignmentNo}}</div>-->
<!--                    </div>-->
<!--                    <div class="row">-->
<!--                        <div class="col-md-2">-->

<!--                            <mat-form-field style="width: 90%;" appearance="standard">-->
<!--                                <mat-label for="totalWeight">-->
<!--                                    Invoice Reference No.<span class="text-danger">*</span>-->
<!--                                </mat-label>-->
<!--                                <input id="total_no_packages" matInput-->
<!--                                    [(ngModel)]="consignment_details.invoiceReferenceNo">-->
<!--                            </mat-form-field>-->

<!--                        </div>-->
<!--                        <div class="col-md-2">-->

<!--                            <mat-form-field style="width: 90%;" appearance="standard">-->
<!--                                <mat-label>-->
<!--                                    Invoice Date-->
<!--                                </mat-label>-->
<!--                                <input id="total_no_packages" matInput-->
<!--                                    [(ngModel)]="consignment_details.invoiceCreatedDate" [max]="maxDate"-->
<!--                                    [matDatepicker]="picker" disabled>-->
<!--                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
<!--                                <mat-datepicker #picker disabled="false"></mat-datepicker>-->
<!--                            </mat-form-field>-->

<!--                        </div>-->
<!--                        <div class="col-md-2">-->

<!--                            <mat-form-field style="width: 90%;" appearance="standard">-->
<!--                                <mat-label for="totalWeight">-->
<!--                                    Consignment Value-->
<!--                                </mat-label>-->
<!--                                <input id="Consignment Value" matInput-->
<!--                                    [(ngModel)]="consignment_details.totalConsignmentValue">-->
<!--                            </mat-form-field>-->

<!--                        </div>-->
<!--                        <div class="col-md-2">-->
<!--                            <mat-form-field appearance="standard">-->

<!--                                <mat-label for="dimensionuUnits">-->
<!--                                    Weight Divisor<span class="text-danger">*</span>-->
<!--                                </mat-label>-->
<!--                                <mat-select id="dimensionuUnits" [(ngModel)]="consignment_details.weightDivisor"-->
<!--                                    (ngModelChange)="allPackCal()">-->
<!--                                    <mat-option selected hidden disabled-->
<!--                                        [value]="consignmentlists.weightDivisor"></mat-option>-->
<!--                                    <mat-option *ngFor="let w_div of weightDivisorList " [value]="w_div">-->
<!--                                        {{w_div}}-->
<!--                                    </mat-option>-->
<!--                                    &lt;!&ndash; <mat-option value=1>-->
<!--                          USD-->
<!--                        </mat-option> &ndash;&gt;-->
<!--                                </mat-select>-->
<!--                            </mat-form-field>-->
<!--                        </div>-->
<!--                        <div class="col-md-2" *ngIf="consignment_details.tentative_pickup_time !=null">-->

<!--                            <mat-form-field style="width: 90%;" appearance="standard">-->
<!--                                <mat-label>-->
<!--                                    Tentative Pickup Date-->
<!--                                </mat-label>-->
<!--                                <input matInput [(ngModel)]="consignment_details.tentative_pickup_time"-->
<!--                                    [matDatepicker]="pickup" disabled>-->
<!--                                <mat-datepicker-toggle matSuffix [for]="pickup"></mat-datepicker-toggle>-->
<!--                                <mat-datepicker #pickup disabled="false"></mat-datepicker>-->
<!--                            </mat-form-field>-->

<!--                        </div>-->
<!--                        <div class="col-md-2" *ngIf="consignment_details.required_temperature !=null ">-->
<!--                            <mat-form-field appearance="standard">-->
<!--                                <mat-label for="Temprature">-->
<!--                                    Temprature <span class="text-danger">*</span>-->
<!--                                </mat-label>-->
<!--                                <mat-select [(ngModel)]="consignment_details.required_temperature">-->
<!--                                    <mat-option [value]="'-20&deg;C'">-->
<!--                                        -20&deg;C-->
<!--                                    </mat-option>-->
<!--                                    <mat-option [value]="'2-8&deg;C'">-->
<!--                                        2-8&deg;C-->
<!--                                    </mat-option>-->
<!--                                    <mat-option [value]="'15-25&deg;C'">-->
<!--                                        15-25&deg;C-->
<!--                                    </mat-option>-->
<!--                                </mat-select>-->
<!--                            </mat-form-field>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="row">-->
<!--                        <div class="col-md-2">-->

<!--                            <mat-form-field style="width: 90%;" appearance="standard">-->
<!--                                <mat-label>-->
<!--                                    Created Date-->
<!--                                </mat-label>-->
<!--                                <input id="createdAt" matInput [(ngModel)]="consignment_details.createdAt"-->
<!--                                    [max]="maxDate" [matDatepicker]="pickers" disabled>-->
<!--                                <mat-datepicker-toggle matSuffix [for]="pickers"></mat-datepicker-toggle>-->
<!--                                <mat-datepicker #pickers disabled="false"></mat-datepicker>-->
<!--                            </mat-form-field>-->

<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="row">-->
<!--                        <div class="col-md-8 row">-->
<!--                            <mat-form-field style="width: 90%;" appearance="standard">-->
<!--                                <mat-label for="totalWeight">-->
<!--                                    Remarks-->
<!--                                </mat-label>-->
<!--                                <textarea id="Remarks" matInput [(ngModel)]="consignment_details.remarks">-->
<!--                        </textarea>-->
<!--                            </mat-form-field>-->
<!--                        </div>-->
<!--                        <div class="col-md-4" *ngIf="consignment_details.package_type!= null">-->
<!--                            <mat-form-field appearance="standard">-->
<!--                                <mat-label for="package_type">-->
<!--                                    Package Type-->
<!--                                </mat-label>-->
<!--                                <textarea id="package_type" matInput [(ngModel)]="consignment_details.package_type">-->
<!--                        </textarea>-->
<!--                            </mat-form-field>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="row" style="width: 100%;">-->
<!--                        <mat-divider></mat-divider>-->
<!--                        <div class="table-responsive">-->
<!--                            <div class="table-responsive">-->
<!--                                <table class="table p-2 ">-->
<!--                                    <thead>-->
<!--                                        <tr>-->

<!--                                            <th>-->
<!--                                                #-->
<!--                                            </th>-->
<!--                                            <th style="min-width: 150px;">-->
<!--                                                Package Dimensions-->
<!--                                            </th>-->
<!--                                            <th class="mx-w-150">-->
<!--                                                No of Packages-->
<!--                                            </th>-->
<!--                                            <th class="mx-w-150">-->
<!--                                                Weight per Package-->
<!--                                            </th>-->
<!--                                            <th class="mx-w-150">-->
<!--                                                Volume Weight-->
<!--                                            </th>-->
<!--                                            <th class="mx-w-200">-->
<!--                                                Total Packages Weight-->
<!--                                            </th>-->
<!--                                            <th class="mx-w-200">-->
<!--                                                Chargeable Weight-->
<!--                                            </th>-->
<!--                                            <th>-->
<!--                                            </th>-->
<!--                                        </tr>-->
<!--                                    </thead>-->
<!--                                    <tbody>-->
<!--                                        <tr *ngFor="let package of changeable_packages">-->
<!--                                            <td>-->
<!--                                                {{package.id}}-->
<!--                                            </td>-->
<!--                                            <td class="mx-w-150">-->
<!--                                                <div class="row">-->

<!--                                                    &lt;!&ndash; <mat-form-field class="m-0" style="width: 25%;" appearance="standard"> &ndash;&gt;-->
<!--                                                    <div class="col-md-4">-->

<!--                                                        <input type="text" placeholder="L"-->
<!--                                                            oninput="if(!this.value.match('^[0-9]*$'))this.value='';"-->
<!--                                                            aria-label="Number" matInput [(ngModel)]="package.length"-->
<!--                                                            min="1" (input)="eachPackageCharged(package.id)">-->
<!--                                                        <span matSuffix>{{selectedDimention}}</span>-->
<!--                                                    </div>-->
<!--                                                    <div class="col-md-4">-->

<!--                                                        &lt;!&ndash; </mat-form-field> &ndash;&gt;-->
<!--                                                        &lt;!&ndash; <mat-form-field class="p-1" style="width: 25%;" appearance="standard"> &ndash;&gt;-->
<!--                                                        <input type="text" placeholder="B"-->
<!--                                                            oninput="if(!this.value.match('^[0-9]*$'))this.value='';"-->
<!--                                                            aria-label="Number" matInput [(ngModel)]="package.breadth"-->
<!--                                                            min="1" (input)="eachPackageCharged(package.id)">-->
<!--                                                        <span matSuffix>{{selectedDimention}}</span>-->
<!--                                                    </div>-->
<!--                                                    <div class="col-md-4">-->

<!--                                                        &lt;!&ndash; </mat-form-field> &ndash;&gt;-->
<!--                                                        &lt;!&ndash; <mat-form-field class="p-1" style="width: 25%;" appearance="standard"> &ndash;&gt;-->

<!--                                                        <input type="text" placeholder="H"-->
<!--                                                            oninput="if(!this.value.match('^[0-9]*$'))this.value='';"-->
<!--                                                            aria-label="Number" matInput [(ngModel)]="package.height"-->
<!--                                                            min="1" (input)="eachPackageCharged(package.id)">-->
<!--                                                        <span matSuffix>{{selectedDimention}}</span>-->
<!--                                                        &lt;!&ndash; </mat-form-field> &ndash;&gt;-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </td>-->
<!--                                            <td class="mx-w-150">-->
<!--                                                <mat-form-field class="py-0" style="width: 90%;" appearance="standard">-->
<!--                                                    <input type="text" placeholder="No of Packages"-->
<!--                                                        oninput="if(!this.value.match('^[0-9]*$'))this.value='';"-->
<!--                                                        aria-label="Number" matInput [(ngModel)]="package.package_no"-->
<!--                                                        min="1" (input)="eachPackageCharged(package.id)">-->

<!--                                                </mat-form-field>-->
<!--                                            </td>-->
<!--                                            <td class="mx-w-150">-->

<!--                                                <mat-form-field class="py-0" style="width: 90%;" appearance="standard">-->
<!--                                                    <input type="text" placeholder="package weight"-->
<!--                                                        oninput="if(!this.value.match(/^\d*(\.\d{0,2})?$/)) this.value = ''"-->
<!--                                                        aria-label="Number" matInput-->
<!--                                                        [(ngModel)]="package.weight_per_package" min="1"-->
<!--                                                        (input)="eachPackageCharged(package.id)">-->
<!--                                                    <span matSuffix>{{ selectedWeight}}</span>-->
<!--                                                </mat-form-field>-->
<!--                                            </td>-->
<!--                                            <td class="mx-w-150">-->
<!--                                                <mat-form-field class="py-0" style="width: 90%;" appearance="standard">-->
<!--                                                    <input type="text" placeholder="Volume Weight" matInput disabled-->
<!--                                                        [(ngModel)]="package.volume_weight">-->
<!--                                                </mat-form-field>-->
<!--                                            </td>-->
<!--                                            <td class="mx-w-200">-->
<!--                                                <mat-form-field class="py-0" style="width: 90%;" appearance="standard">-->
<!--                                                    <input placeholder="Total Package Weight" matInput disabled-->
<!--                                                        [(ngModel)]="package.total_weight">-->
<!--                                                    <span matSuffix>{{ selectedWeight}}</span>-->

<!--                                                </mat-form-field>-->
<!--                                            </td>-->
<!--                                            <td class="mx-w-200">-->
<!--                                                <mat-form-field class="py-0" style="width: 90%;" appearance="standard">-->
<!--                                                    <input placeholder="Chargeable Weight" matInput disabled-->
<!--                                                        [(ngModel)]="package.chargeable_weight">-->
<!--                                                    <span matSuffix>{{ selectedWeight}}</span>-->

<!--                                                </mat-form-field>-->
<!--                                            </td>-->
<!--                                            &lt;!&ndash; <td class="mx-w-150 align-middle">-->
<!--                            <button class="btn btn-danger" (click)="deletePackages(package.id)">-->
<!--                              <i class="pe-7s-trash">-->
<!--                              </i>-->
<!--                            </button>-->
<!--                          </td> &ndash;&gt;-->

<!--                                        </tr>-->
<!--                                        <tr>-->
<!--                                            <td class="mx-w-150">-->
<!--                                            </td>-->
<!--                                            <td class="mx-w-150">-->
<!--                                            </td>-->
<!--                                            <th class="mx-w-150">-->
<!--                                                <mat-label for="totalWeight">-->
<!--                                                    Total No. Packages-->
<!--                                                </mat-label>-->
<!--                                                <mat-form-field style="width: 90%;" appearance="standard">-->
<!--                                                    <input id="total_no_packages" matInput disabled-->
<!--                                                        value="Total No. Packages"-->
<!--                                                        [(ngModel)]="consignment_details.totalNoOfPackages">-->
<!--                                                </mat-form-field>-->
<!--                                            </th>-->


<!--                                            <td>-->
<!--                                            </td>-->
<!--                                            <td>-->

<!--                                            </td>-->
<!--                                            <th class="mx-w-150">-->
<!--                                                <mat-label for="totalWeight">-->
<!--                                                    Total Weight-->
<!--                                                </mat-label>-->
<!--                                                <mat-form-field style="width: 90%;" appearance="standard">-->
<!--                                                    <input id="totalWeight" matInput disabled value="Google"-->
<!--                                                        [(ngModel)]="consignment_details.totalWeight">-->
<!--                                                    <span matSuffix>{{ selectedWeight}}</span>-->

<!--                                                </mat-form-field>-->
<!--                                            </th>-->
<!--                                            <th class="mx-w-200">-->
<!--                                                <mat-label for="totalWeight">-->
<!--                                                    Total Chargeable Weight-->
<!--                                                </mat-label>-->
<!--                                                <mat-form-field style="width: 90%;" appearance="standard">-->
<!--                                                    <input id="total_charged" matInput disabled value="Google"-->
<!--                                                        [(ngModel)]="consignment_details.totalChargableWeight">-->
<!--                                                    <span matSuffix>{{ selectedWeight}}</span>-->

<!--                                                </mat-form-field>-->
<!--                                            </th>-->
<!--                                            &lt;!&ndash; <td class="mx-w-200 align-middle">-->
<!--                            <button class="btn btn-primary" (click)="addPackage()">-->
<!--                              <i class="fa fa-plus">-->
<!--                              </i>-->
<!--                            </button>-->
<!--                          </td> &ndash;&gt;-->
<!--                                        </tr>-->
<!--                                    </tbody>-->
<!--                                </table>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="row p-2">-->
<!--                            <div class="col-md-2 m-auto">-->
<!--                                <div class="btn btn-primary btn-lg m-2" (click)="saveChanges()">-->
<!--                                    Save-->
<!--                                </div>-->
<!--                                <div class="btn btn-danger btn-lg m-2" (click)="viewchange('table',0)">-->
<!--                                    Cancel-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

<!--                &lt;!&ndash; <div class="d-block p-4 text-center card-footer">-->
<!--                <button class="btn-pill btn-shadow btn-wide fsize-1 btn btn-dark btn-lg">-->
<!--                  <span class="me-2 opacity-7">-->
<!--                    <i class="fa fa-cog fa-spin"></i>-->
<!--                  </span>-->
<!--                  <span class="me-1">View Complete Report</span>-->
<!--                </button>-->
<!--              </div> &ndash;&gt;-->
<!--            </div>-->
<!--        </some-element>-->
<!--        <some-element *ngSwitchCase="'addpack'">-->
<!--            <div class="main-card mb-3 card">-->
<!--                <div class="card-header">-->
<!--                    <div class="card-header-title font-size-lg text-capitalize fw-normal">-->
<!--                        Add Packages-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="container ">-->
<!--                    <div class="row">-->
<!--                        <div class="col-md-2 fw-bold h4">Consignment Number</div>-->
<!--                        <div class="col-md-2 h4 text-primary">{{consignmentNo}}</div>-->
<!--                    </div>-->

<!--                    <div class="row" style="width: 100%;">-->
<!--                        <mat-divider></mat-divider>-->
<!--                        <div class="table-responsive">-->
<!--                            <div class="table-responsive">-->
<!--                                <table class="table p-2 ">-->
<!--                                    <thead>-->
<!--                                        <tr>-->

<!--                                            <th>-->
<!--                                                #-->
<!--                                            </th>-->
<!--                                            <th style="min-width: 150px;">-->
<!--                                                Package Dimensions-->
<!--                                            </th>-->
<!--                                            <th class="mx-w-150">-->
<!--                                                No of Packages-->
<!--                                            </th>-->
<!--                                            <th class="mx-w-150">-->
<!--                                                Weight per Package-->
<!--                                            </th>-->
<!--                                            <th class="mx-w-150">-->
<!--                                                Volume Weight-->
<!--                                            </th>-->
<!--                                            <th class="mx-w-200">-->
<!--                                                Total Packages Weight-->
<!--                                            </th>-->
<!--                                            <th class="mx-w-200">-->
<!--                                                Chargeable Weight-->
<!--                                            </th>-->
<!--                                            <th>-->
<!--                                            </th>-->
<!--                                        </tr>-->
<!--                                    </thead>-->
<!--                                    <tbody>-->
<!--                                        <tr *ngFor="let package of add_packages">-->
<!--                                            <td>-->
<!--                                                {{package.id}}-->
<!--                                            </td>-->
<!--                                            <td class="mx-w-150">-->
<!--                                                <div class="row">-->

<!--                                                    &lt;!&ndash; <mat-form-field class="m-0" style="width: 25%;" appearance="standard"> &ndash;&gt;-->
<!--                                                    <div class="col-md-4">-->

<!--                                                        <input type="text" placeholder="L" aria-label="Number" matInput-->
<!--                                                            [(ngModel)]="package.length" min="1"-->
<!--                                                            (input)="addeachPackageCharged(package.id)">-->
<!--                                                        <span matSuffix>{{selectedDimention}}</span>-->
<!--                                                    </div>-->
<!--                                                    <div class="col-md-4">-->

<!--                                                        &lt;!&ndash; </mat-form-field> &ndash;&gt;-->
<!--                                                        &lt;!&ndash; <mat-form-field class="p-1" style="width: 25%;" appearance="standard"> &ndash;&gt;-->
<!--                                                        <input type="text" placeholder="B" aria-label="Number" matInput-->
<!--                                                            [(ngModel)]="package.breadth" min="1"-->
<!--                                                            (input)="addeachPackageCharged(package.id)">-->
<!--                                                        <span matSuffix>{{selectedDimention}}</span>-->
<!--                                                    </div>-->
<!--                                                    <div class="col-md-4">-->

<!--                                                        &lt;!&ndash; </mat-form-field> &ndash;&gt;-->
<!--                                                        &lt;!&ndash; <mat-form-field class="p-1" style="width: 25%;" appearance="standard"> &ndash;&gt;-->

<!--                                                        <input type="text" placeholder="H" aria-label="Number" matInput-->
<!--                                                            [(ngModel)]="package.height" min="1"-->
<!--                                                            (input)="addeachPackageCharged(package.id)">-->
<!--                                                        <span matSuffix>{{selectedDimention}}</span>-->
<!--                                                        &lt;!&ndash; </mat-form-field> &ndash;&gt;-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </td>-->
<!--                                            <td class="mx-w-150">-->
<!--                                                <mat-form-field class="py-0" style="width: 90%;" appearance="standard">-->
<!--                                                    <input type="text" placeholder="No of Packages" aria-label="Number"-->
<!--                                                        matInput [(ngModel)]="package.package_no" min="1"-->
<!--                                                        (input)="addeachPackageCharged(package.id)">-->

<!--                                                </mat-form-field>-->
<!--                                            </td>-->
<!--                                            <td class="mx-w-150">-->

<!--                                                <mat-form-field class="py-0" style="width: 90%;" appearance="standard">-->
<!--                                                    <input type="text" placeholder="package weight" aria-label="Number"-->
<!--                                                        matInput [(ngModel)]="package.weight_per_package" min="1"-->
<!--                                                        (input)="addeachPackageCharged(package.id)">-->
<!--                                                    <span matSuffix>{{ selectedWeight}}</span>-->
<!--                                                </mat-form-field>-->
<!--                                            </td>-->
<!--                                            <td class="mx-w-150">-->
<!--                                                <mat-form-field class="py-0" style="width: 90%;" appearance="standard">-->
<!--                                                    <input type="text" placeholder="Volume Weight" matInput disabled-->
<!--                                                        [(ngModel)]="package.volume_weight">-->
<!--                                                </mat-form-field>-->
<!--                                            </td>-->
<!--                                            <td class="mx-w-200">-->
<!--                                                <mat-form-field class="py-0" style="width: 90%;" appearance="standard">-->
<!--                                                    <input placeholder="Total Package Weight" matInput disabled-->
<!--                                                        [(ngModel)]="package.total_weight">-->
<!--                                                    <span matSuffix>{{ selectedWeight}}</span>-->

<!--                                                </mat-form-field>-->
<!--                                            </td>-->
<!--                                            <td class="mx-w-200">-->
<!--                                                <mat-form-field class="py-0" style="width: 90%;" appearance="standard">-->
<!--                                                    <input placeholder="Chargeable Weight" matInput disabled-->
<!--                                                        [(ngModel)]="package.chargeable_weight">-->
<!--                                                    <span matSuffix>{{ selectedWeight}}</span>-->

<!--                                                </mat-form-field>-->
<!--                                            </td>-->
<!--                                            <td class="mx-w-150 align-middle">-->
<!--                                                <button class="btn btn-danger" (click)="deletePackage(package.id)">-->
<!--                                                    <i class="pe-7s-trash">-->
<!--                                                    </i>-->
<!--                                                </button>-->
<!--                                            </td>-->

<!--                                        </tr>-->
<!--                                        <tr>-->
<!--                                            <td class="mx-w-150">-->
<!--                                            </td>-->
<!--                                            <td class="mx-w-150">-->
<!--                                            </td>-->
<!--                                            <th class="mx-w-150">-->
<!--                                                <mat-label for="totalWeight">-->
<!--                                                    Total No. Packages-->
<!--                                                </mat-label>-->
<!--                                                <mat-form-field style="width: 90%;" appearance="standard">-->
<!--                                                    <input id="total_no_packages" matInput disabled-->
<!--                                                        value="Total No. Packages" [(ngModel)]="totalNoOfPackages">-->
<!--                                                </mat-form-field>-->
<!--                                            </th>-->


<!--                                            <td>-->
<!--                                            </td>-->
<!--                                            <td>-->

<!--                                            </td>-->
<!--                                            <th class="mx-w-150">-->
<!--                                                <mat-label for="totalWeight">-->
<!--                                                    Total Weight-->
<!--                                                </mat-label>-->
<!--                                                <mat-form-field style="width: 90%;" appearance="standard">-->
<!--                                                    <input id="totalWeight" matInput disabled value="Google"-->
<!--                                                        [(ngModel)]="totalWeight">-->
<!--                                                    <span matSuffix>{{ selectedWeight}}</span>-->

<!--                                                </mat-form-field>-->
<!--                                            </th>-->
<!--                                            <th class="mx-w-200">-->
<!--                                                <mat-label for="totalWeight">-->
<!--                                                    Total Chargeable Weight-->
<!--                                                </mat-label>-->
<!--                                                <mat-form-field style="width: 90%;" appearance="standard">-->
<!--                                                    <input id="total_charged" matInput disabled value="Google"-->
<!--                                                        [(ngModel)]="totalChargableWeight">-->
<!--                                                    <span matSuffix>{{ selectedWeight}}</span>-->

<!--                                                </mat-form-field>-->
<!--                                            </th>-->
<!--                                            <td class="mx-w-200 align-middle">-->
<!--                                                <button class="btn btn-primary" (click)="addPackage()">-->
<!--                                                    <i class="fa fa-plus">-->
<!--                                                    </i>-->
<!--                                                </button>-->
<!--                                            </td>-->
<!--                                        </tr>-->
<!--                                    </tbody>-->
<!--                                </table>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="row p-2">-->
<!--                            <div class="col-md-2 m-auto">-->
<!--                                <div class="btn btn-primary btn-lg m-2" (click)="addnewPackages()">-->
<!--                                    Save-->
<!--                                </div>-->
<!--                                <div class="btn btn-danger btn-lg m-2" (click)="viewchange('view',0)">-->
<!--                                    Cancel-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </some-element>-->
    </container-element>
</div>
