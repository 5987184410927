<div *ngIf="loading" class="loader-container">
  <ngx-loading [show]="loading"
               [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
               [template]="loadingTemplate"></ngx-loading>
</div>
<mat-card style="padding: 20px;">
  <div class="card-header-title font-size-lg text-capitalize fw-Bold">
    <div style="display: flex">
      <div>
        <mat-icon (click)="goToContractPage()" style="cursor: pointer">arrow_back</mat-icon>
      </div>
      <div><b style="color: blue;">VIEW CITY PRICE</b></div>
    </div>
  </div>

  <br>

  <div style="margin-left: 26px">
    <b>Customer: </b>&nbsp; {{customerName}}
  </div>


  <mat-card-header>
    <div class="row">
      <div class="col-md-5 row">
        <mat-form-field appearance="standard">
          <mat-label>Origin</mat-label>
          <input matInput placeholder="Search Origin" [(ngModel)]="origin"
                 [matAutocomplete]="autoCity1">
          <mat-autocomplete #autoCity1="matAutocomplete" [displayWith]="displayCityFn">
            <mat-option *ngFor="let city of cities |async | filter: origin"
                        [value]="city" (click)="getAllCityPrices()">{{ city.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="col-md-5 row">
        <mat-form-field appearance="standard">
          <mat-label>Destination</mat-label>
          <input matInput placeholder="Search Destination" [(ngModel)]="destination"
                 [matAutocomplete]="autoCity2">
          <mat-autocomplete #autoCity2="matAutocomplete" [displayWith]="displayCityFn">
            <mat-option *ngFor="let city of cities |async | filter: destination"
                        [value]="city" (click)="getAllCityPrices()">{{ city.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col-md-1">
        <button class="btn btn-secondary mt-3" (click)="reset()">Reset</button>
      </div>
    </div>
  </mat-card-header>

  <ng-container *ngIf="cityPricesTableDataSource?.data?.length > 0; else noRecordsMessage">

    <mat-card-content>
      <mat-table mat-table [dataSource]="cityPricesTableDataSource" class="mat-elevation-z8" style="overflow-x: scroll;">
        <ng-container matColumnDef="Origin">
          <th style="width: 13%" mat-header-cell *matHeaderCellDef> Origin</th>
          <td mat-cell *matCellDef="let element" class="word-wrap2"> {{ element.origin?.name }}</td>
        </ng-container>

        <ng-container matColumnDef="Destination">
          <th style="width: 11%" mat-header-cell *matHeaderCellDef> Destination</th>
          <td mat-cell *matCellDef="let element" class="word-wrap2"> {{ element.destination?.name }}</td>
        </ng-container>

        <ng-container matColumnDef="Product">
          <th style="width: 8%" mat-header-cell *matHeaderCellDef> Product</th>
          <td mat-cell *matCellDef="let element" class="word-wrap"> {{ element.price?.productType?.name }}</td>
        </ng-container>

        <ng-container matColumnDef="Mode">
          <th style="width: 8%" mat-header-cell *matHeaderCellDef> Mode</th>
          <td mat-cell *matCellDef="let element" class="word-wrap"> {{ element.price?.mode }}</td>
        </ng-container>

        <ng-container matColumnDef="CN Type">
          <th style="width: 8%" mat-header-cell *matHeaderCellDef> CN Type</th>
          <td mat-cell *matCellDef="let element"> {{ element.price?.consignmentType?.name }}</td>
        </ng-container>

        <ng-container matColumnDef="CN Subtype">
          <th style="width: 10%" mat-header-cell *matHeaderCellDef> CN Subtype</th>
          <td mat-cell *matCellDef="let element"> {{ element.price?.consignmentSubtype?.name }}</td>
        </ng-container>

        <ng-container matColumnDef="Per KG">
          <th style="width:3%" mat-header-cell *matHeaderCellDef class="word-wrap"> Per KG</th>
          <td mat-cell *matCellDef="let element"> {{ element.price?.perKg }}</td>
        </ng-container>

        <ng-container matColumnDef="AWB Fee">
          <th style="width: 4%" mat-header-cell *matHeaderCellDef class="word-wrap"> AWB Fee</th>
          <td mat-cell *matCellDef="let element"> {{ element.price?.awbFee }}</td>
        </ng-container>

        <ng-container matColumnDef="Min Chargeable Weight">
          <th style="width: 4%" mat-header-cell *matHeaderCellDef class="word-wrap"> Min Char Wt</th>
          <td mat-cell *matCellDef="let element"> {{ element.price?.minChargeableWt }}</td>
        </ng-container>

        <ng-container matColumnDef="Min Freight">
          <th style="width: 6%" mat-header-cell *matHeaderCellDef class="word-wrap"> Min Freight</th>
          <td mat-cell *matCellDef="let element"> {{ element.price?.minFreight }}</td>
        </ng-container>

        <ng-container matColumnDef="FOV">
          <th style="width: 4%" mat-header-cell *matHeaderCellDef> FOV (%)</th>
          <td mat-cell *matCellDef="let element"> {{ element.price?.fov }}</td>
        </ng-container>

        <ng-container matColumnDef="FOV Min">
          <th style="width: 4%" mat-header-cell *matHeaderCellDef class="word-wrap"> FOV Min</th>
          <td mat-cell *matCellDef="let element"> {{ element.price?.fovMin }}</td>
        </ng-container>

        <ng-container matColumnDef="Fuel Surcharge">
          <th style="width: 3%" mat-header-cell *matHeaderCellDef> Fuel Sur (%)</th>
          <td mat-cell *matCellDef="let element"> {{ element.price?.fuelSurcharge }}</td>
        </ng-container>

        <ng-container matColumnDef="OOA Discount">
          <th style="width: 5%" mat-header-cell *matHeaderCellDef> OOA Disc</th>
          <td mat-cell *matCellDef="let element"> {{ element.price?.ooaDiscount }}</td>
        </ng-container>

        <ng-container matColumnDef="Handling Charges">
          <th style="width: 5%" mat-header-cell *matHeaderCellDef> Handl Charg</th>
          <td mat-cell *matCellDef="let element"> {{ element.price?.handlingCharges }}</td>
        </ng-container>

        <ng-container matColumnDef="Dim Weight Calculator">
          <th style="width: 6%" mat-header-cell *matHeaderCellDef> Dim Wt</th>
          <td mat-cell *matCellDef="let element"> {{ element.price?.dimWtCalculator }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </mat-table>
      <mat-paginator [length]="paginatorDetails?.totalElements" [showFirstLastButtons]="true" [pageSize]="paginatorDetails?.pageSize" [pageIndex]="paginatorDetails?.pageNo"
                     [pageSizeOptions]="[5, 10, 20, 30]" (page)="pageEvent = $event; onPaginateChange($event)">
      </mat-paginator>
    </mat-card-content>

    <mat-card-actions align="end">
      <button mat-button class="btn btn-danger" (click)="goToContractPage()">Back</button>
    </mat-card-actions>


  </ng-container>

  <ng-template #noRecordsMessage>
    <h1 class="text-center ">No Records Available..!</h1>
  </ng-template>
</mat-card>
