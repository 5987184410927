import {Component, Inject, OnInit} from '@angular/core';
import {API_URL} from '../../../../Global_API_URL';
import {AuthService} from '../../auth.service';
import {FormControl, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {map, startWith} from 'rxjs/operators';
import {Airport, CustomerList, individuallist} from '../../Layout/golbalInterfaces';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {getCitiesById, getCountries, getPincodesById, getStatesById} from '../../Layout/getCities.js';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-create-new-customer',
  templateUrl: './create-new-customer.component.html',
  styleUrls: ['./create-new-customer.component.sass'],
})
export class CreateNewCustomerComponent implements OnInit {
  TOKEN
  public Representativeload = false;

  constructor(private authService: AuthService, private http: HttpClient, private route: Router, public dialogRef: MatDialogRef<CreateNewCustomerComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.TOKEN = this.authService.getAuthToken();
    this.val = this.data.val;
    this.val == 1 ? this.indiviudal_user.customer_type = 2 : this.indiviudal_user.customer_type = 1;
  }

  ngOnInit(): void {
    this.getOfficeLocations();
  }

  consignmentTax = 0;
  validShipper = true;
  val = 1;
  total_cost = 0;
  indiviudal_user = {
    'id': null,
    'first_name': '',
    'middle_name': '',
    'last_name': '',
    'avatar_url': '',
    'email': '',
    'phone': '',
    'whatsapp': '',
    'designation': null,
    'address': '',
    'city': {
      id: null,
    },
    'zip_code': '',
    'office_location': null,
    'is_super_user': false,
    'user_name': '',
    'password_hash': null,
    'last_logged_in_at': null,
    'last_logged_in_ip': null,
    'is_active': true,
    'created_at': null,
    'updated_at': null,
    'created_by': null,
    'updated_by': null,
    'customer_type': 2, // here 2 is for buisness user
    'org_customer_id': null,
    'customer_id': null,
    'aadhaar_number': null,
    'driving_license': null,
    'gst_number': null,
    'pan_number': null,
    'user_type': 5,
    'employment_no': null,
  }
  customer_shipper = {
    id: null,
    firstName: '',
    address1: '',
    city1: '',
    creditPeriod: '',
    credit_value: null,
    email1: '',
    zipCode: '',
    isCreditExtended: null,
    isKycApproved: null,
    kycApprovedAt: null,
    office_location: null,
    lastName: '',
    phone1: '',
    customerTypeId: 2,
    kycApprovedBy: null,
    isBusinessAccount: true,
    pan: '',
    gst: '',
    Id_type: null,
    Id_value: '',
    otp_varified: null,
    isSezCustomer: false,
  }

  change_business() {
    if (this.val == 1) {
      this.resetIndividualUser();
      this.indiviudal_user.customer_type = 2;
      this.user_sel_country = null;
      this.user_sel_state = null;
    } else {
      this.resetIndividualUser();
      this.indiviudal_user.customer_type = 1;
    }
  }

  resetIndividualUser() {
    this.indiviudal_user = {
      'id': null,
      'first_name': '',
      'middle_name': '',
      'last_name': '',
      'avatar_url': '',
      'email': '',
      'phone': '',
      'whatsapp': '',
      'designation': null,
      'address': '',
      'city': {id: null},
      'zip_code': '',
      'office_location': null,
      'is_super_user': false,
      'user_name': '',
      'password_hash': null,
      'last_logged_in_at': null,
      'last_logged_in_ip': null,
      'is_active': true,
      'created_at': null,
      'updated_at': null,
      'created_by': null,
      'updated_by': null,
      'customer_type': 2, // here 2 is for buisness user
      'org_customer_id': null,
      'customer_id': null,
      'aadhaar_number': null,
      'driving_license': null,
      'gst_number': null,
      'pan_number': null,
      'user_type': 5,
      'employment_no': null,
    };
  }


  CustomerExist = false;

  async nameExist() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/customer/name/' + this.customer_shipper.firstName,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then((result) => {
          if (result.data.length == 0) {
            this.CustomerExist = false;
          } else {
            this.CustomerExist = true;
          }
        })
        .catch((error) => {
          console.error('error', error);
        });
  }

  customer_created = false;
  customer_with_id
  consignment_details = {
    id: null,
    consignmentType: null,
    // consignmentType: ConsignmentTypesModel,
    weightUnit: 1,
    currency: '1',
    weightDivisor: 1,
    consignmentNo: null,
    airlinesId: null,
    isStopover: null,
    totalTransitTime: null,
    senderIndividual: null,
    isIndividualCustomer: null,
    paymentCompletedAt: null,
    isXrayScreening: null,
    isXrayCertification: null,
    isDryIce: null,
    totalWeight: null,
    totalChargableWeight: null,
    planSelected: null,
    senderId: null,
    receiverId: null,
    isDocumentUploaded: null,
    isInsured: false,
    totalShippingCost: null,
    paymentMode: null,
    isPaymentCompleted: null,
    isActive: null,
    createdAt: null,
    updatedAt: null,
    createdBy: null,
    updatedBy: null,
    totalNoOfPackages: null,
    destinationLocation: null,
    consignmentSubType: null,
    originLocation: null,
    dimensionuUnit: 1,
    insuranceCoverage: 0,
    totalConsignmentValue: null,
    shippingCost: 0,
    invoiceCreatedDate: '',
    invoiceReferenceNo: null,
    productType: 1,
    remarks: null,
    // tentative_pickup_time: new Date()
  }

  async createCustomer() {
    const TOKEN = this.authService.getAuthToken();
    this.indiviudal_user.user_name = this.indiviudal_user.email;
    this.indiviudal_user.user_type = 5;

    if ((this.customer_shipper.firstName == null || this.customer_shipper.firstName == '') && this.indiviudal_user.customer_type == 2) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Company Name For Corporate Customer',
        timer: 5000,

      });
      return;
    } else if ((this.customer_shipper.email1 == null || this.customer_shipper.email1 == '') && this.indiviudal_user.customer_type == 2) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Company Email For Corporate Customer',
        timer: 5000,

      });
      return;
    } else if (!(this.emailregex.test(this.customer_shipper.email1)) && this.indiviudal_user.customer_type == 2) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter Valid Email For Corporate Customer',
        timer: 5000,

      });
      return;
    } else if ((this.customer_shipper.phone1 == null || this.customer_shipper.phone1 == '') && this.indiviudal_user.customer_type == 2) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Company Phone No. For Corporate Customer',
        timer: 5000,

      });
      return;
    } else if ((this.customer_shipper.phone1).length < 10 && this.indiviudal_user.customer_type == 2) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Valid Company Phone No. For Corporate Customer',
        timer: 5000,

      });
      return;
    } else if (isNaN(parseInt(this.customer_shipper.phone1)) == true && this.indiviudal_user.customer_type == 2) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Valid Company Phone No. For Corporate Customer',
        timer: 5000,

      });
      return;
    } else if ((this.customer_shipper.address1 == null || this.customer_shipper.address1 == '') && this.indiviudal_user.customer_type == 2) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Company Address For Corporate Customer',
        timer: 5000,

      });
      return;
    } else if ((this.business_sel_country == null || this.business_sel_country == '') && this.indiviudal_user.customer_type == 2) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The Country For Corporate Customer',
        timer: 5000,

      });
      return;
    } else if ((this.business_sel_state == null || this.business_sel_state == '') && this.indiviudal_user.customer_type == 2) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The State For Corporate Customer',
        timer: 5000,

      });
      return;
    } else if ((this.customer_shipper.city1 == null || this.customer_shipper.city1 == '') && this.indiviudal_user.customer_type == 2) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The Company City For Corporate Customer',
        timer: 5000,

      });
      return;
    } else if ((this.customer_shipper.zipCode == null || this.customer_shipper.zipCode == '') && this.indiviudal_user.customer_type == 2) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select The Company Zip Code For Corporate Customer',
        timer: 5000,

      });
      return;
    } else if ((this.customer_shipper.office_location == null || this.customer_shipper.office_location == '') && this.indiviudal_user.customer_type == 2) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Select the Billing Branch For Corporate Customer',
        timer: 5000,

      });
    } else if ((this.customer_shipper.gst == null || this.customer_shipper.gst == '') && this.indiviudal_user.customer_type == 2) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Company GST No. For Corporate Customer',
        timer: 5000,

      });
      return;
    } else if (!(this.gstregex.test(this.customer_shipper.gst)) && this.indiviudal_user.customer_type == 2) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter Valid GST No. For Corporate Customer',
        timer: 5000,

      });
      return;
    } else if ((this.customer_shipper.gst.slice(0, this.gstPrefixCodeLength).toString() != this.gstPrefixCode) && this.indiviudal_user.customer_type == 2) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter Valid Gst Prefix Code For Corporate Customer',
        timer: 5000,
      });
      return;
    }
    // else if (this.gstExist) {
    //   Swal.fire({
    //     icon: 'warning',
    //     title: 'WARNING',
    //     text: "GST No. Already Exists For Corporate Customer",
    //     timer: 5000
    //
    //   });
    //   return;
    // }
    else if ((this.customer_shipper.pan == null || this.customer_shipper.pan == '') && this.indiviudal_user.customer_type == 2) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter The Company PAN No. For Corporate Customer',
        timer: 5000,

      });
      return;
    } else if (!(this.panregex.test(this.customer_shipper.pan)) && this.indiviudal_user.customer_type == 2) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'Please Enter Valid PAN No. For Corporate Customer',
        timer: 5000,

      });
      return;
    }
    // else if (this.panExist) {
    //   Swal.fire({
    //     icon: 'warning',
    //     title: 'WARNING',
    //     text: "PAN No. Already Exists For Corporate Customer",
    //     timer: 5000
    //
    //   });
    // }
    else if (this.indiviudal_user.first_name == null || this.indiviudal_user.first_name == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: this.indiviudal_user.customer_type == 2 ? 'Please Enter The First Name For Corporate Representative' : 'Please Enter The First Name For Retail Customer',
        timer: 5000,

      });
      return;
    } else if (this.indiviudal_user.last_name == null || this.indiviudal_user.last_name == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: this.indiviudal_user.customer_type == 2 ? 'Please Enter The Last Name For Corporate Representative' : 'Please Enter The Last Name For Retail Customer',
        timer: 5000,

      });
      return;
    } else if (this.indiviudal_user.email == null || this.indiviudal_user.email == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: this.indiviudal_user.customer_type == 2 ? 'Please Enter The Email For Corporate Representative' : 'Please Enter The Email For Retail Customer',
        timer: 5000,

      });
      return;
    } else if (!(this.emailregex.test(this.indiviudal_user.email))) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',

        text: this.indiviudal_user.customer_type == 2 ? 'Please Enter The Valid Email For Corporate Representative' : 'Please Enter The Valid Email For Retail Customer',
        timer: 5000,

      });
      return;
    } else if (this.emailExist) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: this.indiviudal_user.customer_type == 2 ? 'Email Already Exists For Corporate Representative' : 'Email Already Exists For Retail Customer',
        timer: 5000,
      });
      return;
    } else if (this.indiviudal_user.phone == null || this.indiviudal_user.phone == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: this.indiviudal_user.customer_type == 2 ? 'Please Enter The Phone No. For Corporate Representative' : 'Please Enter The Phone No. For Retail Customer',
        timer: 5000,

      });
      return;
    } else if (isNaN(parseInt(this.indiviudal_user.phone)) == true || this.indiviudal_user.phone.length < 10) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: this.indiviudal_user.customer_type == 2 ? 'Please Enter The Valid Phone No. For Corporate Representative' : 'Please Enter The Valid Phone No. For Retail Customer',
        timer: 5000,

      });
      return;
    } else if (this.indiviudal_user?.whatsapp?.length > 0 && this.indiviudal_user?.whatsapp?.length < 10) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: this.indiviudal_user.customer_type == 2 ? 'Please Enter The Valid Whatsapp No. For Corporate Representative' : 'Please Enter The Valid Whatsapp No. For Retail Customer',
        timer: 5000,

      });
      return;
    } else if (this.indiviudal_user.address == null || this.indiviudal_user.address == '') {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: this.indiviudal_user.customer_type == 2 ? 'Please Enter The Address For Corporate Representative' : 'Please Enter The Address For Retail Customer',
        timer: 5000,

      });
      return;
    } else if (this.user_sel_country == '' || this.user_sel_country == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: this.indiviudal_user.customer_type == 2 ? 'Please Select The Country For Corporate Representative' : 'Please Select The Country For Retail Customer',
        timer: 5000,

      });
      return;
    } else if (this.user_sel_state == '' || this.user_sel_state == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: this.indiviudal_user.customer_type == 2 ? 'Please Select The State For Corporate Representative' : 'Please Select The State For Retail Customer',
        timer: 5000,

      });
      return;
    } else if (this.indiviudal_user.city.id == '' || this.indiviudal_user.city.id == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: this.indiviudal_user.customer_type == 2 ? 'Please Select The City For Corporate Representative' : 'Please Select The City For Retail Customer',
        timer: 5000,

      });
      return;
    } else if (this.indiviudal_user.zip_code == '' || this.indiviudal_user.zip_code == null) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: this.indiviudal_user.customer_type == 2 ? 'Please Enter The Zip Code For Corporate Representative' : 'Please Enter The Zip Code For Retail Customer',
        timer: 5000,

      });
      return;
    } else if (this.indiviudal_user?.aadhaar_number?.length > 0 && this.indiviudal_user?.aadhaar_number?.length < 12) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: this.indiviudal_user.customer_type == 2 ? 'Aadhaar No. must be 12 digits For Corporate Representative' : 'Aadhaar No. must be 12 digits For Retail Customer',
        timer: 5000,

      });
      return;
    } else if (this.indiviudal_user?.pan_number?.length > 0 && !(this.panregex.test(this.indiviudal_user.pan_number))) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: this.indiviudal_user.customer_type == 2 ? 'Invalid PAN No. For Corporate Representative' : 'Invalid PAN No. For Retail Customer',
        timer: 5000,

      });
      return;
    } else if (this.panExist) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'PAN No. Already Exists For Retail Customer',
        timer: 5000,
      });
      return;
    } else if (this.gstExist) {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'GST No. Already Exists For Retail Customer',
        timer: 5000,
      });
      return;
    } else {
      this.Representativeload = true;
      if (this.indiviudal_user.customer_type == 2) {
        await fetch(API_URL + '/customer',
            {
              method: 'POST',
              body: JSON.stringify(this.customer_shipper),
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN,
              },
            })
            .then((response) => response.json())
            .then(async (result) => {
              this.customer_with_id = result?.data[0];
              this.indiviudal_user.org_customer_id = result?.data[0]?.id;
              this.consignment_details.senderId = result?.data[0]?.id;
              await fetch(API_URL + '/register',
                  {
                    method: 'POST',
                    body: JSON.stringify(this.indiviudal_user),
                    headers: {
                      'Content-Type': 'application/json',
                      'Accept': 'application/json',
                      'Authorization': 'Bearer ' + TOKEN,
                    },
                  })
                  .then((response) => response.json())
                  .then((res) => {
                    Swal.fire({
                      icon: 'success',
                      title: 'Sender created',

                      timer: 5000,
                    });
                    this.Representativeload = false;
                    this.indiviudal_user = res.data[0];
                    this.consignment_details.senderIndividual = this.indiviudal_user.id;
                    this.customer_created = true;
                    this.onNoClick();
                  });
            })
            .catch((error) => {
              console.error('Error', error);
              this.Representativeload = false;
              alert(error);
            });
      } else {
        if (this.indiviudal_user.gst_number) {
          if (this.indiviudal_user.gst_number.slice(0, this.gstPrefixCodeLength).toString() != this.gstPrefixCode) {
            Swal.fire({
              icon: 'warning',
              title: 'WARNING',
              text: 'Please Enter Valid Gst Prefix Code for Retail Customer',
              timer: 5000,
            });
            return;
          } else if (!(this.gstregex.test(this.indiviudal_user.gst_number))) {
            Swal.fire({
              icon: 'warning',
              title: 'WARNING',
              text: 'Please Enter Valid GST No. for Retail Customer',
              timer: 5000,
            });
            return;
          } else {
            await fetch(API_URL + '/register',
                {
                  method: 'POST',
                  body: JSON.stringify(this.indiviudal_user),
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + TOKEN,
                  },
                })
                .then((response) => response.json())
                .then((result) => {
                  if (result.status == 500) {
                    alert('Internal Server');
                  } else {
                    Swal.fire({
                      icon: 'success',
                      title: 'Sender Created',
                      timer: 5000,
                    });
                    this.Representativeload = false;
                    this.indiviudal_user = result.data[0];
                    this.consignment_details.senderIndividual = this.indiviudal_user.id;
                    this.customer_created = true;
                    this.onNoClick();
                  }
                });
          }
        } else {
          await fetch(API_URL + '/register',
              {
                method: 'POST',
                body: JSON.stringify(this.indiviudal_user),
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + TOKEN,
                },
              })
              .then((response) => response.json())
              .then((result) => {
                if (result.status == 500) {
                  alert('Internal Server');
                } else {
                  Swal.fire({
                    icon: 'success',
                    title: 'Sender created',

                    timer: 5000,
                  });
                  this.Representativeload = false;
                  this.indiviudal_user = result.data[0];
                  this.consignment_details.senderIndividual = this.indiviudal_user.id;
                  this.customer_created = true;
                  this.onNoClick();
                }
              });
        }
      }
      this.updateoptions();
    }
  }


  gstPrefixCode;
  gstPrefixCodeLength = 2;
  stateDetails: any;

  getStateById(id) {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.TOKEN,
    };
    this.http.get(API_URL + '/states/' + id, {headers}).subscribe(
        (data: Object) => {
          this.stateDetails = data;
          this.gstPrefixCode = this.stateDetails?.data[0]?.gstPrefixCode;
          this.gstPrefixCodeLength = this.gstPrefixCode.length;
        },
    );
  }

  customerList: CustomerList[];

  async getCustomerList() {
    const TOKEN = this.authService.getAuthToken();
    let businessList: CustomerList[];
    await fetch(API_URL + '/totalcustomers',
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then(async (result) => {
          businessList = result;
        })
        .catch((error) => {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'User Not Found',

            timer: 5000,
          });
        });
    return businessList;
  }

  async getIndividualList() {
    const TOKEN = this.authService.getAuthToken();
    let individualList: individuallist[];
    const is_active = 1; // Set the value of is_active parameter

    await fetch(API_URL + '/individual_user?is_active' + is_active,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
          },
        })
        .then((response) => response.json())
        .then(async (result) => {
          individualList = result;
        })
        .catch((error) => {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'User Not Found',

            timer: 5000,
          });
        });
    return individualList;
  }

  IndividualList: individuallist[];

  private _filterss(name: String): individuallist[] {
    const filterValue = name.toLowerCase();
    return this.IndividualList.filter((option) => option.firstName.toLowerCase().includes(filterValue));
  }

  private _filters(name: String): CustomerList[] {
    const filterValue = name.toLowerCase();
    return this.customerList.filter((option) => option.firstName.toLowerCase().includes(filterValue));
  }

  filteredOptions: Observable<CustomerList[] | Airport[]>;
  filteredOption: Observable<individuallist[]>;
  MyControl_corporate = new FormControl();
  MyControl_retail = new FormControl();

  async updateoptions() {
    this.customerList = await this.getCustomerList();
    this.filteredOptions = this.MyControl_corporate.valueChanges.pipe(
        startWith(''),
        map((value) => {
          const name = typeof value === 'string' ? value : value?.name;
          return name ? this._filters(name as string) : this.customerList.slice();
        }),
    );
    this.IndividualList = await this.getIndividualList();
    this.filteredOption = this.MyControl_retail.valueChanges.pipe(
        startWith(''),
        map((value) => {
          const name = typeof value === 'string' ? value : value?.name;
          return name ? this._filterss(name as string) : this.IndividualList.slice();
        }),
    );
  }

  business_cities
  business_states
  business_pincodes
  business_sel_country
  business_sel_state
  countries = getCountries();

  reprsentativeAddress;
  pincodes
  userDetails;
  user_cities
  user_states
  user_pincodes
  user_countries
  user_sel_state
  user_sel_country
  user_sel_pincodes

  async getIndividaulStates() {
    this.user_states = getStatesById(this.user_sel_country);
    this.user_sel_state = '';
    this.indiviudal_user.city.id = '';
    this.indiviudal_user.zip_code = '';
  }

  async getIndividaulCities() {
    this.user_cities = getCitiesById(this.user_sel_state);
    this.user_sel_pincodes = null;
    this.indiviudal_user.city.id = null;
    this.indiviudal_user.zip_code = '';
    // this.getStateById(stateId);
  }
  async getIndividaulCities1(stateId) {
    this.user_cities = getCitiesById(this.user_sel_state);
    this.user_sel_pincodes = null;
    this.indiviudal_user.city.id = null;
    this.indiviudal_user.zip_code = '';
    this.getStateById(stateId);
  }

  async getIndividualPincodes() {
    this.user_pincodes = getPincodesById(this.indiviudal_user.city.id);
    this.indiviudal_user.zip_code = null;
  }

  async getBusinessStates() {
    this.business_states = getStatesById(this.business_sel_country);
    this.business_sel_state = null;
    this.customer_shipper.city1 = null;
    this.customer_shipper.zipCode = '';
  }

  async getBusinessCities(stateId) {
    this.business_cities = getCitiesById(this.business_sel_state);
    this.business_pincodes = null;
    this.customer_shipper.city1 = null;
    this.getStateById(stateId);
  }

  async getBusinessPincodes() {
    this.business_pincodes = getPincodesById(this.customer_shipper.city1);
    this.customer_shipper.zipCode = null;
  }


  OfficesList

  async getOfficeLocations() {
    try {
      const TOKEN = this.authService.getAuthToken();
      const response = await fetch(API_URL + '/office_locations', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
      });
      if (response.ok) {
        const json = await response.json();
        this.OfficesList = json.data;
      } else {
      }
    } catch (error) {
      console.error('Error fetching office locations:', error);
    }
  }

  emailExist = false;

  async emailAppuser() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/appuser/email/' + this.indiviudal_user.email,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => response.json())
        .then((result) => {
          if (result.data[0] == null) {
            this.emailExist = false;
          } else {
            this.emailExist = true;
          }
        // alert("CustomerExist "+this.emailExist);
        })
        .catch((error) => {
          console.error('error', error);
        });
  }

  phoneNoExist = false;

  async phoneNoExistAppuser() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/appuser/phone/' + this.indiviudal_user.phone,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => response.json())
        .then((result) => {
          if (result.data[0] == null) {
            this.phoneNoExist = false;
          } else {
            this.phoneNoExist = true;
          }
        // alert("CustomerExist "+this.phoneNoExist);
        })
        .catch((error) => {
          console.error('error', error);
        });
  }

  gstExist = false;

  async gstNoExist() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/appUsers/gst/' + this.indiviudal_user.gst_number,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => response.json())
        .then((result) => {
          if (result.data[0] == null) {
            this.gstExist = false;
          } else {
            this.gstExist = true;
            Swal.fire({
              icon: 'warning',
              title: 'WARNING',
              text: 'GST No. Already Exists',
              timer: 5000,
            });
          }
        // alert("CustomerExist "+this.emailExist);
        })
        .catch((error) => {
          console.error('Error' + error);
        });
  }

  panExist = false;

  async panNoExist() {
    const TOKEN = this.authService.getAuthToken();
    await fetch(API_URL + '/appUsers/pan/' + this.indiviudal_user.pan_number,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => response.json())
        .then((result) => {
          if (result.data[0] == null) {
            this.panExist = false;
          } else {
            this.panExist = true;
            Swal.fire({
              icon: 'warning',
              title: 'WARNING',
              text: 'PAN No. Already Exists',
              timer: 5000,

            });
          }
        })
        .catch((error) => {
          console.error('Error' + error);
        });
  }

  emailregex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/
  emailFormControl = new FormControl('', [
    Validators.pattern(this.emailregex),
  ]);
  emailFormControl1 = new FormControl('', [
    Validators.pattern(this.emailregex),
  ]);
  emailFormControl2 = new FormControl('', [
    Validators.pattern(this.emailregex),
  ]);
  panregex = /^[a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][0-9][0-9][0-9][0-9][a-zA-Z]$/
  PanFormControl = new FormControl('', [
    Validators.pattern(this.panregex),
  ]);
  PanFormControl2 = new FormControl('', [
    Validators.pattern(this.panregex),
  ]);
  gstregex = /^[0-9][0-9][a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][0-9][0-9][0-9][0-9][a-zA-Z][0-9a-zA-Z][a-zA-Z][0-9a-zA-Z]$/
  gstFormControl = new FormControl('', [
    Validators.pattern(this.gstregex),
  ]);

  validateInput(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^[a-zA-Z\s]*$/.test(inputChar)) {
      event.preventDefault();
    }
  }

  validateInput1(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^[a-zA-Z0-9]*$/.test(inputChar)) {
      event.preventDefault();
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }


  transformGSTToUpperCaseForCorp() {
    this.customer_shipper.gst = this.customer_shipper.gst.toUpperCase();
  }

  transformPANToUpperCaseForCorp() {
    this.customer_shipper.pan = this.customer_shipper.pan.toUpperCase();
  }

  transformPANToUpperCaseForRepres() {
    this.indiviudal_user.pan_number = this.indiviudal_user.pan_number.toUpperCase();
  }

  transformGSTToUpperCaseForRetail() {
    this.indiviudal_user.gst_number = this.indiviudal_user.gst_number.toUpperCase();
  }

  transformPANToUpperCaseForRetail() {
    this.indiviudal_user.pan_number = this.indiviudal_user.pan_number.toUpperCase();
  }
}
