<h3 style="color: blue;"><b>{{ PRODUCT_TYPE }} (Door To Airport)</b></h3>
<!-- <app-mat-steps></app-mat-steps> -->
<div class="card">
  <!-- <mat-form-field appearance="legacy">
    <mat-label>Legacy form field</mat-label>
    <input matInput placeholder="Placeholder">
    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
    <mat-hint>Hint</mat-hint>
  </mat-form-field> -->
  <mat-stepper orientation="horizontal" [linear]="isLiner" #stepper="matHorizontalStepper">
    <mat-step label="Plan & Estimate" [editable]="editable">
      <div class="container ">
        <div class="row" *ngIf="notdisplaytocustomer">
          <div class="col-md-3">
            <mat-form-field appearance="standard">
              <mat-label>
                Select Shipper <span class="text-danger">*</span>
              </mat-label>
              <mat-select matNativeControl  (blur)="change_business()"
                          [(ngModel)]="val">
                <mat-option [value]="1">
                  Corporate User
                </mat-option>
                <mat-option [value]="2">
                  Retail User
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <ng-template #content4 let-c="close" let-d="dismiss">
              <div class="modal-header">
                <h4 class="modal-title"><b>Find Shipper</b></h4>
                <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
                </button>
              </div>
              <div class="modal-body">
                <div *ngIf="indiviudal_user.customerType == 2; else notbusinessuser">
                  <div class="row">
                    <!-- <div class="col-md-4">
                      <mat-form-field appearance="standard">
                        <mat-label>
                          Business Id
                        </mat-label>
                        <input type="text" placeholder="000000" aria-label="Number" matInput
                          [(ngModel)]="consignment_details.senderId">
                      </mat-form-field>
                    </div> -->
                    <div class="col-md-4">
                      <mat-form-field class="example-full-width" appearance="standard"
                                      style="width: 400px;">
                        <mat-label>Corporate User Name <span
                          class="text-danger">*</span></mat-label>
                        <span matSuffix> <i class="fa fa-times" aria-hidden="true" (click)="clearData()" style="font-size: larger; cursor: pointer"></i> </span>
                        <input type="text" matInput [formControl]="MyControl_corporate"
                               [matAutocomplete]="auto" [(ngModel)]="customer_shipper">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFns">
                          <mat-option *ngFor="let option of filteredOptions | async"
                                      (click)="findbusiness()" [value]="option"
                                      matTooltip="{{option.firstName}}">
                            {{option.firstName}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
<!--                    <div class="col-md-4">-->
<!--                      &lt;!&ndash; <button class="btn btn-primary m-1" (click)="findbusiness()">Search-->
<!--                        <i class="pe-7s-search">-->
<!--                        </i>-->
<!--                      </button> &ndash;&gt;-->
<!--                      <ng-template #content6 let-c="close" let-d="dismiss">-->
<!--                        <div class="modal-header">-->
<!--                          <h4 class="modal-title">Create Representative</h4>-->
<!--                          <button type="button" class="btn-close" aria-label="Close"-->
<!--                                  (click)="d('Cross click')">-->
<!--                          </button>-->
<!--                        </div>-->
<!--                        <div class="modal-body">-->

<!--                          <div class="row">-->
<!--                            <div class="col-md-4">-->
<!--                              <mat-form-field class="py-0" appearance="standard">-->
<!--                                <mat-label>-->
<!--                                  First Name<span class="text-danger">*</span>-->
<!--                                </mat-label>-->
<!--                                <input type="text" placeholder="First Name"-->
<!--                                       aria-label="Number" matInput-->
<!--                                       [(ngModel)]="indiviudal_user.first_name">-->
<!--                              </mat-form-field>-->
<!--                            </div>-->
<!--                            <div class="col-md-4">-->
<!--                              <mat-form-field class="py-0" appearance="standard">-->
<!--                                <mat-label>-->
<!--                                  Middle Name-->
<!--                                </mat-label>-->
<!--                                <input type="text" placeholder="Middle Name"-->
<!--                                       aria-label="Number" matInput-->
<!--                                       [(ngModel)]="indiviudal_user.middle_name">-->
<!--                              </mat-form-field>-->
<!--                            </div>-->
<!--                            <div class="col-md-4">-->
<!--                              <mat-form-field class="py-0" appearance="standard">-->
<!--                                <mat-label>-->
<!--                                  Last Name<span class="text-danger">*</span>-->
<!--                                </mat-label>-->
<!--                                <input type="text" placeholder="Last Name"-->
<!--                                       aria-label="Number" matInput-->
<!--                                       [(ngModel)]="indiviudal_user.last_name">-->
<!--                              </mat-form-field>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                          <div class="row">-->
<!--                            <div class="col-md-4">-->
<!--                              <mat-form-field appearance="standard">-->
<!--                                <mat-label>-->
<!--                                  E-mail<span class="text-danger">*</span>-->
<!--                                </mat-label>-->
<!--                                <input type="text" placeholder="Email"-->
<!--                                       aria-label="Number" matInput-->
<!--                                       [(ngModel)]="indiviudal_user.email"-->
<!--                                       (blur)="emailAppuser()"-->
<!--                                       [formControl]="emailFormControl">-->
<!--                                &lt;!&ndash; <mat-hint class="text-danger fw-normal" *ngIf="emailFormControl.hasError('required')">Email is required</mat-hint> &ndash;&gt;-->
<!--                                <mat-hint class="text-danger fw-normal"-->
<!--                                          *ngIf="emailFormControl.hasError('pattern')">Invalid-->
<!--                                  email address</mat-hint>-->
<!--                                <mat-hint class="text-danger fw-bold"-->
<!--                                          *ngIf="emailExist==true">Email Exist</mat-hint>-->

<!--                              </mat-form-field>-->
<!--                            </div>-->
<!--                            <div class="col-md-4">-->
<!--                              <mat-form-field appearance="standard">-->
<!--                                <mat-label>-->
<!--                                  Mobile No.<span class="text-danger">*</span>-->
<!--                                </mat-label>-->
<!--                                <input type="text" placeholder="Mobile Number"-->
<!--                                       oninput="if(!this.value.match('^[0-9]*$'))this.value='';"-->
<!--                                       aria-label="Number" matInput #phone maxlength="10"-->
<!--                                       [(ngModel)]="indiviudal_user.phone">-->
<!--                                <mat-hint align="end">{{phone.value.length}}/10-->
<!--                                </mat-hint>-->
<!--                              </mat-form-field>-->
<!--                            </div>-->
<!--                            <div class="col-md-4">-->
<!--                              <mat-form-field appearance="standard">-->
<!--                                <mat-label>-->
<!--                                  Whatsapp No.-->
<!--                                </mat-label>-->
<!--                                <input type="text" placeholder="Whatsapp No."-->
<!--                                       aria-label="Number" matInput-->
<!--                                       [(ngModel)]="indiviudal_user.whatsapp">-->

<!--                              </mat-form-field>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                          <div class="row">-->
<!--                            <div class="col-md-4">-->
<!--                              <mat-form-field class="example-full-width"-->
<!--                                              appearance="standard">-->
<!--                                <mat-label>Address<span-->
<!--                                  class="text-danger">*</span></mat-label>-->
<!--                                <textarea matInput placeholder="Address"-->
<!--                                          [(ngModel)]="indiviudal_user.address"></textarea>-->
<!--                              </mat-form-field>-->
<!--                            </div>-->
<!--                            <div class="col-md-4">-->
<!--                              <mat-form-field appearance="standard">-->
<!--                                <mat-label>Country<span-->
<!--                                  class="text-danger">*</span></mat-label>-->
<!--                                <mat-select matNativeControl-->
<!--                                            [(ngModel)]="user_sel_country">-->
<!--                                  <mat-option *ngFor="let con of countries |async"-->
<!--                                              (click)="getIndividaulStates()"-->
<!--                                              [value]="con.id">-->
<!--                                    {{con.name}}-->
<!--                                  </mat-option>-->
<!--                                </mat-select>-->
<!--                              </mat-form-field>-->
<!--                            </div>-->
<!--                            <div class="col-md-4">-->
<!--                              <mat-form-field appearance="standard">-->
<!--                                <mat-label>-->
<!--                                  State<span class="text-danger">*</span>-->
<!--                                </mat-label>-->
<!--                                <mat-select matNativeControl-->
<!--                                            [(ngModel)]="user_sel_state">-->
<!--                                  <mat-option *ngFor="let sta of user_states |async"-->
<!--                                              (click)="getIndividaulCities()"-->
<!--                                              [value]="sta.id">-->
<!--                                    {{sta.name}}-->
<!--                                  </mat-option>-->
<!--                                </mat-select>-->
<!--                              </mat-form-field>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                          <div class="row">-->
<!--                            <div class="col-md-4">-->
<!--                              <mat-form-field appearance="standard">-->
<!--                                <mat-label>-->
<!--                                  City<span class="text-danger">*</span>-->
<!--                                </mat-label>-->
<!--                                <mat-select matNativeControl-->
<!--                                            [(ngModel)]="indiviudal_user.city">-->
<!--                                  <mat-option *ngFor="let cit of user_cities |async"-->
<!--                                              (click)="getIndividualPincodes()"-->
<!--                                              [value]="cit.id">-->
<!--                                    {{cit.name}}-->
<!--                                  </mat-option>-->
<!--                                </mat-select>-->
<!--                              </mat-form-field>-->

<!--                            </div>-->
<!--                            <div class="col-md-4">-->
<!--                              <mat-form-field appearance="standard">-->
<!--                                <mat-label>-->
<!--                                  Zip Code<span class="text-danger">*</span>-->
<!--                                </mat-label>-->
<!--                                <mat-select matNativeControl-->
<!--                                            [(ngModel)]="indiviudal_user.zip_code">-->
<!--                                  <mat-option *ngFor="let cit of user_pincodes |async"-->
<!--                                              [value]="cit.id">-->
<!--                                    {{cit.pin_number}}-->
<!--                                  </mat-option>-->
<!--                                </mat-select>-->
<!--                              </mat-form-field>-->
<!--                            </div>-->
<!--                            <div class="row">-->
<!--                              <div class="col-md-4">-->
<!--                                <mat-form-field class="py-0" appearance="standard">-->
<!--                                  <mat-label>-->
<!--                                    Aadhaar Number-->
<!--                                  </mat-label>-->
<!--                                  <input type="text" placeholder="Aadhaar Number" onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="Number" matInput maxlength="12" #aadhaarnum1-->
<!--                                         [(ngModel)]="indiviudal_user.aadhaar_number">-->
<!--                                  <mat-hint align="">{{aadhaarnum1.value.length}}/12</mat-hint>-->
<!--                                </mat-form-field>-->
<!--                              </div>-->
<!--                              <div class="col-md-4">-->
<!--                                <mat-form-field appearance="standard">-->
<!--                                  <mat-label>-->
<!--                                    Driving License-->
<!--                                  </mat-label>-->
<!--                                  <input type="text" placeholder="Driving License" aria-label="text" matInput-->
<!--                                         [(ngModel)]="indiviudal_user.driving_license">-->
<!--                                </mat-form-field>-->
<!--                              </div>-->
<!--                              <div class="col-md-4">-->
<!--                                <mat-form-field class="py-0" appearance="standard">-->
<!--                                  <mat-label>-->
<!--                                    PAN Number-->
<!--                                  </mat-label>-->
<!--                                  <input type="text" placeholder="PAN Number" aria-label="Number" matInput-->
<!--                                         [(ngModel)]="indiviudal_user.pan_number">-->
<!--                                </mat-form-field>-->
<!--                              </div>-->
<!--                              <div class="col-md-4">-->
<!--                                <mat-form-field class="py-0" appearance="standard">-->
<!--                                  <mat-label>-->
<!--                                    GST Number-->
<!--                                  </mat-label>-->
<!--                                  <input type="text" placeholder="GST Number" aria-label="Number" matInput-->
<!--                                         [(ngModel)]="indiviudal_user.gst_number">-->
<!--                                </mat-form-field>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                        <div class="modal-footer">-->
<!--                          <button type="button" class="btn btn-secondary"-->
<!--                                  (click)="c('Close click')">Close</button>-->
<!--                          <div *ngIf="representative_created==false">-->
<!--                            <button type="button" class="btn btn-primary"-->
<!--                                    (click)="createRep()">Create</button>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                        <ngx-loading [show]="Representativeload"-->
<!--                                     [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"-->
<!--                                     [template]="loadingTemplate"></ngx-loading>-->
<!--                      </ng-template>-->
<!--                    </div>-->
                  </div>
                  <div *ngIf="customer_shipper?.id && show ">
                    <div class="row">
                      <div class="col-md-3 fw-bold">
                        Corporate User Name
                      </div>
                      <div class="col-md-3">
                        {{customer_shipper?.firstName}}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 fw-bold">
                        Address
                      </div>
                      <div class="col-md-3">
                        {{customer_shipper?.address_1}}, {{customer_shipper?.zip_code}}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 fw-bold">
                        Email
                      </div>
                      <div class="col-md-3">
                        {{customer_shipper?.email_1}}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 fw-bold">
                        phone
                      </div>
                      <div class="col-md-3">
                        {{customer_shipper?.phone_1}}
                      </div>
                    </div>
                    <div class="h4">
                      Representative List
                      <button class="btn btn-success m-1" (click)="gotoCreateNewCustomerPage(); resetform()">
                        Create Representative
                      </button>
                      <button (click)="findbusiness()" class="btn btn-secondary m-1"
                              matTooltip="Refresh">
                        Refresh
                        <i class="pe-7s-refresh-2 btn-icon-wrapper"> </i>
                      </button>
                    </div>
                    <div class="row">
                      <div style="max-height: 200px; overflow: auto;">
                        <table
                          class="align-middle text-truncate figure-caption mb-0 table table-borderless table-hover">
                          <thead>
                          <tr>
                            <th class="text-center">Representative Id</th>
                            <th class="text-center">Name</th>
                            <th class="text-center">Address</th>
                            <th class="text-center">City</th>
                            <th class="text-center">Email</th>
                            <th class="text-center">Phone</th>
                            <th class="text-center"></th>
                          </tr>
                          </thead>
                          <tbody *ngIf="representativeList!=null">
                          <tr *ngFor="let rep of representativeList"
                              [ngStyle]="{'background-color': (rep.id==consignment_details.senderIndividual) ? '#abd8ff' : '#ffffff'}">
                            <td class="text-center text-muted" style="width: 10%;">
                              #{{rep?.id}}</td>
                            <td class="text-center text-muted" style="width: 20%;">
                              {{rep?.firstName}}</td>
                            <td class="text-center text-muted" style="width: 30%;">
                              <div
                                style="white-space: pre-wrap;word-wrap: break-word;">
                                {{rep?.billingAddress?.address}}
                              </div>
                            </td>
                            <td class="text-center  text-muted" style="width: 20%;">
                              <div *ngIf="rep?.billingAddress?.pincodeId?.cityId?.name"
                                   style="white-space: pre-wrap;word-wrap: break-word;">
                                {{rep?.billingAddress?.pincodeId?.cityId?.name}}
                              </div>
                            </td>
                            <td class="text-center  text-muted" style="width: 50%;">

																<div
																	style="white-space: pre-wrap;word-wrap: break-word;">
																	{{rep?.email}}
																</div>
															</td>
															<td class="text-center text-muted" style="width: 10%;">
																{{rep?.phone}}</td>
															<td class="text-center text-muted" style="width: 5%;">
																<button type="button" class="btn btn-success btn-sm"
																	(click)="selectRep(rep.id)">
																	Select
																</button>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
								<ng-template #notbusinessuser>
									<div class="row">
										<div class="col-md-4">
											<mat-form-field class="example-full-width" appearance="standard"
												style="width: 400px;">
												<mat-label>Retail User Name</mat-label>
												<input type="text" matInput [formControl]="MyControl_retail"
													[matAutocomplete]="auto" [(ngModel)]="indiviudal_user">
												<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnss">
													<mat-option *ngFor="let option of filteredOption | async"
														[value]="option" matTooltip="{{option.firstName}}">
														{{option.firstName}}
													</mat-option>
												</mat-autocomplete>
											</mat-form-field>
										</div>
										<div class="col-md-4">
											<button class="btn btn-primary m-1 float-end" (click)="findindividual()">
												<i class="pe-7s-search">
												</i>
											</button>
										</div>
									</div>
									<div *ngIf="indiviudal_user.id && show">
										<div class="row">
											<div class="col-md-3 fw-bold">
												Name
											</div>
											<div class="col-md-3">
												{{indiviudal_user?.firstName}} {{indiviudal_user?.lastName}}
											</div>
										</div>
										<div class="row">
											<div class="col-md-3 fw-bold">
												Address
											</div>
											<div class="col-md-3">
												{{indiviudal_user?.billingAddress?.address}}, {{indiviudal_user?.billingAddress?.pincode?.pin_number}}
											</div>
										</div>
										<div class="row">
											<div class="col-md-3 fw-bold">
												Email
											</div>
											<div class="col-md-3">
												{{indiviudal_user?.email}}
											</div>
										</div>
										<div class="row">
											<div class="col-md-3 fw-bold">
												Phone
											</div>
											<div class="col-md-3">
												{{indiviudal_user?.phone}}
											</div>
										</div>
									</div>
								</ng-template>
							</div>
							<div class="modal-footer">
								<button type="button" class="btn btn-secondary"
									(click)="c('Close click')">Close</button>
								<!-- <button type="button" class="btn btn-primary" (click)="createCustomer()">Create</button> -->
							</div>
						</ng-template>
						<button class="btn btn-primary m-1" (click)="open(content4)">
							Find
							<i class="pe-7s-search">
							</i>
						</button>
<!--						<ng-template #content5 let-c="close" let-d="dismiss">-->
<!--							<div class="modal-header">-->
<!--								<h4 class="modal-title">Create Customer</h4>-->
<!--								<button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">-->
<!--								</button>-->
<!--							</div>-->
<!--							<div class="modal-body">-->
<!--								<div class="row">-->
<!--									<div class="col-md-4">-->
<!--										<mat-form-field appearance="standard">-->
<!--											<mat-label>-->
<!--												Customer-->
<!--											</mat-label>-->
<!--											<mat-select matNativeControl  (blur)="change_business()"-->
<!--												[(ngModel)]="val">-->
<!--												<mat-option value="1">-->
<!--													Corporate User-->
<!--												</mat-option>-->
<!--												<mat-option value="2">-->
<!--													Retail User-->
<!--												</mat-option>-->
<!--											</mat-select>-->
<!--										</mat-form-field>-->
<!--									</div>-->
<!--									<div class="col-md-4">-->
<!--										<button class="btn btn-primary m-3">Select</button>-->
<!--									</div>-->
<!--								</div>-->
<!--								<div *ngIf="indiviudal_user.customerType == 2; else notbusinessuser">-->
<!--									<div class="h4">-->
<!--										Corporate Customer Details-->
<!--									</div>-->
<!--									<div class="row">-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field class="py-0" appearance="standard">-->
<!--												<mat-label>-->
<!--													Name<span class="text-danger">*</span>-->
<!--												</mat-label>-->
<!--												<input type="text" placeholder="Name" aria-label="Number" matInput-->
<!--													[(ngModel)]="customer_shipper.firstName" (blur)="nameExist()">-->
<!--												<mat-hint class="text-danger fw-bold" *ngIf="CustomerExist==true">Name-->
<!--													Exist</mat-hint>-->
<!--											</mat-form-field>-->
<!--										</div>-->
<!--									</div>-->
<!--									<div class="row">-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field appearance="standard">-->
<!--												<mat-label>-->
<!--													E-mail <span class="text-danger">*</span>-->
<!--												</mat-label>-->
<!--												<input type="text" placeholder="Email" aria-label="Number" matInput-->
<!--													[(ngModel)]="customer_shipper.email1"-->
<!--													[formControl]="emailFormControl">-->
<!--												&lt;!&ndash; <mat-hint class="text-danger fw-normal" *ngIf="emailFormControl.hasError('required')">Email is required</mat-hint> &ndash;&gt;-->
<!--												<mat-hint class="text-danger fw-normal"-->
<!--													*ngIf="emailFormControl.hasError('pattern')">Invalid email-->
<!--													address</mat-hint>-->

<!--											</mat-form-field>-->
<!--										</div>-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field appearance="standard">-->
<!--												<mat-label>-->
<!--													Mobile No.<span class="text-danger">*</span>-->
<!--												</mat-label>-->
<!--												<input type="text" placeholder="Mobile No."-->
<!--													oninput="if(!this.value.match('^[0-9]*$'))this.value='';"-->
<!--													aria-label="Number" #phone1 matInput maxlength="10"-->
<!--													[(ngModel)]="customer_shipper.phone1">-->
<!--												<mat-hint align="end">{{phone1.value.length}}/10-->
<!--												</mat-hint>-->
<!--											</mat-form-field>-->
<!--										</div>-->
<!--									</div>-->
<!--									<div class="row">-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field class="example-full-width" appearance="standard">-->
<!--												<mat-label>Address<span class="text-danger">*</span></mat-label>-->
<!--												<textarea matInput placeholder="Address"-->
<!--													[(ngModel)]="customer_shipper.address1"></textarea>-->
<!--											</mat-form-field>-->
<!--										</div>-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field appearance="standard">-->
<!--												<mat-label>Country</mat-label>-->
<!--												<mat-select matNativeControl id="" [(ngModel)]="business_sel_country">-->
<!--													<mat-option selected>SELECT</mat-option>-->
<!--													<mat-option *ngFor="let con of countries |async" [value]="con.id"-->
<!--														(click)="getBusinessStates()">-->
<!--														{{con.name}}-->
<!--													</mat-option>-->
<!--												</mat-select>-->
<!--											</mat-form-field>-->
<!--										</div>-->

<!--										<div class="col-md-4">-->
<!--											<mat-form-field appearance="standard">-->
<!--												<mat-label>-->
<!--													State<span class="text-danger">*</span>-->
<!--												</mat-label>-->
<!--												<mat-select matNativeControl-->
<!--													[(ngModel)]="business_sel_state">-->
<!--													<mat-option selected>SELECT</mat-option>-->
<!--													<mat-option *ngFor="let sta of business_states |async"-->
<!--														(click)="getBusinessCities()" [value]="sta.id">-->
<!--														{{sta.name}}-->
<!--													</mat-option>-->
<!--												</mat-select>-->
<!--											</mat-form-field>-->
<!--										</div>-->
<!--									</div>-->
<!--									<div class="row">-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field appearance="standard">-->
<!--												<mat-label>-->
<!--													City<span class="text-danger">*</span>-->
<!--												</mat-label>-->
<!--												<mat-select matNativeControl-->
<!--													[(ngModel)]="customer_shipper.city1">-->
<!--													<mat-option selected>SELECT</mat-option>-->
<!--													<mat-option *ngFor="let cit of business_cities |async"-->
<!--														(click)="getBusinessPincodes()" [value]="cit.id">-->
<!--														{{cit.name}}-->
<!--													</mat-option>-->
<!--												</mat-select>-->
<!--											</mat-form-field>-->

<!--										</div>-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field appearance="standard">-->
<!--												<mat-label>-->
<!--													Zip Code<span class="text-danger">*</span>-->
<!--												</mat-label>-->
<!--												<mat-select matNativeControl-->
<!--													[(ngModel)]="customer_shipper.zipCode">-->
<!--													<mat-option selected>SELECT</mat-option>-->
<!--													<mat-option *ngFor="let cit of business_pincodes |async"-->
<!--														[value]="cit.id">-->
<!--														{{cit.pin_number}}-->
<!--													</mat-option>-->
<!--												</mat-select>-->
<!--											</mat-form-field>-->
<!--										</div>-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field appearance="standard">-->
<!--												<mat-label>-->
<!--													Billing Branch<span class="text-danger">*</span>-->
<!--												</mat-label>-->
<!--												<mat-select matNativeControl id="" [(ngModel)]="customer_shipper.office_location">-->
<!--													<mat-option *ngFor="let role of OfficesList" [value]="role.id">-->
<!--														{{role.name}}-->
<!--													</mat-option>-->
<!--												</mat-select>-->
<!--											</mat-form-field>-->
<!--										</div>-->
<!--									</div>-->
<!--									<div class="row">-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field appearance="standard">-->
<!--												<mat-label>-->
<!--													GST No.<span class="text-danger">*</span>-->
<!--												</mat-label>-->
<!--												<input type="text" placeholder="GST" aria-label="Number" matInput #gst-->
<!--													maxlength="15" [(ngModel)]="customer_shipper.gst"-->
<!--													[formControl]="gstFormControl">-->
<!--												&lt;!&ndash; <mat-hint class="text-danger fw-normal" *ngIf="gstFormControl.hasError('required')">GST No. required</mat-hint> &ndash;&gt;-->
<!--												<mat-hint class="text-danger fw-normal"-->
<!--													*ngIf="gstFormControl.hasError('pattern')">Invalid GST-->
<!--													No.</mat-hint>-->
<!--												<mat-hint align="end">{{gst.value.length}}/15-->
<!--												</mat-hint>-->
<!--											</mat-form-field>-->
<!--										</div>-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field appearance="standard">-->
<!--												<mat-label>-->
<!--													PAN No.<span class="text-danger">*</span>-->
<!--												</mat-label>-->
<!--												<input type="text" placeholder="PAN" aria-label="Number" matInput #pan-->
<!--													maxlength="10" [(ngModel)]="customer_shipper.pan"-->
<!--													[formControl]="PanFormControl">-->
<!--												&lt;!&ndash; <mat-hint class="text-danger fw-normal" *ngIf="PanFormControl.hasError('required')">PAN No. required</mat-hint> &ndash;&gt;-->
<!--												<mat-hint class="text-danger fw-normal"-->
<!--													*ngIf="PanFormControl.hasError('pattern')">Invalid PAN-->
<!--													No.</mat-hint>-->
<!--												<mat-hint align="end">{{pan.value.length}}/10-->
<!--												</mat-hint>-->
<!--											</mat-form-field>-->
<!--										</div>-->
<!--                    <br>-->
<!--                    <section>-->
<!--                      <mat-checkbox [(ngModel)]="customer_shipper.isSezCustomer" >Is SEZ Customer</mat-checkbox>-->
<!--                    </section>-->
<!--                  </div>-->
<!--                  <br>-->
<!--									<mat-divider>-->

<!--									</mat-divider>-->
<!--									<div class="h4">-->
<!--										Corporate Representative Details-->
<!--									</div>-->

<!--									<div class="row">-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field class="py-0" appearance="standard">-->
<!--												<mat-label>-->
<!--													First Name<span class="text-danger">*</span>-->
<!--												</mat-label>-->
<!--												<input type="text" placeholder="First Name" aria-label="Number" matInput-->
<!--													[(ngModel)]="indiviudal_user.first_name">-->
<!--											</mat-form-field>-->
<!--										</div>-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field class="py-0" appearance="standard">-->
<!--												<mat-label>-->
<!--													Middle Name-->
<!--												</mat-label>-->
<!--												<input type="text" placeholder="Middle Name" aria-label="Number"-->
<!--													matInput [(ngModel)]="indiviudal_user.middle_name">-->
<!--											</mat-form-field>-->
<!--										</div>-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field class="py-0" appearance="standard">-->
<!--												<mat-label>-->
<!--													Last Name<span class="text-danger">*</span>-->
<!--												</mat-label>-->
<!--												<input type="text" placeholder="Last Name" aria-label="Number" matInput-->
<!--													[(ngModel)]="indiviudal_user.last_name">-->
<!--											</mat-form-field>-->
<!--										</div>-->
<!--									</div>-->
<!--									<div class="row">-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field appearance="standard">-->
<!--												<mat-label>-->
<!--													E-mail<span class="text-danger">*</span>-->
<!--												</mat-label>-->
<!--												<input type="text" placeholder="Email" aria-label="Number" matInput-->
<!--													[(ngModel)]="indiviudal_user.email" (blur)="emailAppuser()"-->
<!--													[formControl]="emailFormControl">-->
<!--												&lt;!&ndash; <mat-hint class="text-danger fw-normal" *ngIf="emailFormControl.hasError('required')">Email is required</mat-hint> &ndash;&gt;-->
<!--												<mat-hint class="text-danger fw-normal"-->
<!--													*ngIf="emailFormControl.hasError('pattern')">Invalid email-->
<!--													address</mat-hint>-->
<!--												<mat-hint class="text-danger fw-bold" *ngIf="emailExist==true">Email-->
<!--													Exist</mat-hint>-->
<!--											</mat-form-field>-->
<!--										</div>-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field appearance="standard">-->
<!--												<mat-label>-->
<!--													Mobile No.<span class="text-danger">*</span>-->
<!--												</mat-label>-->
<!--												<input type="text" placeholder="Mobile No."-->
<!--													oninput="if(!this.value.match('^[0-9]*$'))this.value='';"-->
<!--													aria-label="Number" matInput #phone maxlength="10"-->
<!--													[(ngModel)]="indiviudal_user.phone" (blur)="phoneNoExistAppuser()">-->
<!--												<mat-hint class="text-danger fw-bold" *ngIf="phoneNoExist==true">Phone-->
<!--													No. Exist</mat-hint>-->
<!--												<mat-hint align="end">{{phone.value.length}}/10-->
<!--												</mat-hint>-->
<!--											</mat-form-field>-->
<!--										</div>-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field appearance="standard">-->
<!--												<mat-label>-->
<!--													Whatsapp No.-->
<!--												</mat-label>-->
<!--												<input type="text" placeholder="Whatsapp No." aria-label="Number"-->
<!--													matInput [(ngModel)]="indiviudal_user.whatsapp">-->

<!--											</mat-form-field>-->
<!--										</div>-->
<!--									</div>-->
<!--									<div class="row">-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field class="example-full-width" appearance="standard">-->
<!--												<mat-label>Address<span class="text-danger">*</span></mat-label>-->
<!--												<textarea matInput placeholder="Address"-->
<!--													[(ngModel)]="indiviudal_user.address"></textarea>-->
<!--											</mat-form-field>-->
<!--										</div>-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field appearance="standard">-->
<!--												<mat-label>Country<span class="text-danger">*</span></mat-label>-->
<!--												<mat-select matNativeControl-->
<!--													[(ngModel)]="user_sel_country">-->
<!--													<mat-option selected>SELECT</mat-option>-->
<!--													<mat-option *ngFor="let con of countries |async" [value]="con.id"-->
<!--														(click)="getIndividaulStates()">-->
<!--														{{con.name}}-->
<!--													</mat-option>-->
<!--												</mat-select>-->
<!--											</mat-form-field>-->
<!--										</div>-->

<!--										<div class="col-md-4">-->
<!--											<mat-form-field appearance="standard">-->
<!--												<mat-label>-->
<!--													State<span class="text-danger">*</span>-->
<!--												</mat-label>-->
<!--												<mat-select matNativeControl-->
<!--													[(ngModel)]="user_sel_state">-->
<!--													<mat-option selected>SELECT</mat-option>-->
<!--													<mat-option *ngFor="let sta of user_states |async" [value]="sta.id"-->
<!--														(click)="getIndividaulCities()">-->
<!--														{{sta.name}}-->
<!--													</mat-option>-->
<!--												</mat-select>-->
<!--											</mat-form-field>-->
<!--										</div>-->
<!--									</div>-->
<!--									<div class="row">-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field appearance="standard">-->
<!--												<mat-label>-->
<!--													City<span class="text-danger">*</span>-->
<!--												</mat-label>-->
<!--												<mat-select matNativeControl-->
<!--													[(ngModel)]="indiviudal_user.city">-->
<!--													<mat-option selected>SELECT</mat-option>-->
<!--													<mat-option *ngFor="let cit of user_cities |async" [value]="cit.id"-->
<!--														(click)="getIndividualPincodes()">-->
<!--														{{cit.name}}-->
<!--													</mat-option>-->
<!--												</mat-select>-->
<!--											</mat-form-field>-->

<!--										</div>-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field appearance="standard">-->
<!--												<mat-label>-->
<!--													Zip Code-->
<!--												</mat-label>-->
<!--												<mat-select matNativeControl-->
<!--													[(ngModel)]="indiviudal_user.zip_code">-->
<!--													<mat-option selected>SELECT</mat-option>-->
<!--													<mat-option *ngFor="let cit of user_pincodes |async"-->
<!--														[value]="cit.id">-->
<!--														{{cit.pin_number}}-->
<!--													</mat-option>-->
<!--												</mat-select>-->
<!--											</mat-form-field>-->
<!--										</div>-->
<!--										<div class="row">-->
<!--											<div class="col-md-4">-->
<!--												<mat-form-field class="py-0" appearance="standard">-->
<!--													<mat-label>-->
<!--														Aadhaar Number-->
<!--													</mat-label>-->
<!--													<input type="text" placeholder="Aadhaar Number" onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="Number" matInput maxlength="12" #aadhaarnum1-->
<!--														[(ngModel)]="indiviudal_user.aadhaar_number">-->
<!--													<mat-hint align="">{{aadhaarnum1.value.length}}/12</mat-hint>-->
<!--												</mat-form-field>-->
<!--											</div>-->
<!--											<div class="col-md-4">-->
<!--												<mat-form-field appearance="standard">-->
<!--													<mat-label>-->
<!--														Driving License-->
<!--													</mat-label>-->
<!--													<input type="text" placeholder="Driving License" aria-label="text" matInput-->
<!--														[(ngModel)]="indiviudal_user.driving_license">-->
<!--												</mat-form-field>-->
<!--											</div>-->
<!--											<div class="col-md-4">-->
<!--												<mat-form-field class="py-0" appearance="standard">-->
<!--													<mat-label>-->
<!--														PAN Number-->
<!--													</mat-label>-->
<!--													<input type="text" placeholder="PAN Number" aria-label="Number" matInput-->
<!--														[(ngModel)]="indiviudal_user.pan_number">-->
<!--												</mat-form-field>-->
<!--											</div>-->
<!--											<div class="col-md-4">-->
<!--												<mat-form-field class="py-0" appearance="standard">-->
<!--													<mat-label>-->
<!--														GST Number-->
<!--													</mat-label>-->
<!--													<input type="text" placeholder="GST Number" aria-label="Number" matInput-->
<!--														[(ngModel)]="indiviudal_user.gst_number">-->
<!--												</mat-form-field>-->
<!--											</div>-->
<!--										</div>-->
<!--									</div>-->
<!--								</div>-->
<!--								<ng-template #notbusinessuser>-->

<!--									<div class="row">-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field class="py-0" appearance="standard">-->
<!--												<mat-label>-->
<!--													First Name<span class="text-danger">*</span>-->
<!--												</mat-label>-->
<!--												<input type="text" placeholder="First Name" aria-label="Number" matInput-->
<!--													[(ngModel)]="indiviudal_user.first_name">-->
<!--											</mat-form-field>-->
<!--										</div>-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field class="py-0" appearance="standard">-->
<!--												<mat-label>-->
<!--													Middle Name-->
<!--												</mat-label>-->
<!--												<input type="text" placeholder="Middle Name" aria-label="Number"-->
<!--													matInput [(ngModel)]="indiviudal_user.middle_name">-->
<!--											</mat-form-field>-->
<!--										</div>-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field class="py-0" appearance="standard">-->
<!--												<mat-label>-->
<!--													Last Name<span class="text-danger">*</span>-->
<!--												</mat-label>-->
<!--												<input type="text" placeholder="Last Name" aria-label="Number" matInput-->
<!--													[(ngModel)]="indiviudal_user.last_name">-->
<!--											</mat-form-field>-->
<!--										</div>-->
<!--									</div>-->
<!--									<div class="row">-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field appearance="standard">-->
<!--												<mat-label>-->
<!--													E-mail<span class="text-danger">*</span>-->
<!--												</mat-label>-->
<!--												<input type="text" placeholder="Email" aria-label="Number" matInput-->
<!--													[(ngModel)]="indiviudal_user.email" (blur)="emailAppuser()"-->
<!--													[formControl]="emailFormControl">-->
<!--												&lt;!&ndash; <mat-hint class="text-danger fw-normal" *ngIf="emailFormControl.hasError('required')">Email is required</mat-hint> &ndash;&gt;-->
<!--												<mat-hint class="text-danger fw-normal"-->
<!--													*ngIf="emailFormControl.hasError('pattern')">Invalid email-->
<!--													address</mat-hint>-->
<!--												<mat-hint class="text-danger fw-bold" *ngIf="emailExist==true">Email-->
<!--													Exist</mat-hint>-->


<!--											</mat-form-field>-->
<!--										</div>-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field appearance="standard">-->
<!--												<mat-label>-->
<!--													Mobile No.<span class="text-danger">*</span>-->
<!--												</mat-label>-->
<!--												<input type="text" placeholder="Mobile No."-->
<!--													oninput="if(!this.value.match('^[0-9]*$'))this.value='';"-->
<!--													aria-label="Number" #phone matInput maxlength="10"-->
<!--													[(ngModel)]="indiviudal_user.phone" (blur)="phoneNoExistAppuser()">-->
<!--												<mat-hint class="text-danger fw-bold" *ngIf="phoneNoExist==true">Phone-->
<!--													No. Exist</mat-hint>-->
<!--												<mat-hint align="end">{{phone.value.length}}/10-->
<!--												</mat-hint>-->
<!--											</mat-form-field>-->
<!--										</div>-->
<!--									</div>-->
<!--									<div class="row">-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field class="example-full-width" appearance="standard">-->
<!--												<mat-label>Address<span class="text-danger">*</span></mat-label>-->
<!--												<textarea matInput placeholder="Address"-->
<!--													[(ngModel)]="indiviudal_user.address"></textarea>-->
<!--											</mat-form-field>-->
<!--										</div>-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field appearance="standard">-->
<!--												<mat-label>Country</mat-label>-->
<!--												<mat-select matNativeControl-->
<!--													(blur)="getIndividaulStates()" [(ngModel)]="user_sel_country">-->
<!--													<mat-option selected>SELECT</mat-option>-->
<!--													<mat-option *ngFor="let con of countries |async" [value]="con.id">-->
<!--														{{con.name}}-->
<!--													</mat-option>-->
<!--												</mat-select>-->
<!--											</mat-form-field>-->
<!--										</div>-->

<!--										<div class="col-md-4">-->
<!--											<mat-form-field appearance="standard">-->
<!--												<mat-label>-->
<!--													State<span class="text-danger">*</span>-->
<!--												</mat-label>-->
<!--												<mat-select matNativeControl-->
<!--													(blur)="getIndividaulCities()" [(ngModel)]="user_sel_state">-->
<!--													<mat-option selected>SELECT</mat-option>-->
<!--													<mat-option *ngFor="let sta of user_states |async" [value]="sta.id">-->
<!--														{{sta.name}}-->
<!--													</mat-option>-->
<!--												</mat-select>-->
<!--											</mat-form-field>-->
<!--										</div>-->
<!--									</div>-->
<!--									<div class="row">-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field appearance="standard">-->
<!--												<mat-label>-->
<!--													City<span class="text-danger">*</span>-->
<!--												</mat-label>-->
<!--												<mat-select matNativeControl-->
<!--													[(ngModel)]="indiviudal_user.city" (blur)="getIndividualPincodes()">-->
<!--													<mat-option selected>SELECT</mat-option>-->
<!--													<mat-option *ngFor="let cit of user_cities |async" [value]="cit.id">-->
<!--														{{cit.name}}-->
<!--													</mat-option>-->
<!--												</mat-select>-->
<!--											</mat-form-field>-->

<!--										</div>-->
<!--										<div class="col-md-4">-->
<!--											<mat-form-field appearance="standard">-->
<!--												<mat-label>-->
<!--													Zip Code-->
<!--												</mat-label>-->
<!--												<mat-select matNativeControl-->
<!--													[(ngModel)]="indiviudal_user.zip_code">-->
<!--													<mat-option selected>SELECT</mat-option>-->
<!--													<mat-option *ngFor="let cit of user_pincodes |async"-->
<!--														[value]="cit.id">-->
<!--														{{cit.pin_number}}-->
<!--													</mat-option>-->
<!--												</mat-select>-->
<!--											</mat-form-field>-->
<!--										</div>-->
<!--										<div class="row">-->

<!--											<div class="row">-->
<!--												<div class="col-md-4">-->
<!--													<mat-form-field class="py-0" appearance="standard">-->
<!--														<mat-label>-->
<!--															Aadhaar Number-->
<!--														</mat-label>-->
<!--														<input type="text" placeholder="Aadhaar Number" onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="Number" matInput maxlength="12" #aadhaarnum1-->
<!--															[(ngModel)]="indiviudal_user.aadhaar_number">-->
<!--														<mat-hint align="">{{aadhaarnum1.value.length}}/12</mat-hint>-->
<!--													</mat-form-field>-->
<!--												</div>-->
<!--												<div class="col-md-4">-->
<!--													<mat-form-field appearance="standard">-->
<!--														<mat-label>-->
<!--															Driving License-->
<!--														</mat-label>-->
<!--														<input type="text" placeholder="Driving License" aria-label="text" matInput-->
<!--															[(ngModel)]="indiviudal_user.driving_license">-->
<!--													</mat-form-field>-->
<!--												</div>-->
<!--												<div class="col-md-4">-->
<!--													<mat-form-field class="py-0" appearance="standard">-->
<!--														<mat-label>-->
<!--															PAN Number-->
<!--														</mat-label>-->
<!--														<input type="text" placeholder="PAN Number" aria-label="Number" matInput-->
<!--															[(ngModel)]="indiviudal_user.pan_number">-->
<!--													</mat-form-field>-->
<!--												</div>-->
<!--												<div class="col-md-4">-->
<!--													<mat-form-field class="py-0" appearance="standard">-->
<!--														<mat-label>-->
<!--															GST Number-->
<!--														</mat-label>-->
<!--														<input type="text" placeholder="GST Number" aria-label="Number" matInput-->
<!--															[(ngModel)]="indiviudal_user.gst_number">-->
<!--													</mat-form-field>-->
<!--												</div>-->
<!--											</div>-->
<!--										</div>-->
<!--									</div>-->

<!--								</ng-template>-->
<!--							</div>-->
<!--							<div class="modal-footer">-->
<!--								<button type="button" class="btn btn-secondary"-->
<!--									(click)="c('Close click')">Close</button>-->
<!--								<button *ngIf="customer_created==false" type="button" class="btn btn-primary"-->
<!--									(click)="createCustomer()">Create</button>-->
<!--							</div>-->
<!--							<ngx-loading [show]="Representativeload"-->
<!--								[config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"-->
<!--								[template]="loadingTemplate"></ngx-loading>-->
<!--						</ng-template>-->
<!--						<button class="btn btn-success " (click)="gotoCreateNewCustomerPage(); createnewformreset()" *ngIf="!displayAdminandPDA">-->
<!--							Create New<i class="fa fa-fw">-->
<!--							</i>-->
<!--						</button>-->
						<!-- <ng-template #createAddress let-c="close" let-d="dismiss">
							<div class="modal-header">
								<h4 class="modal-title">Create Address</h4>
								<button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
								</button>
							</div>
							<div class="modal-body">
								<app-address [user_id]="indiviudal_user.id"
									[user_name]="indiviudal_user.first_name+' '+indiviudal_user.last_name">
								</app-address>
							</div>
						</ng-template> -->
					</div>
					<div class="col-md-2 d-flex float-end">
						<p class="float-end text-danger fw-bold mt-2 "
							*ngIf="errorRepresentative==false && errorShipper ==true">
							Select Corporate Representative Details</p>
						<p class=" text-danger fw-bold fa-1x mt-2" *ngIf="!errorShipper">Please Select
							Shipper </p>
					</div>
					<div class="font-size-md fa-2x fw-bold" *ngIf="indiviudal_user.id && errorShipper">
						<h6><b style="color: rgb(53, 68, 231);">{{indiviudal_user.firstName}}
								{{indiviudal_user.lastName}}</b></h6>
						<!-- <span class="material-icons" style="color: rgb(0, 128, 55);"> check_circle_outline </span> -->
					</div>
				</div>
				<!-- <ng-template #createAddress let-c="close" let-d="dismiss">
					<div class="modal-header">
						<h4 class="modal-title">Create Address</h4>
						<button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
						</button>
					</div>
					<div class="modal-body">
						<app-address [user_id]="indiviudal_user.id"
							[user_name]="indiviudal_user.first_name+' '+indiviudal_user.last_name">
						</app-address>
					</div>
				</ng-template> -->
				<ng-template #createAddress let-c="close" let-d="dismiss">
					<div class="modal-header">
						<h4 class="modal-title">Create Address</h4>
						<button *ngIf="refresh == true" type="button" class="btn-close" aria-label="Close"
							(click)="d('Cross click');getAddressList(indiviudal_user.id);">
						</button>
						<button *ngIf="refresh == false" type="button" class="btn-close" aria-label="Close"
							(click)="d('Cross click');getAddressList(consignment_details.senderIndividual);">
						</button>
					</div>
					<div class="modal-body">
						<app-address [user_id]="indiviudal_user.id"
							[user_name]="indiviudal_user.firstName+' '+indiviudal_user.lastName">
						</app-address>
					</div>
				</ng-template>
				<div class="row">
					<div class="col-md-2">
						<mat-form-field appearance="standard">
							<mat-label for="consignmentType">
								Consignment Type <span class="text-danger">*</span>
							</mat-label>
							<mat-select [(ngModel)]="consignment_details.consignmentType">
								<mat-option (click)="selectConsignmentType(option)" *ngFor="let option of consignmentTypes" [value]="option.id">
									{{option.name}}
								</mat-option>
							</mat-select>
<!--              <mat-select [(ngModel)]="consignment_details.consignmentType"-->
<!--                          (blur)="getConsignmentSubtype()">-->
<!--                <mat-option *ngFor="let option of consignmentTypes | async" [value]="option.id">-->
<!--                  {{option.name}}-->
<!--                </mat-option>-->
<!--              </mat-select>-->
							<!-- <input type="text" placeholder="Select" aria-label="Number" matInput [formControl]="myControl"
								[matAutocomplete]="auto" [(ngModel)]="consignment_details.consignmentType">
							<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
								<mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
									{{option.name}}
								</mat-option>
							</mat-autocomplete> -->
						</mat-form-field>
					</div>
					<div class="col-md-2">
						<mat-form-field appearance="standard">
							<mat-label>
								Consignment Subtype <span class="text-danger">*</span>
							</mat-label>
							<mat-select  [(ngModel)]="consignment_details.consignmentSubType">
								<mat-option *ngFor="let sub_type of consignmentSubTypes " [value]="sub_type.id">
									{{sub_type.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-2">
						<mat-form-field appearance="standard">
							<mat-label>
								Dimensions Units <span class="text-danger">*</span>
							</mat-label>
							<mat-select  [(ngModel)]="consignment_details.dimensionuUnit"
								(blur)="selected_dimentions()">
								<mat-option *ngFor="let d_unit of dimensionuUnits_array " [value]="d_unit.id">
									{{d_unit.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-2">
						<mat-form-field appearance="standard">

              <mat-label for="weightUnit">
                Weight Unit <span class="text-danger">*</span>
              </mat-label>
              <mat-select id="weightUnit" [(ngModel)]="consignment_details.weightUnit"
                          (blur)="selected_weight()">
                <mat-option *ngFor="let w_unit of weightUnits_array " [value]="w_unit.id">
                  {{w_unit.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="standard">

              <mat-label for="dimensionuUnits">
                Currency <span class="text-danger">*</span>
              </mat-label>
              <mat-select  [(ngModel)]="consignment_details.currency">
                <mat-option value="1">
                  INR
                </mat-option>
                <!-- <mat-option value=1>
                  USD
                </mat-option> -->
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="standard">
              <mat-label>
                Weight Divisor <span class="text-danger">*</span>
              </mat-label>
              <mat-select  [(ngModel)]="weight_divisor" [disabled]="!this.notdisplaytocustomer">
                <mat-option [value]=5000>
                  5000
                </mat-option>
                <mat-option [value]=6000>
                  6000
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row py-2">

          <div class="col-md-4 row">
            <mat-form-field appearance="standard">
              <mat-label>
                Origin Pincode <span class="text-danger">*</span>
              </mat-label>
              <input type="text" placeholder="Origin Pincode"
                     oninput="if(!this.value.match('^[0-9]*$'))this.value='';" aria-label="Number" matInput
                     maxlength="6" [(ngModel)]="consignment_details.originLocation"
                     (blur)="validatepincode()">
              <mat-hint class="text-success fw-bold" style=" font-size: 12px"
                        *ngIf="validatepincodesServiceable">{{validatepin.originairport_name}} -
                {{validatepin.originIatacode}} ({{TrueOOA}})</mat-hint>
              <mat-hint class="text-danger fw-bold" *ngIf="validatepincodes">Pincode Not
                serviceable</mat-hint>
            </mat-form-field>
          </div>

          <div class="col-md-4 row">
            <mat-form-field appearance="standard">
              <mat-label>
                Destination (City) <span class="text-danger">*</span>
              </mat-label>
              <input type="text" placeholder="Destination Airport City" aria-label="Number" matInput
                     [formControl]="myControl2" [matAutocomplete]="auto2"
                     [(ngModel)]="consignment_details.destinationLocation">
              <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFn2">
                <mat-option *ngFor="let cit of filteredAirports2 | async" [value]="cit">
                  {{ cit.city?.name }} - {{ cit.iataCode }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-hint class="text-bold text-success fw-bold"
                      *ngIf="consignment_details.destinationLocation?.name != null"
                      style="position: absolute; margin-top: 55px; font-size: smaller;">
              {{consignment_details.destinationLocation?.name}} -
              {{consignment_details.destinationLocation?.iataCode}}
            </mat-hint>


          </div>
          <div class="col-md-2">
						<mat-form-field appearance="standard">
							<mat-label for="commodityTypes">
								Commodity Type<span class="text-danger">*</span>
							</mat-label>
							<mat-select id="commodityTypes" [(ngModel)]="consignment_details.commodityType">
								<mat-option *ngFor="let commodityType of commodityTypes" [value]="commodityType.id">
									{{ commodityType.commodity_type }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
          <!-- <div class="col-md-3">

            <mat-form-field style="width: 90%;" appearance="standard">
              <mat-label>
                Tentative Pickup Date
              </mat-label>
              <input id="total_no_packages" matInput [(ngModel)]="consignment_details.tentative_pickup_time"
                 [matDatepicker]="pickup" disabled>
              <mat-datepicker-toggle matSuffix [for]="pickup"></mat-datepicker-toggle>
              <mat-datepicker #pickup disabled="false"></mat-datepicker>
            </mat-form-field>

          </div> -->
          <div class="col-md-8 row">
            <mat-form-field style="width: 90%;" appearance="standard">
              <mat-label for="totalWeight">
                Remarks
              </mat-label>
              <textarea id="Remarks" matInput [(ngModel)]="consignment_details.remarks">
								</textarea>
            </mat-form-field>

          </div>
          <!-- <div class="col-md-2">
            <mat-form-field appearance="standard">
              <mat-label>
                Expected Time
              </mat-label>
              <mat-select >
                <mat-option [value]="5000">
                  24 Hrs
                </mat-option>
                <mat-option [value]="5000">
                  48 Hrs
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div> -->
        </div>
        <div class="row m-2" style="width: 100%;">
          <mat-divider></mat-divider>
          <div class="table-responsive">
            <table class="p-2 ">
              <thead>
              <tr>

                <th>
                  #
                </th>
                <th style="min-width: 100px;">
                  Package Dimensions <span class="text-danger">*</span>
                </th>
                <th class="mx-w-150">
                  No of Packages <span class="text-danger">*</span>
                </th>
                <th class="mx-w-150">
                  Weight per Package <span class="text-danger">*</span>
                </th>
                <th class="mx-w-150">
                  Volume Weight
                </th>
                <th class="mx-w-200">
                  Total Packages Weight
                </th>
                <th class="mx-w-200">
                  Chargeable Weight
                </th>
                <th>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let package of packages">
                <td>
                  {{package.id}}
                </td>
                <td class="mx-w-150">
                  <div style="display: flex; width: 100%">

                    <!-- <mat-form-field class="m-0" style="width: 25%;" appearance="standard"> -->
                    <div>

                      <input type="text" placeholder="L" aria-label="Number" matInput
                             [(ngModel)]="package.length" min="1" maxlength="5" (keypress)="validateInput($event)"
                             (input)="eachPackageCharged(package.id)">
                      <span class="d-flex" matSuffix>{{selectedDimention}}</span>
                    </div>
                    <div>

                      <!-- </mat-form-field> -->
                      <!-- <mat-form-field class="p-1" style="width: 25%;" appearance="standard"> -->
                      <input type="text" placeholder="B" aria-label="Number" matInput
                             [(ngModel)]="package.breadth" min="1" maxlength="5" (keypress)="validateInput($event)"
                             (input)="eachPackageCharged(package.id)">
                      <span class="d-flex" matSuffix>{{selectedDimention}}</span>
                    </div>
                    <div>

                      <!-- </mat-form-field> -->
                      <!-- <mat-form-field class="p-1" style="width: 25%;" appearance="standard"> -->

                      <input type="text" placeholder="H" aria-label="Number" matInput
                             [(ngModel)]="package.height" min="1" maxlength="5" (keypress)="validateInput($event)"
                             (input)="eachPackageCharged(package.id)">
                      <span class="d-flex" matSuffix>{{selectedDimention}}</span>
                      <!-- </mat-form-field> -->
                    </div>
                  </div>
                </td>
                <td class="mx-w-150">
                  <mat-form-field class="py-0" style="width: 90%;" appearance="standard">
                    <input type="number" placeholder="No of Packages" aria-label="Number" matInput
                           [(ngModel)]="package.packageNo" min="1"
                           (input)="eachPackageCharged(package.id)" (keypress)="validateInput1($event)">

                  </mat-form-field>
                </td>
                <td class="mx-w-150">

                  <mat-form-field class="py-0" style="width: 90%;" appearance="standard">
                    <input type="number" placeholder="package weight" aria-label="Number" matInput
                           [(ngModel)]="package.weight" min="1"
                           (input)="eachPackageCharged(package.id)" (keypress)="validateInput($event)">
                    <span matSuffix>{{ selectedWeight}}</span>
                  </mat-form-field>
                </td>
                <td class="mx-w-150">
                  <mat-form-field class="py-0" style="width: 90%;" appearance="standard">
                    <input type="text" placeholder="Volume Weight" matInput disabled
                           [(ngModel)]="package.totalvolumeweight_per_pack">
                  </mat-form-field>
                </td>
                <td class="mx-w-200">
                  <mat-form-field class="py-0" style="width: 90%;" appearance="standard">
                    <input placeholder="Total Package Weight" matInput disabled
                           [(ngModel)]="package.total_weight">
                    <span matSuffix>{{ selectedWeight}}</span>

                  </mat-form-field>
                </td>
                <td class="mx-w-200">
                  <mat-form-field class="py-0" style="width: 90%;" appearance="standard">
                    <input placeholder="Chargeable Weight" matInput disabled
                           [(ngModel)]="package.chargeable_weight">
                    <span matSuffix>{{ selectedWeight}}</span>

                  </mat-form-field>
                </td>
                <td class="mx-w-150">
                  <button  mat-icon-button color="warn" aria-label="Example icon button with a heart icon" [disabled]="deleteDisable || packages.length == 1" (click)="deletePackage(package.id)">
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </td>
              </tr>
              <tr>
                <td class="mx-w-150">
                </td>
                <td class="mx-w-150">
                </td>
                <th class="mx-w-150">
                  <mat-label for="totalWeight">
                    Total No. Packages
                  </mat-label>
                  <mat-form-field style="width: 90%;" appearance="standard">
                    <input id="total_no_packages" matInput disabled value="Total No. Packages"
                           [(ngModel)]="consignment_details.totalNoOfPackages">
                  </mat-form-field>
                </th>


                <td>

                </td>
                <th class="mx-w-150">
                  <mat-label for="totalWeight">
                    Total Volume Weight
                  </mat-label>
                  <mat-form-field style="width: 90%;" appearance="standard">
                    <input matInput disabled value="Total Volume Weight"
                           [(ngModel)]="totalvolume_weight">
                  </mat-form-field>
                </th>
                <th class="mx-w-150">
                  <mat-label for="totalWeight">
                    Total Weight
                  </mat-label>
                  <mat-form-field style="width: 90%;" appearance="standard">
                    <input id="totalWeight" matInput disabled value="Google"
                           [(ngModel)]="consignment_details.totalWeight">
                    <span matSuffix>{{ selectedWeight}}</span>

                  </mat-form-field>
                </th>
                <th class="mx-w-200">
                  <mat-label for="totalWeight">
                    Total Chargeable Weight
                  </mat-label>
                  <mat-form-field style="width: 90%;" appearance="standard">
                    <input id="total_charged" matInput disabled value="Google"
                           [(ngModel)]="consignment_details.totalChargableWeight">
                    <span matSuffix>{{ selectedWeight}}</span>

                  </mat-form-field>
                </th>
                <td class="mx-w-200">
                  <button class="btn btn-primary" (click)="addPackage()" [disabled]="deleteDisable">
                    <i class="fa fa-plus">
                    </i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <mat-divider></mat-divider>
        </div>

        <div class="row">
          <div class="col-md-3">

          </div>
        </div>


        <div class="row m-2">
          <div class="col-md-2 float-right m-auto">

            <button class="btn btn-primary btn-lg" (click)="createConsignment()">Find</button>
          </div>
        </div>
      </div>
      <div id="plancard" class="card bg-papayawhip p-4 hidden" *ngIf="isplans">
        <!-- <div id="plancard" class="card bg-papayawhip p-4 hidden"> -->

        <h3>
          Best Routes Options <span class="text-danger" style="font-size: 15px;"> * Excluding GST
						Prices</span>
        </h3>
        <!-- <div class="row">
          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>Sort By Flight Type</mat-label>
              <mat-select matNativeControl id="sort-by" [(ngModel)]="BestRouteFilters.FlightType"
                style="width: 150px;  ">
                <mat-option value="" selected disabled hidden>Sort By Flight Type</mat-option>
                <mat-option value=1>All Flights</mat-option>
                <mat-option value=2>Direct Flights</mat-option>
                <mat-option value=3>One Stop Flights</mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
        <!-- <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>Sort By Time</mat-label>
              <mat-select matNativeControl id="sort-by1" [(ngModel)]="BestRouteFilters.gap"
                style="width: 150px;  ">
                <span>Sort By Time</span>
                <mat-option value="" selected disabled hidden>Sort By Time</mat-option>
                <mat-option value=12>12 Hours</mat-option>
                <mat-option value=24>24 Hours</mat-option>
                <mat-option value=36>36 Hours</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>Sort By Airline</mat-label>
              <mat-select matNativeControl id="sort-by2" [(ngModel)]="BestRouteFilters.air"
                style="width: 150px;  ">
                <mat-option value="" selected disabled hidden>Sort By Airline</mat-option>
                <mat-option *ngFor="let cous of Airline" [value]="cous.id">{{cous.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>Sort By Price</mat-label>
              <mat-select matNativeControl id="sort-by3" [(ngModel)]="BestRouteFilters.sort"
                style="width: 150px;  ">
                <mat-option value="" selected disabled hidden>Sort By Price</mat-option>
                <mat-option value="Transit">Transit Time</mat-option>
                <mat-option value="Low">Low to High Price</mat-option>
                <mat-option value="High">High to Low Price</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-1 mt-3">
            <button class="btn btn-primary m-auto" (click)="find()">
              Find
              <i class="pe-7s-search">
              </i>
            </button>
          </div>
          <div class="col-md-2 mt-3">
            <button class="btn btn-primary m-auto" (click)="clear()">
              clear
            </button>
          </div>
        </div> -->
        <div class="row">
          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>Filter By Flights</mat-label>
              <mat-select matNativeControl id="sort-by" [(ngModel)]="BestRouteFilters.FlightType"
                          style="width: 150px;  ">
                <mat-option value="" selected disabled hidden>Sort By Flight Type</mat-option>
                <mat-option value=1>All Flights</mat-option>
                <mat-option value=2>Direct Flights</mat-option>
                <mat-option value=3>One Stop Flights</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>Filter By Airlines</mat-label>
              <mat-select matNativeControl id="sort-by2" [(ngModel)]="BestRouteFilters.air"
                          style="width: 150px;  ">
                <mat-option value=0>All</mat-option>
                <mat-option *ngFor="let cous of Airline" [value]="cous.id">{{cous.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>Filter By Time</mat-label>
              <mat-select matNativeControl id="sort-by1" [(ngModel)]="BestRouteFilters.gap"
                          style="width: 150px;  ">
                <span>Sort By Time</span>
                <mat-option value=12>12 Hours</mat-option>
                <mat-option value=24>24 Hours</mat-option>
                <mat-option value=36>36 Hours</mat-option>
                <mat-option value=60>60 Hours</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2 row">
            <mat-form-field appearance="standard">
              <mat-label>Sort By</mat-label>
              <mat-select matNativeControl id="sort-by3" [(ngModel)]="BestRouteFilters.sort"
                          style="width: 150px;  ">
                <mat-option value="" selected disabled hidden>Sort By Price</mat-option>
                <mat-option value="LTransit">Low-High Transit</mat-option>
                <mat-option value="HTransit">High-Low Transit</mat-option>
                <mat-option value="Low">Low to High Price</mat-option>
                <mat-option value="High">High to Low Price</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2 mt-3">
            <button  (click)="find()" class="form-control btn btn-primary "><i
              class="fa fa-search"></i> Find</button>
          </div>
          <div class="col-md-2 mt-3">
            <button  (click)="resetroutes()"
                    class="form-control btn btn-secondary">Reset</button>
          </div>
        </div>
        <!-- <mat-select id="sort-by" (change)="sort($event)" style="width: 200px;float: right;align-self: end;" class=" form-control">
          <mat-option value="" selected disabled hidden>Sort By</mat-option>
          <mat-option value="Transit">Transit Time</mat-option>
          <mat-option value="Low">Low to High Price</mat-option>
          <mat-option value="High">High to Low Price</mat-option>
        </mat-select> -->

        <!-- <div *ngFor="let plan of planList.bestRoutes">
          {{getTranisitTime(plan.totalTranistTime)}} - {{plan.totalCostIn}} INR
        </div> -->
        <!-- <div class="card m-2  ">
          <div class="container"> -->
        <div *ngIf="array.length!=0">
          <div class="row m-2" *ngFor="let plan of array ">
            <mat-expansion-panel class="col-md-12" style="width:95%" hideToggle>
              <mat-expansion-panel-header style="height:fit-content">
                <div *ngIf="plan.recommended==true"  class="ribbon right" style="--c: #CC333F;--f: 10px;"> Recommended</div>
                <div class="row p-2 w-100">
                  <div class="col-md-3">
                    <div class="widget-chart-content">
                      <div class="widget-subheading fw-bold">Origin Location</div>
                      <div class="widget-numbers text-primary fs-3"><span>{{planList.origin}}
												</span>
                      </div>
                      <div class="widget-description text-focus fw-bold">
                        <div matTooltip="Cargo Drop Time">
                          CDT:
                        </div>
                        <span class="text-info ps-1 ">
													<!-- <fa-icon [icon]="faAngleUp"></fa-icon> -->
													<span class="ps-1"> {{formatDate(plan.dropTime)}}</span>
												</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="widget-subheading fw-bold">Transit Time</div>
                    <div class="widget-numbers text-warning fs-3">
                      <span>{{getTranisitTime(plan.totalTranistTime)}} </span>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="widget-subheading fw-bold">Destination Location</div>
                    <div class="widget-numbers text-primary fs-3">
                      <span>{{planList.destination}}</span>
                    </div>
                    <div class="widget-description text-focus fw-bold">
                      <div matTooltip="Cargo Pickup Time">
                        CPT:
                      </div>
                      <span class="text-info ps-1 ">
												<!-- <fa-icon [icon]="faAngleUp"></fa-icon> -->
												<span class="ps-1">{{formatDate(plan.pickupTime)}}</span>
											</span>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="widget-subheading fw-bold">Total Cost :
                      <div style="float: right;">
                        <button *ngIf="plan.priceBreakdown.hikedAirlineTarrifAmount" style="color: white; background-color: red;">Premium</button>
                      </div>
                    </div>
                    <div *ngIf="(plan.priceBreakdown.discountedAirlineTarrifPrice==null || plan.priceBreakdown.discountedAirlineTarrifAmount==0) && (plan.priceBreakdown.hikedAirlineTarrifPrice==null || plan.priceBreakdown.hikedAirlineTarrifAmount==0)" class="widget-numbers fs-3">
                      <span style="color: green">{{ plan.totalCostIn }} INR</span>
                    </div>
                    <div *ngIf="plan.priceBreakdown.discountedAirlineTarrifPrice!=null &&  plan.priceBreakdown.discountedAirlineTarrifAmount" class="widget-numbers text-success fs-3">
                      <span class="original-price" *ngIf="plan.priceBreakdown.discountedAirlineTarrifAmount != 0">{{ plan.totalCostIn + plan.priceBreakdown.discountedAirlineTarrifAmount }} INR</span>
                      <span class="discounted-price">{{ plan.totalCostIn }} INR</span>
                    </div>
                    <div *ngIf="plan.priceBreakdown.hikedAirlineTarrifPrice!=null && plan.priceBreakdown.hikedAirlineTarrifAmount" class="widget-numbers text-success fs-3">
                      <span class="hiked-price">{{ plan.totalCostIn }} INR
                        <!-- <sup><i style="color: red; font-size: 1.1em" class="fa fa-caret-up" aria-hidden="true"></i></sup> -->
                      </span>
                    </div>
                    <!-- <div *ngIf="plan.priceBreakdown.hikedAirlineTarrifAmount" class="text-success ps-1"><strong>Premium</strong></div> -->
                    <div class="widget-description text-focus mt-4 fw-bold">
                      <div>
                      </div>
                      <span class="text-danger ps-1 ">
												<i class="text-secondary fa fa-plane"></i>
												<span class="ps-1">{{plan.routes[0].airlineID.name}}</span>
											</span>
                    </div>


                  </div>
                  <div class="col-md-1 center">
                    <div class="">
                      <button *ngIf="clicked == false" class="btn btn-success my-auto" mat-button
                              matStepperNext (click)="selectedPlan(plan); ">
                        Select
                      </button>
                      <button *ngIf="clicked == true" class="btn btn-success my-auto" mat-button
                              matStepperNext (click)="selectedPlanChange(plan); ">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel-header>
              <mat-divider></mat-divider>
              <ng-template matExpansionPanelContent class="row m-1">
                <div class="table-responsive">
                  <table class="table w-100">
                    <thead>
                    <tr>
                      <th>
                        Origin
                      </th>
                      <th>
                        Route
                      </th>
                      <th>
                        Airlines
                      </th>
                      <th>
                        Flight No.
                      </th>
                      <th>
                        Departure Time
                      </th>
                      <th>
                        Transit Time
                      </th>
                      <th>
                        Arrival TIme
                      </th>
                      <th>
                        Destination
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let route of plan.routes">
                      <td>
                        {{route.departureAirportID.iataCode}}
                      <td>
                        {{route.transitMode}}
                      </td>
                      <td>
                        <div *ngIf="route.airlineID">
                          {{route.airlineID.name}}
                        </div>
                        <ng-template>

                          <div class="text-center">
                            -
                          </div>
                        </ng-template>
                      </td>
                      <td>
                        <div *ngIf="route.flightNo != null">
                          {{route.flightNo}}
                        </div>
                        <ng-template>
                          <div class="text-center">
                            -
                          </div>
                        </ng-template>
                      </td>
                      <td>
                        {{formatDate(route.departureTime)}}
                      </td>
                      <td>
                        {{getTranisitTime(route.transitTime)}}
                      </td>
                      <td>
                        {{formatDate(route.arrivalTime)}}
                      </td>
                      <td>
                        {{route.arrivalAirportID.iataCode}}
                      </td>
                    </tr>
                    <!-- <tr>
                      <td>
                        BLR
                      <td>
                        Flight
                      </td>
                      <td>
                        Indigo
                      </td>
                      <td>
                        FK7841
                      </td>
                      <td>
                        10:00 PM
                      </td>
                      <td>
                        4 hrs
                      </td>
                      <td>
                        12:30 AM
                      </td>
                      <td>
                        DEL
                      </td>
                    </tr> -->
                    </tbody>
                  </table>

                  <mat-divider></mat-divider>
                  <table class="table w-100">
                    <tbody>
                    <tr>
                      <th>
                        Airline Tarrif
                      </th>
                      <td>
                        {{RoundOffCharge(plan.priceBreakdown.airlineTarrifCharge)}}
                      </td>
                      <th>
                        Other Charges
                      </th>
                      <td>
                        {{RoundOffCharge(plan.priceBreakdown.otherCharges)}}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        AHC Outbound
                      </th>
                      <td>
                        {{RoundOffCharge(plan.priceBreakdown.ahcOutboundCharge)}}
                        <!-- {{plan.priceBreakdown.ahcOutboundCharge}} -->
                      </td>
                      <th>
                        AHC Inbound
                      </th>
                      <td>
                        {{RoundOffCharge(plan.priceBreakdown.ahcInboundCharge)}}
                        <!-- {{plan.priceBreakdown.ahcInboundCharge}} -->
                      </td>
                    </tr>
                    <tr>
                      <th>
                        MAWB Fee
                      </th>
                      <td>
                        {{RoundOffCharge(plan.priceBreakdown.mawbFeeCharge)}}
                      </td>
                      <th>
                        ICLSCM Handling Charges
                      </th>
                      <td>
                        {{RoundOffCharge(plan.priceBreakdown.iclscmHandlingCharge)}}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Airline Surcharge
                      </th>
                      <td>
                        {{RoundOffCharge(plan.priceBreakdown.airlineSurcharge)}}
                      </td>
                      <th>
                        Additional Surcharge
                      </th>
                      <td>
                        {{RoundOffCharge(plan.priceBreakdown.additionalSurcharge)}}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Door Pickup
                      </th>
                      <td>
                        {{RoundOffCharge(plan.priceBreakdown.doorPickupCharge)}}
                      </td>
                      <th>
                        Door Delivery
                      </th>
                      <td>
                        {{RoundOffCharge(plan.priceBreakdown.doorDeliveryCharge)}}
                      </td>
                    </tr>
                    <!-- <tr>
                      <th>
                        Demurge Charges
                      </th>
                      <td>
                        0.0
                      </td>
                      <th>
                        Warehousing Charges
                      </th>
                      <td>
                        0.0
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Value
                      </th>
                      <td>
                        0.0
                      </td>
                      <th>
                        Warehousing Charges
                      </th>
                      <td>
                        0.0
                      </td>
                    </tr> -->
                    </tbody>
                  </table>
                </div>
              </ng-template>
            </mat-expansion-panel>
          </div>
        </div>
        <div *ngIf="array.length==0">
          <h1 class="text-center fw-bold">No Plans Available..!</h1>
        </div>

        <!-- <div class="card m-2  ">
          <div class="container">
            <div class="row">
              <div class="col-md-3">
                <div class="widget-chart-content">
                  <div class="widget-subheading fw-bold">Origin Location</div>
                  <div class="widget-numbers text-success fs-3"><span>HYD</span></div>
                  <div class="widget-description text-focus fw-bold">
                    Drop Time:
                    <span class="text-info ps-1 ">
                      <span class="ps-1">05:30 PM</span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="widget-subheading fw-bold">Transit Time</div>
                <div class="widget-numbers text-primary fs-3"><span>11 Hrs</span></div>
              </div>
              <div class="col-md-2">
                <div class="widget-subheading fw-bold">Destiantion Location</div>
                <div class="widget-numbers text-success fs-3"><span>DEL</span></div>
                <div class="widget-description text-focus fw-bold">
                  Pickup Time:
                  <span class="text-info ps-1 ">
                    <span class="ps-1">04:30 PM</span>
                  </span>
                </div>
              </div>
              <div class="col-md-2">
                <div class="widget-subheading fw-bold">Total Cost :</div>
                <div class="widget-numbers text-danger fs-3"><span>
                    1000 RS</span></div>

              </div>
              <div class="col-md-2">
                <button class="btn btn-success m-auto">
                  Select
                </button>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <ngx-loading [show]="loading"
                   [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
                   [template]="loadingTemplate"></ngx-loading>

    </mat-step>
    <mat-step label="Shipper & Receiver" [editable]="editable">
      <div class="container ">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-4">
                <h3>Shipper</h3>
              </div>
              <!-- <div class="col-md-5 mt-2 fw-bold text-danger">
                <p class="float-end" *ngIf="!errorAddress">Select Shipper Address</p>
              </div> -->
            </div>
            <div class="row">
              <div class="col-md-4">
                <mat-form-field appearance="standard">
                  <mat-label>
                    Customer
                  </mat-label>
                  <mat-select matNativeControl  (blur)="change_business()"
                              [(ngModel)]="val" disabled>
                    <mat-option value="1">
                      Corporate User
                    </mat-option>
                    <mat-option value="2">
                      Retail User
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="indiviudal_user.customerType == 2; else notbusinessuser">
              <div class="h4">
                Corporate Customer Details <span class="text-danger">*</span>
              </div>
              <div class="row">
                <div class="col-md-3 fw-bold">
                  Company Name
                </div>
                <div class="col-md-3">
                  {{customer_shipper.firstName}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 fw-bold">
                  E-mail
                </div>
                <div class="col md-3">
                  {{customer_shipper.email_1}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 fw-bold">
                  Mobile No.
                </div>
                <div class="col-md-3">
                  {{customer_shipper.phone_1}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 fw-bold">
                  Address
                </div>
                <div class="col-md-9">
                  {{customer_shipper.address_1}} , {{customer_shipper.zip_code}}
                  <br />
                  <div *ngIf="customer_shipper.city_1!= null">
                    <div *ngIf="customer_shipper.city_1.id != null">
                      {{customer_shipper.city_1.name}}, {{customer_shipper.city_1.state_id.name}},
                      {{customer_shipper.city_1.state_id.country.name}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 fw-bold">
                  GST No
                </div>
                <div class="col-md-3">
                  {{customer_shipper.gst}}
                </div>
              </div>
              <mat-divider>

              </mat-divider>
              <div class="h4">
                Corporate Representative Details <span class="text-danger">*</span>
              </div>

              <div class="row">
                <div class="col-md-3 fw-bold">
                  Name
                </div>
                <div class="col-md-9">
                  {{indiviudal_user.firstName}} {{indiviudal_user.middleName}}
                  {{indiviudal_user.lastName}}

                </div>
              </div>
              <div class="row">
                <div class="col-md-3 fw-bold">
                  E-mail
                </div>
                <div class="col-md-6">
                  {{indiviudal_user.email}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-3  fw-bold">
                  Mobile No.
                </div>
                <div class="col-md-3">
                  {{ indiviudal_user.phone}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 fw-bold">
                  Whatsapp No.
                </div>
                <div class="col-md-3">
                  {{indiviudal_user.whatsapp}}
                </div>
              </div>
              <div class="h4">
                Choose An Address <span class="text-danger me-4">*</span>
                <button class="btn btn-primary m-1" (click)="open(createAddress);corporefresh();">
                  Add Address<i class="fa fa-fw">
                </i>
                </button>
                <button class="btn btn-secondary m-1" matTooltip="Refresh"
                        (click)="getAddressList(consignment_details.senderIndividual)">
                  Refresh
                  <i class="pe-7s-refresh-2 btn-icon-wrapper"> </i>
                </button>
                <div class="text-danger" *ngIf="!findExisting()">
                  Pickup city not available in Address list
                </div>
              </div>
              <div style="max-height: 200px; overflow: auto;">
                <div *ngIf="reprsentativeAddress!= null">
                  <div *ngFor="let add of reprsentativeAddress " class="flex-row ">
                    <div class="widget-content p-0">
                      <div class="border-bottom border-bottom-3 border-top row"
                           [ngStyle]="{'background-color': (add.id==selectedAddress) ? '#abd8ff' : '#ffffff'}">
                        <div class="col-md-4 fw-bold opacity-7">
                          {{add.address}}
                          <!-- {{consignment_details.originLocation?.city?.id}} == {{add.cityId?.id}} -->
                        </div>
                        <div class="col-md-3 ">
                          <div class="widget-heading text-dark opacity-7">
                            {{add.pincodeId?.pinNumber}}
                          </div>
                          <div class="widget-subheading opacity-10">
                            {{add.pincodeId?.cityId?.name}}
                          </div>
                        </div>
                        <div class="col-md-3 ">
                          <div class="widget-heading text-dark opacity-7">
                            {{add.pincodeId?.cityId?.state_id.name}}
                          </div>
                          <div class="widget-subheading opacity-10 ps-2">
                            {{add.pincodeId?.cityId?.state_id.country.name}}
                          </div>
                        </div>
                        <div class="col-md-1">
                          <div *ngIf="validatepin?.OriginpincodeId == add.pincodeId?.id">

                            <div class="md-2 me-2 btn btn-primary"
                                 (click)="selectAddress(add.id);">
                              Select</div>

                          </div>
                        </div>

                      </div>
                    </div>
                    <!-- <div class="flex-column">
                    {{add.address}}, {{add.pincodeId?.pin_number}}
                  </div> -->
                    <!-- <div class="flex-column">
                      {{add.cityId?.name}}, {{add.cityId?.state_id.name}},
                      {{add.cityId?.state_id.country.name}}
                  </div> -->
                  </div>

                </div>
              </div>
<!--              <div class="row">-->
<!--                <div class="col-md-3 fw-bold">-->
<!--                  <div *ngIf="indiviudal_user.varification_id_type == 1">-->
<!--                    AADHAR-->
<!--                  </div>-->
<!--                  <div *ngIf="indiviudal_user.varification_id_type == 2">-->
<!--                    PAN-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-md-3">-->
<!--                  {{indiviudal_user.verification_id}}-->
<!--                </div>-->
<!--              </div>-->
            </div>
            <ng-template #notbusinessuser>
              <div class="row">
                <div class="h4">
                  Individual Details <span class="text-danger">*</span>
                </div>
                <div class="col-md-3 fw-bold">
                  Name
                </div>
                <div class="col-md-9">
                  {{indiviudal_user.firstName}} {{indiviudal_user.middleName}}
                  {{indiviudal_user.lastName}}

                </div>
              </div>
              <div class="row">
                <div class="col-md-3 fw-bold">
                  E-mail
                </div>
                <div class="col-md-6">
                  {{indiviudal_user.email}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-3  fw-bold">
                  Mobile No.
                </div>
                <div class="col-md-3">
                  {{ indiviudal_user.phone}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 fw-bold">
                  Whatsapp No.
                </div>
                <div class="col-md-3">
                  {{indiviudal_user.whatsapp}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 fw-bold">
                  Address
                </div>
                <div class="col-md-9">
                  {{indiviudal_user.billingAddress?.address}}, {{indiviudal_user.billingAddress?.pincode?.pin_number}}
                  <br />
<!--                  <div *ngIf="indiviudal_user.city!= null">-->
<!--                    <div *ngIf="indiviudal_user.city.name!= null">-->
<!--                      {{indiviudal_user.city.name}}, {{indiviudal_user.city.state_id.name}},-->
<!--                      {{indiviudal_user.city.state_id.country.name}}-->
<!--                    </div>-->
<!--                  </div>-->
                </div>
                <div class="h4">
                  Choose An Address <span class="text-danger me-4">*</span>
                  <button class="btn btn-primary m-1"
                          (click)="open(createAddress);individualrefresh();">
                    Add Address<i class="fa fa-fw">
                  </i>
                  </button>
                  <!-- <button class="m-auto btn-icon btn-icon-only btn mb-1"
                    (click)="getAddressList(consignment_details.senderIndividual)">
                    <i class="pe-7s-refresh-2 btn-icon-wrapper"> </i></button> -->

                  <button class="btn btn-secondary m-1" matTooltip="Refresh"
                          (click)="getAddressList(indiviudal_user.id)">
                    Refresh
                    <i class="pe-7s-refresh-2 btn-icon-wrapper"> </i>
                  </button>
                  <div class="text-danger" *ngIf="!findExisting()">
                    Pickup city not available in Address list
                  </div>
                </div>
                <div style="max-height: 200px; overflow: auto;">
                  <div *ngIf="reprsentativeAddress!= null">
                    <div *ngFor="let add of reprsentativeAddress " class="flex-row ">
                      <div class="widget-content p-0">
                        <div class="border-bottom border-bottom-3 border-top row"
                             [ngStyle]="{'background-color': (add.id==selectedAddress) ? '#abd8ff' : '#ffffff'}">
                          <div class="col-md-4 fw-bold opacity-7">
                            {{add.address}}
                            <!-- {{consignment_details.originLocation?.city?.id}} == {{add.cityId?.id}} -->
                          </div>
                          <div class="col-md-3 ">
                            <div class="widget-heading text-dark opacity-7">
                              {{add.pincodeId?.pin_number}}
                            </div>
                            <div class="widget-subheading opacity-10">
                              {{add.pincodeId?.cityId?.name}}
                            </div>
                          </div>
                          <div class="col-md-3 ">
                            <div class="widget-heading text-dark opacity-7">
                              {{add.pincodeId?.cityId?.state_id.name}}
                            </div>
                            <div class="widget-subheading opacity-10 ps-2">
                              {{add.pincodeId?.cityId?.state_id.country.name}}
                            </div>
                          </div>
                          <div class="col-md-1">
                            <div *ngIf="validatepin?.OriginpincodeId == add.pincodeId?.id">
                              <div class="md-2 me-2 btn btn-primary"
                                   (click)="selectAddress(add.id)">
                                Select</div>

                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="flex-column">
                      {{add.address}}, {{add.pincodeId?.pin_number}}
                    </div> -->
                      <!-- <div class="flex-column">
                        {{add.cityId?.name}}, {{add.cityId?.state_id.name}},
                        {{add.cityId?.state_id.country.name}}
                    </div> -->
                    </div>

                  </div>
                </div>
              </div>
<!--              <div class="row">-->
<!--                <div class="col-md-3 fw-bold">-->
<!--                  <div *ngIf="indiviudal_user.varification_id_type == 1">-->
<!--                    AADHAR-->
<!--                  </div>-->
<!--                  <div *ngIf="indiviudal_user.varification_id_type == 2">-->
<!--                    PAN-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-md-3">-->
<!--                  {{indiviudal_user.verification_id}}-->
<!--                </div>-->
<!--              </div>-->
            </ng-template>

            <!-- <div class="row">
              <div class="col-md-4">
                <mat-form-field appearance="standard">

                  <mat-label>
                    Credit Period
                  </mat-label>
                  <input type="text" placeholder="Credit Period" aria-label="Number" matInput [disabled]="validShipper">
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="standard">

                  <mat-label>
                    Credit Value
                  </mat-label>
                  <input type="text" placeholder="Credit Value" aria-label="Number" matInput [disabled]="validShipper">
                </mat-form-field>
              </div>
            </div> -->
            <div class="row m-4">

            </div>
            <!-- <span class="border-end  border-dark"></span> -->
          </div>
          <div class="col-md-6 border-start  border-dark">
            <div class="row">
              <div class="col-md-4">
                <h3>Receiver <span class="text-danger">*</span></h3>
              </div>
              <div class="col-md-4 fw-bold text-danger">
                <p class="float-end mt-2" *ngIf="!errorReceiver">Enter Receiver Details.</p>
              </div>
            </div>

            <!-- <div class="row">
              <div class="col-md-4">
                <mat-form-field appearance="standard">
                  <mat-label>
                    Customer Id
                  </mat-label>
                  <input aria-label="Number" matInput [(ngModel)]="receiver.id">
                </mat-form-field>
              </div>
            </div> -->
            <div class="row">
              <div class="col-md-4">
                <mat-form-field appearance="standard">
                  <mat-label>
                    First Name
                  </mat-label>
                  <input type="text" placeholder="First Name" aria-label="Number" matInput
                         [(ngModel)]="receiver.firstName">
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="standard">
                  <mat-label>
                    Last Name
                  </mat-label>
                  <input type="text" placeholder="Last Name" aria-label="Number" matInput
                         [(ngModel)]="receiver.lastName">
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="standard">
                  <mat-label>
                    Email
                  </mat-label>
                  <input type="text" placeholder="Email" aria-label="Number" matInput
                         [(ngModel)]="receiver.email1" [formControl]="emailFormControl">
                  <!-- <mat-hint class="text-danger fw-normal" *ngIf="emailFormControl.hasError('required')">Email is required</mat-hint> -->
                  <mat-hint class="text-danger fw-normal"
                            *ngIf="emailFormControl.hasError('pattern')">Invalid email address</mat-hint>
                </mat-form-field>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <mat-form-field appearance="standard">
                    <mat-label>
                      Phone No.
                    </mat-label>
                    <input type="text" placeholder="Phone No."
                           oninput="if(!this.value.match('^[0-9]*$'))this.value='';"
                           aria-label="Number" matInput #phone1 maxlength="10"
                           [(ngModel)]="receiver.phone1">
                    <mat-hint align="end">{{phone1.value.length}}/10
                    </mat-hint>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-4">
                <mat-form-field class="example-full-width" appearance="standard">
                  <mat-label>Address</mat-label>
                  <textarea matInput placeholder="" [(ngModel)]="receiver.address"></textarea>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="standard">
                  <mat-label>
                    City
                  </mat-label>
                  <mat-select disabled  (blur)="getReceverPincodes()"
                              [(ngModel)]="receiver.city">
                    <mat-option *ngFor="let cit of cities |async" [value]="cit.id">
                      {{cit.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="standard">
                  <mat-label>
                    Zip Code
                  </mat-label>
                  <mat-select matNativeControl  [(ngModel)]="receiver.zipCode">
                    <mat-option selected>SELECT</mat-option>
                    <mat-option *ngFor="let cit of pincodes |async" [value]="cit.pinNumber">
                      {{cit.pinNumber}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row m-4">
              <div class="col-md-2 float-right m-auto">

								<!-- <button *ngIf="buttonactive==false" class="btn btn-primary btn-lg"
									(click)="createReceiver()">Save Details</button> -->

									<button *ngIf="!clicked" class="btn btn-primary btn-lg" (click)="saveReceiver()">Save
										Details</button>
									<button *ngIf="clicked" class="btn btn-primary btn-lg" (click)="updateReceiver()">Update
										Details</button>

								<!-- <div class="col-md-2 float-right m-auto"
									*ngIf="selectedAddress!=null && this.receiver_with_id?.id!=undefined">
									<button *ngIf="buttonactive==true" (click)='goToUpload()'
										class="btn btn-primary btn-lg float-end" mat-button matStepperNext>Next</button>

                </div> -->
              </div>

            </div>
            <div class="">
              <button class="btn btn-primary float-end" mat-button matStepperNext (click)="goToUpload()"
                      *ngIf="selectedAddress!=null && this.receiver_with_id?.id!=undefined">Next</button>
              <button class="btn btn-primary" mat-button matStepperPrevious>Back</button>
            </div>
            <!-- <div class="row ">
              <div class="col-md-2 float-right m-auto"
                  *ngIf="selectedAddress!=null && this.receiver_with_id?.id!=undefined">
                  <button *ngIf="buttonactive==true" (click)='goToUpload()'
                    class="btn btn-primary btn-lg float-end" mat-button matStepperNext>Next</button>
                </div>
              <div class="col-md-1">
                <button class="btn btn-primary" mat-button matStepperPrevious>Back</button>
              </div>
            </div> -->
            <!-- <div *ngIf="selectedAddress!=null && this.receiver_with_id?.id!=undefined"
              class="d-flex flex-row-reverse">
              <button class="btn btn-primary btn-lg" mat-button matStepperNext
                (click)='goToUpload()'>Next</button> -->
            <!-- </div> -->
            <!-- </div> -->
            <!-- <span class="border-end  border-dark"></span> -->
          </div>
        </div>



			</div>
			<ngx-loading [show]="loadingSecound"
				[config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
				[template]="loadingTemplate"></ngx-loading>
		</mat-step>
		<mat-step label="Upload Documents" [editable]="editable">
			<div class="container ">
<!--				<div class="row">-->
<!--					<div class="col-md-2">-->
<!--						<mat-form-field appearance="standard">-->
<!--							<mat-label for="consignmentType">-->
<!--								Consignment Type-->
<!--							</mat-label>-->
<!--							<mat-select id="consignmentType" [(ngModel)]="consignment_details.consignmentType">-->
<!--								<mat-option (click)="selectConsignmentType(option)" *ngFor="let option of consignmentTypes" [value]="option.id">-->
<!--									{{option.name}}-->
<!--								</mat-option>-->
<!--							</mat-select>-->
<!--&lt;!&ndash;              <mat-select [(ngModel)]="consignment_details.consignmentType"&ndash;&gt;-->
<!--&lt;!&ndash;                          (blur)="getConsignmentSubtype()">&ndash;&gt;-->
<!--&lt;!&ndash;                <mat-option *ngFor="let option of consignmentTypes | async" [value]="option.id">&ndash;&gt;-->
<!--&lt;!&ndash;                  {{option.name}}&ndash;&gt;-->
<!--&lt;!&ndash;                </mat-option>&ndash;&gt;-->
<!--&lt;!&ndash;              </mat-select>&ndash;&gt;-->
<!--						</mat-form-field>-->
<!--					</div>-->
<!--					<div class="col-md-2">-->
<!--						<mat-form-field appearance="standard">-->
<!--							<mat-label>-->
<!--								Consignment Subtype-->
<!--							</mat-label>-->
<!--							<mat-select  [(ngModel)]="consignment_details.consignmentSubType"-->
<!--								disabled>-->
<!--								<mat-option *ngFor="let sub_type of consignmentSubTypes " [value]="sub_type.id">-->
<!--									{{sub_type.name}}-->
<!--								</mat-option>-->
<!--							</mat-select>-->
<!--						</mat-form-field>-->
<!--					</div>-->
<!--				</div>-->

<div class="row">
	<div class="row p-1" *ngFor="let doc of requiredDocument ">


		<div class="col-md-2">
			{{doc.name}}
		</div>

		<div class="col-md-2 center">
			<mat-checkbox class="example-margin" [(ngModel)]="doc.upload_later">Has & Upload Later
			</mat-checkbox>
		</div>

		<div class="col-md-6 center">
			<input class="form-control" [disabled]="doc.upload_later" type="file"
				(change)="getFiles($event,doc.id)">

		</div>

	  <div class="col-md-2 float-right m-auto">
      <button style="margin-right: 10px;" class="btn btn-primary btn-lg" [disabled]="doc.upload_later || doc.uploaded" (click)="uploadDocument(doc)">Upload</button>
		<button style="margin-right: 10px;" class="btn btn-success btn-lg" matTooltip="Download" *ngIf="doc.id !== 3" (click)="downloadDocuments(doc.id)">
			<i class="pe-7s-download"></i>
		</button>


	</div>

	</div>
</div>

        <div class="">
          <button class="btn btn-primary float-end" mat-button matStepperNext
                  (click)="getTotalcost()">Next</button>
          <button class="btn btn-primary" mat-button matStepperPrevious>Back</button>
        </div>

      </div>
      <ngx-loading [show]="loadingThird"
                   [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
                   [template]="loadingTemplate"></ngx-loading>
    </mat-step>
    <mat-step label="Payment" [editable]="editable">
      <div class="container">
        <!-- <div *ngIf="consignment_with_id" class="row">
          <h4>
            Consignment Id : {{consignment_with_id.data[0].consignmentNo}}
          </h4>
        </div> -->
        <div class="row">
          <div class="col-md-3 center">
            Total Consignment Value<span class="text-danger me-auto">*</span>
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="standard">
              <input type="text" placeholder="Total Consignment Value" (input)="getTotalcost()"
                     [(ngModel)]="consignment_details.totalConsignmentValue"
                     oninput="if(!this.value.match('^[0-9]*$'))this.value='';" maxlength="10" aria-label="Number" matInput>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 center mt-3">

            Opt for Insurance?
          </div>
          <div class="col-md-2 mt-3">
            <mat-slide-toggle (blur)="isInsuredConisgnment()"></mat-slide-toggle>
          </div>
          <div class="col-md-7 text-danger mt-3 fw-bold" *ngIf="!consignment_details.isInsured"
               style="font-style: italic;">
						<span>
							In case insurance is not selected our liability is limited to freight
							charges subject to a maximum of Rs.5000. We recommend insurance for your shipment.
						</span>
          </div>
        </div>
        <div *ngIf="consignment_details.isInsured" class="row">

          <div class="col-md-3 center">
            Insurance Cost
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="standard">
              <input type="text" placeholder="Insurence Coverage" disabled
                     [(ngModel)]="consignment_details.insuranceCoverage" aria-label="Number" matInput>
            </mat-form-field>

          </div>
        </div>
        <div class="row">
          <div class="col-md-3 center">
            Shipping Cost
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="standard">
              <input type="text" disabled placeholder="Shipping Cost" aria-label="Number"
                     [(ngModel)]="consignment_details.shippingCost" matInput>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 center">
            Tax (18%)
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="standard">
              <input type="text" disabled placeholder="Tax" aria-label="Number"
                     [(ngModel)]="consignmentTax" matInput>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 center">
            Total Shipping Cost
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="standard">
              <input type="text" disabled placeholder="Total Shipping Cost"
                     [(ngModel)]="consignment_details.totalShippingCost" aria-label="Number" matInput>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 center">
            Payment Mode<span class="text-danger me-auto">*</span>
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="standard">
              <mat-select  [(ngModel)]="consignment_details.paymentMode">
                <mat-option selected disabled [value]="0">
                  Select
                </mat-option>
                <mat-option *ngIf="val == 2" [value]="1">
                  Cash
                </mat-option>
                <mat-option *ngIf="val == 3" [value]="3">
                  Credit/Debit Card
                </mat-option>
                <mat-option *ngIf="val == 1" selected [value]="2">
                  Credit
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-7 text-danger mt-3 fw-bold" style="font-style: italic;">
						<span>
							In case of cash bookings, should there be any increase in chargeable weight,
							shipment will be delivered on payment of balance freight. Contract customers to make payment
							along with freight bill.
						</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div *ngIf="paymentdone == false">
              <div *ngIf="val == 2" class="col-md-10 m-auto p-4">

                <button class="btn btn-primary btn-lg w-50 float-right" style="float: right;"
                        (click)="createPayment()">Pay now</button>
              </div>
              <div *ngIf="val == 1" class="col-md-10 m-auto p-4">
                <button class="btn btn-primary btn-lg w-50 float-right" style="float: right;"
                        (click)="createPayment()">Confirm Booking</button>
              </div>

						</div>
						<!-- <div class="col-md-10 m-auto p-4" *ngIf="paymentdone == true">
							<button class="btn btn-primary btn-lg w-50 float-right" style="float: right;"
								(click)="getRecord()">Download
								Report</button>
						</div> -->
					</div>
					<div class=" float-end">
						<div class="flex-row">
							<!-- <div class="flex-column float-end">
								<button class="btn btn-primary btn-lg" (click)="myFunction() ; stepper.reset()">Create
									New</button>
							</div> -->
						</div>
						<div class="">
							<button class="btn btn-primary" mat-button matStepperPrevious>Back</button>
						</div>

          </div>
          <ngx-loading [show]="loading"
                       [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
                       [template]="loadingTemplate"></ngx-loading>
        </div>
      </div>
    </mat-step>
    <ngx-loading [show]="loadingForth"
                 [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
                 [template]="loadingTemplate"></ngx-loading>
  </mat-stepper>
</div>
