<div class="col-md-20">
	<container-element [ngSwitch]="pageview">
		<some-element *ngSwitchCase="'table'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						<b style="color: blue;">PINCODES</b>
					</div>
					<div class="btn-actions-pane-right p-2" *ngIf="!displaytoAccountant">
						<div class="mb-2 me-2 btn btn-primary" (click)="viewchange('add', 'add')">Add</div>
					</div>
				</div>
				<div class="row p-4">
					<div class="col-md-2 row">
						<mat-form-field appearance="standard">
							<mat-label>State Name</mat-label>
							<mat-select matNativeControl [(ngModel)]="stateid" (blur)="getIndividaulCities()">
								<mat-option value="">
									All
								</mat-option>
								<mat-option *ngFor="let sta of state" [value]="sta.id">
									{{sta.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-2 row">
						<mat-form-field appearance="standard">
							<mat-label>City Name</mat-label>
							<mat-select matNativeControl [(ngModel)]="cityid">
								<mat-option value="">
									All
								</mat-option>
								<mat-option *ngFor="let cit of city" [value]="cit.id">
									{{cit.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-2 row">
						<mat-form-field appearance="standard">
							<mat-label>IB</mat-label>
							<mat-select matNativeControl [(ngModel)]="ib">
								<mat-option value="">
									All
								</mat-option>
								<mat-option value="1">
									Active
								</mat-option>
								<mat-option value="0">
									In Active
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-2 row">
						<mat-form-field appearance="standard">
							<mat-label>OB</mat-label>
							<mat-select matNativeControl [(ngModel)]="ob">
								<mat-option value="">
									All
								</mat-option>
								<mat-option value="1">
									Active
								</mat-option>
								<mat-option value="0">
									In Active
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-2 row">
						<mat-form-field appearance="standard">
							<mat-label>OOA</mat-label>
							<mat-select matNativeControl [(ngModel)]="ooa">
								<mat-option value="">
									All
								</mat-option>
								<mat-option value="1">
									Active
								</mat-option>
								<mat-option value="0">
									In Active
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-1 mt-3">
						<button class="btn btn-primary" (click)="tablefilter()">Find</button>
					</div>
					<div class="col-md-1 mt-3">
						<button class="btn btn-secondary" (click)="reset()">Reset</button>
					</div>
					<div class="col-md-1 row">
						<button class="btn btn-success m-auto mt-3" (click)="exportToCSV()">
							Report
						</button>
					</div>
					<div class="col-md-2 row">
						<mat-form-field appearance="standard">
							<mat-label>Pincode</mat-label>
							<input matInput (keyup)="applyFilter($event)" placeholder="Pincode"
								onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="6" #input>
						</mat-form-field>
					</div>
				</div>

				<!-- <div class="container">
					<div class="row">
						<div class="">
							<div class="table-responsive">
								<table class="align-middle text-truncate mb-0 table table-borderless table-hover">
									<thead>
										<tr>
											<th class="text-center">Pincode Number</th>
											<th class="text-center">City</th>
											<th class="text-center">State</th>
											<th class="text-center">Country</th>
											<th class="text-center">In Bound</th>
											<th class="text-center">Out Bound</th>
											<th class="text-center">OOA</th>
											<th class="text-center">Action</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let con of PincodesList"
											[ngClass]="con.ooa == false && con.ib == false && con.ob == false? 'red':'white'">
											<td class="text-center">
												{{con.pin_number}}
											</td>
											<td class="text-center">
												{{con.cityId.name}}
											</td>
											<td class="text-center">
												{{con.cityId?.state_id?.name}}
											</td>

											<td class="text-center">
												{{con.cityId?.state_id?.country?.name}}
											</td>
											<td class="text-center" *ngIf="con.ib==true">
												{{"Active"}}
											</td>
											<td class="text-center" *ngIf="con.ib==false">
												{{"In Active"}}
											</td>
											<td class="text-center" *ngIf="con.ob==true">
												{{"Active"}}
											</td>
											<td class="text-center" *ngIf="con.ob==false">
												{{"In Active"}}
											</td>
											<td class="text-center" *ngIf="con.ooa==true">
												{{"Active"}}
											</td>
											<td class="text-center" *ngIf="con.ooa==false">
												{{"In Active"}}
											</td>
											<td class="text-center">
												<div role="group" class="btn-group-sm btn-group m-1">
													<button class="btn-shadow btn btn-primary" matTooltip="Edit"
														*ngIf="con.is_Active==true" (click)="viewchange1('edit',con)"><i
															class="lnr-pencil"></i></button>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
								<mat-paginator (page)="handlePageEvent($event)" [length]="PincodesList?.totalElements"
									[pageSize]="PincodesList?.numberOfElements"
									[showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions"
									[pageIndex]="PincodesList?.number" aria-label="Select page">
								</mat-paginator>
							</div>
						</div>
					</div>
				</div> -->
				<!-- <div class="table-responsive"> -->
				<div class="table-responsive">
					<div *ngIf="!loading" style="overflow-x: auto">
						<ng-container *ngIf="dataSource?.data?.length > 0; else noRecordsMessage">
							<table class="w-100" mat-table [dataSource]="dataSource" matSort
								(matSortChange)="announceSortChange($event)">
								<!-- Position Column -->
								<ng-container matColumnDef="pincode">
									<th class="text-center" mat-header-cell *matHeaderCellDef
										sortActionDescription="Sort by pincode" style="width: 16%;">Pincode</th>
									<td class="text-center" mat-cell *matCellDef="let element">{{element.pinNumber}}
									</td>
								</ng-container>
								<ng-container matColumnDef="city">
									<th class="text-center" mat-header-cell *matHeaderCellDef
										sortActionDescription="Sort by city">City </th>
									<td class="text-center" mat-cell *matCellDef="let element"> {{element.cityId.name}}
									</td>
								</ng-container>
								<ng-container matColumnDef="state">
									<th class="text-center" mat-header-cell *matHeaderCellDef
										sortActionDescription="Sort by state">State</th>
									<td class="text-center" mat-cell *matCellDef="let element">
										{{element?.cityId?.state_id?.name}} </td>
								</ng-container>

								<ng-container matColumnDef="country">
									<th class="text-center" mat-header-cell *matHeaderCellDef
										sortActionDescription="Sort by country">
										Country</th>
									<td class="text-center" mat-cell *matCellDef="let element">
										{{element?.cityId?.state_id?.country?.name}}</td>
								</ng-container>

								<ng-container matColumnDef="InBound">
									<th class="text-center" mat-header-cell *matHeaderCellDef
										sortActionDescription="Sort by pincode">In Bound</th>
									<td class="text-center" mat-cell *matCellDef="let element">

										<div *ngIf="element.ib== true">Active</div>
										<div *ngIf="element.ib== false">InActive</div>
									</td>
								</ng-container>
								<ng-container matColumnDef="OutBound">
									<th class="text-center" mat-header-cell *matHeaderCellDef
										sortActionDescription="Sort by pincode">Out Bound</th>
									<td class="text-center" mat-cell *matCellDef="let element">
										<div *ngIf="element.ob== true">Active</div>
										<div *ngIf="element.ob== false">InActive</div>
									</td>
								</ng-container>
								<ng-container matColumnDef="ooa">
									<th class="text-center" mat-header-cell *matHeaderCellDef
										sortActionDescription="Sort by ooa">OOA</th>
									<td class="text-center" mat-cell *matCellDef="let element">
										<div *ngIf="element.ooa== true">Active</div>
										<div *ngIf="element.ooa== false">InActive</div>
									</td>
								</ng-container>
								<ng-container matColumnDef="Active">
									<th mat-header-cell *matHeaderCellDef
										[style.display]="displaytoAccountant ? 'none' : 'table-cell'"> Action </th>
									<td mat-cell *matCellDef="let element">
										<div class="d-flex">
											<div role="group" class="btn-group-sm btn-group m-1">
												<button class="btn-shadow btn btn-primary" matTooltip="Edit"
													*ngIf="element.is_Active==true && !displaytoAccountant"
													(click)="viewchange1('edit',element)"><i
														class="lnr-pencil"></i></button>
											</div>
											<div *ngIf="!displaytoAccountant" role="group"
												class="btn-group-sm btn-group m-1">
												<span class="material-icons btn-shadow btn btn-danger"
													matTooltip="Delete" (click)="DeletePincodeNumber(element)"><i
														class="pe-7s-trash"></i></span>
											</div>
										</div>
									</td>
								</ng-container>
								<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
								<tr mat-row *matRowDef="let row; columns: displayedColumns;"
									[ngClass]="row.ooa == false && row.ib == false && row.ob == false? 'red':'white'">
								</tr>
							</table>
						</ng-container>
					</div>
					<mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
					</mat-paginator>
					<ng-template #noRecordsMessage>
						<h1 class="text-center fw-bold">No Records Available..!</h1>
					</ng-template>
				</div>
				<ngx-loading [show]="loading"
					[config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}"
					[template]="loadingTemplate"></ngx-loading>
			</div>
			<!-- </div> -->
		</some-element>

		<some-element *ngSwitchCase="'add'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						<b style="color: blue;">ADD PINCODE</b>
					</div>

				</div>

				<div class="container">
					<div class="row">
						<div class="col-md-6">
							<mat-form-field appearance="standard">
								<mat-label>
									Pincode <span class="text-danger">*</span>
								</mat-label>
								<input type="text" [(ngModel)]="editparams.pincode" placeholder="Pincode"
									onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="6"
									(input)="pincodeexist()" aria-label="Number" matInput>
								<mat-hint *ngIf="pinexist==true" class="text-danger fw-bold">Pincode Exist</mat-hint>
							</mat-form-field>
						</div>
						<div class="col-md-6">
							<mat-form-field appearance="standard">
								<mat-label>Country<span class="text-danger">*</span></mat-label>
								<mat-select matNativeControl [(ngModel)]="editparams.countryid">
									<mat-option>SELECT</mat-option>
									<mat-option (click)="getRecverStates()" *ngFor="let con of countries |async"
										[value]="con.id">
										{{con.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-md-6">
							<mat-form-field appearance="standard">
								<mat-label>State<span class="text-danger">*</span></mat-label>
								<mat-select matNativeControl [(ngModel)]="editparams.stateid">
									<mat-option>SELECT</mat-option>
									<mat-option *ngFor="let con of states1 |async" [value]="con.id"
										(click)="getBusinessCities()">
										{{con.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>

						</div>
						<div class="col-md-6">
							<mat-form-field appearance="standard">
								<mat-label>City<span class="text-danger">*</span></mat-label>
								<mat-select matNativeControl [(ngModel)]="editparams.cityid">
									<mat-option>SELECT</mat-option>
									<mat-option *ngFor="let con of business_cities |async" [value]="con.id">
										{{con.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>

						<div class="col-md-6">
							<mat-form-field appearance="standard">
								<mat-label>
									Area Name<span class="text-danger">*</span>
								</mat-label>
								<input type="text" [(ngModel)]="editparams.AreaName" placeholder="Area Name"
									aria-label="Number" matInput>

							</mat-form-field>
						</div>
						<div class="row">
							<div class="col-md-4">
								<h6><b>IB<span class="text-danger">*</span></b></h6>
								<mat-radio-group aria-label="Select IB" [(ngModel)]="edit.IB">
									<mat-radio-button value=1>Active</mat-radio-button>
									<br>
									<mat-radio-button value=0>In Active</mat-radio-button>
								</mat-radio-group>
							</div>
							<div class="col-md-4">
								<h6><b>OB<span class="text-danger">*</span></b></h6>
								<mat-radio-group aria-label="Select OB" [(ngModel)]="edit.OB">
									<mat-radio-button value=1>Active</mat-radio-button>
									<br>
									<mat-radio-button value=0>In Active</mat-radio-button>
								</mat-radio-group>
							</div>
							<div class="col-md-4">
								<h6><b>OOA<span class="text-danger">*</span></b></h6>
								<mat-radio-group aria-label="Select OOA" [(ngModel)]="edit.OOA">
									<mat-radio-button value=1>Active</mat-radio-button>
									<br>
									<mat-radio-button value=0>In Active</mat-radio-button>
								</mat-radio-group>
							</div>
						</div>
					</div>
				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table', 'cancel')">Cancel</div>
						<div class="mb-2 me-2 btn btn-primary" (click)="Createpincode()">Add</div>
					</div>
				</div>
			</div>
		</some-element>

		<some-element *ngSwitchCase="'edit'">
			<div class="card">
				<div class="card-header">

					<div class="card-header-title font-size-lg text-capitalize fw-normal">
						<b style="color: blue;">EDIT PINCODE DETAILS</b>
					</div>

				</div>

				<div class="container">
					<div class="row">
						<div class="col-md-6">
							<mat-form-field appearance="standard">
								<mat-label>
									Pincode<span class="text-danger">*</span>
								</mat-label>
								<input type="text" [(ngModel)]="editparams.pincode" placeholder="Pincode"
									onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="6"
									(input)=" pincodeexist2()" aria-label="Number" matInput disabled>
								<mat-hint *ngIf="pinexist2==true" class="text-danger fw-bold">Pincode Exist</mat-hint>
							</mat-form-field>
						</div>
						<div class="col-md-6">
							<mat-form-field appearance="standard">
								<mat-label>Country<span class="text-danger">*</span></mat-label>
								<mat-select matNativeControl [(ngModel)]="editparams.countryid" disabled>
									<mat-option>SELECT</mat-option>
									<mat-option *ngFor="let con of countries |async" [value]="con.id"
										(click)="getRecverStates()">
										{{con.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-md-6">
							<mat-form-field appearance="standard">
								<mat-label>State<span class="text-danger">*</span></mat-label>
								<mat-select matNativeControl [(ngModel)]="editparams.stateid" disabled>
									<mat-option>SELECT</mat-option>
									<mat-option *ngFor="let con of states1 |async" [value]="con.id"
										(click)="getReceverCities()">
										{{con.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>

						</div>
						<div class="col-md-6">
							<mat-form-field appearance="standard">
								<mat-label>City<span class="text-danger">*</span></mat-label>
								<mat-select matNativeControl [(ngModel)]="editparams.cityid" disabled>
									<mat-option>SELECT</mat-option>
									<mat-option *ngFor="let con of cities1 | async" [value]="con.id">
										{{con.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>

						<div class="col-md-6">
							<mat-form-field appearance="standard">
								<mat-label>
									Area Name<span class="text-danger">*</span>
								</mat-label>
								<input type="text" [(ngModel)]="editparams.AreaName" placeholder="Area Name"
									aria-label="Number" matInput>

							</mat-form-field>
						</div>

						<div class="row">
							<div class="col-md-4">
								<h6><b>IB</b><span class="text-danger">*</span></h6>
								<mat-radio-group aria-label="Select IB" [(ngModel)]="edit.IB">
									<mat-radio-button value=1>Active</mat-radio-button>
									<br>
									<mat-radio-button value=0>In Active</mat-radio-button>
								</mat-radio-group>
							</div>
							<div class="col-md-4">
								<h6><b>OB</b><span class="text-danger">*</span></h6>
								<mat-radio-group aria-label="Select OB" [(ngModel)]="edit.OB">
									<mat-radio-button value=1>Active</mat-radio-button>
									<br>
									<mat-radio-button value=0>In Active</mat-radio-button>
								</mat-radio-group>
							</div>
							<div class="col-md-4">
								<h6><b>OOA</b><span class="text-danger">*</span></h6>
								<mat-radio-group aria-label="Select OOA" [(ngModel)]="edit.OOA">
									<mat-radio-button value=1>Active</mat-radio-button>
									<br>
									<mat-radio-button value=0>In Active</mat-radio-button>
								</mat-radio-group>
							</div>
						</div>

					</div>
				</div>
				<div class="card-footer">
					<div class="btn-actions-pane-right p-2">
						<div class="mb-2 me-2 btn btn-danger" (click)="viewchange('table', 'cancel')">Cancel</div>
						<div class="mb-2 me-2 btn btn-primary" (click)="editpincode()">Save</div>
					</div>
				</div>
			</div>
		</some-element>
	</container-element>
</div>