import { PageEvent } from '@angular/material/paginator';
import { API_URL } from '../../../../../Global_API_URL';
import { getAirlines, getAirports } from '../../getAirportAirlines.js';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';
import { getCities } from '../../getCities.js';
import Swal from 'sweetalert2';
import { AuthService } from '../../../auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from '../../Components/confirmDialog/confirmDialog.component';


const PrimaryWhite = '#fff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#d92550';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-door-pricing',
  templateUrl: './door-pricing.component.html',
  styleUrls: ['./door-pricing.component.sass'],
})

export class DoorPricingComponent implements OnInit {
  airline;
  csvfile;
  airlinesList = [];
  pageview = 'table';
  filterParams = '';

  airports = getAirports();
  pagenation = {
    col_name: 'id',
    page_size: 20,
    page_no: 0,
    sort_dir: 'ASC',
  }
  city: any = '';

  doorcity = {
    id: null,
    city_id: null,
    inbound_min: null,
    inbound_perkg: null,
    oaa_inbound_min: null,
    oaa_inbound_perkg: null,
    oaa_outbound_min: null,
    oaa_outbound_perkg: null,
    outbound_min: null,
    outbound_perkg: null,
  }

  editDoorPricing = {
    id: null,
    city_id: null,
    inbound_min: null,
    inbound_perkg: null,
    oaa_inbound_min: null,
    oaa_inbound_perkg: null,
    oaa_outbound_min: null,
    oaa_outbound_perkg: null,
    outbound_min: null,
    outbound_perkg: null,
  }

  addDoorPricing = {
    id: null,
    city_id: null,
    inbound_min: null,
    inbound_perkg: null,
    oaa_inbound_min: null,
    oaa_inbound_perkg: null,
    oaa_outbound_min: null,
    oaa_outbound_perkg: null,
    outbound_min: null,
    outbound_perkg: null,
  }

  DoorPricingList = null;
  constructor(private authService: AuthService, private matDialog: MatDialog) {
    this.authService.getAuthToken();
  }

  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  public Loading = false;
  public primaryColour = SecondaryBlue;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public loadingTemplate: TemplateRef<any>;
  public config = {
    animationType: ngxLoadingAnimationTypes.none,
    primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour,
    tertiaryColour: this.primaryColour,
    backdropBorderRadius: '3px',
  };
  Citynames = getCities();
  async ngOnInit() {
    this.airlinesList = await getAirlines();
    this.DoorPricingList = this.getDoorPricingList();
    this.airports = getAirports();
    this.Citynames = await getCities();
  }

  getFiles(event) {
    this.csvfile = event.target.files[0];
  }

  viewchange(val, users) {
    this.addDoorPricing = {
      id: null,
      city_id: null,
      inbound_min: '',
      inbound_perkg: '',
      oaa_inbound_min: '',
      oaa_inbound_perkg: '',
      oaa_outbound_min: '',
      oaa_outbound_perkg: '',
      outbound_min: '',
      outbound_perkg: '',
    };

    // console.log(users,"users")
    this.getDoorPricingList();
    this.editDoorPricing = users;
    this.doorcity = users;
    //  console.log("this is user details"+this.doorcity.city_id.id)
    this.pageview = val;
  }
  viewchange1(val) {
    this.DoorPricingList = this.getDoorPricingList();
    this.pageview = val;
  }

  // async uploadDocument() {
  //   const TOKEN = this.authService.getAuthToken();
  //   if (this.csvfile == null) {
  //     alert('uplad later');
  //   } else {
  //     this.Loading = true;
  //     const head: any = {
  //       'Authorization': 'Bearer ' + TOKEN,
  //       'Accept': 'application/json',
  //     };
  //     const formdata = new FormData();
  //     formdata.append('file', this.csvfile);
  //     // console.log("file data --- "+formdata)

  //     await fetch(API_URL + '/bulkUpload/uploadDoorCharges',
  //       {
  //         method: 'POST',
  //         body: formdata,
  //         headers: head,
  //       })
  //       .then((response) => {
  //         // let msg = response.statusText;
  //         this.Loading = false;
  //         if (response.status == 200) {
  //           alert('File Uploaded');
  //         } else {
  //           alert('File Miss Match ');
  //         }
  //       })
  //       .catch((error) => {
  //         this.Loading = false;
  //         console.error('Error', error);
  //         alert(error);
  //       });
  //   }
  // }

  async uploadDocument() {
    const TOKEN = this.authService.getAuthToken();
    if (this.csvfile == null) {
      Swal.fire({
        icon: 'warning',
        title: 'No File Selected',
        text: 'Please upload a file later.',
      });
    } else {
      this.Loading = true;
      const head: any = {
        'Authorization': 'Bearer ' + TOKEN,
        'Accept': 'application/json',
      };
      const formdata = new FormData();
      formdata.append('file', this.csvfile);

      try {
        const response = await fetch(API_URL + '/bulkUpload/uploadDoorCharges', {
          method: 'POST',
          body: formdata,
          headers: head,
        });

        this.Loading = false;
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'File Uploaded',
            text: 'Your file has been successfully uploaded.',
          });
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Upload Failed',
            text: 'There was a file mismatch. Please try again.',
          });
        }
      } catch (error) {
        this.Loading = false;
        console.error('Error', error);
        Swal.fire({
          icon: 'error',
          title: 'Upload Error',
          text: 'An error occurred while uploading the file. Please try again later.',
        });
      }
    }
  }


  async reset() {
    this.city = '';

    this.getDoorPricingList();
  }

  pageSizeOptions = [20, 40, 60];
  tablefilter() {
    this.pagenation.page_no = 0;
    this.pagenation.sort_dir = 'ASC';
    this.getDoorPricingList();
  }
  // origin="";
  // Destination = "";
  // Airline = "";
  filter() {
    this.filterParams = '?';
    if (this.city != '') {
      this.filterParams = this.filterParams + 'city=' + this.city + '&';
    }
    // if(this.Destination!=""){
    //   this.filterParams= this.filterParams+"Destination="+this.Destination+"&"
    // }
    // if(this.Airline!=""){
    //   this.filterParams= this.filterParams+"Airline="+this.Airline+"&"
    // }
    this.filterParams = this.filterParams + 'page_no=' + this.pagenation.page_no + '&page_size=' + this.pagenation.page_size + '&col_name=' + this.pagenation.col_name + '&sort_dir=' + this.pagenation.sort_dir;
    return this.filterParams;
  }
  handlePageEvent(event: PageEvent) {
    this.pagenation.page_no = event.pageIndex;
    this.pagenation.page_size = event.pageSize;
    this.getDoorPricingList();
  }
  async getDoorPricingList() {
    const TOKEN = this.authService.getAuthToken();
    this.DoorPricingList = [];
    // console.log(API_URL)
    const api = '/getdoorCharges';
    const params = this.filter();
    await fetch(API_URL + api + params, {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + TOKEN,
      },
    })
      // Converting received data to JSON
      .then((response) => response.json())
      .then((json) => {
        this.DoorPricingList = json;
      });
    return this.DoorPricingList;
  }

  async Deletedata(id) {
    const TOKEN = this.authService.getAuthToken();
    // console.log(id ,"Delete")

    // console.log(id)
    // const data = JSON.stringify({
    //   'is_active': 0,
    // });
    await fetch(API_URL + '/deleteDoorCharges/' + id,
      {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
        // body: data,
      })

      // Converting received data to JSON
      // .then(response => response.text())
      .then((response) => {
        if (response.ok) {
          // Display success message using Swal fire
          // Swal.fire({
          //   position: 'center',
          //   icon: 'success',
          //   title: 'Deleted successfully',
          //   // showConfirmButton: false,
          //   timer: 3000
          // });
          // Refresh the door pricing list after successful deletion
          this.getDoorPricingList();
        } else {
          // Handle non-successful response here if needed
          console.error('Failed to delete data:', response.statusText);
        }
      })
      .catch((error) => console.error('Error deleting data:', error));
    // .then(result => console.log(result))
    // this.getDoorPricingList()
    // .catch(error => console.error('error', error));
  }

  DeleteDoorPricing(doorPricing) {
    const dialogRef = this.matDialog.open(ConfirmDialog, {
      width: '25rem',
      data: 'Are you sure you want to delete ' + doorPricing?.city_id?.name + ' ?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.Deletedata(doorPricing.id);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Deleted successfully',
          // showConfirmButton: false,
          timer: 3000,
        });
      }
    });
  }

  displayedColumns = ['City', 'Inbound_min', 'Inbound_perkg', 'OAA_inbound_min', 'OAA_inbound_perkg', 'OAA_outbound_min', 'OAA_outbound_perkg', 'Outbound_min', 'Outbound_perkg', 'Active']

  async Restoredata(id) {
    const TOKEN = this.authService.getAuthToken();
    // console.log(id ,"restore")
    // console.log(id)
    const data = JSON.stringify({
      'is_active': 1,
    });
    await fetch(API_URL + '/updatedoorCharges/isActive/' + id,
      {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: data,
      })

      // Converting received data to JSON
      .then((response) => response.text());
    // .then(result => console.log(result))
    this.getDoorPricingList()
      .catch((error) => console.error('error', error));
  }

  // async edituser() {
  //   const TOKEN = this.authService.getAuthToken();
  //   const editDoorPricing = {
  //     'id': this.editDoorPricing.id,
  //     'city_id': this.editDoorPricing.city_id.id,
  //     'inbound_min': this.editDoorPricing.inbound_min,
  //     'inbound_perkg': this.editDoorPricing.inbound_perkg,
  //     'oaa_inbound_min': this.editDoorPricing.oaa_inbound_min,
  //     'oaa_inbound_perkg': this.editDoorPricing.oaa_inbound_perkg,
  //     'oaa_outbound_min': this.editDoorPricing.oaa_outbound_min,
  //     'oaa_outbound_perkg': this.editDoorPricing.oaa_outbound_perkg,
  //     'outbound_min': this.editDoorPricing.outbound_min,
  //     'outbound_perkg': this.editDoorPricing.outbound_perkg,
  //     'is_active': true,

  //   };
  //   // console.log("this is city"+this.user_id.city_id.id)

  //   // console.log("Given Users"+users);
  //   if (editDoorPricing.city_id == null || editDoorPricing.city_id === "") {
  //     Swal.fire({
  //       icon: 'warning',
  //       title: 'WARNING',
  //       text: 'City is Missing',
  //       timer: 5000,
  //     });
  //     this.Loading = false;
  //   }
    
  //   await fetch(API_URL + '/updatedoorCharges/' + this.doorcity.id, {
  //     method: 'PATCH',
  //     body: JSON.stringify(editDoorPricing),
  //     headers: {
  //       'Authorization': 'Bearer ' + TOKEN,
  //       'Content-Type': 'application/json',
  //       'Accept': 'application/json',
  //     },
  //   })
  //   .then(async (response) => {
  //     if (!response.ok) {
  //       // If the response is not ok, it means there was an error.
  //       const errorData = await response.json(); // Parse the error message
  //       throw new Error(errorData.message || 'Something went wrong');
  //     }
  //     return response.json(); // Parse the success response
  //   })
  //     .then((result) => {
  //       // console.log(result)
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'success',
  //         title: 'Updated successfully',
  //         showConfirmButton: false,
  //         timer: 5000,
  //       });
  //       this.viewchange('table', this.editDoorPricing.id);
  //       this.getDoorPricingList();
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'error',
  //         title: 'Update failed',
  //         text: error.message, // Display the error message from the backend
  //         showConfirmButton: true,
  //       });
  //     });
  // }

  // async adduser() {
  //   const TOKEN = this.authService.getAuthToken();
  //   const users1 = {
  //     'id': this.add_user.id,
  //     'city_id': this.add_user.city_id,
  //     'inbound_min': this.add_user.inbound_min,
  //     'inbound_perkg': this.add_user.inbound_perkg,
  //     'oaa_inbound_min': this.add_user.oaa_inbound_min,
  //     'oaa_inbound_perkg': this.add_user.oaa_inbound_perkg,
  //     'oaa_outbound_min': this.add_user.oaa_outbound_min,
  //     'oaa_outbound_perkg': this.add_user.oaa_outbound_perkg,
  //     'outbound_min': this.add_user.outbound_min,
  //     'outbound_perkg': this.add_user.outbound_perkg,
  //     'is_active': true,
  //   };
  //   // console.log("this is city"+this.add_user.city_id)
  //   const existingRecord = await fetch(API_URL + '/doorpricingByCityId/' + users1.city_id, {
  //     method: 'GET',
  //     headers: {
  //       'Authorization': 'Bearer ' + TOKEN,
  //       'Content-Type': 'application/json',
  //       'Accept': 'application/json',
  //     },
  //   })
  //     .then((response) => response.json())
  //     .catch((error) => {
  //       console.error('error', error);
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'ERROR',
  //         text: 'An error occurred while checking for existing records',
  //         timer: 5000,

  //       });
  //     });

  //   if (existingRecord && existingRecord.exists) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'ERROR',
  //       text: 'A record with this city name already exists',
  //       timer: 5000,

  //     });
  //     this.Loading = false;
  //     return;
  //   } else if (users1.city_id == null || users1.city_id == "") {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'ERROR',
  //       text: 'City is Missing',
  //       timer: 5000,

  //     });
  //     this.Loading = false;
  //   } else if (users1.inbound_min == null || users1.inbound_min == "") {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'ERROR',
  //       text: 'Inbound Min is Missing',
  //       timer: 5000,

  //     });
  //     this.Loading = false;
  //   } else if (users1.inbound_perkg == null || users1.inbound_perkg == "") {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'ERROR',
  //       text: 'Inbound PerKG is Missing',
  //       timer: 5000,

  //     });
  //     this.Loading = false;
  //   } else if (users1.oaa_inbound_min == null || users1.oaa_inbound_min == "") {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'ERROR',
  //       text: 'OOA Inbound Min is Missing',
  //       timer: 5000,

  //     });
  //     this.Loading = false;
  //   } else if (users1.oaa_inbound_perkg == null || users1.oaa_inbound_perkg == "") {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'ERROR',
  //       text: 'OOA Inbound PerKG is Missing',
  //       timer: 5000,

  //     });
  //     this.Loading = false;
  //   } else if (users1.oaa_outbound_min == null || users1.oaa_outbound_min == "") {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'ERROR',
  //       text: 'OOA Outbound Min is Missing',
  //       timer: 5000,

  //     });
  //     this.Loading = false;
  //   } else if (users1.oaa_outbound_perkg == null || users1.oaa_outbound_perkg == "") {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'ERROR',
  //       text: 'OOA Outbound PerKG is Missing',
  //       timer: 5000,

  //     });
  //     this.Loading = false;
  //   } else if (users1.outbound_min == null || users1.outbound_min == "") {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'ERROR',
  //       text: 'Outbound Min is Missing',
  //       timer: 5000,

  //     });
  //     this.Loading = false;
  //   } else if (users1.outbound_perkg == null || users1.outbound_perkg == "") {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'ERROR',
  //       text: 'Outbound PerKG is Missing',
  //       timer: 5000,

  //     });
  //     this.Loading = false;
  //   }
  //   // console.log("Given Users"+users);
  //   else {
  //     await fetch(API_URL + '/postdoorCharges', {
  //       method: 'POST',
  //       body: JSON.stringify(users1),
  //       headers: {
  //         'Authorization': 'Bearer ' + TOKEN,
  //         'Content-Type': 'application/json',
  //         'Accept': 'application/json',
  //       },
  //     })
  //       .then((response) => response.json())
  //       .then((result) => {
  //         // console.log(result)
  //         Swal.fire({
  //           position: 'center',
  //           icon: 'success',
  //           title: 'Created successfully',
  //           showConfirmButton: false,
  //           timer: 5000,
  //         });
  //         this.viewchange("table", null)
  //         this.getDoorPricingList();
  //       })
  //       .catch((error) => console.error('error', error.message));
  //   }
  // }

  async createDoorPricing() {
    const TOKEN = this.authService.getAuthToken();
    const createDoorPricing = {
      'id': this.addDoorPricing.id,
      'city_id': this.addDoorPricing.city_id,
      'inbound_min': this.addDoorPricing.inbound_min,
      'inbound_perkg': this.addDoorPricing.inbound_perkg,
      'oaa_inbound_min': this.addDoorPricing.oaa_inbound_min,
      'oaa_inbound_perkg': this.addDoorPricing.oaa_inbound_perkg,
      'oaa_outbound_min': this.addDoorPricing.oaa_outbound_min,
      'oaa_outbound_perkg': this.addDoorPricing.oaa_outbound_perkg,
      'outbound_min': this.addDoorPricing.outbound_min,
      'outbound_perkg': this.addDoorPricing.outbound_perkg,
      'is_active': true,
    };
    if (createDoorPricing.city_id == null || createDoorPricing.city_id === "") {
      Swal.fire({
        icon: 'warning',
        title: 'WARNING',
        text: 'City is Missing',
        timer: 5000,
      });
      this.Loading = false;
    } else{
      try {
        const existingRecord = await fetch(API_URL + '/doorpricingByCityId/' + createDoorPricing.city_id, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });

        if (!existingRecord.ok) {
          const errorResponse = await existingRecord.json();
          throw new Error(errorResponse.message || 'An error occurred while checking for existing records');
        }

        const record = await existingRecord.json();

        if (record && record.exists) {
          Swal.fire({
            icon: 'warning',
            title: 'WARNING',
            text: 'A record with this city name already exists',
            timer: 5000,
          });
          this.Loading = false;
          return;
        }

        /*if (createDoorPricing.city_id == null || createDoorPricing.city_id === "") {
          Swal.fire({
            icon: 'warning',
            title: 'WARNING',
            text: 'City is Missing',
            timer: 5000,
          });
          this.Loading = false;
        } else*/ if (createDoorPricing.inbound_min == null || createDoorPricing.inbound_min === "") {
          Swal.fire({
            icon: 'warning',
            title: 'WARNING',
            text: 'Inbound Min is Missing',
            timer: 5000,
          });
          this.Loading = false;
        } else if (createDoorPricing.inbound_perkg == null || createDoorPricing.inbound_perkg === "") {
          Swal.fire({
            icon: 'warning',
            title: 'WARNING',
            text: 'Inbound PerKG is Missing',
            timer: 5000,
          });
          this.Loading = false;
        } else if (createDoorPricing.oaa_inbound_min == null || createDoorPricing.oaa_inbound_min === "") {
          Swal.fire({
            icon: 'warning',
            title: 'WARNING',
            text: 'OOA Inbound Min is Missing',
            timer: 5000,
          });
          this.Loading = false;
        } else if (createDoorPricing.oaa_inbound_perkg == null || createDoorPricing.oaa_inbound_perkg === "") {
          Swal.fire({
            icon: 'warning',
            title: 'WARNING',
            text: 'OOA Inbound PerKG is Missing',
            timer: 5000,
          });
          this.Loading = false;
        } else if (createDoorPricing.oaa_outbound_min == null || createDoorPricing.oaa_outbound_min === "") {
          Swal.fire({
            icon: 'warning',
            title: 'WARNING',
            text: 'OOA Outbound Min is Missing',
            timer: 5000,
          });
          this.Loading = false;
        } else if (createDoorPricing.oaa_outbound_perkg == null || createDoorPricing.oaa_outbound_perkg === "") {
          Swal.fire({
            icon: 'warning',
            title: 'WARNING',
            text: 'OOA Outbound PerKG is Missing',
            timer: 5000,
          });
          this.Loading = false;
        } else if (createDoorPricing.outbound_min == null || createDoorPricing.outbound_min === "") {
          Swal.fire({
            icon: 'warning',
            title: 'WARNING',
            text: 'Outbound Min is Missing',
            timer: 5000,
          });
          this.Loading = false;
        } else if (createDoorPricing.outbound_perkg == null || createDoorPricing.outbound_perkg === "") {
          Swal.fire({
            icon: 'warning',
            title: 'WARNING',
            text: 'Outbound PerKG is Missing',
            timer: 5000,
          });
          this.Loading = false;
        } else {
          const response = await fetch(API_URL + '/postdoorCharges', {
            method: 'POST',
            body: JSON.stringify(createDoorPricing),
            headers: {
              'Authorization': 'Bearer ' + TOKEN,
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            },
          });

          if (!response.ok) {
            const errorResponse = await response.json();
            throw new Error(errorResponse.message || 'An error occurred while creating the record');
          }

          const result = await response.json();

          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Created successfully',
            showConfirmButton: false,
            timer: 5000,
          });
          this.viewchange("table", null);
          this.getDoorPricingList();
        }
      } catch (error) {
        Swal.fire({
          icon: 'warning',
          title: 'WARNING',
          text: error.message,
          timer: 5000,
        });
        console.error('error', error.message);
      } finally {
        this.Loading = false;
      }
    }

  }

  async updateDoorPricing() {
    const TOKEN = this.authService.getAuthToken();
    const editDoorPricing = {
      'id': this.editDoorPricing.id,
      'city_id': this.editDoorPricing.city_id?.id,
      'inbound_min': this.editDoorPricing.inbound_min,
      'inbound_perkg': this.editDoorPricing.inbound_perkg,
      'oaa_inbound_min': this.editDoorPricing.oaa_inbound_min,
      'oaa_inbound_perkg': this.editDoorPricing.oaa_inbound_perkg,
      'oaa_outbound_min': this.editDoorPricing.oaa_outbound_min,
      'oaa_outbound_perkg': this.editDoorPricing.oaa_outbound_perkg,
      'outbound_min': this.editDoorPricing.outbound_min,
      'outbound_perkg': this.editDoorPricing.outbound_perkg,
      'is_active': true,
    };
  
    // Validate fields
    if (!editDoorPricing.city_id) {
      Swal.fire('Warning', 'City ID is required', 'warning');
      return;
    } else if (!editDoorPricing.inbound_min) {
      Swal.fire('Warning', 'Inbound Min is required', 'warning');
      return;
    } else if (!editDoorPricing.inbound_perkg) {
      Swal.fire('Warning', 'Inbound Per KG is required', 'warning');
      return;
    } else if (!editDoorPricing.oaa_inbound_min) {
      Swal.fire('Warning', 'OAA Inbound Min is required', 'warning');
      return;
    } else if (!editDoorPricing.oaa_inbound_perkg) {
      Swal.fire('Warning', 'OAA Inbound Per KG is required', 'warning');
      return;
    } else if (!editDoorPricing.oaa_outbound_min) {
      Swal.fire('Warning', 'OAA Outbound Min is required', 'warning');
      return;
    } else if (!editDoorPricing.oaa_outbound_perkg) {
      Swal.fire('Warning', 'OAA Outbound Per KG is required', 'warning');
      return;
    } else if (!editDoorPricing.outbound_min) {
      Swal.fire('Warning', 'Outbound Min is required', 'warning');
      return;
    } else if (!editDoorPricing.outbound_perkg) {
      Swal.fire('Warning', 'Outbound Per KG is required', 'warning');
      return;
    }
  
    // If all fields are valid, hit the API
    await fetch(API_URL + '/updatedoorCharges/' + this.doorcity.id, {
      method: 'PATCH',
      body: JSON.stringify(editDoorPricing),
      headers: {
        'Authorization': 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((result) => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Updated successfully',
          showConfirmButton: false,
          timer: 5000,
        });
        this.viewchange('table', this.editDoorPricing.id);
        this.getDoorPricingList();
      })
      .catch((error) => console.error('error', error));
  }

}
